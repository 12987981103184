import React, { useState } from 'react';
import Logo from '../logo/logo.jsx';
import styles from './signedOutNavStyles.module.css';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { useLocation } from 'react-router-dom';

function SignedOutNav(props) {
    const [click, setClick] = useState(false);
    const [navbar, setNavbar] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const location = useLocation();

    const handleNavbarScroll = () => {
        if (window.scrollY >= 10) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    window.addEventListener('scroll', handleNavbarScroll);

    return (
        <>
            <IconContext.Provider value={{ color: '#000' }}>
                <div className={navbar ? styles.navbar + ' ' + styles.active : styles.navbar}>
                    <div className={styles.navbarContainer + ' ' + styles.container}>
                        <Logo onClick={closeMobileMenu} />
                        <div className={styles.menuIcon} onClick={handleClick}>
                            {click ? <FaTimes /> : <FaBars />}
                        </div>
                        <ul className={click ? styles.navMenu + ' ' + styles.active : styles.navMenu}>
                            {(location.pathname !== '/') &&
                            <li className={styles.navItem}>
                                <Link to='/' 
                                    className={styles.navLinks} 
                                    style={{ color: 'var(--primary-color)' }} 
                                    onClick={closeMobileMenu}
                                >
                                    Home
                                </Link>
                            </li>
                            }
                            <li className={styles.navItem}>
                                <Link to='/signup' 
                                    className={styles.navLinks} 
                                    style={{ color: 'var(--secondary-color)' }} 
                                    onClick={closeMobileMenu}
                                >
                                    Join Now
                                </Link>
                            </li>
                            <li className={styles.navItem}>
                                <Link to='/signin' className={styles.navLinks} onClick={closeMobileMenu}>
                                    Sign In
                                </Link>
                            </li>
                            <li className={styles.navItem}>
                                <Link to='/faqs' className={styles.navLinks} onClick={closeMobileMenu}>
                                    FAQs
                                </Link>
                            </li>
                        </ul>
                    </div>

                </div>
            </IconContext.Provider>
        </>
    )
}

export default SignedOutNav
