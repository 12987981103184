import React, { Component } from 'react'
import { Close, DeleteOutline, Image, Save, Send, VideoCall } from '@mui/icons-material';
import { Autocomplete, Box, Button, Chip, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import _config from "../signup/config";
import ClipLoader from "react-spinners/ClipLoader";
import SemanticSearchCard from '../components/SemanticSearchCard/SemanticSearchCard';
import { keywords1, keywords2, keywords3 } from '../components/KeywordsStore/KeywordsStore';
import styles from './CreatePaperFormStyles.module.css'
import Popup from '../components/popup/popup';
import axios from 'axios';

import RequestService from "../requestservice/requestservice";

import { PaperImageUploader } from '../components/file-uploader';
import crypto from 'crypto-browserify';

const randomBytes = crypto.randomBytes;

const keywordsArray = [...keywords1, ...keywords2, ...keywords3];

function toUrlString(buffer) {
  return buffer.toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

export default class CreatePaperForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      semanticSearch: '',
      semanticItems: [],
      usingSemantic: false,
      searchingSemantic: false,
      isSemanticLoaded: false,
      isSemanticSelected: false,
      paperId: this.props.paperId ? this.props.paperId : toUrlString(randomBytes(16)),
      userId: this.props.userId,
      semanticPaperId: '',
      chatGptMainFinding: '',
      title: '',
      authors: [],
      year: '',
      paperUrl: '',
      keywords: [],
      abstract: '',
      tldr: '',
      videoLink: '',
      imageFile: null,
      paperImageUrl: '',
      discardPopup: false,
      isEditing: this.props.paperId ? true : false,
      loadingEditPaper: true,
      allRequiredComplete: true, // assume true to start
    }

    const poolData = {
      UserPoolId: _config.cognito.userPoolId,
      ClientId: _config.cognito.userPoolClientId,
    };
    this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    this.getPaperAPI = this.getPaperAPI.bind(this);
    this.handleTextFieldChange = this.handleTextFieldChange.bind(this);
    this.semanticSubmit = this.semanticSubmit.bind(this);
    this.handleSemanticSelect = this.handleSemanticSelect.bind(this);
    this.handleSemanticSearchClose = this.handleSemanticSearchClose.bind(this);
    this.handleAuthorChange = this.handleAuthorChange.bind(this);
    this.handleKeywordsChange = this.handleKeywordsChange.bind(this);
    this.handlePaperImageChange = this.handlePaperImageChange.bind(this);
    this.handlePaperImageURLChange = this.handlePaperImageURLChange.bind(this);
    this.setDiscardPopup = this.setDiscardPopup.bind(this);
    this.submitPaperForm = this.submitPaperForm.bind(this);
    this.requestService = new RequestService();
  }

  componentDidMount() {
    if (this.props.paperId) {
      this.getPaperAPI(this.props.paperId); // need to double check something is wrong here
    }
  }

  async getPaperAPI(paperId) {
    const data = { paperId: paperId };
    const gettingPaper = await this.requestService.getPaperAPI(data, this, () => this.setState({ loadingEditPaper: false }));
  }

  handleTextFieldChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  handleSemanticSelect(paperId) {
    let self = this;
    console.log('this.state.semanticItems');
    console.log(this.state.semanticItems);
    let semanticPaperId;
    this.state.semanticItems.data
      .filter(i => i.paperId === paperId)
      .map((item) => {
        const authors = item.authors.map(a => a.name);
        semanticPaperId = item.paperId;
        self.setState({
          semanticPaperId: item.paperId,
          title: item.title,
          authors,
          paperUrl: item.url,
          keywords: item.fieldsOfStudy,
          abstract: item.abstract,
          isSemanticSelected: true,
          year: item.year.toString(),
          chatGptMainFinding: item.tldr ? item.tldr.text : null,
        });
      });
  }

  semanticSubmit(e) {
    e.preventDefault();
    this.setState({ isSemanticLoaded: false, searchingSemantic: true, usingSemantic: true });

    const query = this.state.semanticSearch;
    const SEMANTIC_API = `https://api.semanticscholar.org/graph/v1/paper/search?query=${query}&offset=10&limit=10&fields=title,authors,url,fieldsOfStudy,abstract,year,tldr`;

    fetch(SEMANTIC_API)
      .then(res => res.json())
      .then(json => {
        this.setState({
          isSemanticLoaded: true,
          searchingSemantic: false,
          semanticItems: json,
        })
      })
  }

  searchSemanticPapers(e) {
    if (e?.key === 'Enter') {
      this.semanticSubmit(e);
    }
  }

  handleSemanticSearchClose() {
    this.setState({
      usingSemantic: false,
      searchingSemantic: false,
      isSemanticLoaded: false
    });
  }

  handleAuthorChange(text) {
    this.setState({ authors: text });
  }

  handleKeywordsChange(text) {
    this.setState({ keywords: text });
  }

  setDiscardPopup(bool) {
    this.setState({ discardPopup: bool });
  }

  handlePaperImageChange(image) {
    this.setState({ imageFile: image });
  }

  handlePaperImageURLChange(imageURL) {
    this.setState({ paperImageUrl: imageURL });
  }

  async submitPaperForm(e) {
    e.preventDefault();
    const { title, authors, year, paperUrl, tldr } = this.state;
    this.setState({ allRequiredComplete: true }); // assume innocent until proven guilty
    var onSuccess = function registerSuccess(result) {
      var paperId = result.data.paperId;
      console.log('paper id is ' + paperId);
      var confirmation = ('paper id created!');
      if (confirmation) {
        console.log('Going to paper');
        window.location.href = `/paper/${paperId}`;
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };

    const data = {
      "paperId": this.state.paperId,
      "userId": this.state.userId,
      'paperUrl': this.state.paperUrl,
      "title": this.state.title,
      "authors": this.state.authors,
      "chatGptMainFinding": this.state.chatGptMainFinding,
      "semanticPaperId": this.state.semanticPaperId,
      "year": this.state.year,
      "tldr": this.state.tldr,
      "keywords": this.state.keywords,
      "videoLink": this.state.videoLink,
      "paperImageUrl": this.state.paperImageUrl,
    };
    var self = this;
    if (title && authors[0] && tldr) {
      self.props.closeCreatePaperPopup();
      var cognitoUser = self.userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession(function sessionCallback(err, session) {
          if (err) {
            console.log(err);
          } else if (!session.isValid()) {
            console.log('invalid sess');
          } else {
            const authToken = session.getIdToken().getJwtToken();
            const api = _config.api.invokeUrl + '/createpaper';
            const axiosConfig = {
              headers: {
                "Authorization": authToken,
              }
            };
            axios
              .post(api, data, axiosConfig)
              .then((response) => {
                console.log(response);
                onSuccess(response);
              })
              .catch((error) => {
                console.log(error);
                onFailure(error);
              });
          }
        });
      }
    } else {
      self.setState({ allRequiredComplete: false });
    }
  }

  render() {
    const { semanticSearch, usingSemantic, searchingSemantic, isSemanticLoaded,
      isSemanticSelected, isEditing, loadingEditPaper, semanticItems, paperId,
      year, title, authors, paperUrl, keywords, tldr, videoLink, imageFile, paperImageUrl,
      discardPopup, allRequiredComplete, } = this.state;
    return (
      <div className={styles.CreatePaperForm}>
        <div className={styles.paperDetailsTitle}>Paper details</div>
        <form onSubmit={(e) => this.submitPaperForm(e)}>
          <div className={styles.SemanticScholarFind}>
            {!isEditing ?
              <Box sx={{ display: 'flex', alignItems: 'end' }}>
                <Box sx={{ width: '-webkit-fill-available' }}>
                  Find on Semantic Scholar
                  <TextField
                    id='semantic-search'
                    label="Search by keywords"
                    variant='standard'
                    name='semanticSearch'
                    autoFocus
                    fullWidth
                    multiline
                    onChange={this.handleTextFieldChange}
                    onKeyDown={(e) => this.searchSemanticPapers(e)}
                    value={semanticSearch}
                    margin='normal'
                  />
                </Box>
                <Button className={styles.semanticScholarButton} sx={{
                  background: '#006F82',
                  mb: '8px',
                  ml: '16px',
                  color: 'white',
                  ':hover': {
                    background: 'var(--primary-color)'
                  }
                }} onClick={this.semanticSubmit}>Search</Button>
              </Box>
              :
              <div>
                {loadingEditPaper &&
                  <div style={{ color: 'var(--primary-color)', fontWeight: '600' }}>
                    Loading paper information...
                  </div>}
              </div>
            }
            {usingSemantic && searchingSemantic &&
              <div className={styles.LoadingDiv}>
                <h4 style={{ margin: '8px' }}>Searching Semantic Scholar...</h4>
                <ClipLoader
                  color={"var(--primary-color)"}
                  loading={!isSemanticLoaded}
                  size={50}
                />
              </div>
            }
            {isSemanticLoaded && (semanticItems.total > 0) ?
              <div>
                <div className={styles.SearchResultsTitle}>
                  Top 10 Results (click to select):
                  <Tooltip title='Close'>
                    <IconButton onClick={this.handleSemanticSearchClose}>
                      <Close />
                    </IconButton>
                  </Tooltip>
                </div>

                {semanticItems.data.map(item =>
                  <SemanticSearchCard
                    key={item.paperId}
                    paperId={item.paperId}
                    title={item.title}
                    authors={item.authors}
                    year={item.year}
                    handleSemanticSelect={this.handleSemanticSelect}
                    handleSemanticSearchClose={this.handleSemanticSearchClose}
                  />)}
              </div>
              :
              <div className={styles.SearchResultsTitle}>
                {isSemanticLoaded && 'No results found on Semantic Scholar. Please try again or enter your paper manually.'}
              </div>
            }
            <div className={styles.PaperDetails}>
              <TextField
                id='title'
                label="Paper Title"
                variant='standard'
                InputLabelProps={{ shrink: true }}
                name='title'
                fullWidth
                multiline
                onChange={this.handleTextFieldChange}
                value={title}
                margin='normal'
                required
                error={!allRequiredComplete && !title}
              />
              <Autocomplete
                multiple
                id="authors"
                options={authors.map((option) => option)}
                fullWidth
                value={authors}
                onChange={(author, text) => this.handleAuthorChange(text)}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin='normal'
                    InputLabelProps={{ shrink: true, required: true }}
                    variant="standard"
                    label="Author(s)"
                    placeholder={'Type author name and press enter'}
                    error={!allRequiredComplete && !authors[0]}
                  />
                )}
              />
              <TextField
                id='year'
                label='Year of Publication'
                name='year'
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='standard'
                margin='normal'
                placeholder='Enter the publication year'
                onChange={this.handleTextFieldChange}
                value={year}
                error={!allRequiredComplete && !year}
              />
              <TextField
                // type='url'
                id='paper-url'
                name='paperUrl'
                label='Paper URL'
                fullWidth
                variant='standard'
                margin='normal'
                InputLabelProps={{ shrink: true }}
                placeholder='Copy and paste a link to the paper'
                onChange={this.handleTextFieldChange}
                value={paperUrl}
                error={!allRequiredComplete && !paperUrl}
                helperText={paperUrl}
              />
              <Autocomplete
                multiple
                id="keywords"
                options={keywordsArray.map((option) => option)}
                fullWidth
                value={keywords}
                onChange={(event, text) => this.handleKeywordsChange(text)}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin='normal'
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    label="Keywords (recommended)"
                    placeholder={'Search for keywords or add your own using enter/return'}
                  />
                )}
              />
            </div>
            <TextField
              id='tldr'
              label="Summary"
              variant='standard'
              name='tldr'
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
              placeholder='Tell others what you find interesting about the paper'
              onChange={this.handleTextFieldChange}
              value={tldr}
              margin='normal'
              required
              error={!allRequiredComplete && !tldr}
            />
            <TextField
              id='video-link'
              label="Video link"
              variant='standard'
              name='videoLink'
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
              placeholder='YouTube, etc.'
              onChange={this.handleTextFieldChange}
              value={videoLink}
              margin='normal'
              sx={{ pb: '36px' }}
            />
            {paperId &&
              <div>
                {imageFile &&
                  <p style={{ fontSize: '12px', marginBottom: '4px' }}>
                    Uploaded file: <span style={{ color: 'green' }}>{imageFile.name}</span>
                  </p>
                }
                <PaperImageUploader
                  onFileSelect={this.handlePaperImageChange}
                  paperId={paperId}
                  onS3Upload={this.handlePaperImageURLChange}
                  thumbnailURL={paperImageUrl}
                  userPool={this.userPool}
                />
              </div>}
            <Box sx={{ color: '#0288D1' }}>
              *required fields
            </Box>
          </div>
          <div className={styles.submitDivCreatePaper}>
            <Button
              type="button"
              variant='contained'
              onClick={() => this.setDiscardPopup(true)}
              sx={{
                background: 'white',
                border: '2px solid #FEBA3F',
                ":hover": {
                  bgcolor: '#eeeeee',
                }
              }}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ color: '#1B065E', ml: '12px', }}>
              {isEditing ? 'Save' : 'Post'}
            </Button>
          </div>
          <Popup
            trigger={this.state.discardPopup}
            setTrigger={this.setDiscardPopup}
          >
            <Typography variant='h3'>Discard your entry?</Typography>
            <div className={styles.popupButtonDiv}>
              <Button
                type="button"
                variant='contained'
                className={styles.goBackButton}
                onClick={() => this.setDiscardPopup(false)}
                sx={{
                  background: 'white',
                  border: '2px solid #FEBA3F',
                  ":hover": {
                    bgcolor: '#eeeeee',
                  }
                }}>Cancel</Button>
              <Button
                variant="contained"
                className={styles.discardPopupButton}
                sx={{ color: '#1B065E', ml: '12px', }}
                onClick={this.props.closeCreatePaperPopup}
              >
                Discard
              </Button>

            </div>
          </Popup>
        </form >
      </div >
    )
  }
}
