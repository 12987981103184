import React, { useState, useEffect } from 'react'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import _config from "../signup/config";
import axios from 'axios';
import banner from '../../images/symposia_stock_03-compressed.jpg'
import emailjs from '@emailjs/browser';
import styles from './FeedbackStyles.module.css'
import { Button, TextField, Typography, useTheme } from '@mui/material';

function Feedback() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [userId, setUserId] = useState('');

  const poolData = {
    UserPoolId: _config.cognito.userPoolId,
    ClientId: _config.cognito.userPoolClientId,
  };
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const theme = useTheme();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    var cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      return;
    }

    cognitoUser.getSession(function sessionCallback(err, session) {
      if (err) {
        console.log(err);
      } else if (!session.isValid()) {
        console.log('invalid sess');
      } else {
        cognitoUser.getUserAttributes(function (err, result) {
          if (err) {
            console.log(err);
          } else {
            if (result[3].Name !== 'custom:userId' || !result[3].Value) {
              return;
            }
            setUserId(result[3].Value);
            const realUserId = result[3].Value;
            const authToken = session.getIdToken().getJwtToken();
            const api = `${_config.api.invokeUrl}/getuserlite?userId=${realUserId}`;
            const axiosConfig = {
              headers: {
                "Authorization": authToken,
              }
            };
            axios
              .get(api, axiosConfig)
              .then((response) => {
                setName(response.data.firstName + ' ' + response.data.lastName);
                setEmail(response.data.email.replace('-at-', '@'));
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      }
    });
  }

  const submitForm = e => {
    e.preventDefault();

    const emailParams = {
      from_name: name,
      to_name: 'Symposia Team',
      message: feedback,
      reply_to: email,
    }

    emailjs.send(
      'service_xjdhqpr',
      'template_kr9pviq',
      emailParams,
      'lxNjTHrgxLGNi7xZn'
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setSubmitted(true);
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
  }

  return (
    <>
      <div className={styles.imageHolderHeader}>
        <img src={banner} alt='Professionals Standing Banner' className={styles.bannerImage} />
      </div>
      <div className={styles.center}>
        {!submitted && <Typography variant='body1'>
          Peer reviews are critical to publishing quality research. Your reviews of how you experience Symposia will also be critical to building a quality product. If you have ideas of features we should add, remove, or think about, please let us know below.
        </Typography>}

        {submitted ?
          <div>
            <Typography variant='body1'>
              Thank you for taking the time to leave your feedback, {name}! Keep a lookout on the email you provided ({email}) as we may follow up with questions. Close this dialog to continue browsing.
            </Typography>
          </div>
          :
          <div>
            <form onSubmit={submitForm}>
              <TextField
                id='name'
                InputLabelProps={{ shrink: true }}
                label='Name'
                variant='standard'
                autoFocus
                onChange={(e) => setName(e.target.value)}
                value={name}
                margin='normal'
                type='text'
                sx={{ width: '100%' }}
                required
              />
              <TextField
                id='email'
                InputLabelProps={{ shrink: true }}
                label='Email'
                variant='standard'
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                margin='normal'
                type='text'
                sx={{ width: '100%' }}
                required
              />
              <TextField
                id='feedback'
                variant='standard'
                autoFocus
                fullWidth
                multiline
                margin='normal'
                onChange={(e) => setFeedback(e.target.value)}
                value={feedback}
                required
              />
              <div className={styles.buttonDiv}>
                <Button
                  sx={{
                    background: '#FEBA3F',
                    color: '#1B065E',
                    boxShadow: '0px 0px 12px 0px #1B065E26',
                    textTransform: 'none',
                  }} type="submit" onClick={(e) => submitForm(e)}>
                  <Typography variant='subtitle1'>
                    Submit
                  </Typography>
                </Button>
              </div>
            </form>
          </div>}
      </div>
    </>
  )
}

export default Feedback;
