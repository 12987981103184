import React, { Component } from 'react'
import styles from './PersonStyles.module.css'
import { Verified } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import KeywordSmall from '../KeywordSmall/KeywordSmall';

export default class Person extends Component {
  render() {
    const { firstName, lastName, profilePictureUrl, jobTitle, organization, userId, prefix, suffix, areasOfInterest } = this.props;
    const name = prefix + ' ' + firstName + ' ' + lastName + ' ' + suffix;
    return (
      <div className={styles.PersonCard}>
        <div className={styles.PersonPhotoAndInfo}>
          <Link className={styles.NavLinks}
            to={{
              pathname: '/userprofile/' + userId,
              state: this.props
            }}>
            {profilePictureUrl
              ? (<img className={styles.UserProfilePictureImage} src={profilePictureUrl} alt={`${name}'s Profile Picture`} />)
              : (<div className={styles.UserProfilePictureImage + ' ' + styles.AvatarFill}>
                {firstName ? firstName.charAt(0).toUpperCase() : ''}{lastName ? lastName.charAt(0).toUpperCase() : ''}
              </div>)}
          </Link>

          <div className={styles.UserInfo}>
            <Link className={styles.UserName}
              to={{
                pathname: '/userprofile/' + userId,
                state: this.props
              }}>
              {name}
              {firstName && lastName && organization && profilePictureUrl ? <Verified sx={{ ml: '4px', fontSize: '20px', color: '#43a4fe' }} /> : ''}
            </Link>
            <div className={styles.UserPosition}>
              {jobTitle ? <span>{jobTitle.trim()}</span> : ''}{organization ? <span> at {organization.trim()}</span> : ''}
            </div>
          </div>
        </div>
        {areasOfInterest &&
          <div className={styles.Keywords}>
            {areasOfInterest.slice(0, 4).map((areaOfInterest) => {
              return (
                <KeywordSmall
                  key={areaOfInterest}
                  label={areaOfInterest}
                />
              );
            })}
          </div>
        }

      </div>
    )
  }
}