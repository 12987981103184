import _config from "../../signup/config";
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import styles from './UserInfoStyles.module.css'

class UserInfo extends Component {
  constructor(props) {
    super(props);
    const poolData = {
      UserPoolId: _config.cognito.userPoolId,
      ClientId: _config.cognito.userPoolClientId,
    };
    this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  }

  render() {
    const { userId, profilePictureUrl, firstName, lastName, userUpcomingTalks, } = this.props;
    return (
      <div>
        <div>
          <div className={styles.UserInfo}>
            <Link className={styles.UserName}
              to={{
                pathname: '/profile/' + userId,
                state: this.props
              }}>
              {/* {window.innerWidth > 768 && <div className={styles.UserFullName}>
                {firstName && firstName} {lastName && lastName}
              </div>} */}
              {profilePictureUrl && <img className={styles.UserProfilePictureImage} src={`${profilePictureUrl}?${Date.now()}`} alt='Profile Picture' />}
              {!profilePictureUrl && this.userPool.getCurrentUser()
                && (<div className={styles.UserProfilePictureImage + ' ' + styles.AvatarFill}>
                  {firstName ? firstName.charAt(0).toUpperCase() : ''}{lastName ? lastName.charAt(0).toUpperCase() : ''}
                </div>)}
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default UserInfo