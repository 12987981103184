import React, { useState, useEffect, useRef } from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import axios from 'axios';
import _config from "../signup/config";
import styles from './CreateProfileStyles.module.css';
import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import RequestService from "../requestservice/requestservice";
import { keywords1, keywords2, keywords3 } from '../components/KeywordsStore/KeywordsStore';
import FadeLoader from "react-spinners/FadeLoader";
import { withRouter } from "react-router-dom";
import crypto from 'crypto-browserify';
import PubNub from "pubnub";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const randomBytes = crypto.randomBytes;
const keywordsArray = [...keywords1, ...keywords2, ...keywords3];

const prefixOptions = {
    'None': null,
    'Mr.': 'mr',
    'Mrs.': 'mrs',
    'Ms.': 'ms',
    'Miss.': 'miss',
    'Mx.': 'mx',
    'Dr.': 'dr',
    'Prof.': 'prof',
    'Rev.': 'rev',
};

const pronounsOptions = {
    'None': null,
    'She/her/hers': 'she',
    'He/him/his': 'he',
    'They/them/theirs': 'they',
    'Ze/zir/zirs': 'ze',
    'Other': 'other',
};

function toUrlString(buffer) {
    return buffer.toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

const CreateProfile = (props) => {
    const isMobile = window.innerWidth <= 768;
    const [signedInUserId, setSignedInUserId] = useState(props.userId);
    const [userId, setUserId] = useState(props.userId ? props.userId : toUrlString(randomBytes(16)));
    const [firstNameValue, setFirstNameValue] = useState('');
    const [lastNameValue, setLastNameValue] = useState('');
    const [suffixValue, setSuffixValue] = useState('');
    const [jobTitleValue, setJobTitleValue] = useState('');
    const [pronouns, setPronouns] = useState(props?.profileState?.pronouns);
    const [websiteValue, setWebsiteValue] = useState('');
    const [organizationValue, setOrganizationValue] = useState('');
    const [bioValue, setBioValue] = useState('');
    const [email, setEmail] = useState(props?.profileState?.email);
    const [areasOfInterest, setAreasOfInterest] = useState([]);
    const [profilePictureUrl, setProfilePictureUrl] = useState(null);
    const [prefix, setPrefix] = useState(props?.profileState?.prefix);
    const [isEmailVisible, setIsEmailVisible] = useState(false);
    const [createAccountButtonDisabled, setCreateAccountButtonDisabled] = useState(false);
    const [cvUrl, setCvUrl] = useState('');
    const [isEditMode, setIsEditMode] = useState(props.isEditMode);
    const [isLoading, setIsLoading] = useState(false);
    const [profilePictureUploadTime, setProfilePictureUploadTime] = useState(Date.now());
    const [cvUploadTime, setCvUploadTime] = useState(Date.now());
    const [pictureLoading, setPictureLoading] = useState(false);
    const formRef = useRef(null);
    const requestService = props.requestService ?? new RequestService();
    const poolData = {
        UserPoolId: _config.cognito.userPoolId,
        ClientId: _config.cognito.userPoolClientId,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    useEffect(() => {
        if (props.userId) {
            setUserId(props.userId);
            getUserData(props.userId);
        }
    }, [props.userId]);

    useEffect(() => {
        if (props?.profileState?.email) {
            setEmail(props.profileState.email);
        }
    }, [props?.profileState?.email]);

    const getUserData = () => {
        const profileState = props.profileState;
        setFirstNameValue(profileState.firstNameValue);
        setLastNameValue(profileState.lastNameValue);
        setSuffixValue(profileState.suffixValue);
        setJobTitleValue(profileState.jobTitleValue);
        setWebsiteValue(profileState.websiteValue);
        setBioValue(profileState.bioValue);
        setOrganizationValue(profileState.organizationValue);
        setEmail(profileState.email.replace('-at-', '@'));
        setAreasOfInterest(profileState.areasOfInterest);
        setPronouns(profileState.pronouns);
        setPrefix(profileState.prefix);
        setIsEmailVisible(profileState.isEmailVisible);
        setProfilePictureUrl(profileState.profilePictureUrl);
        setCvUrl(profileState.cvUrl);
        setUserId(profileState.userId);
    };

    function stringToNumberInRange(str) {
        // Calculate a hash of the input string
        const hash = stringHash(str);

        // Map the hash to a number in the range 1 to 6
        const numberInRange = (hash % 6) + 1;

        return numberInRange;
    }

    // Simple string hashing function (you can use a more robust one if needed)
    function stringHash(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash += str.charCodeAt(i);
        }
        return hash;
    }

    const createAccount = (event) => {
        if (!formRef.current.checkValidity()) {
            formRef.current.reportValidity()
            return;
        }

        setIsLoading(true);
        setCreateAccountButtonDisabled(true)

        // if (isEditMode) {
        //     props.closeEditProfilePopup();
        // }

        event.preventDefault();
        const cognitoUser = userPool.getCurrentUser();
        // const email = cognitoUser.getUsername();
        if (!cognitoUser) {
            return;
        }
        const onSuccess = (response) => {
            if (isEditMode) {
                props.updatedProfile();
            } else {
                window.location = '/';
            }
        };

        const onFailure = (err) => {
            alert(err.message);
        };

        function getKeyByValue(object, value) {
            return Object.keys(object).find(key => object[key] === value);
        }

        const defaultPicIndex = stringToNumberInRange(userId ?? props.userId);
        const defaultPicUrl = `https://${_config.s3.bucketName}.s3.${_config.s3.region}.amazonaws.com/${_config.s3.dirName}/default/stock${defaultPicIndex}.jpg`;
        const data = {
            userId: userId ?? props.userId,
            email: email,
            prefix: getKeyByValue(prefixOptions, prefix),
            firstName: firstNameValue,
            lastName: lastNameValue,
            suffix: suffixValue,
            jobTitle: jobTitleValue,
            website: websiteValue,
            organization: organizationValue,
            bio: bioValue,
            areasOfInterest: areasOfInterest,
            pronouns: getKeyByValue(pronounsOptions, pronouns),
            isEmailVisible: isEmailVisible,
            profilePictureUrl: profilePictureUrl || defaultPicUrl,
            cvUrl: cvUrl,
        };

        cognitoUser.getSession(function sessionCallback(err, session) {
            if (err) {
                console.log(err);
            } else if (!session.isValid()) {
                console.log('invalid sess');
            } else {
                const authToken = session.getIdToken().getJwtToken();
                const api = `${_config.api.invokeUrl}/createuser`;
                const axiosConfig = {
                    headers: {
                        Authorization: authToken,
                    },
                };
                axios
                    .post(api, data, axiosConfig)
                    .then((response) => {
                        const userIdAttr = new AmazonCognitoIdentity.CognitoUserAttribute({
                            Name: 'custom:userId',
                            Value: response.data.userId,
                        });
                        const pubnub = new PubNub({
                            publishKey: _config.pubNub.pubKey,
                            subscribeKey: _config.pubNub.subKey,
                            userId: response.data.userId,
                        });
                        const fullname = response.data.lastName
                            ? `${response.data.firstName} ${response.data.lastName}`
                            : response.data.firstName;
                        const pubnubUserData = {
                            name: fullname,
                            email: response.data.email,
                            id: response.data.userId,
                            profileUrl: response.data.profilePictureUrl
                                ? `${response.data.profilePictureUrl}?${Date.now()}`
                                : '',
                        };
                        // add user to pubnub
                        pubnub.objects.setUUIDMetadata({ userId: response.data.userId, data: pubnubUserData });

                        cognitoUser.updateAttributes([userIdAttr], function (err, result) {
                            if (!err) {
                                onSuccess(response);
                            } else {
                                setIsLoading(false);
                                setCreateAccountButtonDisabled(false);
                            }

                        });
                    })
                    .catch((error) => {
                        onFailure(error);
                        console.log(error);
                        setIsLoading(false);
                        setCreateAccountButtonDisabled(false);
                    });
            }
        });
    };

    const handleFirstNameChange = (event) => {
        setFirstNameValue(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setLastNameValue(event.target.value);
    };

    const handleSuffixChange = (event) => {
        setSuffixValue(event.target.value);
    };

    const handleJobTitleChange = (event) => {
        setJobTitleValue(event.target.value);
    };

    const handleWebsiteChange = (event) => {
        setWebsiteValue(event.target.value);
    };

    const handleOrganizationChange = (event) => {
        setOrganizationValue(event.target.value);
    };

    const handleBioChange = (event) => {
        setBioValue(event.target.value);
    };

    const handleAreasOfInterestChange = (text) => {
        setAreasOfInterest(text);
    };

    const handleEmailVisibility = (event) => {
        setIsEmailVisible(event.target.checked);
    };

    const handleProfilePictureUpload = (e) => {
        const cognitoUser = userPool.getCurrentUser();
        if (!cognitoUser) {
            return;
        }
        var onFailure = function registerFailure(err) {
            alert(err.message);
        };
        setPictureLoading(true);

        let file = e.target.files[0];
        if (!file) {
            return;
        }
        const fileName = `pictures/userId_${userId ?? props.userId}_pic`;
        let blob = file.slice(0, file.size, file.type);
        cognitoUser.getSession(function sessionCallback(err, session) {
            if (err) {
                console.log(err);
            } else if (!session.isValid()) {
                console.log('invalid sess');
            } else {
                const authToken = session.getIdToken().getJwtToken();
                const api = _config.api.invokeUrl + '/gets3uploadurl';
                const axiosConfig = {
                    headers: {
                        "Authorization": authToken,
                    },
                };
                const data = { "fileName": fileName, };

                const options = {
                    headers: {
                        'Content-Type': file.type
                    }
                };
                axios
                    .post(api, data, axiosConfig)
                    .then((response) => {
                        axios
                            .put(response.data.uploadURL, blob, options)
                            .then((responseS3) => {
                                const longS3PicLocation = responseS3.config.url;
                                const strippedS3PicLocation = longS3PicLocation.substring(0, longS3PicLocation.indexOf('?'));
                                setProfilePictureUrl(strippedS3PicLocation);
                                setProfilePictureUploadTime(Date.now());
                                setPictureLoading(false);
                            }).catch((error) => {
                                console.log(error);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        onFailure(error);
                    });
            }
        });
    };

    const handleCvUpload = (e) => {
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        const fileName = `cvs/userId_${userId}_cv.${file.type.split('/')[1]}`;
        let blob = file.slice(0, file.size, file.type);

        let cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.getSession(function sessionCallback(err, session) {
                if (err) {
                    console.log(err);
                } else if (!session.isValid()) {
                    console.log('invalid sess');
                } else {
                    const authToken = session.getIdToken().getJwtToken();
                    const api = _config.api.invokeUrl + '/gets3uploadurl';
                    const axiosConfig = {
                        headers: {
                            "Authorization": authToken,
                        },
                    };
                    const data = { "fileName": fileName, };

                    const options = {
                        headers: {
                            'Content-Type': file.type
                        }
                    };
                    axios
                        .post(api, data, axiosConfig)
                        .then((response) => {
                            axios
                                .put(response.data.uploadURL, blob, options)
                                .then((responseS3) => {
                                    const longS3cvLocation = responseS3.config.url;
                                    const strippedS3cvLocation = longS3cvLocation.substring(0, longS3cvLocation.indexOf('?'));
                                    setCvUrl(strippedS3cvLocation);
                                    setCvUploadTime(Date.now());
                                }).catch((error) => {
                                    console.log(error);
                                });
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            });
        }
    };

    const deleteAccount = async (paperId) => {
        const data = { paperId: paperId };
        if (window.confirm("Are you sure you want to delete your account?") === true) {
            await requestService.deleteUser(data, this);
            props.history.push('/');
        }
    };

    return (
        <section>
            <form className={styles.contentContainer} ref={formRef}>
                <div className={styles.accountInfoTitle}>
                    <Typography variant='h1'>
                        Account info
                    </Typography>
                </div>
                {/* Profile picture upload */}
                <Box sx={{ display: 'flex', pt: '24px', flexDirection: 'column' }}>
                    <Box sx={{
                        margin: 'auto'
                    }}>
                        <input
                            id="profilePicUploader"
                            className={styles.profilePictureUploader}
                            type="file"
                            accept="image/*"
                            size="1"
                            onChange={handleProfilePictureUpload}
                        />
                        <label htmlFor='profilePicUploader'>
                            {profilePictureUrl ? (
                                <div className={styles.userProfilePicture}>
                                    <img
                                        className={styles.userProfilePictureImage}
                                        src={`${profilePictureUrl}?${profilePictureUploadTime}`}
                                        alt='Profile Picture Upload'
                                    />
                                </div>
                            ) : (
                                <Box>
                                    <label htmlFor='profilePicUploader'>
                                        <Box sx={{
                                            alignItems: 'center',
                                            border: '5px dashed #FEBA3F',
                                            borderRadius: '50%',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            height: '300px',
                                            width: '300px',
                                            alignSelf: 'center',
                                        }}>
                                            <Box sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                            }}>
                                                {pictureLoading ?
                                                    <FadeLoader
                                                        color={'var(--primary-color)'}
                                                        loading={pictureLoading}
                                                        size={50}
                                                    /> :
                                                    <CloudUploadIcon sx={{
                                                        color: '#FEBA3F',
                                                        height: '80px',
                                                        width: '112px',
                                                    }} />
                                                }
                                                <Box sx={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                }}>
                                                    <div className={styles.textSpace + ' ' + styles.blueText}>Upload a profile image</div>
                                                    <br />
                                                    <div>*3MB max image file size.</div>
                                                    <div>*accepted file formats: jpg, png, gif</div>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </label>
                                </Box>
                            )}
                        </label>
                    </Box>
                    <div>
                        <div className={styles.emailAddressPlaceholder}>
                            <Typography variant='body1'>
                                Email
                            </Typography>
                        </div>
                        <Box sx={{ color: 'black' }}>
                            <Typography variant='subtitle1'>
                                {email}
                            </Typography>
                        </Box>
                        <div className={styles.emailVisibility}>
                            <FormControlLabel
                                className={styles.emailVisibility}
                                control={
                                    <Checkbox
                                        onChange={handleEmailVisibility}
                                        checked={isEmailVisible}
                                    />
                                }
                                label="Allow other researchers to contact you via email"
                            />
                        </div>
                    </div>
                </Box>

                {/* Prefix and Pronouns */}
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <FormControl fullWidth>
                        <InputLabel variant='standard' id="prefix-select-label">Select prefix</InputLabel>
                        <Select
                            fullWidth
                            labelId="prefix-select-label"
                            variant='standard'
                            id="prefix-select"
                            value={prefix}
                            onChange={(e) => setPrefix(e.target.value)}>
                            {Object.keys(prefixOptions).map((prefixOption) => <MenuItem key={prefixOption} value={prefixOptions[prefixOption]}>{prefixOption}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel variant='standard' id="pronouns-select-label">Select pronouns</InputLabel>
                        <Select
                            fullWidth
                            labelId="pronouns-select-label"
                            variant='standard'
                            id="pronouns-select"
                            value={pronouns}
                            onChange={(e) => setPronouns(e.target.value)}>
                            {Object.keys(pronounsOptions).map((pronounsOption) => <MenuItem key={pronounsOption} value={pronounsOptions[pronounsOption]}>{pronounsOption}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Box>
                {/* First Name and Last Name */}
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <TextField
                        id='first-name'
                        variant='standard'
                        autoFocus
                        fullWidth
                        margin='normal'
                        label="First name"
                        onChange={handleFirstNameChange}
                        value={firstNameValue}
                        required={true}
                    />
                    <TextField
                        id='last-name'
                        variant='standard'
                        autoFocus
                        fullWidth
                        margin='normal'
                        label="Last name"
                        onChange={handleLastNameChange}
                        value={lastNameValue}
                    />
                </Box>
                {/* Suffix and Job Title */}
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <TextField
                        id='suffix'
                        variant='standard'
                        autoFocus
                        fullWidth
                        margin='normal'
                        label="Suffix"
                        onChange={handleSuffixChange}
                        value={suffixValue}
                    />

                    <TextField
                        id='job-title'
                        variant='standard'
                        autoFocus
                        fullWidth
                        margin='normal'
                        label="Job title"
                        onChange={handleJobTitleChange}
                        value={jobTitleValue}
                        required={true}
                    />
                </Box>
                {/* Website and Organization */}
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <TextField
                        id='website'
                        variant='standard'
                        autoFocus
                        fullWidth
                        margin='normal'
                        label="Website"
                        onChange={handleWebsiteChange}
                        value={websiteValue}
                    />

                    <TextField
                        id='instituiton-organization'
                        variant='standard'
                        autoFocus
                        fullWidth
                        margin='normal'
                        label="Institution"
                        onChange={handleOrganizationChange}
                        value={organizationValue}
                    />
                </Box>
                {/* Areas of Interest */}
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Autocomplete
                        multiple
                        id="tags"
                        options={keywordsArray}
                        fullWidth
                        value={areasOfInterest}
                        onChange={(event, text) => handleAreasOfInterestChange(text)}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Select areas of interest"
                                placeholder={'Enter keywords'}
                            />
                        )}
                    />
                    <div></div>
                </Box>
                {/* Bio */}
                <TextField
                    id='bio'
                    variant='standard'
                    autoFocus
                    fullWidth
                    multiline
                    margin='normal'
                    label='Enter your bio'
                    onChange={handleBioChange}
                    value={bioValue}
                    required={true}
                />
                <div className={styles.createAccountButtonContainer}>
                    {/* Delete Account Button */}
                    {/* <Button
                        type="button"
                        variant='contained'
                        onClick={deleteAccount}
                        sx={{
                            background: 'white',
                            border: '2px solid #FEBA3F',
                            ":hover": {
                                bgcolor: '#eeeeee',
                            }
                        }}
                    >
                        Delete account
                    </Button> */}
                    {/* Create Profile Button */}
                    <Button
                        onClick={createAccount}
                        disabled={createAccountButtonDisabled}
                        variant="contained"
                        sx={{ color: '#1B065E', ml: '12px' }}
                    >
                        {isEditMode ? 'Save' : 'Create profile'}
                    </Button>
                </div>
            </form >
        </section >
    );
};

export default withRouter(CreateProfile);