import React, { Component } from 'react'
import ToggleQuestion from '../components/toggleQuestion/toggleQuestion'
import styles from './QuestionsStyles.module.css'

class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleContent: false,
    };

  }
  render() {
    const { pageTitle, drawerWidth, history } = this.props;
    return (
      <div>
        <div className={styles.center}>
          <ToggleQuestion
            question='Do I need to sign up to post a talk or paper on Symposia?'
            answer='Yes. Only registered users can host (create) an event.'
          />
          <ToggleQuestion
            question='What can I do without signing up?'
            answer='Currently, we require that users of the site be registered to post and view talks and papers. In the future, unregistered users will be able to browse all talks, register to attend talks, and see posted papers.'
          />
          <ToggleQuestion
            question='How do I get a Verified check?'
            answer={'Researchers who have included their profile picture, first and last name, and institution are automatically verified.'}
          />
          <ToggleQuestion
            question='What is Symposia’s stance on fake accounts?'
            answer={'At Symposia, we want authentic users to share meaningful research-related content. In line with this, we reserve the right to remove users that impersonate others or use a fake identity in a manner that disrupts the experience of others on Symposia.'}
          />
          <ToggleQuestion
            question='Is Symposia available to all researchers?'
            answer={'Yes. While Symposia was initially rolled out to robotics and artificial intelligence (machine learning) disciplines in early 2023, we have now expanded to all academic disciplines.  We encourage the community of users to join alongside and contribute research-related events, papers, and jobs across disciplines.'}
          />
          <ToggleQuestion
            question='What kind of events can I host on Symposia?'
            answer={'You can host talks, journal clubs, workshops/demos, open meetings, dissertation defenses, and conferences. We think this should cover all the types of events users might want to host, but we welcome feedback about other events that are not currently covered.'}
          />
          <ToggleQuestion
            question='Who should I enter as the speaker of an event if I am not presenting at the event?'
            answer={'You can enter the speaker or presenter as the speaker of the event if you are creating the event on behalf of another person. The event will still be linked to the profile of the Symposia user who created it.'}
          />
          <ToggleQuestion
            question='I have further questions, how can I reach you?'
            answer={'If you have registered for an account, you can click "Leave Feedback" from the home page to send us a note. Otherwise, you can send us an email at symposia.site@gmail.com.'}
          />
          <ToggleQuestion
            question="What is Symposia's privacy policy?"
            answer={"Symposia's privacy policy can be found here:"}
            link='https://1drv.ms/b/s!Au3dM09VAVRCzmiw6WmNEDDZMMsk'
            linkText='privacy policy.'
          />
        </div>

      </div>
    )
  }
}

export default Questions