import React, { useState, useEffect } from 'react';
import TalkCard from '../TalkCard/TalkCard';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import _config from '../../signup/config';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import styles from './TalksFeedStyles.module.css';
import { Divider, Typography } from '@mui/material';
import RequestService from '../../requestservice/requestservice.js';
import { Pagination } from '@mui/material';

function TalksFeed({
  query,
  setFeedLoaded,
  fullLeftBar,
  allUserItems,
  handleAddedUpcomingTalk,
  handleRemoveUpcomingTalk,
  handleAddedSavedTalk,
  handleRemoveSavedTalk,
  userEvents,
}) {
  const savedNewsItems = [];
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageNum = Number(queryParams.get('page')) || 1;

  const [newsItems, setNewsItems] = useState(savedNewsItems || []);
  const [recordedNewsItems, setRecordedNewsItems] = useState(newsItems.filter(
    e => e.activeLoc === 'Recorded'
  ));
  const [futureNewsItems, setFutureNewsItems] = useState(newsItems.filter(
    e => e.activeLoc !== 'Recorded'
  ));
  const [arrangedNewsItems, setArrangedNewsItems] = useState([...futureNewsItems, ...recordedNewsItems]);
  const [currentUserId, setCurrentUserId] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(pageNum);

  const poolData = {
    UserPoolId: _config.cognito.userPoolId,
    ClientId: _config.cognito.userPoolClientId,
  };
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const requestService = new RequestService();
  const history = useHistory();

  useEffect(() => {
    if (setFeedLoaded && !loading) {
      setFeedLoaded(true);
    }
  }, [loading]);

  useEffect(
    () => {
      const recordItems = newsItems.filter(
        e => e.activeLoc === 'Recorded'
      );
      const futureItems = newsItems.filter(
        e => e.activeLoc !== 'Recorded'
      );
      setRecordedNewsItems(recordItems);
      setFutureNewsItems(futureItems);
      setArrangedNewsItems([...futureItems, ...recordItems]);
    }, [newsItems]
  );

  useEffect(() => {
    // if (!userEvents) {
    //   return;
    // }
    if (query) {
      const data = { searchQuery: query };
      requestService.searchEvents(data, (newsFeedEvents) => {
        setNewsItems(newsFeedEvents);
        setLoading(false);
        syncLocalStorage();
      });
    } else if (userEvents) {
      setNewsItems(userEvents);
      setLoading(false);
    } else {
      getNewsfeed(currentPage);
    }
  }, []);

  useEffect(() => {
    if (userEvents) {
      return;
    }
    getNewsfeed(currentPage);
  }, [currentPage]);

  const syncLocalStorage = () => {
    window.localStorage.setItem('newsItems', JSON.stringify(newsItems));
  };

  const getNewsfeed = (page) => {
    const currentUser = userPool.getCurrentUser();

    if (!currentUser) {
      setLoading(true);
      const api = `${_config.api.invokeUrl}/getnewsfeed?page=${page}`;
      axios.get(api)
        .then(response => {
          setLoading(false);
          const newsFeedEvents = response.data;
          setNewsItems([...newsFeedEvents]);
          syncLocalStorage();
          const scrollPosition = sessionStorage.getItem('scrollPosition');
          if (scrollPosition) {
            window.scrollTo(0, parseInt(scrollPosition));
            sessionStorage.removeItem('scrollPosition');
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      currentUser.getSession((err, session) => {
        if (err) {
          console.log(err);
          return;
        }
        if (!session.isValid()) {
          console.log('invalid sess');
          return;
        }
        currentUser.getUserAttributes((err, result) => {
          if (err) {
            console.log(err);
            return;
          }
          const userIdAttribute = result.find(attr => attr.Name === 'custom:userId');
          if (userIdAttribute && userIdAttribute.Value) {
            setCurrentUserId(userIdAttribute.Value);
            const authToken = session.getIdToken().getJwtToken();
            setLoading(true);
            const api = `${_config.api.invokeUrl}/getnewsfeed?userId=${userIdAttribute.Value}&page=${page}`;
            const axiosConfig = {
              headers: {
                Authorization: authToken
              }
            };
            axios.get(api, axiosConfig)
              .then(response => {
                setLoading(false);
                const newsFeedEvents = response.data;
                setNewsItems([...newsFeedEvents]);
                const recordedNewsItems = newsFeedEvents.filter(
                  e => e.activeLoc === 'Recorded'
                );
                const futureNewsItems = newsFeedEvents.filter(
                  e => e.activeLoc !== 'Recorded'
                );
                let arrangedNewsItems = [...futureNewsItems, ...recordedNewsItems];
                syncLocalStorage();
                const scrollPosition = sessionStorage.getItem('scrollPosition');
                if (scrollPosition) {
                  window.scrollTo(0, parseInt(scrollPosition));
                  sessionStorage.removeItem('scrollPosition');
                }
              })
              .catch(error => {
                console.log(error);
              });
          }
        });
      });
    }
  };

  const handlePaginationChange = (event, value) => {
    history.push(`${location.pathname}?page=${value}`);
    setCurrentPage(value);
  };

  return (
    <div>
      {loading ? (
        <div>
          <div className={styles.LoadingDiv}>
            <Typography sx={{ m: '8px', color: '#1B065E' }} variant='h1'>Loading...</Typography>
            <ClipLoader
              color={"var(--primary-color)"}
              loading={loading}
              size={100}
            />
          </div>
        </div>
      ) : (
        <>
          {arrangedNewsItems.map((e, index) => {
            const startDateInMilliSecs = new Date(e.startDate).getTime()
            const endDateInMilliSecs = new Date(e.endDate).getTime()
            const isLive =
              startDateInMilliSecs <= Date.now() &&
              Date.now() <= endDateInMilliSecs
            return (
              <div key={e.EventId}>
                <TalkCard
                  key={e.EventId}
                  eventId={e.EventId}
                  startDate={e.startDate}
                  endDate={e.endDate}
                  thumbnailURL={e.thumbnailURL}
                  userId={e.userId}
                  title={e.title}
                  date={
                    moment(e.startDate).format('ddd, M/D/YYYY') ===
                      moment(e.endDate).format('ddd, M/D/YYYY')
                      ? moment(e.startDate).format('ddd, M/D/YYYY')
                      : moment(e.startDate).format('ddd, M/D/YYYY') +
                      ' to ' +
                      moment(e.endDate).format('ddd, M/D/YYYY')
                  }
                  time={
                    moment(e.startDate).format('h:mm a') +
                    ' to ' +
                    moment(e.endDate).format('h:mm a')
                  }
                  timeZone={e.timeZone.value}
                  eventType={e.eventType.label}
                  activeLoc={e.activeLoc}
                  description={e.description}
                  hostName={e.hostName}
                  speakerBio={e.speakerBio}
                  institution={e.institution}
                  venue={e.venue}
                  meetingLink={e.meetingLink}
                  talkWebPage={e.talkWebPage}
                  keywords={e.keywords}
                  liked={e.liked}
                  saved={e.saved}
                  addedToCalendar={e.attending}
                  isLive={isLive}
                  allUserItems={allUserItems}
                  fullLeftBar={fullLeftBar}
                  handleAddedUpcomingTalk={handleAddedUpcomingTalk}
                  handleRemoveUpcomingTalk={handleRemoveUpcomingTalk}
                  handleAddedSavedTalk={handleAddedSavedTalk}
                  handleRemoveSavedTalk={handleRemoveSavedTalk}
                />
              </div>
            )
          })}
        </>
      )}
      {!query && arrangedNewsItems.length !== 0 && !loading && arrangedNewsItems[0].pages > 1 && (
        <div className={styles.PaginationButtons}>
          <Pagination page={currentPage} count={arrangedNewsItems[0].pages} onChange={handlePaginationChange} />
        </div>
      )}{' '}
      {!loading && arrangedNewsItems.length === 0 && (
        <h3 className={styles.NoEventsText}>No events found.</h3>
      )}
    </div>
  );
}

export default TalksFeed;
