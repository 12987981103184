import React, { Component } from 'react'
import SignedInNav from '../components/SignedInNav/SignedInNav';
import SignedOutNav from '../components/SignedOutNav/SignedOutNav';
import styles from './faqsStyles.module.css'
import ToggleQuestion from '../components/toggleQuestion/toggleQuestion'
import banner from '../../images/symposia_stock_02-compressed.jpg';
import _config from "../signup/config";
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

export class FAQs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleContent: false,
    };

    var poolData = {
      UserPoolId: _config.cognito.userPoolId,
      ClientId: _config.cognito.userPoolClientId,
    };
    this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    this.cognitoUser = this.userPool.getCurrentUser();

  }


  render() {
    return (
      <div>
        {this.cognitoUser ? (<SignedInNav />) : (<SignedOutNav />)}
        <div className={styles.imageHolderHeader}>
          <img src={banner} alt='Research Banner' className={styles.bannerImage} />
          <div className={styles.titleHolder}>
            <div className={styles.mottoText}>Frequently Asked Questions<br />(FAQs)</div>
          </div>
        </div>
        <div className={styles.center}>
          {/*DEVON ADD Q&A SECTION*/}
          <ToggleQuestion
            question='Q & A About Comp?'
            answer={'Words.'}
          />
          {/*END DEVON ADD*/}
          <ToggleQuestion
            question='Do I need to sign up to host an event on Symposia?'
            answer='Yes. Only registered users can host (create) an event.'
          />
          <ToggleQuestion
            question='What can I do without signing up?'
            answer='Currently, we require that users of the site be registered to host and view events. In the future, unregistered users will be able to browse all events on the site and register to attend events.'
          />
          {/* <ToggleQuestion
            question='Why do I need an email address with ".edu" domain to sign up?'
            answer={'We currently require that all registered users have a ".edu" email address to maintain the integrity of the site as a tool for researchers. We admit this requirement is flawed but it is a starting point for us to provide the value for which Symposia intends to provide. We understand that not all researchers will have a ".edu" email address and not all people with a ".edu" email address are researchers. However, the method allows access to a plurality of researchers as well as administrators who may be creating events on behalf of their institutions. We\'re working hard on a method to validate the credentials of other researchers and welcome ideas.'}
          /> */}
          <ToggleQuestion
            question='Is Symposia available to all researchers?'
            answer={'Yes. However, researchers in robotics and artificial intelligence (machine learning) disciplines will get the most value from the site immediately. Our early focus is on robotics and learning and we (the Symposia team) plan to curate a space for these communities to centralize the activities of robotics-related research. However, we realize there is value in doing the same for other fields and will be expanding as quickly as possible. We encourage the community of users to join alongside and contribute research-related events for other fields.'}
          />
          <ToggleQuestion
            question='What kind of events can I host on Symposia?'
            answer={'You can host talks, journal clubs, workshops/demos, open meetings, dissertation defenses, and conferences. We think this should cover all the types of events users might want to host, but we welcome feedback about other events that are not currently covered.'}
          />
          <ToggleQuestion
            question='Who should I enter as the speaker of an event if I am not presenting at the event?'
            answer={'You can enter the speaker or presenter as the speaker of the event if you are creating the event on behalf of another person. The event will still be linked to the profile of the Symposia user who created it.'}
          />
          <ToggleQuestion
            question='I have further questions, how can I reach you?'
            answer={'If you have registered for an account, you can go to the "Peer Review" webpage to send us a note. Otherwise, you can send us an email at researchos@outlook.com.'}
          />
          <ToggleQuestion
            question="What is Symposia's privacy policy?"
            answer={"Symposia's privacy policy can be found here"}
          />
        </div>

      </div>
    )
  }
}

export default FAQs
