import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import _config from "../signup/config";
import React from 'react';
import styles from './CreateTalkFormStyles.module.css';
import { ThumbnailUploader } from '../components/file-uploader';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import axios from 'axios';
import Popup from '../components/popup/popup';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DeleteOutline, Send } from '@mui/icons-material';
import { keywords1, keywords2, keywords3 } from '../components/KeywordsStore/KeywordsStore';
import { DropDown } from '../components/drop-down';
import validator from 'validator';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import crypto from 'crypto-browserify';
import moment from 'moment-timezone';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const randomBytes = crypto.randomBytes;
const keywordsArray = [...keywords1, ...keywords2, ...keywords3];

const eventOptions = {
    'journal-club': 'Journal Club',
    'talk-seminar': 'Talk/Seminar',
    'defense': 'Dissertation Defense',
    'workshop-tutorial': 'Workshop/Tutorial',
    'meeting': 'Open Meeting',
    'conference': 'Conference'
};


const visibilityOptions = [
    { label: 'Public (default)', value: 'public' },
    { label: 'Registered users', value: 'registered-users' }
];

const locTypeOptions = {
    VIRTUAL: 'Virtual',
    // HYBRID: 'Hybrid',
    RECORDED: 'Recorded',
}

function toUrlString(buffer) {
    return buffer.toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

class CreateTalkForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: this.props.eventId ? this.props.eventId : toUrlString(randomBytes(16)),
            groupId: '',
            group: '',
            userId: '',
            thumbnail: null,
            thumbnailURL: '',
            talkWebpage: '',
            title: '',
            hostName: '',
            institution: '',
            eventType: '',
            description: '',
            keywords: [],
            startDate: null,
            startTime: null,
            endDate: null,
            endTime: null,
            timeZone: { label: moment.tz.guess(), value: moment.tz(moment.tz.guess()).format('z') },
            refFiles: null,
            refFilesURL: '',
            addInfo: '',
            activeLoc: "Virtual",
            locType: locTypeOptions,
            venue: {},
            address: '',
            meetingLink: '',
            meetingDetails: '',
            visibility: visibilityOptions[0],
            discardPopup: false,
            submitPopup: false,
            speakerBio: '',
            allRequiredComplete: true, // assume true to start
            windowWidth: window.innerWidth,
            postButtonDisabled: false,
        }

        const poolData = {
            UserPoolId: _config.cognito.userPoolId,
            ClientId: _config.cognito.userPoolClientId,
        };
        this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

        this.getUser = this.getUser.bind(this);
        this.autocomplete = null;
        this.submitForm = this.submitForm.bind(this);
        this.onPlaceChanged = this.onPlaceChanged.bind(this);
        this.autoCompleteRef = React.createRef();

        this.handleAdditionalInfoChange = this.handleAdditionalInfoChange.bind(this);
        this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleEventTypeChange = this.handleEventTypeChange.bind(this);
        this.handleHostNameChange = this.handleHostNameChange.bind(this);
        this.handleInstitutionChange = this.handleInstitutionChange.bind(this);
        this.handleKeywordsChange = this.handleKeywordsChange.bind(this);
        this.handleGroupChange = this.handleGroupChange.bind(this);
        this.handleMeetingDetailsChange = this.handleMeetingDetailsChange.bind(this);
        this.handleMeetingLinkChange = this.handleMeetingLinkChange.bind(this);
        this.handleTalkWebpageChange = this.handleTalkWebpageChange.bind(this);
        this.handleRefFilesChange = this.handleRefFilesChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleThumbnailChange = this.handleThumbnailChange.bind(this);
        this.handleThumbnailURLChange = this.handleThumbnailURLChange.bind(this);
        this.handleTimeZoneChange = this.handleTimeZoneChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
        this.setSubmitPopup = this.setSubmitPopup.bind(this);
        this.setDiscardPopup = this.setDiscardPopup.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleSpeakerBioChange = this.handleSpeakerBioChange.bind(this);
        this.getEvent = this.getEvent.bind(this);
        this.getEventAPI = this.getEventAPI.bind(this);
    }

    componentDidMount() {
        this.getUser();
        if (this.props.eventId) {
            this.getEventAPI(this.props.eventId); // need to double check something is wrong here
        }
        window.addEventListener('resize', this.handleResize)

        // const googleScript = document.getElementById('googleMapPlacesScript')
        // var self = this;
        // function setUpAutocomplete(self) {
        //     self.autocomplete = new window.google.maps.places.Autocomplete(
        //         self.autoCompleteRef.current,
        //         {
        //             componentRestrictions: { 'country': ['US'] },
        //             fields: ['geometry', 'name', 'formatted_address']
        //         }
        //     );
        //     self.autocomplete.setFields(['geometry', 'name', 'formatted_address']);
        //     self.autocomplete.setComponentRestrictions({ 'country': ['US'] });
        //     self.autocomplete.addListener('place_changed', self.onPlaceChanged);
        // }

        // if (window.google && window.google.maps && window.google.maps.places && !this.autocomplete) {
        //     setUpAutocomplete(self);
        // } else if (googleScript) {
        //     googleScript.addEventListener('load', () => {
        //         setUpAutocomplete(self);

        //     });
        // }
    }

    getEvent(eventId) {
        let talkState = this.props.talkState
        this.setState({
            thumbnailURL: talkState.thumbnailURL,
            title: talkState.title,
            hostName: talkState.hostName,
            institution: talkState.institution,
            speakerBio: talkState.speakerBio,
            eventType: talkState.eventType,
            description: talkState.description,
            keywords: talkState.keywords,
            timeZone: talkState.timeZone,
            activeLoc: talkState.activeLoc,
            venue: talkState.venue,
            refFilesURL: talkState.refFilesURL,
            addInfo: talkState.additionalInfo,
            userId: talkState.userId,
            eventId: talkState.eventId,
            startDate: new Date(talkState.startDate),
            endDate: new Date(talkState.endDate),
            meetingLink: talkState.meetingLink,
            meetingDetails: talkState.meetingDetails,
            talkWebpage: talkState.talkWebPage,
            visibility: talkState.visibility,
            liked: talkState.liked,
            groupId: talkState.groupId,
        });
    }

    getEventAPI(eventId) {
        var cognitoUser = this.userPool.getCurrentUser();

        if (cognitoUser) {
            var self = this;
            cognitoUser.getSession(function sessionCallback(err, session) {
                if (err) {
                    console.log(err);
                } else if (!session.isValid()) {
                    console.log('invalid sess');
                } else {
                    const authToken = session.getIdToken().getJwtToken();
                    cognitoUser.getUserAttributes(function (err, result) {
                        if (err) {
                            console.log(err);
                        } else {
                            const loggedInUserId = result[3].Value;
                            const api = _config.api.invokeUrl + '/getevent?eventId=' + eventId + '&userId=' + loggedInUserId;
                            const axiosConfig = {
                                headers: {
                                    "Authorization": authToken,
                                }
                            };
                            axios
                                .get(api, axiosConfig)
                                .then((response) => {
                                    self.setState({
                                        thumbnailURL: response.data.thumbnailURL,
                                        thumbnail: response.data.thumbnailURL ? { name: 'eventId_' + eventId + '_thumbnail' } : undefined,
                                        title: response.data.title,
                                        hostName: response.data.hostName,
                                        institution: response.data.institution,
                                        speakerBio: response.data.speakerBio,
                                        eventType: response.data.eventType?.value,
                                        description: response.data.description,
                                        keywords: response.data.keywords,
                                        timeZone: response.data.timeZone,
                                        activeLoc: response.data.activeLoc,
                                        talkWebpage: response.data.talkWebPage,
                                        venue: response.data.venue,
                                        refFilesURL: response.data.refFilesURL,
                                        addInfo: response.data.additionalInfo,
                                        userId: response.data.userId,
                                        eventId: response.data.EventId,
                                        startDate: new Date(response.data.startDate),
                                        endDate: new Date(response.data.endDate),
                                        meetingLink: response.data.meetingLink,
                                        meetingDetails: response.data.meetingDetails,
                                        address: response.data.venue ? response.data.venue.formatted_address : '',
                                        visibility: response.data.visibility,
                                        liked: response.data.liked,
                                        groupId: response.data.groupId,
                                    });
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    });
                }
            });
        }
    }

    getUser() {
        var cognitoUser = this.userPool.getCurrentUser();
        if (cognitoUser) {
            let self = this;
            cognitoUser.getSession(function sessionCallback(err, session) {
                if (err) {
                    console.log(err);
                } else if (!session.isValid()) {
                    console.log('invalid sess');
                } else {
                    cognitoUser.getUserAttributes(function (err, result) {
                        if (err) {
                            console.log(err);
                        } else {
                            if (result[3].Name === 'custom:userId' && result[3].Value) {
                                self.setState({ userId: result[3].Value });
                            }
                        }
                    }
                    );
                }
            });
        }

    }

    handleResize() {
        this.setState({ windowWidth: window.innerWidth });
    }


    onPlaceChanged() {
        let place = this.autocomplete.getPlace();
        this.setState({ venue: place, address: place.name + ', ' + place.formatted_address });
        console.log(place);
    }

    async submitForm(e) {
        e.preventDefault();
        const { title, description, hostName, activeLoc, startDate, endDate, meetingLink } = this.state;
        this.setState({ allRequiredComplete: true, postButtonDisabled: true }); // assume innocent until proven guilty

        var onSuccess = function registerSuccess(result, self) {
            var eventId = result.data.eventId;
            console.log('event id is ' + eventId);
            var confirmation = ('event id created!');
            if (confirmation) {
                console.log('Done. Going to event.');
                window.location.href = `/talk/${eventId}`;
            }
        };

        var onFailure = function registerFailure(err) {
            alert(err.message);
        };
        const data = {
            "eventId": this.state.eventId,
            "groupId": this.state.group !== '' ? this.state.group.id : '',
            "userId": this.state.userId,
            'thumbnailURL': this.state.thumbnailURL,
            "title": this.state.title,
            "hostName": this.state.hostName,
            "institution": this.state.institution,
            // eventType is saved in a strange data format due to a legacy autocomplete and poor coding design from another dev.
            "eventType": {
                label: eventOptions[this.state.eventType],
                value: this.state.eventType,
            },
            "description": this.state.description,
            "keywords": this.state.keywords,
            "startDate": this.state.startDate ? this.state.startDate : Date.now(),
            "startTime": moment(this.state.startDate).unix(),
            "endDate": this.state.endDate ? this.state.endDate : Date.now(),
            "endTime": this.state.endTime,
            "timeZone": this.state.timeZone,
            "refFilesURL": this.state.refFilesURL,
            "additionalInfo": this.state.addInfo,
            "activeLoc": this.state.activeLoc,
            "venue": this.state.venue,
            "meetingLink": this.state.meetingLink,
            "meetingDetails": this.state.meetingDetails,
            "visibility": this.state.visibility,
            "talkWebpage": this.state.talkWebpage,
            "speakerBio": this.state.speakerBio,
        };

        var self = this;

        if (title && description && hostName && (activeLoc == 'Recorded' || (startDate && endDate))) {
            if (this.state.refFiles) {
                const fileName = "reference_files/eventId_" + this.state.eventId + '_refFile';
                let blob = this.state.refFiles.slice(0, this.state.refFiles.size, this.state.refFiles.type);

                let cognitoUser = this.userPool.getCurrentUser();
                if (cognitoUser) {
                    await cognitoUser.getSession(async function sessionCallback(err, session) {
                        if (err) {
                            console.log(err);
                        } else if (!session.isValid()) {
                            console.log('invalid sess');
                        } else {
                            const authToken = session.getIdToken().getJwtToken();
                            const api = _config.api.invokeUrl + '/gets3uploadurl';
                            const axiosConfig = {
                                headers: {
                                    "Authorization": authToken,
                                },
                            };
                            const data = { "fileName": fileName, };

                            const options = {
                                headers: {
                                    'Content-Type': blob.type
                                }
                            };
                            await axios
                                .post(api, data, axiosConfig)
                                .then(async (response) => {
                                    await axios
                                        .put(response.data.uploadURL, blob, options)
                                        .then((responseS3) => {
                                            const longS3Location = responseS3.config.url;
                                            const strippedS3Location = longS3Location.substring(0, longS3Location.indexOf('?'));
                                            self.setState({ refFilesURL: strippedS3Location });
                                        }).catch((error) => {
                                            self.setState({ postButtonDisabled: false });
                                            console.log(error);
                                        });
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    });
                }

            }

            var cognitoUser = this.userPool.getCurrentUser();

            if (cognitoUser) {
                cognitoUser.getSession(function sessionCallback(err, session) {
                    if (err) {
                        console.log(err);
                    } else if (!session.isValid()) {
                        console.log('invalid sess');
                    } else {
                        const authToken = session.getIdToken().getJwtToken();

                        const api = _config.api.invokeUrl + '/createevent';
                        const axiosConfig = {
                            headers: {
                                "Authorization": authToken,
                            }
                        };
                        axios
                            .post(api, data, axiosConfig)
                            .then((response) => {
                                self.setState({ postButtonDisabled: false });
                                console.log(response);
                                onSuccess(response, self);
                            })
                            .catch((error) => {
                                self.setState({ postButtonDisabled: false });
                                console.log(error);
                                onFailure(error);
                            });
                    }
                });
            }
        } else {
            this.setState({ allRequiredComplete: false, postButtonDisabled: false });
        }
    };

    handleAutocompleteChange(text) {
        if (window.google.maps.places && !this.autoComplete) {
            this.componentDidMount();
        }
        this.setState({ address: text });
    }

    handleThumbnailChange(thumbnail) {
        this.setState({ thumbnail: thumbnail });
    }

    handleThumbnailURLChange(thumbnailURL) {
        this.setState({ thumbnailURL: thumbnailURL });
    }

    handleTitleChange(text) {
        this.setState({ title: text.target.value });
    }

    handleHostNameChange(text) {
        this.setState({ hostName: text.target.value });
    }

    handleInstitutionChange(text) {
        this.setState({ institution: text.target.value });
    }

    handleSpeakerBioChange(e) {
        this.setState({ speakerBio: e.target.value });
    }

    handleEventTypeChange(option) {
        this.setState({ eventType: option })
    }

    handleDescriptionChange(text) {
        this.setState({ description: text.target.value });
    }

    handleKeywordsChange(text) {
        this.setState({ keywords: text });
    }

    handleGroupChange(text) {
        this.setState({ group: text });
    }

    handleStartDateChange(datetime) {
        this.setState({
            startDate: datetime,
        });

    }

    handleEndDateChange(datetime) {
        this.setState({
            endDate: datetime,
        });
    }

    handleTimeZoneChange(option) {
        this.setState({ timeZone: option });
    }

    handleRefFilesChange(files) {
        this.setState({ refFiles: files })
    }

    handleAdditionalInfoChange(text) {
        this.setState({ addInfo: text.target.value });
    }

    handleMeetingLinkChange(url) {
        this.setState({ meetingLink: url.target.value, address: this.state.address });
    }

    handleTalkWebpageChange(url) {
        this.setState({ talkWebpage: url.target.value });
    }

    handleMeetingDetailsChange(text) {
        this.setState({ meetingDetails: text })
    }

    handleVisibilityChange(option) {
        this.setState({ visibility: option });
    }

    setDiscardPopup(bool) {
        this.setState({ discardPopup: bool });
    }

    setSubmitPopup(bool) {
        this.setState({ submitPopup: bool });
    }


    render() {
        return (
            <>
                <div>
                    <form onSubmit={(e) => this.submitForm(e)}>
                        <div>
                            <div className={styles.thumbnailUploader}>Thumbnail</div>
                            <div>
                                {this.state.thumbnail &&
                                    <p style={{ fontSize: '12px', marginBottom: '4px' }}>
                                        Uploaded file: <span style={{ color: 'green' }}>{this.state.thumbnail.name}</span>
                                    </p>
                                }
                            </div>
                            < ThumbnailUploader
                                onFileSelect={this.handleThumbnailChange}
                                eventId={this.state.eventId}
                                onS3Upload={this.handleThumbnailURLChange}
                                thumbnailURL={this.state.thumbnailURL}
                                userPool={this.userPool}
                            />
                            <Box sx={{
                                fontSize: '20px',
                                fontWeight: '500',
                                lineHeight: '24px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                            }}>Talk details</Box>
                            <div>
                                <TextField
                                    id='title'
                                    label="Title required"
                                    variant='standard'
                                    autoFocus
                                    fullWidth
                                    multiline
                                    onChange={this.handleTitleChange}
                                    value={this.state.title}
                                    required={true}
                                    margin='normal'
                                    error={!this.state.allRequiredComplete && !this.state.title}
                                />
                                <TextField
                                    id='abstract'
                                    label="Abstract required"
                                    variant='standard'
                                    fullWidth
                                    multiline
                                    onChange={this.handleDescriptionChange}
                                    value={this.state.description}
                                    required={true}
                                    margin='normal'
                                    error={!this.state.allRequiredComplete && !this.state.description}
                                />

                                <Autocomplete
                                    multiple
                                    id="tags"
                                    options={keywordsArray.map((option) => option)}
                                    fullWidth
                                    value={this.state.keywords}
                                    onChange={(event, text) => this.handleKeywordsChange(text)}
                                    freeSolo
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            margin='normal'
                                            variant="standard"
                                            label="Keywords"
                                            placeholder={'Enter keywords'}
                                        />
                                    )}
                                />
                                <Box sx={{
                                    display: 'flex', position: 'relative', mt: '36px', pb: '36px'
                                }}>
                                    <div>
                                        <InputLabel variant='standard' id="talk-type-select-label">Talk type *</InputLabel>
                                        <Select
                                            sx={{ minWidth: 168, }}
                                            labelId="talk-type-select-label"
                                            variant='standard'
                                            id="talk-type-select"
                                            value={this.state.eventType}
                                            onChange={(e) => this.setState({ eventType: e.target.value })}
                                            label="Talk type"
                                            displayEmpty
                                            renderValue={(value) => value in eventOptions ? eventOptions[value] : 'Select'}
                                            required>
                                            {Object.keys(eventOptions).map((eventOption, i) => <MenuItem key={eventOption} value={eventOption}>{eventOptions[eventOption]}</MenuItem>)}
                                        </Select>
                                    </div>
                                    <RadioGroup
                                        row
                                        value={this.state.activeLoc}
                                        onChange={(e) => this.setState({ activeLoc: e.target.value })}
                                        aria-labelledby="talk-type-radio-buttons-group-label"
                                        name="talk-type-radio-buttons-group"
                                        sx={{ ml: '24px' }}
                                    >
                                        <FormControlLabel value="Virtual" control={<Radio />} label="Virtual" />
                                        <FormControlLabel value="Recorded" control={<Radio />} label="Recorded" />
                                    </RadioGroup>
                                </Box>


                                {this.state.activeLoc !== 'Recorded' &&
                                    <>
                                        <Typography>
                                            Enter times in your local time zone:
                                            <span style={{ color: 'green' }}>{` ${moment.tz(moment.tz.guess()).format('z')} - ${moment.tz.guess()}`}</span>
                                        </Typography>
                                        <div className={styles.dateTimePickerDiv}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DateTimePicker
                                                    renderInput={(props) => <TextField {...props} margin='none' variant='standard' />}
                                                    label="Start time"
                                                    minDate={new Date()}
                                                    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 4))}
                                                    value={this.state.startDate}
                                                    onChange={this.handleStartDateChange}
                                                    clearable
                                                />
                                            </LocalizationProvider>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DateTimePicker
                                                    renderInput={(props) => <TextField {...props} margin='none' variant='standard' />}
                                                    label="End time"
                                                    minDate={this.state.startDate ? new Date(new Date(this.state.startDate).setHours(this.state.startDate.getHours() + 1)) : new Date()}
                                                    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 4))}
                                                    value={this.state.endDate}
                                                    onChange={this.handleEndDateChange}
                                                    clearable
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </>
                                }
                                {(!this.state.allRequiredComplete && (!this.state.startDate || !this.state.endDate)) &&
                                    <div style={{ color: 'red' }}>
                                        *** Set the start and end date/time. ***
                                    </div>

                                }
                                {this.state.activeLoc === "Virtual" &&
                                    <TextField
                                        // type='url'
                                        id='meeting-url'
                                        label='Link to join required'
                                        fullWidth
                                        required
                                        variant='standard'
                                        margin='none'
                                        placeholder='Copy and paste the link to join the meeting.'
                                        onChange={this.handleMeetingLinkChange}
                                        value={this.state.meetingLink}
                                        error={!this.state.allRequiredComplete && !this.state.meetingLink}
                                        helperText={this.state.meetingLink}
                                    />/* make sure this is checking URL validation */
                                }
                                {this.state.activeLoc === "Hybrid" &&
                                    <>
                                        <TextField
                                            // type='url'
                                            id='meeting-url'
                                            label='Link to join'
                                            fullWidth
                                            required
                                            variant='standard'
                                            margin='none'
                                            placeholder='Copy and paste the link to join the meeting.'
                                            onChange={this.handleMeetingLinkChange}
                                            value={this.state.meetingLink}
                                            error={!this.state.allRequiredComplete && !this.state.meetingLink}

                                        />
                                        {/* <AutoCompleteField
                                        label='Venue Location'
                                        id='autocomplete'
                                        autoRef={this.autoCompleteRef}
                                        placeholder='Search for a venue or address'
                                        onChange={this.handleAutocompleteChange}
                                        value={this.state.address}
                                        required={true}
                                        /> */}
                                    </>
                                }
                                {this.state.activeLoc === "Recorded" &&
                                    <TextField
                                        // type='url'
                                        id='recording-url'
                                        label='Link to recording'
                                        fullWidth
                                        required
                                        variant='standard'
                                        margin='none'
                                        placeholder='Copy and paste the link to the recording.'
                                        onChange={this.handleMeetingLinkChange}
                                        value={this.state.meetingLink}
                                        error={!this.state.allRequiredComplete && !this.state.meetingLink && !(validator.isURL(this.state.meetingLink))}

                                    />}

                                <div className={styles.SpeakerDetailsTitle} style={{ marginTop: '24px', marginBottom: '0px' }}>Speaker details</div>
                                <div className={styles.nameAndAffiliationContainer}>
                                    <TextField
                                        label="Name"
                                        id='speaker-name'
                                        fullWidth
                                        variant='standard'
                                        margin='normal'
                                        onChange={this.handleHostNameChange}
                                        value={this.state.hostName}
                                        required={true}
                                        error={!this.state.allRequiredComplete && !this.state.hostName}
                                    />

                                    <TextField
                                        sx={{ ml: '8px' }}
                                        className={styles.affiliationTextField}
                                        label="Affiliation(s)"
                                        id='affiliation'
                                        fullWidth
                                        variant='standard'
                                        margin='normal'
                                        placeholder='University, company, etc.'
                                        onChange={this.handleInstitutionChange}
                                        value={this.state.institution}
                                    />
                                </div>
                                <TextField
                                    label="Bio(s)"
                                    id='speaker-bio'
                                    fullWidth
                                    multiline
                                    variant='standard'
                                    margin='normal'
                                    onChange={this.handleSpeakerBioChange}
                                    value={this.state.speakerBio}
                                />
                            </div>
                        </div >

                        <div className={styles.optionalTitle}>Optional</div>
                        <TextField
                            // type='url'
                            id='talk-webpage'
                            label='Talk Webpage'
                            fullWidth
                            variant='standard'
                            margin='none'
                            onChange={this.handleTalkWebpageChange}
                            value={this.state.talkWebpage}
                        />
                        <TextField
                            id='additional-info'
                            label="Additional Information"
                            variant='standard'
                            fullWidth
                            multiline
                            onChange={this.handleAdditionalInfoChange}
                            value={this.state.addInfo}
                            margin='normal'
                            placeholder='Passcodes, call-in details, etc.'
                        />
                        <Box sx={{ color: '#0288D1' }}>
                            *required fields
                        </Box>
                        <div className={styles.submitDivCreateTalk}>
                            <Button
                                type="button"
                                variant='contained'
                                onClick={() => this.setDiscardPopup(true)}
                                sx={{
                                    background: 'white',
                                    border: '2px solid #FEBA3F',
                                    ":hover": {
                                        bgcolor: '#eeeeee',
                                    }
                                }}>Cancel</Button>
                            <Button
                                type="submit"
                                disabled={this.state.postButtonDisabled}
                                variant="contained"
                                sx={{ color: '#1B065E', ml: '12px', }}
                            >
                                Post
                            </Button>
                        </div>
                        <Popup
                            trigger={this.state.discardPopup}
                            setTrigger={this.setDiscardPopup}
                        >
                            <Typography variant='h3'>Discard your entry?</Typography>
                            <div className={styles.popupButtonDiv}>
                                <Button
                                    type="button"
                                    variant='contained'
                                    className={styles.goBackButton}
                                    onClick={() => this.setDiscardPopup(false)}
                                    sx={{
                                        background: 'white',
                                        border: '2px solid #FEBA3F',
                                        ":hover": {
                                            bgcolor: '#eeeeee',
                                        }
                                    }}>Cancel</Button>
                                <Button
                                    variant="contained"
                                    className={styles.discardPopupButton}
                                    sx={{ color: '#1B065E', ml: '12px', }}
                                    onClick={this.props.closeCreateTalkPopup}
                                >
                                    Discard
                                </Button>

                            </div>
                        </Popup>
                    </form >
                </div >

            </>

        );
    }

}

export default CreateTalkForm;