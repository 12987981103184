import React, { useState, useEffect } from 'react';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import _config from '../../signup/config';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import RequestService from '../../requestservice/requestservice.js';
import { Divider, Pagination, Typography } from '@mui/material';
import { withRouter } from 'react-router-dom';
import JobCard from '../JobCard/JobCard';
import styles from './JobsFeed.module.css';

const JobsFeed = ({ query, setFeedLoaded, location, allUserItems, fullLeftBar, userJobs, history }) => {
  const queryParams = new URLSearchParams(location.search);
  const initialPageNum = Number(queryParams.get('page')) || 1;

  const [jobItems, setJobItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(initialPageNum);

  const poolData = {
    UserPoolId: _config.cognito.userPoolId,
    ClientId: _config.cognito.userPoolClientId
  };

  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();
  const requestService = new RequestService();

  useEffect(() => {
    if (setFeedLoaded && !loading) {
      setFeedLoaded(true);
    }
  }, [loading]);

  useEffect(() => {
    if (!jobItems) return;
    if (query) {
      const data = { searchQuery: query };
      requestService.searchJobs(data, (loadedJobItems) => {
        setJobItems(loadedJobItems);
        setLoading(false);
      });
    } else if (userJobs) {
      setJobItems(userJobs);
      setLoading(false);
    } else {
      requestService.getJobsFeed(currentPage, (loadedJobItems) => {
        setJobItems(loadedJobItems);
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    requestService.getJobsFeed(this);
  }, [currentPage]);

  const handlePaginationChange = (event, value) => {
    history.push(`${location.pathname}?page=${value}`);
    setCurrentPage(value);
  };

  const url = new URL(window.location.href);
  const searchQuery = queryParams.get('q');

  return (
    <div>
      {loading ? (
        <div className={styles.LoadingDiv}>
          <Typography sx={{ m: '8px', color: '#1B065E' }} variant='h1'>Loading...</Typography>
          <ClipLoader
            color={"var(--primary-color)"}
            loading={loading}
            size={100}
          />
        </div>
      ) : (
        jobItems.map((e, index) => (
          <div key={e.JobId}>
            <JobCard
              key={e.jobId}
              jobId={e.jobId}
              jobTitle={e.jobTitle}
              description={e.description}
              employer={e.employer}
              userId={e.userId}
              compensation={e.compensation}
              location={e.location}
              qualifications={e.qualifications}
              disclaimers={e.disclaimers}
              companyOverview={e.companyOverview}
              instructions={e.instructions}
              benefits={e.benefits}
              thumbnailUrl={e.thumbnailUrl}
              applicationDeadline={e.applicationDeadline}
              datePosted={e.datePosted}
              sourceJobLink={e.sourceJobLink}
              allUserItems={allUserItems}
              fullLeftBar={fullLeftBar}
            />
          </div>
        ))
      )}
      {!searchQuery && cognitoUser && jobItems.length !== 0 && !loading && jobItems[0].pages > 1 && (
        <div className={styles.PaginationButtons}>
          <Pagination page={currentPage} count={jobItems[0].pages} onChange={handlePaginationChange} />
        </div>
      )}
      {!loading && jobItems.length === 0 && <h3 className={styles.NoJobsText}>No jobs found.</h3>}
    </div>
  );
};

export default withRouter(JobsFeed);