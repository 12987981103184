import styles from './logoStyles.module.css';
import { Link } from 'react-router-dom';
import _config from "../../signup/config";
import new_beta_logo from '../../../images/symposia_beta_logo_noBG.svg'
import favicon_logo from '../../../images/Favicon_bulb.svg';
import { Box } from '@mui/material';

function Logo({ width, centerImg = false, }) {
  const isMobile = window.innerWidth <= 768;
  const defaultWidth = isMobile ? '140px' : '196px';
  return (
    <>
      <Box sx={{ alignSelf: centerImg ? 'center' : 'flex-start' }}>
        <Link to='/' className={styles.navlogoDiv}>
          <img className={styles.Logo} width={width ?? defaultWidth} src={new_beta_logo} alt='Logo' />
        </Link>
      </Box>
    </>
  )
}

function FaviconLogo({ }) {

  return (
    <>
      <Link to='/' className={styles.navlogoDiv}>
        <img src={favicon_logo} alt='Logo' />
      </Link>
    </>
  )
}

export default Logo;
export { FaviconLogo };
