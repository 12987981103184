import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import _config from "../signup/config";
import styles from './createaccountStyles.module.css';
import crypto from 'crypto-browserify';
import CreateProfile from "../CreateProfile/CreateProfile";
import TopBar from "../components/TopBar/TopBar";
import { AppBar, Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import RequestService from "../requestservice/requestservice";
import Logo from "../components/logo/logo";

const randomBytes = crypto.randomBytes;

function toUrlString(buffer) {
    return buffer.toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

function CreateAccount(props) {
    const isMobile = window.innerWidth <= 768;
    const [state, setState] = useState({
        signedInUserId: '',
        userId: toUrlString(randomBytes(16)),
        firstNameValue: '',
        lastNameValue: '',
        suffixValue: '',
        jobTitleValue: '',
        pronouns: '',
        websiteValue: '',
        organizationValue: '',
        bioValue: '',
        email: '',
        areasOfInterest: [],
        profilePictureUrl: '',
        prefix: '',
        isEmailVisible: false,
        cvUrl: '',
        isEditMode: false,
        isLoading: false,
        profilePictureUploadTime: '',
        cvUploadTime: '',
        pictureLoading: false,
    });

    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
        UserPoolId: _config.cognito.userPoolId,
        ClientId: _config.cognito.userPoolClientId,
    });

    const cognitoUser = userPool.getCurrentUser();
    const theme = useTheme();
    const requestService = new RequestService();
    document.body.style.background = theme.palette.secondary.background;

    useEffect(() => {
        if (cognitoUser) {
            cognitoUser.getSession(function sessionCallback(err, session) {
                if (err) {
                    console.log(err);
                } else if (!session.isValid()) {
                    console.log('invalid sess');
                } else {
                    cognitoUser.getUserAttributes(function (err, result) {
                        if (err) {
                            console.log(err);
                        } else {
                            const userHasUserId = result[3]?.Name === 'custom:userId' && result[3]?.Value;
                            const signedInUserId = result[3]?.Value;
                            const isEditing = props.match.path === '/edituser/:userId';
                            if (isEditing && userHasUserId) {
                                setState(prevState => ({ ...prevState, userId: signedInUserId }));
                                setState(prevState => ({ ...prevState, userId: signedInUserId }));
                                getUserData();
                            } else if (result[3] && result[3].Name === 'custom:userId' && result[3].Value) {
                                window.location.href = '/user/' + result[3].Value;
                            }
                            setState(prevState => ({ ...prevState, email: result[2]?.Value }));
                        }
                    });
                }
            });
        }
    }, []);

    const getUserData = () => {
        var cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.getSession(function sessionCallback(err, session) {
                if (err) {
                    console.log(err);
                } else if (!session.isValid()) {
                    console.log('invalid sess');
                } else {
                    const authToken = session.getIdToken().getJwtToken();

                    const api = _config.api.invokeUrl + '/getuser?userId=' + state.userId;
                    const axiosConfig = {
                        headers: {
                            "Authorization": authToken,
                        }
                    };
                    axios
                        .get(api, axiosConfig)
                        .then((response) => {
                            setState(prevState => ({
                                ...prevState,
                                firstNameValue: response.data.firstName,
                                lastNameValue: response.data.lastName,
                                suffixValue: response.data.suffix,
                                jobTitleValue: response.data.jobTitle,
                                websiteValue: response.data.website,
                                bioValue: response.data.bio,
                                organizationValue: response.data.organization,
                                userEvents: response.data.userEvents,
                                userLikedEvents: response.data.userLikedEvents,
                                email: response.data.email.replace('-at-', '@'),
                                areasOfInterest: response.data.areasOfInterest,
                                pronouns: response.data.pronouns,
                                prefix: response.data.prefix,
                                isEmailVisible: response.data.isEmailVisible,
                                profilePictureUrl: response.data.profilePictureUrl,
                                cvUrl: response.data.cvUrl,
                                pageUserId: response.data.userId,
                            }));
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            });
        }
    };

    return (
        <section>
            {/* <TopBar hideNotifications></TopBar> */}
            <AppBar position="fixed" elevation={0} style={{ zIndex: 1201, background: theme.palette.primary.mainGradient }}
            >
                <Logo width={'240px'} centerImg={isMobile}></Logo>
            </AppBar>
            <Box sx={{ position: 'relative', top: '84px', display: 'flex', justifyContent: 'center', }}>
                <Box sx={{ background: theme.palette.secondary.light, padding: isMobile ? '36px 24px' : '48px', boxShadow: '0px 0px 14px 0px #0000001A;' }}>
                    <CreateProfile
                        userId={state.userId}
                        profileState={state}
                        updatedProfile={() => console.log('unused')}
                        requestService={requestService}
                    />
                </Box>
            </Box>
        </section>
    );
}

export default CreateAccount;
