import React, { Component } from 'react'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import Amplify from "aws-amplify";
import AWS from 'aws-sdk';
import ClipLoader from "react-spinners/ClipLoader";
import avatar_icon from '../../images/avatar-pic-24.svg'
import { Verified, PostAddOutlined, Visibility, Edit, EventOutlined, Language, Email } from '@mui/icons-material';
import awsExports from "../../aws-exports";
import axios from 'axios';
import { Accordion, AccordionDetails, AccordionSummary, Tab, Tabs, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import 'react-loading-skeleton/dist/skeleton.css'
import { Divider, Tooltip, IconButton, Button } from '@mui/material';
import KeywordSmall from '../components/KeywordSmall/KeywordSmall';
import {
  withRouter
} from "react-router-dom";
import styles from './ProfileStyles.module.css';
import DialogBox from '../components/DialogBox/DialogBox';
import CreateProfile from '../CreateProfile/CreateProfile';
import { ToastContainer, toast } from 'react-toastify';
import ProfileSavedTalks from '../components/ProfileSavedTalks/ProfileSavedTalks';
import ProfileSavedPapers from '../components/ProfileSavedPapers/ProfileSavedPapers';
import RequestService from "../requestservice/requestservice";
import CreateTalkForm from '../CreateTalkForm/CreateTalkForm';
import CreatePaperForm from '../CreatePaperForm/CreatePaperForm';
import TalksFeed from '../components/TalksFeed/TalksFeed';
import PapersFeed from '../components/PapersFeed/PapersFeed';
import ProfileLikesFeed from '../components/ProfileLikesFeed/ProfileLikesFeed';
import ArticleIcon from '@mui/icons-material/Article';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

Amplify.configure(awsExports);
const SESConfig = {
  apiVersion: "2010-12-01",
  accessKeyId: _config.s3.accessKeyId,
  accessSecretKey: _config.s3.secretAccessKey,
  region: "us-east-2"
}
AWS.config.update(SESConfig);
const prefixOptions = {
  'Mr.': 'mr',
  'Mrs.': 'mrs',
  'Ms.': 'ms',
  'Miss.': 'miss',
  'Mx.': 'mx',
  'Dr.': 'dr',
  'Prof.': 'prof',
  'Rev.': 'rev',
};

const pronounsOptions = {
  'She/her/hers': 'she',
  'He/him/his': 'he',
  'They/them/theirs': 'they',
  'Ze/zir/zirs': 'ze',
  'Other': 'other',
};
const respectablePrefixes = new Set(['Dr.', 'Prof.', 'Rev.']);
// TODO: Convert to react hooks instead of react component.
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstNameValue: '',
      lastNameValue: '',
      suffixValue: '',
      jobTitleValue: '',
      websiteValue: '',
      organizationValue: '',
      feed: 0, // talks
      bioValue: '',
      areasOfInterest: [],
      userEvents: [],
      userSavedEvents: [],
      userRsvpEvents: [],
      userLikedEvents: [],
      userPapers: [],
      userSavedPapers: [],
      userReadPapers: [],
      userLikedPapers: [],
      profilePictureUrl: '',
      email: '',
      pronouns: '',
      prefix: '',
      isEmailVisible: false,
      cvUrl: '',
      signedInUserId: '',
      pageUserId: '',
      loadingEvents: true,
      openEditProfilePopup: false,
      openTalksPopup: false,
      openPapersPopup: false,
      profilePicturePopup: false,
    };

    this.getAccount = this.getAccount.bind(this);
    this.goToUserWebsite = this.goToUserWebsite.bind(this);
    this.emailUser = this.emailUser.bind(this);
    this.toggleState = this.toggleState.bind(this);
    this.editUser = this.editUser.bind(this);
    this.handleEditProfileOpen = this.handleEditProfileOpen.bind(this);
    this.handleEditProfileClose = this.handleEditProfileClose.bind(this);
    this.goToCv = this.goToCv.bind(this);
    this.updatedProfile = this.updatedProfile.bind(this);
    this.removeFromSavedTalks = this.removeFromSavedTalks.bind(this);
    this.removeFromRsvpTalks = this.removeFromRsvpTalks.bind(this);
    this.fromSavedToRsvpTalk = this.fromSavedToRsvpTalk.bind(this);
    this.fromSavedToReadPapers = this.fromSavedToReadPapers.bind(this);
    this.fromReadToSavedPapers = this.fromReadToSavedPapers.bind(this);
    this.removeFromSavedPapers = this.removeFromSavedPapers.bind(this);
    this.removeFromReadPapers = this.removeFromReadPapers.bind(this);
    this.handleTalksPopupOpen = this.handleTalksPopupOpen.bind(this);
    this.handleTalksPopupClose = this.handleTalksPopupClose.bind(this);
    this.handlePapersPopupOpen = this.handlePapersPopupOpen.bind(this);
    this.handlePapersPopupClose = this.handlePapersPopupClose.bind(this);
    this.handleAddedSavedPaper = this.handleAddedSavedPaper.bind(this);
    this.handleRemoveSavedPaper = this.handleRemoveSavedPaper.bind(this);
    this.handleAddedSavedTalk = this.handleAddedSavedTalk.bind(this);
    this.handleRemoveSavedTalk = this.handleRemoveSavedTalk.bind(this);
    this.handleAddedUpcomingTalk = this.handleAddedUpcomingTalk.bind(this);
    this.handleRemoveUpcomingTalk = this.handleRemoveUpcomingTalk.bind(this);
    this.setProfilePicturePopup = this.setProfilePicturePopup.bind(this);
    this.getKeyByValue = this.getKeyByValue.bind(this);
    var poolData = {
      UserPoolId: _config.cognito.userPoolId,
      ClientId: _config.cognito.userPoolClientId,
    };
    this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    this.requestService = this.props.requestService ?? new RequestService();
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  componentDidMount() {
    if (this.props.user) {
      const user = this.props.user;
      this.setState({
        firstNameValue: user.firstName,
        lastNameValue: user.lastName,
        profilePictureUrl: user.profilePictureUrl,
        organizationValue: user.organization,
        jobTitleValue: user.jobTitle,
      });
    }
    const userId = this.props.match.params.userId;
    this.getAccount(userId);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.match.params.userId !== prevProps.match.params.userId) {
      this.setState({ loadingEvents: true });
      if (this.props.location.state) {
        const user = this.props.location.state;
        this.setState({
          firstNameValue: user.firstName,
          lastNameValue: user.lastName,
          profilePictureUrl: user.profilePictureUrl,
          organizationValue: user.organization,
          jobTitleValue: user.jobTitle,
          loadingEvents: true,
          areasOfInterest: [],
          userEvents: [],
          userSavedEvents: [],
          userRsvpEvents: [],
          userLikedEvents: [],
          userPapers: [],
          userSavedPapers: [],
          userReadPapers: [],
          userLikedPapers: [],
        });
      }
      const userId = this.props.match.params.userId;
      this.getAccount(userId);
    }
  }

  getAccount(userId) {
    const cognitoUser = this.userPool.getCurrentUser();
    let self = this;

    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              if (result[3].Name === 'custom:userId' && result[3].Value) {
                self.setState({ signedInUserId: result[3].Value });
              }

              const authToken = session.getIdToken().getJwtToken();

              const api = `${_config.api.invokeUrl}/getuser?userId=${userId}&selfUserId=${self.state.signedInUserId}`;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .get(api, axiosConfig)
                .then((response) => {
                  // getProfilePicture(userId, self);
                  self.setState({
                    firstNameValue: response.data.firstName,
                    lastNameValue: response.data.lastName,
                    suffixValue: response.data.suffix,
                    jobTitleValue: response.data.jobTitle,
                    websiteValue: response.data.website,
                    bioValue: response.data.bio,
                    organizationValue: response.data.organization,
                    userEvents: response.data.userEvents,
                    userSavedEvents: response.data.userSavedEvents,
                    userRsvpEvents: response.data.userRsvpEvents,
                    userLikedEvents: response.data.userLikedEvents,
                    userPapers: response.data.userPapers,
                    userSavedPapers: response.data.userSavedPapers,
                    userReadPapers: response.data.userReadPapers,
                    userLikedPapers: response.data.userLikedPapers,
                    email: response.data.email.replace('-at-', '@'),
                    areasOfInterest: response.data.areasOfInterest,
                    pronouns: response.data.pronouns ? pronounsOptions[response.data.pronouns] : '',
                    prefix: response.data.prefix ? prefixOptions[response.data.prefix] : '',
                    isEmailVisible: response.data.isEmailVisible,
                    cvUrl: response.data.cvUrl,
                    pageUserId: response.data.userId,
                    loadingEvents: false,
                    profilePictureUrl: response.data.profilePictureUrl,
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
        }
      });
    } else {
      if (userId !== 'PdT4dUHg8CUlZvjdepDK9g') {
        window.location.href = '/signin';
      }
      const api = _config.api.invokeUrl + '/getuser?userId=' + userId;
      axios
        .get(api)
        .then((response) => {
          // getProfilePicture(userId, self);
          self.setState({
            firstNameValue: response.data.firstName,
            lastNameValue: response.data.lastName,
            suffixValue: response.data.suffix,
            jobTitleValue: response.data.jobTitle,
            websiteValue: response.data.website,
            bioValue: response.data.bio,
            organizationValue: response.data.organization,
            userEvents: response.data.userEvents,
            userSavedEvents: response.data.userSavedEvents,
            userRsvpEvents: response.data.userRsvpEvents,
            userLikedEvents: response.data.userLikedEvents,
            userPapers: response.data.userPapers,
            userSavedPapers: response.data.userSavedPapers,
            userReadPapers: response.data.userReadPapers,
            userLikedPapers: response.data.userLikedPapers,
            email: response.data.email.replace('-at-', '@'),
            areasOfInterest: response.data.areasOfInterest,
            pronouns: response.data.pronouns,
            prefix: respectablePrefixes.has(response.data.prefix) ? response.data.prefix : '',
            isEmailVisible: response.data.isEmailVisible,
            cvUrl: response.data.cvUrl,
            pageUserId: response.data.userId,
            loadingEvents: false,
            profilePictureUrl: response.data.profilePictureUrl,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  goToUserWebsite() {
    const startsWithHttp = this.state.websiteValue && this.state.websiteValue.startsWith('http');
    const userSite = startsWithHttp ? this.state.websiteValue : 'https://' + this.state.websiteValue;
    window.open(userSite);
  }

  emailUser() {
    window.open(`mailto:${this.state.email}`);
  }

  goToCv() {
    window.open(this.state.cvUrl);
  }

  toggleState(e, value) {
    if (value !== null) {
      this.setState({ feed: value });
    }
  }

  editUser() {
    window.location = `/edituser/${this.state.signedInUserId}`;
  }

  handleEditProfileOpen() {
    this.setState({ openEditProfilePopup: true });
  }

  handleEditProfileClose() {
    this.setState({ openEditProfilePopup: false });
  }

  async removeFromSavedTalks(eventId) {
    const data = { eventId: eventId };
    this.setState({
      userSavedEvents: this.state.userSavedEvents.filter((event) => event.EventId !== eventId),
    });
    await this.requestService.removeSavedEvent(data, this);
  }

  async removeFromRsvpTalks(eventId) {
    const data = { eventId: eventId };
    this.setState({
      userRsvpEvents: this.state.userRsvpEvents.filter((event) => event.EventId !== eventId),
    });
    await this.requestService.removeFromCalendar(data, this);
    this.props.handleRemoveUpcomingTalk(eventId);
  }

  fromSavedToRsvpTalk(eventId) {
    const event = this.state.userSavedEvents.find((event) => event.EventId === eventId);
    this.setState({
      userRsvpEvents: [...this.state.userRsvpEvents, event],
    });
    this.props.handleAddedUpcomingTalk(event);
  }

  async removeFromSavedPapers(paperId) {
    const data = { paperId: paperId };
    this.setState({
      userSavedPapers: this.state.userSavedPapers.filter((paper) => paper.PaperId !== paperId),
    });
    await this.requestService.removeSavedPaper(data, this);
    this.props.handleRemoveSavedPaper(paperId);
  }

  async removeFromReadPapers(paperId) {
    const data = { paperId: paperId }
    this.setState({
      userReadPapers: this.state.userReadPapers.filter((paper) => paper.PaperId !== paperId),
    });
    await this.requestService.removeReadPaper(data, this);
  }

  async fromSavedToReadPapers(paperId) {
    const data = { paperId: paperId }
    const paper = this.state.userSavedPapers.find((paper) => paper.PaperId === paperId)
    this.setState({
      userSavedPapers: this.state.userSavedPapers.filter((paper) => paper.PaperId !== paperId),
      userReadPapers: [...this.state.userReadPapers, paper],
    });
    await this.requestService.addReadPaper(data, this);
    await this.requestService.removeSavedPaper(data, this);
    this.props.handleRemoveSavedPaper(paperId);
  }

  async fromReadToSavedPapers(paperId) {
    const data = { paperId: paperId }
    const paper = this.state.userReadPapers.find((paper) => paper.PaperId === paperId)
    this.setState({
      userReadPapers: this.state.userReadPapers.filter((paper) => paper.PaperId !== paperId),
      userSavedPapers: [...this.state.userSavedPapers, paper],
    });
    await this.requestService.addSavedPaper(data, this);
    await this.requestService.removeReadPaper(data, this);
    this.props.handleAddedSavedPaper(paper);
  }

  handleAddedSavedPaper(paper) {
    this.props.handleAddedSavedPaper(paper);
    this.setState({ userSavedPapers: [...this.state.userSavedPapers, paper] });
  }

  handleRemoveSavedPaper(paperId) {
    this.props.handleRemoveSavedPaper(paperId);
    this.setState({
      userSavedPapers: this.state.userSavedPapers.filter((paper) => paper.PaperId !== paperId),
    });
  }

  handleAddedSavedTalk(event) {
    this.setState({ userSavedEvents: [...this.state.userSavedEvents, event] });
  }

  handleRemoveSavedTalk(eventId) {
    this.setState({
      userSavedEvents: this.state.userSavedEvents.filter((event) => event.EventId !== eventId),
    });
  }

  handleAddedUpcomingTalk(event) {
    this.props.handleAddedUpcomingTalk(event);
    this.setState({ userRsvpEvents: [...this.state.userRsvpEvents, event] });
  }

  handleRemoveUpcomingTalk(eventId) {
    this.props.handleRemoveUpcomingTalk(eventId);
    this.setState({
      userRsvpEvents: this.state.userRsvpEvents.filter((event) => event.EventId !== eventId),
    });
  }

  handleTalksPopupOpen() {
    const cognitoUser = this.userPool.getCurrentUser();
    if (!cognitoUser) {
      window.location.href = '/signin';
      return;
    }
    this.setState({ openTalksPopup: true });
  };
  handleTalksPopupClose() {
    this.setState({ openTalksPopup: false });
  };

  handlePapersPopupOpen() {
    const cognitoUser = this.userPool.getCurrentUser();
    if (!cognitoUser) {
      window.location.href = '/signin';
      return;
    }
    this.setState({ openPapersPopup: true });
  }

  handlePapersPopupClose() {
    this.setState({ openPapersPopup: false });
  }

  setProfilePicturePopup() {
    this.setState({ profilePicturePopup: !this.state.profilePicturePopup });
  }

  updatedProfile() {
    window.location.reload();
    toast('Account updated!')
  }

  render() {
    const { pageTitle, drawerWidth, history, allUserItems, fullLeftBar, } = this.props;
    const { prefix, firstNameValue, lastNameValue, suffixValue, profilePictureUrl, organizationValue,
      jobTitleValue, areasOfInterest, bioValue, signedInUserId, pageUserId, feed,
      loadingEvents, userEvents, userSavedEvents, userRsvpEvents, userLikedEvents, userPapers, userReadPapers,
      userSavedPapers, userLikedPapers, websiteValue, openEditProfilePopup, openPapersPopup, openTalksPopup } = this.state;
    const currentTime = Date.now();
    const futureSavedTalks = userSavedEvents.filter((talk) => new Date(talk.endDate).getTime() > currentTime || talk.activeLoc === 'Recorded');
    const futureUpcomingTalks = userRsvpEvents.filter((talk) => new Date(talk.endDate).getTime() > currentTime);
    const numberOfPosts = userEvents.length + userPapers.length;
    const userLikes = [...userLikedEvents, ...userLikedPapers];
    return (

      <div className={styles.Profile}>
        <div className={styles.ProfileContainer}>
          <div className={styles.ProfileHeader}></div>
          <div className={styles.UserContentWrapper}>
            <div className={styles.UserProfilePicture} onClick={this.setProfilePicturePopup}>
              {profilePictureUrl && <img className={styles.userProfilePictureImage} src={`${profilePictureUrl}?${Date.now()}`} />}
              {!profilePictureUrl && <img className={styles.userProfilePictureImage} src={avatar_icon} />}
            </div>
            <div className={styles.UserNameAndPosition}>
              <div className={styles.UserNameAndEdit}>
                <div className={styles.UserNameAndIcons}>
                  <Typography variant='h3'>
                    {prefix && respectablePrefixes.has(this.getKeyByValue(prefixOptions, prefix)) && `${this.getKeyByValue(prefixOptions, prefix)} `} {firstNameValue} {lastNameValue} {suffixValue && ` ${suffixValue}`}
                    {firstNameValue && lastNameValue && organizationValue && this.state.profilePictureUrl && <Verified sx={{ ml: '4px', fontSize: '20px', color: '#43a4fe' }} />}
                  </Typography>
                  {this.state.isEmailVisible &&
                    <a href={`mailto:${this.state.email}`}>
                      <Tooltip title={'Email ' + this.state.firstNameValue}>
                        <IconButton onClick={this.emailUser}>
                          <Email sx={{ fontSize: '20px', color: '#6F6F6F', cursor: 'pointer' }} />
                        </IconButton>
                      </Tooltip>
                    </a>
                  }
                </div>
                {signedInUserId && pageUserId && signedInUserId === pageUserId &&
                  <div className={styles.EditButton}>
                    {fullLeftBar ?
                      <Button
                        variant="contained"
                        startIcon={<Edit />}
                        sx={{ color: '#1B065E' }}
                        onClick={this.handleEditProfileOpen}
                      >
                        Edit
                      </Button>
                      :
                      <IconButton
                        onClick={this.handleEditProfileOpen}
                      >
                        <Edit />
                      </IconButton>
                    }
                  </div>
                }
              </div>
              <div className={styles.UserPosition}>
                {jobTitleValue ? <span>{jobTitleValue.trim()}</span> : ''}{organizationValue ? <span> at {organizationValue.trim()}</span> : ''}
              </div>
              <Typography variant='body1'>
                {Object.keys(pronounsOptions).find(key => pronounsOptions[key] === this.state.pronouns)}
              </Typography>
            </div>
            {websiteValue &&
              <div className={styles.UserWebsite} onClick={this.goToUserWebsite}>
                <Language sx={{ mr: '6px', fontSize: '20px', color: '#6F6F6F' }} />
                <span>{websiteValue}</span>
              </div>
            }
            {areasOfInterest.length > 0 &&
              <div className={styles.AreasOfInterest}>
                <Typography variant='h4' sx={{ color: '#212121' }}>
                  Areas of Interest
                </Typography>
                <div className={styles.Keywords}>
                  {areasOfInterest.map((areaOfInterest) => {
                    return (
                      <KeywordSmall
                        key={areaOfInterest}
                        label={areaOfInterest}
                      />
                    );
                  })}
                </div>
              </div>}

            {bioValue &&
              <>
                <div className={styles.BioSection}>
                  <div className={styles.BioTitle}>
                    <Typography variant='h4' sx={{ color: '#212121' }}>
                      Bio
                    </Typography>
                  </div>
                  <div className={styles.BioInfo}>
                    <Typography variant='body1' sx={{ color: '#616161' }}>
                      {bioValue}
                    </Typography>
                  </div>
                </div>
              </>}
            {/* Add a counter for the papers in the database and lambdas */}
            <div className={styles.SharedPapersAndTalks}>
              <div className={styles.SharedPapers}>
                <span style={{ fontWeight: 'bold', marginRight: '8px' }}>{userPapers ? userPapers.length : 0}</span>Shared Papers
              </div>
              <div className={styles.SharedTalks}>
                <span style={{ fontWeight: 'bold', marginRight: '8px' }}>{userEvents ? userEvents.length : 0}</span>Shared Talks
              </div>
            </div>
          </div>
          <Divider />
          {signedInUserId && pageUserId && signedInUserId === pageUserId &&
            <>
              <div className={styles.PapersSection}>
                <Accordion sx={{ width: '-webkit-fill-available' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{
                      fontSize: '18px',
                      fontWeight: '600',
                      lineHeight: '24px',
                      letterSpacing: '0em',
                      textAlign: 'left',
                    }}>Saved papers</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {userSavedPapers ?
                        <div className={styles.Papers}>
                          <ProfileSavedPapers
                            papers={userSavedPapers}
                            type='saved'
                            fullLeftBar={fullLeftBar}
                            fromSavedToReadPapersCall={this.fromSavedToReadPapers}
                            removeFromSavedPapersCall={this.removeFromSavedPapers}
                            userId={this.state.signedInUserId}
                            requestService={this.requestService}
                          />
                        </div>
                        :
                        <div className={styles.NoPapers}>
                          You haven't saved any papers yet.
                        </div>
                      }
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion sx={{ width: '-webkit-fill-available' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{
                      fontSize: '18px',
                      fontWeight: '600',
                      lineHeight: '24px',
                      letterSpacing: '0em',
                      textAlign: 'left',
                    }}>Read papers</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {userReadPapers ?
                        <div className={styles.Papers}>
                          <ProfileSavedPapers
                            papers={userReadPapers}
                            type='read'
                            fullLeftBar={fullLeftBar}
                            fromReadToSavedPapersCall={this.fromReadToSavedPapers}
                            removeFromReadPapersCall={this.removeFromReadPapers}
                            userId={this.state.signedInUserId}
                            requestService={this.requestService}
                          />
                        </div>
                        :
                        <div className={styles.NoPapers}>
                          You haven't marked any papers as read yet.
                        </div>
                      }
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion sx={{ width: '-webkit-fill-available' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{
                      fontSize: '18px',
                      fontWeight: '600',
                      lineHeight: '24px',
                      letterSpacing: '0em',
                      textAlign: 'left',
                    }}>Saved talks</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {userSavedEvents && userSavedEvents[0] ?
                        <div className={styles.Talks}>
                          <ProfileSavedTalks
                            userTalks={futureSavedTalks}
                            removeFromSavedTalksCall={this.removeFromSavedTalks}
                            fromSavedToRsvpTalkCall={this.fromSavedToRsvpTalk}
                            type='saved' />
                        </div>
                        :
                        <div className={styles.NoPapers}>
                          You haven't saved any talks yet.
                        </div>

                      }
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion sx={{ width: '-webkit-fill-available' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{
                      fontSize: '18px',
                      fontWeight: '600',
                      lineHeight: '24px',
                      letterSpacing: '0em',
                      textAlign: 'left',
                    }}>Upcoming talks</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {userRsvpEvents && userRsvpEvents[0] ?
                        <div className={styles.Talks}>
                          <ProfileSavedTalks
                            userTalks={futureUpcomingTalks}
                            removeFromRsvpTalksCall={this.removeFromRsvpTalks}
                            type='upcoming' />
                        </div>
                        :
                        <div className={styles.NoPapers}>
                          You haven't added any talks to your calendar yet.
                        </div>}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              <Divider />
            </>}
          <div className={styles.FeedContainer}>
            <Tabs
              TabIndicatorProps={{
                style: {
                  background: '#009891',
                }
              }}
              sx={{
                '& .MuiTabs-scroller': {
                  borderBottom: '1px solid #E0E0E0',
                }
              }}
              value={feed} onChange={this.toggleState}
              aria-label="talks, papers, and likes tabs">t
              <Tab icon={<CoPresentIcon />} sx={{
                '&.Mui-selected': {
                  color: "#009891",
                },
              }} iconPosition="start" label="talks" />
              <Tab sx={{
                '&.Mui-selected': {
                  color: "#009891",
                },
              }} icon={<ArticleIcon />} iconPosition="start" label="papers" />
              <Tab sx={{
                '&.Mui-selected': {
                  color: "#009891",
                },
              }} icon={<FavoriteIcon />} iconPosition="start" label="likes" />
            </Tabs>
            {loadingEvents ?
              <div className={styles.loadingDiv}>
                <Typography sx={{ p: '16px', color: '#1B065E' }} variant='h1'>Loading...</Typography>
                <ClipLoader
                  color={"var(--primary-color)"}
                  loading={loadingEvents}
                  size={100}
                />
              </div>
              :
              <div>
                {/* Talks */}
                {feed === 0 &&
                  <div>
                    {!(userEvents[0]) ?
                      <div>
                        {signedInUserId && pageUserId && (signedInUserId === pageUserId) ?
                          <div className={styles.NoPapersCentered}>
                            You haven't posted any talks yet.
                          </div>
                          :
                          <div className={styles.NoPapersCentered}>
                            {firstNameValue} hasn't posted any talks yet.
                          </div>
                        }
                      </div>
                      :
                      <TalksFeed
                        userEvents={userEvents}
                        allUserItems={allUserItems}
                        fullLeftBar={fullLeftBar}
                        handleAddedUpcomingTalk={this.handleAddedUpcomingTalk}
                        handleRemoveUpcomingTalk={this.handleRemoveUpcomingTalk}
                        handleAddedSavedTalk={this.handleAddedSavedTalk}
                        handleRemoveSavedTalk={this.handleRemoveSavedTalk} />
                    }
                  </div>
                }
                {/* Papers */}
                {feed === 1 &&
                  <div>
                    {!(userPapers[0]) ?
                      <div>
                        {signedInUserId && pageUserId && (signedInUserId === pageUserId) ?
                          <div className={styles.NoPapersCentered}>
                            You haven't posted any papers yet.
                          </div>
                          :
                          <div className={styles.NoPapersCentered}>
                            {firstNameValue} hasn't posted any papers yet.
                          </div>
                        }
                      </div>
                      :
                      <PapersFeed
                        userPapers={userPapers}
                        allUserItems={allUserItems}
                        fullLeftBar={fullLeftBar}
                        handleAddedSavedPaper={this.handleAddedSavedPaper}
                        handleRemoveSavedPaper={this.handleRemoveSavedPaper} />
                    }
                  </div>
                }
                {/* Likes */}
                {feed === 2 &&
                  <div>
                    {!(userLikes[0]) ?
                      <div>
                        {signedInUserId && pageUserId && (signedInUserId === pageUserId) ?
                          <div className={styles.NoPapersCentered}>
                            You haven't liked anything yet.
                          </div>
                          :
                          <div className={styles.NoPapersCentered}>
                            {firstNameValue} hasn't liked anything yet.
                          </div>
                        }
                      </div>
                      :
                      <ProfileLikesFeed
                        userLikes={userLikes}
                        allUserItems={allUserItems}
                        fullLeftBar={fullLeftBar}
                        handleAddedSavedPaper={this.handleAddedSavedPaper}
                        handleRemoveSavedPaper={this.handleRemoveSavedPaper}
                        handleAddedUpcomingTalk={this.handleAddedUpcomingTalk}
                        handleRemoveUpcomingTalk={this.handleRemoveUpcomingTalk}
                        handleAddedSavedTalk={this.handleAddedSavedTalk}
                        handleRemoveSavedTalk={this.handleRemoveSavedTalk} />
                    }
                  </div>}
              </div>}
          </div>

        </div>
        <DialogBox
          open={openEditProfilePopup}
          handleClose={this.handleEditProfileClose}
        // title='Account info'
        >
          <CreateProfile
            closeEditProfilePopup={this.handleEditProfileClose}
            userId={this.state.pageUserId}
            profileState={this.state}
            updatedProfile={this.updatedProfile}
            isEditMode
          />
        </DialogBox>

        {
          profilePictureUrl &&
          <DialogBox
            open={this.state.profilePicturePopup}
            handleClose={this.setProfilePicturePopup}
            fullWidth={true}
            maxWidth='md'
          >
            <div className={styles.ProfilePicturePopupDiv}>
              <img className={styles.ProfilePicturePopupImage} src={`${profilePictureUrl}?${Date.now()}`} />
            </div>
          </DialogBox>
        }
        <DialogBox
          open={openTalksPopup}
          handleClose={this.handleTalksPopupClose}
          title='Post a Talk/Workshop'
        >
          <CreateTalkForm
            closeCreateTalkPopup={this.handleTalksPopupClose}
          />
        </DialogBox>
        <DialogBox
          open={openPapersPopup}
          handleClose={this.handlePapersPopupClose}
          title='Post a Paper'
        >
          <CreatePaperForm
            closeCreatePaperPopup={this.handlePapersPopupClose}
            userId={this.props.match.params.userId}
          />
        </DialogBox>
        <ToastContainer />
      </div >
    )
  }
}

export default withRouter(Profile);