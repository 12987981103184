import React, { Component } from 'react'
import styles from './privacyPolicyStyles.module.css';
import { Box } from '@mui/material';
export class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={styles.privacyPolicyWrapper}>
        <Box sx={{ pb: '16px' }}>
          <h1>Privacy Policy</h1>
        </Box>
        <div>
          Protecting your private information is our priority. This Statement of Privacy applies
          to <a href="https://www.symposia.site">www.symposia.site</a>, and Symposia Site Inc. and governs data collection and usage. For the
          purposes of this Privacy Policy, unless otherwise noted, all references to Symposia Site Inc.
          include <a href="https://www.symposia.site">www.symposia.site</a> and Symposia. The Symposia website is a academic social networking site.
          By using the Symposia website, you consent to the data practices described in this statement.
        </div>
        <Box sx={{ pb: '16px', pt: '16px' }}>

          <h2>Access to Google User Data</h2>
        </Box>
        <div>
          You can grant Symposia permission to view and edit events in your Google Calendar.
          This read-write access allows Symposia to seamlessly manage the creation, modification,
          and deletion of upcoming talks found on <a href="https://www.symposia.site">www.symposia.site</a> in your Google Calendar.
          To grant Symposia such permission, click “Add to Google Calendar” on any upcoming talk
          of interest and choose which Google account you want the talk to be added to. Upon doing
          so the first time, you will be presented with a Google consent screen that will ask
          whether you grant Symposia such permission. If you continue, Symposia’s read-write
          access will be effective immediately. Subsequently, the upcoming talk will appear in
          your Google Calendar as well in your profile and on the home page. Symposia keeps track
          of talks you have attended for your future reference.
        </div> <br />
        <div>
          Symposia will never modify your calendar other than as it relates to the creation,
          modification, and deletion of talks found on <a href="https://www.symposia.site">www.symposia.site</a>. The copying of talks
          to your calendar is automated and does not involve any person affiliated with Symposia
          making manual modifications to your calendar. Symposia collects no other data from you
          via your Google account. Symposia does not share your Google Calendar information with
          any third party. Symposia’s access to your Google Calendar may be revoked anytime by
          updating your permissions on your Google account.
        </div>
        <Box sx={{ pb: '16px', pt: '16px' }}>
          <h2>Collection of your Personal Information</h2>
        </Box>
        <div>
          In order to better provide you with products and services offered, Symposia may collect personally
          identifiable information, such as your:
        </div>

        <ul>
          <li>First and Last Name</li>
          <li>E-mail Address</li>
          <li>Employer</li>
          <li>Academic Affiliation and Credentials</li>
        </ul>

        Symposia may also collect anonymous demographic information, which is not unique to you, such as your:

        <ul>
          <li>Gender</li>
        </ul>
        <div>
          Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through Symposia's public message boards, this information may be collected and used by others.
        </div>
        <div>
          We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services.
          These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message;
          (e) submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for, but not limited to, communicating with you in relation to services
          and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.
        </div>
        <Box sx={{ pb: '16px', pt: '16px' }}>
          <h2>Use of your Personal Information</h2>
        </Box>
        <div>
          Symposia collects and uses your personal information to operate and deliver the services you have requested.
        </div>
        <div>
          Symposia may also use your personally identifiable information to inform you of other products or services
          available from Symposia and its affiliates.
        </div>
        <Box sx={{ pb: '16px', pt: '16px' }}>
          <h2>Sharing Information with Third Parties</h2>
        </Box>
        <div>
          Symposia does not sell, rent or lease its customer lists to third parties.
        </div>
        <div>
          Symposia may share data with trusted partners to help perform statistical analysis, send you email or postal
          mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your
          personal information except to provide these services to Symposia, and  they are required to maintain the confidentiality of your information.
        </div>
        <div>
          Symposia may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is
          necessary to: (a) conform to the edicts of the law or comply with legal process served on Symposia or the site; (b) protect and defend the rights
          or property of Symposia; and/or (c) act under exigent circumstances to protect the personal safety of users of Symposia, or the public.
        </div>
        <Box sx={{ pb: '16px', pt: '16px' }}>
          <h2>Automatically Collected Information</h2>
        </Box>
        <div>
          Information about your computer hardware and software may be automatically collected by Symposia. This information can include: your IP address,
          browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain
          quality of the service, and to provide general statistics regarding use of the Symposia website.
        </div>
        <Box sx={{ pb: '16px', pt: '16px' }}>
          <h2>Links</h2>
        </Box>
        <div>
          This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites.
          We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.
        </div>
        <Box sx={{ pb: '16px', pt: '16px' }}>
          <h2>Right to Deletion</h2>
        </Box>
        <div>
          Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
        </div>

        <ul>
          <li>Delete your personal information from our records; and</li>
          <li>Direct any service providers to delete your personal information from their records.</li>
        </ul>

        <div>
          Please note that we may not be able to comply with requests to delete your personal information
          if it is necessary to:
        </div>
        <ul>
          <li>Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;</li>
          <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity; •Debug to identify and repair errors that impair existing intended functionality; •Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;</li>
          <li>Comply with the California Electronic Communications Privacy Act;</li>
          <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;</li>
          <li>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;</li>
          <li>Comply with an existing legal obligation; or</li>
          <li>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information. Children Under ThirteenSymposia does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.</li>
        </ul>

        <h2>E-mail Communications</h2>
        <div>
          From time to time, Symposia may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from Symposia or click on a link therein.
        </div>
        <div>
          If you would like to stop receiving marketing or promotional communications via email from Symposia, you may opt out of such communications by clicking the unsubscribe option at the bottom of Symposia emails.
        </div>
        <Box sx={{ pb: '16px', pt: '16px' }}>
          <h2>External Data Storage Sites</h2>
        </Box>
        <div>
          We may store your data on servers provided by third party hosting vendors with whom we have contracted.
        </div>
        <Box sx={{ pb: '16px', pt: '16px' }}>
          <h2>Changes to this Statement</h2>
        </Box>
        <div>
          Symposia reserves the right to change this Privacy Policy from time to time.  We will notify you about
          significant changes in the way we treat personal information by sending a notice to the primary email
          address specified in your account, by placing a prominent notice on our website, and/or by updating
          any privacy information. Your continued use of the website and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.
        </div>
        <Box sx={{ pb: '16px', pt: '16px' }}>
          <h2>Contact Information</h2>
        </Box>
        <div>
          Symposia welcomes your questions or comments regarding this Statement of Privacy. If you believe that
          Symposia has not adhered to this Statement, please contact Symposia at:
        </div> <br />

        <div>
          Symposia Site Inc.
        </div>
        <div>

          ____________________
        </div>
        <div>
          New York, New York
        </div>
        <div>
          ____________________
        </div>
        <div>
          Email Address:
        </div>
        <div>
          symposia.site@gmail.com
        </div>
        <div>
          ____________________
        </div>
        <div>
          Effective as of August 10, 2022
        </div>
      </div>
    )
  }
}

export default PrivacyPolicy;
