import React, { useState, useEffect } from 'react';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import _config from "../../signup/config";
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import PaperCard from '../PaperCard/PaperCard';
import RequestService from "../../requestservice/requestservice";
import styles from './PapersFeedStyles.module.css';
import { Pagination, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

export default function PapersFeed(props) {
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  const pageNum = queryParams.has('page') ? Number(queryParams.get('page')) : 1;

  const [paperItems, setPaperItems] = useState([]);
  const [currentUserId, setCurrentUserId] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(pageNum);
  const poolData = {
    UserPoolId: _config.cognito.userPoolId,
    ClientId: _config.cognito.userPoolClientId,
  };

  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();
  const requestService = new RequestService();
  const history = useHistory();

  useEffect(() => {
    if (props.setFeedLoaded && !loading) {
      props.setFeedLoaded(true);
    }
  }, [loading]);

  const handlePaginationChange = (event, value) => {
    history.push(`${location.pathname}?page=${value}`);
    setCurrentPage(value);
  };

  useEffect(() => {
    if (props.query) {
      const data = { searchQuery: props.query };
      requestService.searchPapers(data, (loadedPaperItems) => {
        setPaperItems([...paperItems, ...loadedPaperItems]);
        setLoading(false);
      });
    } else if (props.userPapers) {
      const { userPapers } = props;
      setPaperItems(prevItems => [...prevItems, ...userPapers]);
      setLoading(false);
    } else {
      getPapersFeed(currentPage);
    }
  }, [props.userPapers, currentPage]);

  const getPapersFeed = (page) => {
    if (!cognitoUser) {
      const api = _config.api.invokeUrl + '/getpapersfeed';
      axios
        .get(api)
        .then((response) => {
          setLoading(false);
          let loadedPaperItems = response.data;
          setPaperItems(loadedPaperItems);
          const scrollPosition = sessionStorage.getItem("scrollPosition");
          if (scrollPosition) {
            window.scrollTo(0, parseInt(scrollPosition));
            sessionStorage.removeItem("scrollPosition");
          }
        })
        .catch((error) => {
          console.log(error);
        });
      return;
    } else {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes((err, result) => {
            if (err) {
              console.log(err);
            } else {
              if (result[3].Name === 'custom:userId' && result[3].Value) {
                const userId = result[3].Value;
                setCurrentUserId(result[3].Value);
                const authToken = session.getIdToken().getJwtToken();
                let api = `${_config.api.invokeUrl}/getpapersfeed?userId=${userId}&page=${page}`;
                const axiosConfig = {
                  headers: {
                    "Authorization": authToken,
                  }
                };
                axios
                  .get(api, axiosConfig)
                  .then((response) => {
                    setLoading(false);
                    let loadedPaperItems = response.data;
                    setPaperItems(loadedPaperItems);
                    const scrollPosition = sessionStorage.getItem("scrollPosition");
                    if (scrollPosition) {
                      window.scrollTo(0, parseInt(scrollPosition));
                      sessionStorage.removeItem("scrollPosition");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            }
          });
        }
      });
    }
  };

  const { allUserItems, fullLeftBar, handleAddedSavedPaper, handleRemoveSavedPaper } = props;

  return (
    <div>
      {loading ?
        <div>
          <div className={styles.LoadingDiv}>
            <Typography sx={{ m: '8px', color: '#1B065E' }} variant='h1'>Loading...</Typography>
            <ClipLoader
              color={"var(--primary-color)"}
              loading={loading}
              size={100}
            />
          </div>
        </div>
        :
        paperItems.map((e, index) => (
          <div key={e.PaperId}>
            <PaperCard
              key={e.PaperId}
              paperId={e.PaperId}
              semanticPaperId={e.semanticPaperId}
              paperUrl={e.paperUrl}
              paperImageUrl={e.paperImageUrl}
              userId={e.userId}
              title={e.title}
              year={e.year}
              authors={e.authors}
              tldr={e.tldr}
              chatGptMainFinding={e.chatGptMainFinding}
              chatGptSummary={e.chatGptSummary}
              videoLink={e.videoLink}
              keywords={e.keywords}
              requestTime={e.RequestTime}
              saved={e.saved}
              read={e.read}
              liked={e.liked}
              allUserItems={allUserItems}
              fullLeftBar={fullLeftBar}
              handleAddedSavedPaper={handleAddedSavedPaper}
              handleRemoveSavedPaper={handleRemoveSavedPaper} />
          </div>
        ))
      }
      {!props.query && cognitoUser && paperItems.length !== 0 && !loading && paperItems[0].pages > 1 && (
        <div className={styles.PaginationButtons}>
          <Pagination sx={{ margin: 'auto' }} page={currentPage} count={paperItems[0].pages} onChange={handlePaginationChange} />
        </div>
      )}{' '}
      {!loading && paperItems.length === 0 && <h3 className={styles.NoPapersText}>No papers found.</h3>}
    </div>
  );
}