window._config = {
    api: {
        invokeUrl: 'https://1kdvyosr33.execute-api.us-east-2.amazonaws.com/prod', // e.g. https://rc7nyt4tql.execute-api.us-west-2.amazonaws.com/prod',
        createUser: ' https://cinyu099jh.execute-api.us-east-2.amazonaws.com/prod'
    },
    cognito: {
        userPoolId: 'us-east-2_I7SjeZ4iQ', // e.g. us-east-2_uXboG5pAb
        userPoolClientId: '7ah02f7vf8g9dbb1cjse1c1035', // e.g. 25ddkmj4v6hfsfvruhpfi7n4hv
        region: 'us-east-2'
    },
    pubNub: {
        pubKey: 'pub-c-42e76bcd-57cc-42fe-813d-3bacadfb1461',
        subKey: 'sub-c-233f627c-b4d2-4a00-b88d-8d14d6141924'
    },
    s3: {
        bucketName: 'nex-lab',
        dirName: 'pictures',
        region: 'us-east-2',
    },
    s3Cvs: {
        bucketName: 'nex-lab',
        dirName: 'cvs',
        region: 'us-east-2',
    },
    s3RefFiles: {
        bucketName: 'nex-lab',
        dirName: 'reference_files',
        region: 'us-east-2',
    },
    s3ThumbnailFiles: {
        bucketName: 'nex-lab',
        dirName: 'thumbnails',
        region: 'us-east-2',
    }
};

export default window._config;