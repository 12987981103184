import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { Component, React } from 'react';
import './App.css';
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Analytics from '@aws-amplify/analytics';
import CreateAccount from "./ui/createaccount/createaccount"
import CreateEventForm from "./ui/createEvent/createEvent"
import ResetPassword from "./ui/resetpassword/resetpassword";
import SignIn from "./ui/signin/signin.jsx";
import SignUp from "./ui/signup/signup.jsx";
import Verify from "./ui/verify/verify.jsx";
import FAQs from './ui/faqs/faqs';
import ThreeColumnWrapper from './ui/ThreeColumnWrapper/ThreeColumnWrapper';
import NotFound from './ui/components/NotFound/NotFound';
import _config from "./ui/signup/config";
import { alpha, ThemeProvider, createTheme } from '@mui/material/styles';
import CreateProfile from './ui/CreateProfile/CreateProfile';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  palette: {
    primary: {
      main: '#FE9D44', // type of orange
      mainGradient: "linear-gradient(to left, #FE9D44, #FFF500)", // orange to light orange
      light: '#F3F3F3', // some type of light cream
    },
    secondary: {
      main: '#F0DFD3', // type of very light purple
      light: '#F8F7FF', // type of white
      dark: '#1B065E', // type of dark purple
      background: '#F3F3F3',
    },
    secondaryLight: createColor('#F8F7FF'),
    secondaryDark: createColor('#1B065E'),
  },
});
document.body.style.background = theme.palette.primary.light;
theme.typography.h1 = {
  fontFamily: 'Cairo',
  fontSize: '42px',
  fontWeight: '500',
  lineHeight: '42px',
  letterSpacing: '0em',
  textAlign: 'left',
};

theme.typography.h2 = {
  color: 'rgba(0, 0, 0, 0.87)',
  fontFamily: 'Cairo',
  fontSize: '32px',
  fontWeight: '600',
  lineHeight: '32px',
  letterSpacing: '0em',
  textAlign: 'left',
};

theme.typography.h3 = {
  fontFamily: 'Cairo',
  color: '#262322',
  fontSize: '24px',
  fontWeight: '500',
  lineHeight: '28px',
  textAlign: 'left',
};

theme.typography.h4 = {
  fontFamily: 'Cairo',
  color: theme.palette.secondary.dark,
  fontSize: '20px',
  fontWeight: '500',
  lineHeight: '24px',
};

theme.typography.subtitle1 = {
  fontFamily: 'Cairo',
  // color: theme.palette.secondary.dark,
  fontSize: '18px',
  fontWeight: '600',
  lineHeight: '24px',
};

theme.typography.subtitle2 = {
  fontFamily: 'Cairo',
  color: theme.palette.secondary.dark,
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
};

theme.typography.body1 = {
  fontFamily: 'Cairo',
  color: '#616161',
  fontSize: '15px',
  fontWeight: '400',
  lineHeight: '24px',
  textAlign: 'left',
};

theme.typography.bodyL = {
  fontFamily: 'Cairo',
  fontSize: '18px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0em',
  textAlign: 'left',
}

Amplify.configure(awsExports);
Analytics.autoTrack('pageView', {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // OPTIONAL, the event name, by default is 'pageView'
  eventName: 'pageView',
  // OPTIONAL, the attributes of the event, you can either pass an object or a function 
  // which allows you to define dynamic attributes
  attributes: {
    attr: 'attr'
  },
  // when using function
  // attributes: () => {
  //    const attr = somewhere();
  //    return {
  //        myAttr: attr
  //    }
  // },
  // OPTIONAL, by default is 'multiPageApp'
  // you need to change it to 'SPA' if your app is a single-page app like React
  type: 'SPA',
  // OPTIONAL, the service provider, by default is the Amazon Pinpoint
  provider: 'AWSPinpoint',
  // OPTIONAL, to get the current page url
  getUrl: () => {
    // the default function
    return window.location.origin + window.location.pathname;
  }
});

function PrivateRoute({ component: Component, currentUser, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => currentUser
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />}
    />
  )
}

class App extends Component {
  constructor(props) {
    super(props);
    const poolData = {
      UserPoolId: _config.cognito.userPoolId,
      ClientId: _config.cognito.userPoolClientId,
    };
    this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className='AppFont'>
          <Router>
            <Switch>
              <Route exact path="/"
                render={(routeProps) =>
                  <ThreeColumnWrapper
                    pageTitle='Home'
                    {...routeProps} />}
              />
              <Route exact path="/papers"
                render={(routeProps) =>
                  <ThreeColumnWrapper
                    pageTitle='Papers'
                    {...routeProps} />}
              />
              <Route exact path="/jobs"
                render={(routeProps) =>
                  <ThreeColumnWrapper
                    pageTitle='Jobs'
                    {...routeProps} />}
              />
              <Route exact path="/home"
                render={(routeProps) =>
                  <ThreeColumnWrapper
                    pageTitle='Home'
                    {...routeProps} />}
              />
              <Route path="/messages"
                render={(routeProps) => this.userPool.getCurrentUser() ?
                  <ThreeColumnWrapper
                    pageTitle='messages'
                    {...routeProps} /> : <Redirect to={{ pathname: '/signin', state: { from: window.location.href } }} />}
              />

              <Route path="/createaccount" component={CreateAccount} />
              <Route path="/edituser/:userId" component={CreateAccount} />
              <Route path="/createEvent" component={CreateEventForm} />
              <Route path="/editevent/:eventId" component={CreateEventForm} />
              <Route path="/signin" component={SignIn} />
              <Route path="/signup" render={(routeProps) => <SignUp {...routeProps} />} />
              <Route path="/verify" render={(routeProps) => <Verify {...routeProps} />} />
              <Route path="/faqs" component={FAQs} />

              {/*DEVON ADD*/}
              <Route path="/resetpassword" component={ResetPassword} />
              {/*END DEVON ADD*/}

              <Route path="/privacy" render={(routeProps) =>
                <ThreeColumnWrapper
                  pageTitle='Privacy Policy'
                  {...routeProps}
                />}
              />
              <Route path="/questions" render={(routeProps) =>
                <ThreeColumnWrapper
                  pageTitle='FAQs'
                  {...routeProps}
                />}
              />
              <Route path="/people" render={(routeProps) =>
                <ThreeColumnWrapper
                  pageTitle='People'
                  {...routeProps}
                />}
              />
              <Route path="/profile/:userId" render={(routeProps) =>
                <ThreeColumnWrapper
                  pageTitle='Profile'
                  {...routeProps}
                />}
              />

              {/* <PrivateRoute currentUser={this.userPool.getCurrentUser()} pageTitle='User profile' path="/userprofile/:userId" component={ThreeColumnWrapper} /> */}
              <Route path="/userprofile/:userId" render={(routeProps) =>
                <ThreeColumnWrapper
                  pageTitle='User Profile'
                  {...routeProps} />}
              />
              <Route path="/talk/:eventId" render={(routeProps) => this.userPool.getCurrentUser() ?
                <ThreeColumnWrapper
                  pageTitle='Talk Thread'
                  {...routeProps} />
                : <Redirect to={{ pathname: '/signin', state: { from: window.location.href } }} />}
              />
              <Route path="/job/:jobId" render={(routeProps) => this.userPool.getCurrentUser() ?
                <ThreeColumnWrapper
                  pageTitle='Job Thread'
                  {...routeProps} />
                : <Redirect to={{ pathname: '/signin', state: { from: window.location.href } }} />}
              />
              <Route path="/paper/:paperId" render={(routeProps) => this.userPool.getCurrentUser() ?
                <ThreeColumnWrapper
                  pageTitle='Paper Thread'
                  {...routeProps} />
                : <Redirect to={{ pathname: '/signin', state: { from: window.location.href } }} />}
              />
              {/* Catch all route */}
              <Route render={(routeProps) => <NotFound {...routeProps} />} />
            </Switch>
          </Router>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;