import React, { useState, useEffect, useRef } from 'react'
import { Button, TextField, TextareaAutosize, Typography } from '@mui/material';
import RequestService from '../../requestservice/requestservice';
import moment from 'moment';
import ClipLoader from "react-spinners/ClipLoader";
import styles from './PaperNoteStyles.module.css';
import {useTheme} from '@mui/material';

const PaperNote = ({ title, paperId, closeNoteDialog, signedInUserId, requestService = new RequestService() }) => {
  const [note, setNote] = useState('');
  const [lastUpdatedTime, setLastUpdatedTime] = useState('');
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  useEffect(() => {
    if (paperId && signedInUserId) {
      getPaperNote();
    }
  }, [paperId, signedInUserId]);

  const getPaperNote = async () => {
    const paperNote = await requestService.getPaperNote(signedInUserId, paperId);
    if (paperNote.Note) {
      setNote(paperNote.Note);
    }
    setLoading(false);
  }

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  }

  const saveNote = async (e) => {
    closeNoteDialog(e);
    const data = { paperId, userId: signedInUserId, note, };
    await requestService.addPaperNote(data);
  }

  return (
    <div className={styles.PaperNote}>
      <div className={styles.NoteTitle}>
        <Typography variant='body1'>
          Your notes for "{title}"
        </Typography>
      </div>
      {loading ?
        <div className={styles.LoadingDiv}>
          Checking for saved notes...
          <ClipLoader
            color={"var(--primary-color)"}
            loading={loading}
            size={50}
          />
        </div>
        :
        <>
          {lastUpdatedTime &&
            <div className={styles.LastPostTime}>
              Last updated: {moment(lastUpdatedTime).local().fromNow()}
            </div>
          }
          <TextField
            minRows={3}
            label="Note"
            id='note'
            fullWidth
            multiline
            variant='standard'
            margin='normal'
            placeholder={'My main takeaways from this paper are...'}
            onChange={handleNoteChange}
            value={note}
          />
          <div className={styles.SaveButtonDiv}>
            <Button
              type="submit"
              variant="contained"
              onClick={saveNote}
              sx={{ color: '#1B065E', ml: '12px', }}
            >
              Save
            </Button>
          </div>
        </>
      }
    </div>
  )
}

export default PaperNote;
