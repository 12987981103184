/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_mobile_analytics_app_id": "e8b5f54eff5f4f8797e94593d0cf010e",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "e8b5f54eff5f4f8797e94593d0cf010e",
            "region": "us-east-1"
        }
    },
    "aws_cognito_identity_pool_id": "us-east-2:0210809f-533d-435e-81e3-600d41127694",
    "aws_cognito_region": "us-east-2",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_cognito_login_mechanism": [
        "EMAIL"
    ]
};


export default awsmobile;
