
    import { Buffer } from "buffer/"; // <-- no typo here ("/")
    import process from "process";
    import EventEmitter from "events";

    window.global = window;
    window.Buffer = Buffer;
    window.process = {
      env: { DEBUG: undefined },
    };
    window.EventEmitter = EventEmitter;
    var global = global || window
    var exports = {};
  