import React, { useState, useEffect } from 'react'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import _config from "../signup/config";
import TalksFeed from '../components/TalksFeed/TalksFeed';
import { useHistory, withRouter } from 'react-router-dom';
import styles from './HomeFeedStyles.module.css'
import { Button, InputAdornment, MenuItem, TextField } from '@mui/material';
import DialogBox from '../components/DialogBox/DialogBox';
import CreateTalkForm from '../CreateTalkForm/CreateTalkForm';
import CreateJobForm from '../CreateJobForm/CreateJobForm';
import CreatePaperForm from '../CreatePaperForm/CreatePaperForm';
import PapersFeed from '../components/PapersFeed/PapersFeed';
import JobsFeed from '../components/JobsFeed/JobsFeed';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import WorkIcon from '@mui/icons-material/Work';
import ArticleIcon from '@mui/icons-material/Article';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const HomeFeed = (props) => {
  const feedPath = window.sessionStorage.getItem("FeedPath");
  if (feedPath && feedPath !== window.location.pathname && `${feedPath}/` !== window.location.pathname) {
    window.location.href = feedPath;
  }
  const poolData = {
    UserPoolId: _config.cognito.userPoolId,
    ClientId: _config.cognito.userPoolClientId,
  };
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const currentFeed = JSON.parse(window.localStorage.getItem("SymposiaHomeFeed"));

  const [query, setQuery] = useState(() => {
    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(url.search);
    return queryParams.get("q") ?? '';
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [feed, setFeed] = useState(currentFeed ? currentFeed : 'talks');
  const [feedLoaded, setFeedLoaded] = useState(false);
  const [openTalksPopup, setOpenTalksPopup] = useState(false);
  const [openPapersPopup, setOpenPapersPopup] = useState(false);
  const [openJobsPopup, setOpenJobsPopup] = useState(false);
  const [createAnchorEl, setCreateAnchorEl] = React.useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
  const openCreate = Boolean(createAnchorEl);
  const openFilter = Boolean(filterAnchorEl);
  const history = useHistory();
  const theme = useTheme();
  const selectedFilter = props.pageTitle == 'Home' ? 'Talks' : props.pageTitle;
  const cognitoUser = userPool.getCurrentUser();

  useEffect(() => {
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes((err, result) => {
            if (err) {
              console.log(err);
            } else {
              const userHasUserId = result[3] && result[3].Name === 'custom:userId' && result[3].Value;
              if (!userHasUserId) {
                window.location.href = '/createaccount';
              }
            }
          });
        }
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleCreateClick = (event) => {
    setCreateAnchorEl(event.currentTarget);
  };

  const handleCreateClose = () => {
    setCreateAnchorEl(null);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleTalksPopupOpen = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      window.location.href = '/signin';
      return;
    }
    setOpenTalksPopup(true);
  };


  const handleTalksPopupClose = () => {
    setOpenTalksPopup(false);
  };

  const handleJobsPopupOpen = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      window.location.href = '/signin';
      return;
    }
    setOpenJobsPopup(true);
  };

  const handleJobsPopupClose = () => {
    setOpenJobsPopup(false);
  };

  const handlePapersPopupClose = () => {
    setOpenPapersPopup(false);
  };

  const handlePapersPopupOpen = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      window.location.href = '/signin';
      return;
    }
    setOpenPapersPopup(true);
  };

  const navigateToPath = (newPath) => {
    window.sessionStorage.setItem("FeedPath", newPath);
    history.push(newPath);
    handleFilterClose();
  };

  const search = (text, event) => {
    if (event?.key === 'Enter') {
      const cognitoUser = userPool.getCurrentUser();
      if (!cognitoUser) {
        window.location.href = '/signin';
        return;
      }
      let url = new URL(window.location.href);
      url.searchParams.set('q', text);
      window.location.href = url.href;
      return;
    }
    setQuery(text);
  };

  const { fullLeftBar, pageTitle, drawerWidth, profilePictureUrl, userId,
    firstName, lastName, prefix, suffix, allUserItems, handleAddedSavedPaper,
    handleRemoveSavedPaper, handleAddedUpcomingTalk, handleRemoveUpcomingTalk, userGroups } = props;
  const name = prefix + ' ' + firstName + ' ' + lastName + ' ' + suffix;

  return (
    <>
      {/* Filter button */}
      {feedLoaded &&
        <div className={styles.ControlBar}>
          <div className={styles.ControlBarStart}>

            <Button
              id="filter-button"
              aria-controls={openFilter ? 'filter-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openFilter ? 'true' : undefined}
              variant="contained"
              disableElevation
              className={styles.dropdownFilter}
              onClick={handleFilterClick}
              startIcon={<FilterAltIcon />}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{
                background: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.42)', borderRadius: '0',
                "&:hover": {
                  background: '#e3e1e1'
                },
              }}
            >
              {selectedFilter}
            </Button>
            <Menu
              id="filter-menu"
              MenuListProps={{
                'aria-labelledby': 'filter-button',
              }}
              anchorEl={filterAnchorEl}
              open={openFilter}
              onClose={handleFilterClose}
              sx={{
                '& .MuiPaper-root': {
                  borderRadius: 6,
                  marginTop: theme.spacing(1),
                  minWidth: 180,
                  color:
                    theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
                  boxShadow:
                    'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                  '& .MuiMenu-list': {
                    padding: '4px 0',
                  },
                  '& .MuiMenuItem-root': {
                    '& .MuiSvgIcon-root': {
                      fontSize: 18,
                      color: theme.palette.text.secondary,
                      marginRight: theme.spacing(1.5),
                    },
                    '&:active': {
                      backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                      ),
                    },
                  },
                },
              }}
            >
              {selectedFilter !== 'Talks' &&
                <MenuItem onClick={() => navigateToPath('/')} disableRipple>
                  <CoPresentIcon />
                  Talks
                </MenuItem>}
              {selectedFilter !== 'Jobs' &&
                <MenuItem onClick={() => navigateToPath('/jobs')} disableRipple>
                  <WorkIcon />
                  Jobs
                </MenuItem>}
              {selectedFilter !== 'Papers' &&
                <MenuItem onClick={() => navigateToPath('/papers')} disableRipple>
                  <ArticleIcon />
                  Papers
                </MenuItem>}
            </Menu>

            {/* Search bar */}
            <TextField placeholder="Search" variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                position: 'relative',
                top: '6px',
                ml: '16px',
                pr: '8px'
              }}
              value={query}
              onChange={(e) => search(e.target.value, e)}
              onKeyDown={(e) => search(e.target.value, e)} />
          </div>

          {/* Create button */}
          <Button
            id="create-button"
            aria-controls={openCreate ? 'create-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openCreate ? 'true' : undefined}
            variant="contained"
            disableElevation
            className={styles.CreateButton}
            onClick={handleCreateClick}
            startIcon={<AddIcon />}
            sx={{
              background: '#FEBA3F',
            }}
          >
            Create
          </Button>
          <Menu
            id="create-menu"
            MenuListProps={{
              'aria-labelledby': 'create-button',
            }}
            anchorEl={createAnchorEl}
            open={openCreate}
            onClose={handleCreateClose}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: 6,
                marginTop: theme.spacing(1),
                minWidth: 180,
                color:
                  theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
                boxShadow:
                  'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                '& .MuiMenu-list': {
                  padding: '4px 0',
                },
                '& .MuiMenuItem-root': {
                  '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                  },
                  '&:active': {
                    backgroundColor: alpha(
                      theme.palette.primary.main,
                      theme.palette.action.selectedOpacity,
                    ),
                  },
                },
              },
            }}
          >
            <MenuItem onClick={() => handleTalksPopupOpen()} disableRipple>
              <CoPresentIcon />
              Talks
            </MenuItem>

            <MenuItem onClick={() => handleJobsPopupOpen()} disableRipple>
              <WorkIcon />
              Jobs
            </MenuItem>
            <MenuItem onClick={() => handlePapersPopupOpen()} disableRipple>
              <ArticleIcon />
              Papers
            </MenuItem>
          </Menu>
        </div>}
      <div className={styles.NewsFeed}>
        {selectedFilter === 'Talks' && <TalksFeed
          setFeedLoaded={setFeedLoaded}
          query={query}
          fullLeftBar={fullLeftBar}
          allUserItems={allUserItems}
          handleAddedUpcomingTalk={handleAddedUpcomingTalk}
          handleRemoveUpcomingTalk={handleRemoveUpcomingTalk}
        />}
        {selectedFilter === 'Papers' &&
          <PapersFeed
            setFeedLoaded={setFeedLoaded}
            query={query}
            userId={props.userId}
            fullLeftBar={fullLeftBar}
            allUserItems={allUserItems}
            handleAddedSavedPaper={handleAddedSavedPaper}
            handleRemoveSavedPaper={handleRemoveSavedPaper}
          />}
        {selectedFilter === 'Jobs' &&
          <JobsFeed
            query={query}
            setFeedLoaded={setFeedLoaded}
            fullLeftBar={fullLeftBar}
            allUserItems={allUserItems}
          />}
      </div>
      {/* Dialogs */}
      <DialogBox
        open={openTalksPopup}
        handleClose={handleTalksPopupClose}
        title='Talk form'>
        <CreateTalkForm
          closeCreateTalkPopup={handleTalksPopupClose}
        />
      </DialogBox>
      <DialogBox
        open={openJobsPopup}
        handleClose={handleJobsPopupClose}
        title='Job form'
      >
        <CreateJobForm
          closeCreateJobPopup={handleJobsPopupClose}
        />
      </DialogBox>
      <DialogBox
        open={openPapersPopup}
        handleClose={handlePapersPopupClose}
        title='Paper form'
      >
        <CreatePaperForm
          closeCreatePaperPopup={handlePapersPopupClose}
          userId={userId}
        />
      </DialogBox>
    </>
  )
}

{/* <div className={styles.Post}>
<div className={styles.ShareButtonsDiv}>
  {fullLeftBar ?
    <div className={styles.ShareCallout} onClick={this.handleTalksPopupOpen}>
      <Event sx={{ color: '#FE9D44', fontSize: '40px' }} />
      <div className={styles.ShareText} style={{ width: "max-content" }}>
        Share Talks
      </div>
    </div>
    :
    <div className={styles.ShareCallout} onClick={this.handleTalksPopupOpen}>
      <Tooltip title='Share Talks'>
        <Event sx={{ color: '#FE9D44', fontSize: '40px' }} />
      </Tooltip>
    </div>}
  {fullLeftBar ?
    <div className={styles.ShareCallout} onClick={this.handleJobsPopupOpen}>
      <Work sx={{ color: '#7BC27B', fontSize: '40px' }} />
      <div className={styles.ShareText} style={{ width: "max-content" }}>
        Share Jobs
      </div>
    </div>
    :
    <div className={styles.ShareCallout} onClick={this.handleJobsPopupOpen}>
      <Tooltip title='Share Jobs'>
        <Work sx={{ color: '#7BC27B', fontSize: '40px' }} />
      </Tooltip>
    </div>}
  {fullLeftBar ?
    <div className={styles.ShareCallout} onClick={this.handlePapersPopupOpen}>
      <Article sx={{ color: '#43A4FE', fontSize: '40px' }} />
      <div className={styles.ShareText} style={{ width: "max-content" }}>
        Share Papers
      </div>
    </div>
    :
    <div className={styles.ShareCallout} onClick={this.handlePapersPopupOpen}>
      <Tooltip title='Share Papers'>
        <Article sx={{ color: '#43A4FE', fontSize: '40px' }} />
      </Tooltip>
    </div>}
</div>
{this.state.query && <h3>Search results for "{this.state.query}"</h3>}
</div>
<Divider />
<ToggleButtonGroup
color="primary"
value={feed}
exclusive
fullWidth
className={styles.ToggleButton}
onChange={this.toggleState}
>
<ToggleButton value="talks"
  sx={{ fontSize: '20px', color: '#6F6F6F', textTransform: 'none' }}
>
  Talks
</ToggleButton>
<ToggleButton value="jobs"
  sx={{ fontSize: '20px', color: '#6F6F6F', textTransform: 'none' }}
>
  Jobs
</ToggleButton>
<ToggleButton value="papers"
  sx={{ fontSize: '20px', color: '#6F6F6F', textTransform: 'none' }}
>
  Papers
</ToggleButton>
</ToggleButtonGroup>
<Divider /> */}
{/* : feed === 'papers' ?
            <div className={styles.NewsFeed}>
              <PapersFeed
                fullLeftBar={fullLeftBar}
                allUserItems={allUserItems}
                handleAddedSavedPaper={handleAddedSavedPaper}
                handleRemoveSavedPaper={handleRemoveSavedPaper}
              />
            </div> :
            <div className={styles.NewsFeed}>
              <JobsFeed
                fullLeftBar={fullLeftBar}
                allUserItems={allUserItems}
                match={this.props.match}
              />
            </div> */}
export default withRouter(HomeFeed)