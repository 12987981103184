import React, { Component } from 'react'
import styles from './ProfilePaperStyles.module.css'
import { NoteAdd } from '@mui/icons-material'
import { Tooltip, IconButton, Box } from '@mui/material'
import { Link } from 'react-router-dom';
import DialogBox from '../DialogBox/DialogBox';
import PaperNote from '../PaperNote/PaperNote';
import DeleteIcon from '@mui/icons-material/Delete';

export default class ProfilePaper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saved: this.props.saved,
      liked: this.props.liked,
      read: this.props.read,
      openNoteDialog: false,
    }
    this.deletePaper = this.deletePaper.bind(this);
    this.handleOpenNoteDialog = this.handleOpenNoteDialog.bind(this);
    this.handleCloseNoteDialog = this.handleCloseNoteDialog.bind(this);
    this.goToPaper = this.goToPaper.bind(this);

  }

  deletePaper(e) {
    e.preventDefault();
    e.stopPropagation();
    const paperId = this.props.paperId;
    if (this.props.type === 'saved') {
      this.props.removeFromSavedPapersCall(paperId);
    } else {
      this.props.removeFromReadPapersCall(paperId);
    }
  }

  handleOpenNoteDialog(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ openNoteDialog: true });
  }

  handleCloseNoteDialog(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ openNoteDialog: false });
  }

  goToPaper() {
    window.open(this.props.paperUrl);
  }

  render() {
    const { title, authors, year, type, paperId, fromSavedToReadPapersCall, fromReadToSavedPapersCall, signedInUserId, requestService } = this.props;
    const { openNoteDialog } = this.state;
    const firstAuthorArray = authors[0].split(' ');
    const firstAuthorFirstInitial = firstAuthorArray[0].charAt(0);
    const firstAuthorLastName = firstAuthorArray[firstAuthorArray.length - 1];
    return (
      <div>
        <Link style={{ textDecoration: 'none', color: 'black' }}
          to={{
            pathname: '/paper/' + paperId,
            paperProps: this.props,
            paperState: this.state
          }}>
          <div className={styles.ProfilePaper}>
            <Box sx={{
              fontSize: '15px',
              fontWeight: '400',
              lineHeight: '24px',
              letterSpacing: '0em',
              textAlign: 'left',
            }}>{`${authors.join(', ')}, ${year}, "${title}"`}</Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Tooltip title='Add note'>
                <IconButton sx={{
                  background: '#EEEEEE'
                }}
                  onClick={(e) => this.handleOpenNoteDialog(e)}>
                  <NoteAdd
                    sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '24px', cursor: 'pointer' }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title='Delete save'>
                <IconButton sx={{
                  background: '#EEEEEE'
                }} aria-label='delete' onClick={(e) => this.deletePaper(e)}>
                  <DeleteIcon
                    sx={{
                      color: 'rgba(0, 0, 0, 0.6)',
                      fontSize: '24px',
                      cursor: 'pointer',
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </div>
        </Link>
        {/* Dialogs */}
        <DialogBox
          open={openNoteDialog}
          handleClose={this.handleCloseNoteDialog}
          title='Add a Note'
        >
          <PaperNote
            closeNoteDialog={this.handleCloseNoteDialog}
            title={title}
            paperId={paperId}
            signedInUserId={signedInUserId}
            requestService={requestService}
          />
        </DialogBox>
      </div>
    )
  }
}
