export const keywords1 = [
  "Acceptability and Trust",
  "Actuation and Joint Mechanisms",
  "Additive Manufacturing",
  "Aerial Systems: Applications",
  "Aerial Systems: Mechanics and Control",
  "Aerial Systems: Perception and Autonomy",
  "Agent-Based Systems",
  "Agricultural Automation",
  "AI-Based Methods",
  "AI-Enabled Robotics",
  "Art and Entertainment Robotics",
  "Assembly",
  "Audio-Visual SLAM",
  "Automation at Micro-Nano Scales",
  "Autonomous Agents",
  "Automation Technologies for Smart Cities",
  "Autonomous Vehicle Navigation",
  "Behavior-Based Systems",
  "Big Data in Robotics and Automation",
  "Bimanual Manipulation",
  "Bioinspired Robot Learning",
  "Biological Cell Manipulation",
  "Biologically-Inspired Robots",
  "Biomimetics",
  "Body Balancing",
  "Brain-Machine Interfaces",
  "Building Automation",
  "Calibration and Identification",
  "Cellular and Modular Robots",
  "Climbing Robots",
  "Cognitive Control Architectures",
  "Cognitive Modeling",
  "Collision Avoidance",
  "Compliance and Impedance Control",
  "Compliant Assembly",
  "Compliant Joints and Mechanisms",
  "Computational Geometry",
  "Computer Architecture for Robotic and Automation",
  "Computer Vision for Automation",
  "Computer Vision for Manufacturing",
  "Computer Vision for Medical Robotics",
  "Computer Vision for Transportation",
  "Constrained Motion Planning",
  "Control Architectures and Programming",
  "Contact Modeling",
  "Continual Learning",
  "Cooperating Robots",
  "Cyborgs",
  "Datasets for Human Motion",
  "Data Sets for Robot Learning",
  "Data Sets for Robotic Vision",
  "Data Sets for SLAM",
  "Deep Learning in Grasping and Manipulation",
  "Deep Learning for Visual Perception",
  "Deep Learning Methods",
  "Demining Systems",
  "Design and Human Factors",
  "Developmental Robotics",
  "Dexterous Manipulation",
  "Disassembly",
  "Discrete Event Dynamic Automation Systems",
  "Distributed Robot Systems",
  "Domestic Robotics",
  "Dual Arm Manipulation",
  "Dynamics",
  "Education Robotics",
  "Embedded Systems for Robotic and Automation",
  "Embodied Cognitive Science",
  "Emotional Robotics",
  "Energy and Environment-Aware Automation",
  "Engineering for Robotic Systems",
  "Environment Monitoring and Management",
  "Epigenetic Robotics",
  "Evolutionary Robotics",
  "Ethics and Philosophy",
  "Factory Automation",
  "Failure Detection and Recovery",
  "Field Robots",
  "Flexible Robotics",
  "Force and Tactile Sensing",
  "Force Control",
  "Formal Methods in Robotics and Automation",
  "Foundations of Automation",
  "Gesture, Posture and Facial Expressions",
  "Grasping",
  "Grippers and Other End-Effectors",
  "Haptics and Haptic Interfaces"];

export const keywords2 = [
  "Hardware-Software Integration in Robotics",
  "Health Care Management",
  "Human and Humanoid Motion Analysis and Synthesis",
  "Human-Aware Motion Planning",
  "Human-Centered Automation",
  "Human-Centered Robotics",
  "Human Detection and Tracking",
  "Human Factors and Human-in-the-Loop",
  "Human Performance Augmentation",
  "Human-Robot Collaboration",
  "Human-Robot Teaming",
  "Humanoid and Bipedal Locomotion",
  "Humanoid Robot Systems",
  "Hybrid Logical/Dynamical Planning and Verification",
  "Hydraulic/Pneumatic Actuators",
  "Imitation Learning",
  "Incremental Learning",
  "Industrial Robots",
  "In-Hand Manipulation",
  "Intelligent Transportation Systems",
  "Intention Recognition",
  "Intelligent and Flexible Manufacturing",
  "Integrated Planning and Control",
  "Integrated Planning and Learning",
  "Inventory Management",
  "Kinematics",
  "Learning Categories and Concepts",
  "Learning from Demonstration",
  "Learning from Experience",
  "Legged Robots",
  "Localization",
  "Logistics",
  "Long term Interaction",
  "Machine Learning for Robot Control",
  "Manipulation Planning",
  "Manufacturing, Maintenance and Supply Chains",
  "Mapping",
  "Marine Robotics",
  "Medical Robots and Systems",
  "Mechanism Design",
  "Methods and Tools for Robot System Design",
  "Micro/Nano Robots",
  "Mining Robotics",
  "Mobile Manipulation",
  "Model Learning for Control",
  "Modeling, Control, and Learning for Soft Robots",
  "Modeling and Simulating Humans",
  "Motion Control",
  "Motion and Path Planning",
  "Multifingered Hands",
  "Multi-Contact Whole-Body Motion Planning and Control",
  "Multi-Modal Perception for HRI",
  "Multi-Robot Systems",
  "Multi-Robot SLAM",
  "Nanomanufacturing",
  "Natural Dialog for HR",
  "Natural Machine Motion",
  "Networked Robots",
  "Neural and Fuzzy Control",
  "Neurorobotics",
  "Nonholonomic Mechanisms and Systems",
  "Nonholonomic Motion Planning",
  "Object Detection, Segmentation and Categorization",
  "Omnidirectional Vision",
  "Optimization and Optimal Control",
  "Parallel Robots",
  "Passive Walking",
  "Path Planning for Multiple Mobile Robots or Agents",
  "Perception-Action Coupling",
  "Perception for Grasping and Manipulation",
  "Performance Evaluation and Benchmarking",
  "Petri Nets for Automation Control"];

export const keywords3 = [
  "Physically Assistive Devices",
  "Physical Human-Robot Interaction",
  "Planning, Scheduling and Coordination",
  "Planning under Uncertainty",
  "Probabilistic Inference",
  "Probability and Statistical Methods",
  "Process Control",
  "Product Design, Development and Prototyping",
  "Prosthetics and Exoskeletons",
  "Range Sensing",
  "Reactive and Sensor-Based Planning",
  "Recognition",
  "Redundant Robots",
  "Rehabilitation Robotics",
  "Reinforcement Learning",
  "Representation Learning",
  "RGB-D Perception",
  "Robot Audition",
  "Robot Companions",
  "Robot Safety",
  "Robotics and Automation in Agriculture and Forestry",
  "Robotics and Automation in Construction",
  "Robotics and Automation in Life Sciences",
  "Robotics in Hazardous Fields",
  "Robotics in Under-Resourced Settings",
  "Robust/Adaptive Control",
  "Safety in HRI",
  "Search and Rescue Robots",
  "Semantic Scene Understanding",
  "Semiconductor Manufacturing",
  "Sensor Fusion",
  "Sensor-based Control",
  "Sensorimotor Learning",
  "Sensor Networks",
  "Service Robotics",
  "Simulation and Animation",
  "SLAM",
  "Social HRI",
  "Software, Middleware and Programming Environments",
  "Soft Sensors and Actuators",
  "Software Architecture for Robotic and Automation",
  "Software Tools for Benchmarking and Reproducibility",
  "Software Tools for Robot Programming",
  "Software-Hardware Integration for Robot Systems",
  "Soft Robot Applications",
  "Soft Robot Materials and Design",
  "Space Robotics and Automation",
  "Surgical Robotics: Laparoscopy",
  "Surgical Robotics: Planning",
  "Surveillance Robotic Systems",
  "Surgical Robotics: Steerable Catheters/Needles",
  "Sustainable Production and Service Automation",
  "Swarm Robotics",
  "Task and Motion Planning",
  "Task Planning",
  "Telerobotics and Teleoperation",
  "Tendon/Wire Mechanism",
  "Touch in HRI",
  "Transfer Learning",
  "Underactuated Robots",
  "View Planning for SLAM",
  "Virtual Reality and Interfaces",
  "Vision-Based Navigation",
  "Visual Learning",
  "Visual-Inertial SLAM",
  "Visual Servoing",
  "Visual Tracking",
  "Wearable Robotics",
  "Wheeled Robots",
  "Whole-Body Motion Planning and Control"];