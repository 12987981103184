import { React, useState, useRef } from 'react'
import styles from './fileUploaderStyles.module.css'
import _config from "../../signup/config";
import RequestService from "../../requestservice/requestservice";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box } from '@mui/material';

export function ThumbnailUploader({
    onFileSelect = () => { },
    eventId,
    jobId,
    onS3Upload = () => { },
    thumbnailURL = '',
    userPool,
}) {
    const fileInput = useRef(null);
    const [currentTime, setCurrentTime] = useState(Date.now());
    const requestService = new RequestService();

    async function handleFileInput(e) {
        // handle file validations
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        onFileSelect(file);
        // add to s3 bucket
        const s3PicLocation = eventId ? await requestService.uploadEventThumbnailPicToS3(eventId, file) : await requestService.uploadJobThumbnailPicToS3(jobId, file);
        onS3Upload(s3PicLocation);
        setCurrentTime(Date.now());
    }

    function handlePhotoClick(e) {
        fileInput.current.click();
    }

    const InputUploader = <input
        className={styles.thumbnailUploader}
        type="file"
        accept="image/gif, image/jpeg, image/jpg, image/png"
        onChange={(e) => { handleFileInput(e) }}
        ref={fileInput}
    />

    return (
        <>
            <div>
                {thumbnailURL ?
                    <div className={styles.thumbnailFileUpload}>
                        {thumbnailURL && <img className={styles.thumbnailImage} src={`${thumbnailURL}?${currentTime}`} onClick={(e) => { handlePhotoClick(e) }} />}
                        {InputUploader}
                    </div>
                    :
                    <label className={styles.thumbnailFileUpload}>
                        {InputUploader}
                        <Box sx={{ display: 'flex' }}>
                            <CloudUploadIcon sx={{
                                color: '#FEBA3F',
                                height: '80px',
                                width: '112px',
                            }} />
                            <div>
                                <div className={styles.textSpace + ' ' + styles.blueText}>Browse an image</div>
                                <div>*3MB max image file size.</div>
                                <div>*accepted file formats: jpg, png, gif</div>
                            </div>
                        </Box>
                    </label>
                }
            </div>

            {/* add custom style */}
            {/* <button onClick={e => fileInput.current && fileInput.current.click()} className="btn btn-primary">Upload file</button> */}
        </>
    )
}


export function PaperImageUploader({
    onFileSelect = () => { },
    paperId,
    onS3Upload = () => { },
    thumbnailURL = '',
    userPool,
}) {
    const fileInput = useRef(null);
    const [currentTime, setCurrentTime] = useState(Date.now());
    const requestService = new RequestService();

    async function handleFileInput(e) {
        // handle file validations
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        onFileSelect(file);
        // add to s3 bucket
        const s3PicLocation = await requestService.uploadPaperImageToS3(paperId, file);
        onS3Upload(s3PicLocation);
        setCurrentTime(Date.now());
    }

    function handlePhotoClick(e) {
        fileInput.current.click();
    }

    const InputUploader = <input
        className={styles.thumbnailUploader}
        type="file"
        accept="image/*"
        onChange={(e) => { handleFileInput(e) }}
        ref={fileInput}
    />

    return (
        <>
            <div>
                {thumbnailURL ?
                    <div className={styles.thumbnailFileUpload}>
                        {thumbnailURL && <img className={styles.thumbnailImage} src={`${thumbnailURL}?${currentTime}`} onClick={(e) => { handlePhotoClick(e) }} />}
                        {InputUploader}
                    </div>
                    :
                    <label className={styles.thumbnailFileUpload}>
                        {InputUploader}
                        <Box sx={{ display: 'flex' }}>
                            <CloudUploadIcon sx={{
                                color: '#FEBA3F',
                                height: '80px',
                                width: '112px',
                            }} />
                            <div>
                                <div className={styles.textSpace + ' ' + styles.blueText}>Browse an image</div>
                                <div>*3MB max image file size.</div>
                                <div>*accepted file formats: jpg, png, gif</div>
                            </div>
                        </Box>
                    </label>
                }
            </div>
        </>
    )
}

export function ReferenceUploader({
    onFileSelect = () => { }
}) {
    const fileInput = useRef(null);
    const MAX_LENGTH = 3;


    const handleFilesInput = (e) => {
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        onFileSelect(file);

    }

    return (
        <>
            <div className={styles.referenceFileUpload}>
                <input
                    type="file"
                    onChange={handleFilesInput}
                    accept='application/pdf'
                />
            </div>

        </>
    )
}
