import React, { useState, useEffect } from 'react';
import Amplify from "aws-amplify";
import AWS from 'aws-sdk';
import awsExports from "../../../aws-exports";
import _config from "../../signup/config";
import styles from './UserCardFeedStyles.module.css';
import { Typography } from '@mui/material';

Amplify.configure(awsExports);
const SESConfig = {
  apiVersion: "2010-12-01",
  accessKeyId: _config.s3.accessKeyId,
  accessSecretKey: _config.s3.secretAccessKey,
  region: "us-east-2"
};
AWS.config.update(SESConfig);

const respectablePrefixes = new Set(['Dr.', 'Prof.', 'Rev.']);

const UserCardFeed = (props) => {
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    profilePictureUrl: '',
    jobTitle: '',
    organization: '',
    prefix: '',
    suffix: '',
    loading: true,
  });

  useEffect(() => {
    getUserInfo(props.userId);
  }, [props.allUserItems, props.userId]);

  const getUserInfo = (userId) => {
    const user = props.allUserItems.find((user) => user.UserId === userId);
    if (user) {
      setState({
        firstName: user.FirstName,
        lastName: user.LastName,
        suffix: user.Suffix,
        jobTitle: user.JobTitle,
        organization: user.Organization,
        prefix: respectablePrefixes.has(user.Prefix) ? user.Prefix : '',
        profilePictureUrl: user.ProfilePictureUrl,
        loading: false,
      });
    }
  };

  const onUserClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `/userprofile/${props.userId}`;
  };

  const { firstName, lastName, profilePictureUrl, jobTitle, organization, prefix, suffix, loading } = state;
  const name = prefix + ' ' + firstName + ' ' + lastName + ' ' + suffix;

  return (
    <>
      {!loading &&
        <div className={styles.UserCard}>
          <div className={styles.NavLinks} onClick={onUserClick}>
            {profilePictureUrl
              ? (<img className={styles.UserProfilePictureImage} src={profilePictureUrl} alt={`${name}'s Profile Picture`} />)
              : (<div className={`${styles.UserProfilePictureImage} ${styles.AvatarFill}`}>
                {firstName ? firstName.charAt(0).toUpperCase() : ''}{lastName ? lastName.charAt(0).toUpperCase() : ''}
              </div>)}
          </div>

          <div className={styles.UserInfo}>
            <div className={styles.UserName} onClick={onUserClick}>
              <Typography variant='subtitle1'>{name}</Typography>
            </div>
            {jobTitle &&
              <Typography variant='body1'>{jobTitle.trim()}{organization ? ` at ${organization.trim()}` : ''}</Typography>
            }
          </div>
        </div>
      }
    </>
  );
};

export default UserCardFeed;
