import { Divider } from '@mui/material';
import React, { Component } from 'react'
import PaperCard from '../PaperCard/PaperCard';
import TalkCard from '../TalkCard/TalkCard';
import moment from 'moment';

export default class ProfileLikesFeed extends Component {
  render() {
    const {userLikes, allUserItems, fullLeftBar, handleAddedSavedPaper, handleRemoveSavedPaper, 
      handleAddedUpcomingTalk, handleRemoveUpcomingTalk, handleAddedSavedTalk, 
      handleRemoveSavedTalk} = this.props;
      userLikes.sort((b, a) => new Date(b.RequestTime).getTime() - new Date(a.RequestTime).getTime());
    return (
      <div>
        {userLikes.map((e, index) => {
          if (e.PaperId) {
          return (
            <div key={e.PaperId}>
              <PaperCard
                key={e.PaperId}
                paperId={e.PaperId}
                semanticPaperId={e.semanticPaperId}
                paperUrl={e.paperUrl}
                paperImageUrl={e.paperImageUrl}
                userId={e.userId}
                title={e.title}
                year={e.year}
                authors={e.authors}
                tldr={e.tldr}
                videoLink={e.videoLink}
                keywords={e.keywords}
                chatGptMainFinding={e.chatGptMainFinding}
                chatGptSummary={e.chatGptSummary}
                requestTime={e.RequestTime}
                saved={e.saved}
                read={e.read}
                liked={e.liked}
                allUserItems={allUserItems}
                fullLeftBar={fullLeftBar}
                handleAddedSavedPaper={handleAddedSavedPaper}
                handleRemoveSavedPaper={handleRemoveSavedPaper}
              />
              {index === userLikes.length - 1 ? '': <Divider />}
              </div>
          );
        } else if (e.EventId) {
          const startDateInMilliSecs = new Date(e.startDate).getTime();
          const endDateInMilliSecs = new Date(e.endDate).getTime();
          const isLive = startDateInMilliSecs <= Date.now() && Date.now() <= endDateInMilliSecs;
          return (
            <div key={e.EventId}>
                <TalkCard
                  key={e.EventId}
                  eventId={e.EventId}
                  startDate={e.startDate}
                  endDate={e.endDate}
                  thumbnailURL={e.thumbnailURL}
                  userId={e.userId}
                  title={e.title}
                  date={
                    moment(e.startDate).format("ddd, M/D/YYYY") === moment(e.endDate).format("ddd, M/D/YYYY") ?
                      moment(e.startDate).format("ddd, M/D/YYYY") :
                      moment(e.startDate).format("ddd, M/D/YYYY") + ' to ' + moment(e.endDate).format("ddd, M/D/YYYY")}
                  time={moment(e.startDate).format('h:mm a') + ' to ' + moment(e.endDate).format('h:mm a')}
                  timeZone={e.timeZone.value}
                  eventType={e.eventType.label}
                  activeLoc={e.activeLoc}
                  description={e.description}
                  hostName={e.hostName}
                  speakerBio={e.speakerBio}
                  institution={e.institution}
                  venue={e.venue}
                  meetingLink={e.meetingLink}
                  keywords={e.keywords}
                  liked={e.liked}
                  saved={e.saved}
                  addedToCalendar={e.attending}
                  isLive={isLive}
                  allUserItems={allUserItems}
                  fullLeftBar={fullLeftBar}
                  handleAddedUpcomingTalk={handleAddedUpcomingTalk}
                  handleRemoveUpcomingTalk={handleRemoveUpcomingTalk}
                  handleAddedSavedTalk={handleAddedSavedTalk}
                  handleRemoveSavedTalk={handleRemoveSavedTalk}
                />
                {index === userLikes.length - 1 ? '': <Divider />}
              </div>
          );
        }
        })}
      </div>
    )
  }
}
