import React, { useState, useEffect } from 'react';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import _config from '../../signup/config';
import axios from 'axios';
import UserCardFeed from '../UserCardFeed/UserCardFeed';
import {
  ContentCopyOutlined,
  DeleteOutline,
  Edit,
  Info,
  ReadMore,
} from '@mui/icons-material';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import { Divider, Rating } from '@mui/material';
import ClipLoader from 'react-spinners/ClipLoader';
import { ToastContainer, toast } from 'react-toastify';
import RequestService from '../../requestservice/requestservice.js';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import styles from './JobThreadStyles.module.css';
import { withRouter } from 'react-router-dom';
import CreateJobForm from '../../CreateJobForm/CreateJobForm';
import DialogBox from '../DialogBox/DialogBox';

function JobThread(props) {
  const [loading, setLoading] = useState(true);
  const [jobId, setJobId] = useState(
    props.match.params.jobId ? props.match.params.jobId : ''
  );
  const [userId, setUserId] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [description, setDescription] = useState('');
  const [employer, setEmployer] = useState('');
  const [location, setLocation] = useState('');
  const [compensation, setCompensation] = useState('');
  const [qualifications, setQualifications] = useState('');
  const [disclaimers, setDisclaimers] = useState('');
  const [benefits, setBenefits] = useState('');
  const [companyOverview, setCompanyOverview] = useState('');
  const [instructions, setInstructions] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [applicationDeadline, setApplicationDeadline] = useState('');
  const [datePosted, setDatePosted] = useState('');
  const [sourceJobLink, setSourceJobLink] = useState('');
  const [showMore, setShowMore] = useState(true);
  const [editJobPopup, setEditJobPopup] = useState(false);
  const [checkDeletePopup, setCheckDeletePopup] = useState(false);

  const userPool = new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: _config.cognito.userPoolId,
    ClientId: _config.cognito.userPoolClientId,
  });

  const requestService = new RequestService();

  useEffect(() => {
    if (props.location.jobProps) {
      getJob();
    } else if (jobId && props.signedInUserId) {
      getJobApi();
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [props.match.params.jobId, props.location.jobProps, props.signedInUserId]);

  const getJob = () => {
    const jobProps = props.location.jobProps;
    if (props.location.jobProps) {
      setLoading(false);
      setUserId(jobProps.userId);
      setJobTitle(jobProps.jobTitle);
      setDescription(jobProps.description);
      setEmployer(jobProps.employer);
      setLocation(jobProps.location);
      setCompensation(jobProps.compensation);
      setQualifications(jobProps.qualifications);
      setDisclaimers(jobProps.disclaimers);
      setBenefits(jobProps.benefits);
      setCompanyOverview(jobProps.companyOverview);
      setInstructions(jobProps.instructions);
      setThumbnailUrl(jobProps.thumbnailUrl);
      setApplicationDeadline(jobProps.applicationDeadline);
      setDatePosted(jobProps.datePosted);
      setSourceJobLink(jobProps.sourceJobLink);
    }
  };

  const getJobApi = async () => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      return;
    }
    const job = await requestService.getJob(jobId, props.signedInUserId);
    if (!job) {
      return;
    }
    setLoading(false);
    setUserId(job.userId);
    setJobTitle(job.jobTitle);
    setDescription(job.description);
    setEmployer(job.employer);
    setLocation(job.location);
    setCompensation(job.compensation);
    setQualifications(job.qualifications);
    setDisclaimers(job.disclaimers);
    setBenefits(job.benefits);
    setCompanyOverview(job.companyOverview);
    setInstructions(job.instructions);
    setThumbnailUrl(job.thumbnailUrl);
    setApplicationDeadline(
      job.applicationDeadline
        ? new Date(job.applicationDeadline)
        : ''
    );
    setDatePosted(new Date(job.datePosted));
    setSourceJobLink(job.sourceJobLink);
  };

  const whenSaveClicked = async () => {
    setLoading(false);
    const data = { jobId };
    await requestService.addSavedJob(data, props);
    const job = {
      JobId: jobId,
      semanticJobId: props.semanticJobId,
      jobUrl: props.jobUrl,
      thumbnailUrl,
      userId,
      title: jobTitle,
      year: props.year,
      authors: props.authors,
      tldr: props.tldr,
      videoLink: props.videoLink,
      keywords: props.keywords,
      RequestTime: props.requestTime,
      saved: true,
      read: props.read,
      liked: props.liked,
      activeComp: props.activeComp,
    };
    if (props.location.jobProps) {
      props.location.jobProps.handleAddedSavedJob(job);
    } else {
      props.handleAddedSavedJob(job);
    }
  };

  const handleEditPopupOpen = () => {
    setEditJobPopup(true);
  };

  const handleJobPopupClose = () => {
    setEditJobPopup(false);
  };

  const setDeletePopup = (bool) => {
    setCheckDeletePopup(bool);
  };

  const goToJob = () => {
    if (!sourceJobLink) {
      return;
    }
    const link = sourceJobLink.startsWith('http')
      ? sourceJobLink
      : 'https://' + sourceJobLink;
    window.open(link);
  };

  const deleteJob = async () => {
    const data = { jobId };
    await requestService.deleteJob(data, () => props.history.goBack());
  };

  const copyLink = (e) => {
    navigator.clipboard.writeText(window.location.toString());
    toast('Job link copied to your clipboard!');
  };

  const nl2br = (str) => {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
  };

  const {
    pageTitle,
    drawerWidth,
    history,
    allUserItems,
    signedInUserId,
  } = props;

  const a = moment(); //now
  const b = moment(datePosted);

  const inMinutes = a.diff(b, 'minutes');
  const inHours = a.diff(b, 'hours');
  const inDays = a.diff(b, 'days');
  const inWeeks = a.diff(b, 'weeks');
  const postTime =
    inWeeks > 0
      ? b.format('MMM D')
      : inDays > 0
        ? `${inDays}d`
        : inHours > 0
          ? `${inHours}h`
          : `${inMinutes}m`;

  return (
    <div>
      <Helmet>
        <title>{jobTitle}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={jobTitle} />
        <meta name="author" content="JobHax" />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="nositelinkssearchbox" />
        <meta name="google" content="notranslate" />
      </Helmet>
      {loading ? (
        <div className={styles.LoadingDiv}>
          <Typography sx={{ m: '8px', color: '#1B065E' }} variant='h1'>Loading...</Typography>
          <ClipLoader
            color={"var(--primary-color)"}
            loading={loading}
            size={100}
          />
        </div>
      ) : (
        <div className={styles.JobThread}>
          <div className={styles.JobDiv}>
            <div className={styles.JobContainer}>
              <div className={styles.UserCard}>
                {userId && (
                  <>
                    {userId !== 'dOIL81did2gwO6Wz5Afbcw' ? (
                      <UserCardFeed userId={userId} allUserItems={allUserItems} />
                    ) : (
                      <div className={styles.Info}>
                        <Info sx={{ fontSize: '16px', color: '#5591C9', marginRight: '2px' }} />
                        <span>This job was sourced from a public website.</span>
                      </div>
                    )}
                  </>
                )}
                <div className={styles.TimeAndModifyButtons}>
                  <div className={styles.PostTime}>{postTime}</div>
                  <div className={styles.LowLevelInteractions}>
                    {userId === signedInUserId && (
                      <>
                        <Tooltip title="Edit Job">
                          <IconButton onClick={handleEditPopupOpen}>
                            <Edit sx={{ fontSize: '24px' }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Job">
                          <IconButton onClick={() => setDeletePopup(true)}>
                            <DeleteOutline sx={{ fontSize: '24px' }} />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <Tooltip title="Copy Post Link">
                      <IconButton onClick={copyLink}>
                        <ContentCopyOutlined sx={{ fontSize: '24px' }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className={styles.Title}>
                <Typography variant="h3">{jobTitle}</Typography>
              </div>
              {employer && (
                <div className={styles.Employer}>
                  <Typography variant="h3">{employer}</Typography>
                </div>
              )}
              {location && (
                <div className={styles.Location}>
                  <Typography variant="body1">
                    <span dangerouslySetInnerHTML={{ __html: nl2br(location) }} />
                  </Typography>
                </div>
              )}
              {compensation && (
                <p>
                  <Typography variant="h4">Compensation</Typography>
                  <Typography variant="body1">
                    <span
                      className={styles.description}
                      dangerouslySetInnerHTML={{ __html: nl2br(compensation) }}
                    />
                  </Typography>
                </p>
              )}
              {description && (
                <p className={styles.description}>
                  <Typography variant="h4">Description</Typography>
                  <Typography variant="body1">
                    <span
                      className={styles.Tldr}
                      dangerouslySetInnerHTML={{ __html: nl2br(description) }}
                    />
                  </Typography>
                </p>
              )}

              {companyOverview && (
                <p className={styles.description}>
                  <Typography variant="h4">Company overview</Typography>
                  <Typography variant="body1">
                    <span
                      dangerouslySetInnerHTML={{ __html: nl2br(companyOverview) }}
                    />
                  </Typography>
                </p>
              )}
              {benefits && (
                <p className={styles.description}>
                  <Typography variant="h4">Benefits</Typography>
                  <Typography variant="body1">
                    <span
                      dangerouslySetInnerHTML={{ __html: nl2br(benefits) }}
                    />
                  </Typography>
                </p>
              )}
              {instructions && (
                <p className={styles.description}>
                  <Typography variant="h4">Instructions</Typography>
                  <Typography variant="body1">
                    <span
                      dangerouslySetInnerHTML={{ __html: nl2br(instructions) }}
                    />
                  </Typography>
                </p>
              )}
              {qualifications && (
                <p className={styles.description}>
                  <Typography variant="h4">Qualifications</Typography>
                  <Typography variant="body1">
                    <span
                      dangerouslySetInnerHTML={{ __html: nl2br(qualifications) }}
                    />
                  </Typography>
                </p>
              )}
              {disclaimers && (
                <p className={styles.description}>
                  <Typography variant="h4">Disclaimers</Typography>
                  <Typography variant="body1">
                    <span
                      dangerouslySetInnerHTML={{ __html: nl2br(disclaimers) }}
                    />
                  </Typography>
                </p>
              )}
              {applicationDeadline && (
                <span className={styles.DateTime}>
                  <Typography variant="h4">Application deadline</Typography>
                  <Typography variant="body1">
                    {moment(applicationDeadline).format('ddd, MMMM Do YYYY')}
                  </Typography>
                </span>
              )}
              {sourceJobLink && (
                <button onClick={goToJob} className={styles.ApplyButton}>
                  <div>Apply</div>
                  <div>
                    <i className="material-icons-outlined small">open_in_new</i>
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <Divider />
      <DialogBox
        open={editJobPopup}
        handleClose={handleJobPopupClose}
        title="Edit Job"
      >
        <CreateJobForm
          closeCreateJobPopup={handleJobPopupClose}
          jobId={jobId}
          jobState={props}
        />
      </DialogBox>
      <DialogBox
        open={checkDeletePopup}
        handleClose={() => setDeletePopup(false)}
        title="Delete job?"
      >
        <div className={styles.PopupButtonDiv}>
          <Button
            type="button"
            variant="contained"
            className={styles.goBackButton}
            onClick={() => setDeletePopup(false)}
            sx={{
              background: 'white',
              border: '2px solid #FEBA3F',
              ':hover': {
                bgcolor: '#eeeeee',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            className={styles.discardPopupButton}
            sx={{ color: '#1B065E', ml: '12px' }}
            onClick={deleteJob}
          >
            Delete
          </Button>
        </div>
      </DialogBox>
      <ToastContainer />
    </div>
  );
}

export default withRouter(JobThread);
