import React, { Component } from 'react'
import styles from './KeywordSmallStyles.module.css'

export default class KeywordSmall extends Component {
  render() {
    return (
      <div className={styles.KeywordSmall}>
        {this.props.label}
      </div>
    )
  }
}
