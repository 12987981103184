import React, { Component } from 'react'
import RequestService from '../../requestservice/requestservice'
import styles from './ProfileTalkStyles.module.css'
import _config from "../../signup/config";
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { RemoveCircleOutline, CalendarToday, ThumbUpSharp } from '@mui/icons-material'
import { Tooltip, IconButton, Box } from '@mui/material'
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

const requestService = new RequestService();

export default class ProfileTalk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: this.props.eventId,
      title: this.props.title,
      venue: this.props.venue,
      meetingLink: this.props.meetingLink,
      timeZone: this.props.timeZone,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      description: this.props.description,
      addedToCalendar: this.props.addedToCalendar,
      liked: this.props.liked,
      saved: this.props.saved,
      activeLoc: this.props.activeLoc,
    };

    var poolData = {
      UserPoolId: _config.cognito.userPoolId,
      ClientId: _config.cognito.userPoolClientId,
    };
    this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    this.deleteTalk = this.deleteTalk.bind(this);
    this.addSavedTalkToCalendar = this.addSavedTalkToCalendar.bind(this);
  }

  deleteTalk(e, eventId) {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.type === 'saved') {
      this.props.removeFromSavedTalksCall(eventId);
    } else {
      this.props.removeFromRsvpTalksCall(eventId);
    }
  }

  addSavedTalkToCalendar(e, eventId) {
    e.preventDefault();
    e.stopPropagation();
    this.props.fromSavedToRsvpTalkCall(eventId);
    requestService.addToCalendar(this);
  }


  render() {
    const { hostName, institution, dateTime, title, type, eventId, activeLoc } = this.props;
    return (
      <Link style={{ textDecoration: 'none', color: 'black' }}
        to={{
          pathname: '/talk/' + eventId,
          talkProps: this.props,
          talkState: this.state
        }}>
        <div className={styles.ProfileTalk}>
          <Box sx={{ width: '-webkit-fill-available' }}>{`${hostName}, ${institution && `institution, `} "${title}"`}</Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>

            <Tooltip title='Add to calendar'>
              <IconButton sx={{
                background: '#EEEEEE'
              }} onClick={(e) => this.addSavedTalkToCalendar(e, eventId)}>
                <CalendarToday
                  sx={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    fontSize: '24px',
                    cursor: 'pointer'
                  }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title='Delete'>
              <IconButton sx={{
                background: '#EEEEEE'
              }} aria-label='delete' onClick={(e) => this.deleteTalk(e, eventId)}>
                <DeleteIcon
                  sx={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    fontSize: '24px',
                    cursor: 'pointer'
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
          {/* <div className={styles.TalkInfoAndIcons}>
          <div className={styles.TitleAndAuthor}>
          <Link style={{textDecoration: 'none', color: 'black'}} 
              to={{pathname: '/talk/' + eventId,
                  talkProps: this.props,
                  talkState: this.state}}>
              <div className={styles.AuthorAndInstitution}>
                {hostName}{institution && <span>, {institution}</span>}
              </div>
          </Link>
            <div className={styles.Title}>
              "{title}"
            </div>
            <div className={styles.DateAndTime}>
              {this.state.activeLoc === 'Recorded' ? 'Recording' : dateTime}
            </div>
          </div>
          {type === 'saved' && 
          <div className={styles.Icons}>
            <Tooltip title='Add to Calendar'>
              <IconButton onClick={() => this.addSavedTalkToCalendar(eventId)}>
                <CalendarToday 
                sx={{ color: '#6F6F6F', fontSize: '24px', cursor: 'pointer'}}
                />
              </IconButton>
            </Tooltip>
          </div>}
        </div> */}

        </div>
      </Link >
    )
  }
}
