import { IconButton, Tooltip } from '@mui/material';
import { FavoriteBorder } from '@mui/icons-material';
import CommentWrapper from '../CommentWrapper/CommentWrapper';
import React, { Component } from 'react'
import styles from './PaperCommentStyles.module.css'
import RequestService from "../../requestservice/requestservice";

export default class PaperComment extends CommentWrapper {

  constructor(props) {
    super(props);
    this.commentId = this.props.commentId;
    this.paperId = this.props.paperId;
  }

  async getCommentLikes() {
    this.requestService.getLikesForPaperComment(this.commentId,
      (response) => this.setNumLikesAndSelfUserLikedCallback(response));
  }

  async likeComment() {
    await this.requestService.likePaperComment({ paperCommentId: this.commentId, },
      () => { this.likeCommentUpdateUiCallback() });
  }

  async unlikeComment() {
    await this.requestService.unlikePaperComment({ paperCommentId: this.commentId, }, () => { this.unlikeCommentUpdateUiCallback() });
  }

  async deleteComment() {
    this.requestService.deletePaperComment({ paperId: this.paperId, paperCommentId: this.commentId, }, () => this.props.deleteCommentFromCommentsUiCallback(this.commentId));
  }
}
