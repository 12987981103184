import { IconButton, Tooltip } from '@mui/material';
import { FavoriteBorder } from '@mui/icons-material';
import CommentWrapper from '../CommentWrapper/CommentWrapper';
import React, { Component } from 'react'
import styles from './TalkCommentStyles.module.css'
import RequestService from "../../requestservice/requestservice";

export default class TalkComment extends CommentWrapper {

  constructor(props) {
    super(props);
    this.commentId = this.props.commentId;
    this.eventId = this.props.eventId;
  }

  async getCommentLikes() {
    this.requestService.getLikesForEventComment(this.commentId,
      (response) => this.setNumLikesAndSelfUserLikedCallback(response));
  }

  async likeComment() {
    await this.requestService.likeEventComment({ eventCommentId: this.commentId, },
      () => { this.likeCommentUpdateUiCallback() });
  }

  async unlikeComment() {
    await this.requestService.unlikeEventComment({ eventCommentId: this.commentId, }, () => { this.unlikeCommentUpdateUiCallback() });
  }

  async deleteComment() {
    this.requestService.deleteEventComment({ eventId: this.eventId, eventCommentId: this.commentId, }, () => this.props.deleteCommentFromCommentsUiCallback(this.commentId));
  }
}
