import React, { Component } from 'react'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import axios from 'axios';
import _config from "../../signup/config";
import {
  CalendarToday, Bookmark,
  BookmarkBorderOutlined, CommentOutlined, Info, MeetingRoomOutlined, Favorite, FavoriteBorderOutlined, OndemandVideo
} from '@mui/icons-material';
import UserCardFeed from '../UserCardFeed/UserCardFeed';
import TalkComments from '../TalkComments/TalkComments';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { gapi, CLIENT_ID, API_KEY, DISCOVERY_DOCS, SCOPES, timeZoneToIanaTimezone } from '../GoogleCalAPI/APIvariables';
import RequestService from "../../requestservice/requestservice";
import ScriptTag from 'react-script-tag';
import google_calendar_logo from '../../../images/btn_google_signin_light_normal_web.png';
import { toast } from 'react-toastify';
import CoPresentIcon from '@mui/icons-material/CoPresent';

import styles from './TalkCardStyles.module.css';
import { Link } from 'react-router-dom';
let google = window.google;


class TalkCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liked: this.props.liked,
      saved: this.props.saved,
      showMore: false,
      eventId: this.props.eventId,
      addedToCalendar: this.props.addedToCalendar,
      showComments: false,
      showBio: false,
      isDesktop: window.innerWidth > 768,
    };

    var poolData = {
      UserPoolId: _config.cognito.userPoolId,
      ClientId: _config.cognito.userPoolClientId,
    };

    this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    this.showText = this.showText.bind(this);
    this.clickedCommentButton = this.clickedCommentButton.bind(this);
    this.addToCalendar = this.addToCalendar.bind(this);
    this.whenSaveClicked = this.whenSaveClicked.bind(this);
    this.whenUnsaveClicked = this.whenUnsaveClicked.bind(this);
    this.whenUnlikeClicked = this.whenUnlikeClicked.bind(this);
    this.whenLikeClicked = this.whenLikeClicked.bind(this);
    this.addToCalendarBackend = this.addToCalendarBackend.bind(this);
    this.removeFromCalendar = this.removeFromCalendar.bind(this);
    this.goToMeeting = this.goToMeeting.bind(this);
    this.toggleBio = this.toggleBio.bind(this);
    this.requestService = new RequestService();
  }

  showText(e) {
    e.preventDefault()
    e.stopPropagation();
    this.setState({ showMore: !this.state.showMore });
  }

  componentDidMount() {
    gapi.load('client', async () => {
      await gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: DISCOVERY_DOCS,
      });
    });
  }

  async addToCalendar(evt) {
    evt.preventDefault();
    const cognitoUser = this.userPool.getCurrentUser();
    if (!cognitoUser) {
      window.location.href = '/signin';
      return;
    }

    try {
      const client = google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        callback: (tokenResponse) => {
          if (tokenResponse && tokenResponse.access_token &&
            google.accounts.oauth2.hasGrantedAllScopes(tokenResponse,
              SCOPES)) {
            // Can add calendar events
            const event = {
              'summary': this.props.title,
              'location': this.props.venue.formatted_address ? this.props.venue.formatted_address + ' ' + (this.props.meetingLink ? this.props.meetingLink : '')
                : (this.props.meetingLink ? this.props.meetingLink : ''),
              'description': this.props.description,
              'start': {
                'dateTime': moment(this.props.startDate).format().substring(0, moment(this.props.startDate).format().length - 6),
                'timeZone': moment.tz.guess(),
              },
              'end': {
                'dateTime': moment(this.props.endDate).format().substring(0, moment(this.props.startDate).format().length - 6),
                'timeZone': moment.tz.guess(),
              },
              'reminders': {
                'useDefault': false,
                'overrides': [
                  { 'method': 'email', 'minutes': 24 * 60 },
                  { 'method': 'popup', 'minutes': 10 }
                ]
              }
            };

            const request = gapi.client.calendar.events.insert({
              'calendarId': 'primary',
              'resource': event
            });

            request
              .execute(event => {
                window.open(event.htmlLink);
                this.addToCalendarBackend();
                toast("Talk added to your calendar!");
              });
          }
        }
      });
      client.requestAccessToken();
    } catch (err) {
      this.addToCalendarBackend();
      toast("Talk added to your calendar!");
    }
  }

  addToCalendarBackend() {
    const cognitoUser = this.userPool.getCurrentUser();
    if (!cognitoUser) {
      window.location.href = '/signin';
      return;
    }
    this.setState({ addedToCalendar: true });
    var self = this;
    var onSuccessAdded = function registerSuccess(result) {
      var eventId = result.data.eventId;
      var userId = result.data.userId;
      var confirmation = ('talk added to event calendar database!');
      const event = {
        EventId: self.props.eventId,
        hostName: self.props.hostName,
        institution: self.props.institution,
        startDate: self.props.startDate,
        endDate: self.props.endDate,
        title: self.props.title,
        venue: self.props.venue,
        meetingLink: self.props.meetingLink,
        timeZone: self.props.timeZone,
        description: self.props.description,
        speakerBio: self.props.speakerBio,
        thumbnailURL: self.props.thumbnailURL,
        userId: self.props.userId,
        eventType: self.props.eventType,
        activeLoc: self.props.activeLoc,
        liked: self.props.liked,
      }
      if (confirmation) {
        self.setState({ addedToCalendar: true });
        self.props.handleAddedUpcomingTalk(event);
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
      self.setState({ addedToCalendar: false });
    };
    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const data = {
                "userId": result[3].Value,
                "eventId": self.state.eventId,
              };
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/addeventtousercalendar';
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .post(api, data, axiosConfig)
                .then((response) => {
                  onSuccessAdded(response)
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          });
        }
      });
    }
  }

  removeFromCalendar(evt) {
    evt.preventDefault();
    this.setState({ addedToCalendar: false });
    var self = this;
    var onSuccessDeleted = function registerSuccess(result) {
      var eventId = result.data.eventId;
      var userId = result.data.userId;
      var confirmation = ('event removed from the liked events database.');
      if (confirmation) {
        self.setState({ addedToCalendar: false });
        self.props.handleRemoveUpcomingTalk(eventId);
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
      self.setState({ addedToCalendar: true });
    };
    var cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              let currentUserId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/deleteeventfromusercalendar?userId=' + currentUserId + '&eventId=' + self.state.eventId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .delete(api, axiosConfig)
                .then((response) => {
                  onSuccessDeleted(response)
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          })
        }
      });
    }
  }

  async whenSaveClicked(evt) {
    evt.preventDefault();
    const cognitoUser = this.userPool.getCurrentUser();
    if (!cognitoUser) {
      window.location.href = '/signin';
      return;
    }
    this.setState({ saved: true });
    const data = { eventId: this.props.eventId };
    if (this.props.handleAddedSavedTalk) {
      const event = {
        EventId: this.props.eventId,
        hostName: this.props.hostName,
        institution: this.props.institution,
        venue: this.props.venue,
        userId: this.props.userId,
        title: this.props.title,
        timeZone: this.props.timeZone,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        meetingLink: this.props.meetingLink,
        keywords: this.props.keywords,
        description: this.props.description,
        speakerBio: this.props.speakerBio,
        thumbnailURL: this.props.thumbnailURL,
        eventType: this.props.eventType,
        activeLoc: this.props.activeLoc,
        RequestTime: this.props.requestTime,
        saved: this.state.saved,
        attending: this.state.addedToCalendar,
        liked: this.state.liked,
      };
      this.props.handleAddedSavedTalk(event);
    }

    await this.requestService.addSavedEvent(data, this);
    // alert("View saved talks in your profile, where you can add notes.");
  }

  async whenUnsaveClicked(evt) {
    evt.preventDefault();
    this.setState({ saved: false });
    const data = { eventId: this.props.eventId };
    if (this.props.handleRemoveSavedTalk) {
      this.props.handleRemoveSavedTalk(this.props.eventId);
    }
    await this.requestService.removeSavedEvent(data, this);
  }

  whenLikeClicked(evt) {
    evt.preventDefault();
    const cognitoUser = this.userPool.getCurrentUser();
    if (!cognitoUser) {
      window.location.href = '/signin';
      return;
    }
    var self = this;
    self.setState({ liked: true });
    var onSuccessLiked = function registerSuccess(result) {
      var eventId = result.data.eventId;
      var userId = result.data.userId;
      var confirmation = ('event put into the liked events database!');
      if (confirmation) {
        self.setState({ liked: true });
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
      self.setState({ liked: false });
    };
    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const data = {
                "userId": result[3].Value,
                "eventId": self.state.eventId,
              };
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/addlikedevent';
              console.log('likedEvent data:');
              console.log(data);
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .post(api, data, axiosConfig)
                .then((response) => {
                  onSuccessLiked(response)
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          });
        }
      }
      );
    }
  }

  whenUnlikeClicked(evt) {
    evt.preventDefault();
    var self = this;
    self.setState({ liked: false });
    var onSuccessUnliked = function registerSuccess(result) {
      var eventId = result.data.eventId;
      var userId = result.data.userId;
      var confirmation = ('event removed from the liked events database.');
      if (confirmation) {
        self.setState({ liked: false });
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
      self.setState({ liked: true });
    };
    var cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              let currentUserId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/unlikeevent?userId=' + currentUserId + '&eventId=' + self.state.eventId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              console.log('unlikeEvent API: ');
              console.log(api);
              axios
                .delete(api, axiosConfig)
                .then((response) => {
                  onSuccessUnliked(response)
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          })
        }
      });
    }
  }

  clickedCommentButton(e) {
    e.preventDefault();
    const cognitoUser = this.userPool.getCurrentUser();
    if (!cognitoUser) {
      window.location.href = '/signin';
      return;
    }
    this.setState({ showComments: !this.state.showComments });
  }

  goToMeeting(e) {
    e.preventDefault();
    window.open(this.props.meetingLink);
  }

  toggleBio(e) {
    e.preventDefault();
    this.setState({ showBio: !this.state.showBio });
  }

  render() {
    const { userId, thumbnailURL, date, startDate, time, activeLoc, timeZone, title, hostName, institution,
      description, speakerBio, fullLeftBar, allUserItems, talkWebPage, isLive } = this.props;
    const { eventId, liked, saved, addedToCalendar, showBio } = this.state;
    const recorded = activeLoc == 'Recorded';
    return (
      <div>
        <ScriptTag isHydrating={true} type="text/javascript"
          src="https://accounts.google.com/gsi/client" />
        <Link style={{ textDecoration: 'none', color: 'black' }}
          to={{
            pathname: `/talk/${eventId}`,
            talkProps: this.props,
            talkState: this.state
          }}>
          <div className={styles.TalkDiv}>
            <div className={styles.TalkText}>
              <div className={styles.TalkUser}>
                {userId &&
                  <div className={styles.UserTitle}>
                    {userId !== 'dOIL81did2gwO6Wz5Afbcw' ?
                      <UserCardFeed
                        userId={userId}
                        allUserItems={allUserItems} />
                      :
                      <div className={styles.Info}>
                        <Info sx={{ fontSize: '16px', color: '#5591C9', marginRight: '2px' }} />
                        <span>Symposia sourced this talk from a public
                          website.</span>
                      </div>
                    }
                  </div>
                }
                <Box sx={{ marginLeft: 'auto' }}>
                  <CoPresentIcon style={{ color: '#5591C9' }}></CoPresentIcon>
                </Box>
              </div>
              <div className={styles.Thumbnail}>
                {thumbnailURL &&
                  <img className={styles.ThumbnailImage} src={thumbnailURL} />
                }
              </div>
              <div className={styles.SpeakerNameAndInstitution}>
                <div className={styles.SpeakerName}>
                  <Typography variant='subtitle1'>{hostName}</Typography>
                </div>
                <div className={styles.SpeakerInstitution}>
                  <Typography variant='body1'> {institution && institution} </Typography>
                </div>
              </div>

              <div className={styles.TalkTitle}>
                <Typography variant='h3'>{title}</Typography>
              </div>
              {recorded ?
                <div className={styles.DateTime}>
                  <Typography variant='body1'>(Recording)</Typography>
                </div>
                :
                <div className={styles.DateTime}>
                  {date}, {time} {moment.tz(moment.tz.guess()).format('z')}
                </div>
              }
              <div className={styles.descriptionContainer}>
                <Typography variant="body1">{description?.length < 100 || this.state.showMore ? description : `${description?.substring(0, 100)}...`}</Typography>
                {description?.length >= 100 &&
                  <Button className={styles.showButton} onClick={(e) => this.showText(e)}>
                    <Typography variant='subtitle2' sx={{ color: '#212121' }}> {!this.state.showMore ? 'See more' : 'See less'}</Typography>
                  </Button>}
              </div>
            </div>
            <div className={styles.Interactions}>
              {liked ?
                <Tooltip title='Remove Like'>
                  <button className={styles.InteractionButton + ' ' + styles.Liked} onClick={this.whenUnlikeClicked}>
                    {fullLeftBar ?
                      <>
                        <Favorite sx={{ mr: '8px', fontSize: '24px', color: '#1B065E' }} />
                        {window.innerWidth > 768 && 'Liked'}
                      </>
                      :
                      <>
                        <Favorite sx={{ fontSize: '24px', color: '#1B065E' }} />
                      </>}
                  </button>
                </Tooltip>
                :
                <>
                  {fullLeftBar ?
                    <button className={styles.InteractionButton} onClick={this.whenLikeClicked}>
                      <FavoriteBorderOutlined sx={{ mr: '8px', fontSize: '24px', color: '#1B065E' }} />
                      {this.state.isDesktop && 'Like'}
                    </button>
                    :
                    <Tooltip title='Like'>
                      <button className={styles.InteractionButton} onClick={this.whenLikeClicked}>
                        <FavoriteBorderOutlined sx={{ fontSize: '24px', color: '#1B065E' }} />
                      </button>
                    </Tooltip>}
                </>
              }
              {isLive && this.props.meetingLink ?
                <>
                  {fullLeftBar ?
                    <button className={styles.InteractionButton + ' ' + styles.Interacted} onClick={this.goToMeeting}>
                      <MeetingRoomOutlined sx={{ mr: '8px', fontSize: '24px' }} />
                      {this.state.isDesktop && 'Join'}
                    </button>
                    :
                    <Tooltip title='Join Meeting'>
                      <button className={styles.InteractionButton + ' ' + styles.Interacted} onClick={this.goToMeeting}>
                        <MeetingRoomOutlined sx={{ fontSize: '24px' }} />
                      </button>
                    </Tooltip>}
                </>
                :
                <>
                  {recorded ?
                    <>
                      {fullLeftBar ?
                        <button className={styles.InteractionButton} onClick={this.goToMeeting}>
                          <OndemandVideo sx={{ mr: '8px', fontSize: '24px' }} />
                          {this.state.isDesktop && 'Watch'}
                        </button>
                        :
                        <Tooltip title='Watch Video'>
                          <button className={styles.InteractionButton} onClick={this.goToMeeting}>
                            <OndemandVideo sx={{ fontSize: '24px' }} />
                          </button>
                        </Tooltip>
                      }
                    </>
                    :
                    <>
                      {!addedToCalendar ?
                        <>
                          {fullLeftBar ?
                            <button className={styles.InteractionButton} onClick={this.addToCalendar}>
                              {/* <CalendarTodayOutlined sx={{ mr: '8px', fontSize: '24px' }} /> */}
                              <img src={google_calendar_logo} style={{ width: "45px" }} alt="Add to Google Calendar"></img>
                              {this.state.isDesktop && 'Add to Google Calendar'}
                            </button>
                            :
                            <Tooltip title='Add to Google Calendar'>
                              <button className={styles.InteractionButton} onClick={this.addToCalendar}>
                                <img src={google_calendar_logo} style={{ width: "45px" }} alt="Add to Google Calendar"></img>
                              </button>
                            </Tooltip>
                          }
                        </>
                        :
                        <Tooltip title='Remove from Upcoming Talks'>
                          <button
                            className={styles.InteractionButton + ' ' + styles.Interacted}
                            onClick={this.removeFromCalendar}
                          >
                            {fullLeftBar ?
                              <>
                                <CalendarToday sx={{ mr: '8px', fontSize: '24px' }} />
                                {this.state.isDesktop && 'Attending'}
                              </>
                              :
                              <CalendarToday sx={{ fontSize: '24px' }} />
                            }
                          </button>
                        </Tooltip>
                      }
                    </>
                  }

                </>

              }

              {saved ?
                <Tooltip title='Remove from Saved Talks'>
                  <button className={styles.InteractionButton + ' ' + styles.Interacted} onClick={this.whenUnsaveClicked}>
                    {fullLeftBar ?
                      <>
                        <Bookmark sx={{ mr: '8px', fontSize: '24px' }} />
                        {this.state.isDesktop && 'Saved'}
                      </>
                      :
                      <>
                        <Bookmark sx={{ fontSize: '24px' }} />
                      </>}
                  </button>
                </Tooltip>
                :
                <button className={styles.InteractionButton} onClick={this.whenSaveClicked}>
                  {fullLeftBar ?
                    <>
                      <BookmarkBorderOutlined sx={{ mr: '8px', fontSize: '24px' }} />
                      {this.state.isDesktop && 'Save'}
                    </>
                    :
                    <Tooltip title='Save'>
                      <BookmarkBorderOutlined sx={{ fontSize: '24px' }} />
                    </Tooltip>}
                </button>
              }
              {fullLeftBar ?
                <button className={styles.InteractionButton} onClick={this.clickedCommentButton}>
                  <CommentOutlined sx={{ mr: '8px', fontSize: '24px' }} />
                  {this.state.isDesktop && 'Comment'}
                </button>
                :
                <Tooltip title='Comment'>
                  <button className={styles.InteractionButton} onClick={this.clickedCommentButton}>
                    <CommentOutlined sx={{ fontSize: '24px' }} />
                  </button>
                </Tooltip>}
            </div>
          </div>
        </Link >
        {this.state.showComments && <TalkComments eventId={this.state.eventId} />}
      </div >
    )
  }
}


export default TalkCard