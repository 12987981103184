import { React, useState } from 'react'
import styles from './urlFieldStyles.module.css'
import validator from 'validator';

export function UrlField({
    type = 'text',
    placeholder,
    label = '',
    value,
    onChange = () => { },
    required = false,
}) {

    const [errorMessage, setErrorMessage] = useState('')
    const id = label
        .toLowerCase()
        .split(' ')
        .map((word) => word.replace(/[^a-z]+/g, ""))
        .join("-");

    const validate = (urlValue) => {
        if (validator.isURL(urlValue)) {
            setErrorMessage('Is Valid URL')
            value = urlValue;
        } else {
            setErrorMessage('Please Enter a Valid URL')
            value = urlValue;
        }
    }
    return (

        <div className={styles.textFieldDiv}>
            <label className={styles.labelText} htmlFor={id}>
                {label} {required && <span style={{ color: 'red', fontSize: '14px' }}>*</span>}
            </label>
            <input
                className={styles.textField}
                type={type}
                id={id}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value, e)}
                value={value}
                required={required}
                formnovalidate="formnovalidate"
            />
        </div>



    );
}