import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import Amplify from "aws-amplify";
import Logo from '../components/logo/logo.jsx';
import React, { useState, useEffect } from 'react';
import _config from "../signup/config.js";
import awsExports from "../../aws-exports.js";
import lightbulb from '../../images/light_bulb.jpeg';
import styles from './verifyStyles.module.css';
import { AppBar, Box, Button, TextField, Typography } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

Amplify.configure(awsExports);

const Verify = (props) => {
    const isMobile = window.innerWidth <= 768;
    const location = useLocation();
    const [email, setEmail] = useState(location?.state?.email);
    const [password, setPassword] = useState(location?.state?.password);
    const [code, setCode] = useState('');
    const theme = useTheme();

    const poolData = {
        UserPoolId: _config.cognito.userPoolId,
        ClientId: _config.cognito.userPoolClientId,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const signin = () => {
        const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
            Username: toUsername(email),
            Password: password
        });
        const cognitoUser = createCognitoUser(email);
        const onSuccess = () => {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    console.log(err);
                } else if (!session.isValid()) {
                    console.log('invalid sess');
                } else {
                    cognitoUser.getUserAttributes((err, result) => {
                        if (err) {
                            console.log(err);
                        } else {
                            props.history.push('/createaccount')
                        }
                    });
                }
            });
        };

        const onFailure = (err) => {
            alert(err.message);
        }

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: onSuccess,
            onFailure: onFailure
        });
    }

    const handleVerify = (event) => {
        if (!email || !code) {
            return;
        }
        event.preventDefault();
        verify(email, code,
            (result) => {
                signin();
            },
            (err) => {
                toast.error(err.message);
            }
        );
    }

    const verify = (email, code, onSuccess, onFailure) => {
        createCognitoUser(email).confirmRegistration(code, true, (err, result) => {
            if (!err) {
                onSuccess(result);
            } else {
                onFailure(err);
            }
        });
    }

    const createCognitoUser = (email) => {
        return new AmazonCognitoIdentity.CognitoUser({
            Username: toUsername(email),
            Pool: userPool
        });
    }

    const toUsername = (email) => {
        return email.replace('@', '-at-');
    }

    return (
        <div className={styles.verifyPage}>
            {isMobile && <AppBar position="fixed" elevation={0} style={{ zIndex: 1201, background: theme.palette.primary.mainGradient }}
            >
                <Logo width={'240px'} centerImg></Logo>
            </AppBar>}
            {!isMobile && <Box sx={{
                background: 'linear-gradient(to left, #FE9D44, #FFF500)', height: '100vh',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center'
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', color: 'black', ml: '100px' }}>
                    <Logo></Logo>
                    <Typography variant='body'>
                        Discover. Discuss. Develop.
                    </Typography>
                </Box>
            </Box>}
            <div className={styles.verifyUiContainer}>
                <div className={styles.verifyUi}>
                    <form id="verifyForm">
                        <Box sx={{ mb: !isMobile ? '204px' : '0px' }}>
                            <Typography variant='h1'>
                                Verify
                            </Typography>
                        </Box>
                        <div>
                            <Typography variant='subtitle2'>
                                Use the verification code sent to your email to verify your account. Be sure to check your junk, spam, or promotional inbox.
                            </Typography>
                        </div>
                        <TextField
                            id='emailAddress'
                            InputLabelProps={{ shrink: true }}
                            disabled
                            label="Email"
                            variant='standard'
                            autoFocus
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            margin='normal'
                            type='email'
                            className={styles.emailTextField}
                            sx={{ width: '100%' }}
                            required
                        />
                        <TextField
                            id='password'
                            InputLabelProps={{ shrink: true }}
                            label="Verification Code"
                            variant='standard'
                            autoFocus
                            onChange={(e) => setCode(e.target.value)}
                            value={code}
                            margin='normal'
                            type='text'
                            className={styles.passwordTextField}
                            sx={{ width: '100%' }}
                            required
                        />
                        <Button
                            sx={{
                                background: '#FEBA3F',
                                color: '#1B065E',
                                width: '100%',
                                boxShadow: '0px 0px 12px 0px #1B065E26',
                                textTransform: 'none',
                            }} type="submit" onClick={(e) => handleVerify(e)}>
                            <Typography variant='subtitle1'>
                                Continue
                            </Typography>
                        </Button>
                    </form>
                </div>
            </div>
            <ToastContainer></ToastContainer>
        </div >
    );
}

export default Verify;