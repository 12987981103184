import React, { useState, useEffect } from 'react';
import styles from './UserCardStyles.module.css';
import { Verified } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

const UserCard = (props) => {
  const { firstName, lastName, profilePictureUrl, jobTitle, organization, userId, prefix, suffix } = props;
  const name = `${prefix} ${firstName} ${lastName} ${suffix}`;
  const [isUserClicked, setUserClicked] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (isUserClicked) {
      history.push(`/userprofile/${userId}`);
    }
  }, [isUserClicked, userId]);

  return (
    <div className={styles.UserCard}>
      <div className={styles.NavLinks}>
        {profilePictureUrl ? (
          <img className={styles.UserProfilePictureImage} src={profilePictureUrl} alt={`${name}'s Profile Picture`} />
        ) : (
          <div className={`${styles.UserProfilePictureImage} ${styles.AvatarFill}`}>
            {firstName ? firstName.charAt(0).toUpperCase() : ''}{lastName ? lastName.charAt(0).toUpperCase() : ''}
          </div>
        )}
      </div>

      <div className={styles.UserInfo}>
        <div className={styles.UserName} onClick={() => setUserClicked(true)}>
          <Typography variant="subtitle1">{name}</Typography>
          {firstName && lastName && organization && profilePictureUrl ? (
            <Verified sx={{ ml: '4px', fontSize: '20px', color: '#43a4fe' }} />
          ) : (
            ''
          )}
        </div>
        {jobTitle && (
          <Typography variant="body1">
            {jobTitle.trim()} {organization ? `at ${organization.trim()}` : ''}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default UserCard;
