import { Button, Dialog, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material'
import { Send } from '@mui/icons-material'
import React from 'react'
import styles from './DialogBoxStyles.module.css'

const DialogBox = ({ open, handleClose, fullWidth, maxWidth, title, children, handleSubmit, submitText }) => {
  const theme = useTheme();
  return (
    <Dialog open={open} onClose={handleClose} fullWidth={fullWidth} maxWidth={maxWidth}>
      <DialogTitle sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant="h2">
          {title}
        </Typography>
        {submitText &&
          <Button
            variant="outlined"
            startIcon={<Send sx={{ color: '#43A4FE' }} />}
            onClick={handleSubmit}
            className={styles.SubmitButton}
          >
            {submitText}
          </Button>
        }
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default DialogBox;
