import React, { Component } from 'react';
import ProfilePaper from '../ProfilePaper/ProfilePaper';
import styles from './ProfileSavedPapersStyles.module.css';

export default class ProfileSavedPapers extends Component {
  render() {
    const { papers, type, fullLeftBar, fromSavedToReadPapersCall, fromReadToSavedPapersCall,
      removeFromSavedPapersCall, removeFromReadPapersCall, userId, requestService } = this.props;
    papers.sort((a, b) => new Date(a.RequestTime).getTime() - new Date(b.RequestTime).getTime());
    return (
      <div className={styles.ProfileSavedPapers}>
        {papers.length === 0 ?
          <div className={styles.NoPapers}>
            {type === 'saved' ?
              <>{'You don\'t have any papers saved.'}</> :
              <>{'You don\'t have any read papers.'}</>
            }
          </div>
          :
          papers.map((e) => {
            return (
              <ProfilePaper
                key={e.PaperId}
                paperId={e.PaperId}
                semanticPaperId={e.semanticPaperId}
                paperUrl={e.paperUrl}
                paperImageUrl={e.paperImageUrl}
                userId={e.userId}
                title={e.title}
                year={e.year}
                authors={e.authors}
                tldr={e.tldr}
                videoLink={e.videoLink}
                keywords={e.keywords}
                requestTime={e.RequestTime}
                saved={e.saved}
                read={e.read}
                liked={e.liked}
                fullLeftBar={fullLeftBar}
                fromSavedToReadPapersCall={fromSavedToReadPapersCall}
                fromReadToSavedPapersCall={fromReadToSavedPapersCall}
                removeFromSavedPapersCall={removeFromSavedPapersCall}
                removeFromReadPapersCall={removeFromReadPapersCall}
                signedInUserId={userId}
                type={type}
                requestService={requestService}
              />
            );
          })
        }
      </div>
    )
  }
}
