import React, { Component } from 'react'
import Skeleton from 'react-loading-skeleton'

import styles from './UserCardStyles.module.css'


class UserCardLoader extends Component {
  render() {
    return (
      <div>
        <div className={styles.UserCard}>
          <div className={styles.UserProfilePictureImageLoader}></div>
          <div className={styles.UserInfoLoader}>
            <Skeleton count={1}/>
            <div className={styles.UserPosition}>
                <Skeleton count={1}/>
              </div>
        </div>
      </div>
      <div className={styles.UserCard}>
          <div className={styles.UserProfilePictureImageLoader}></div>
          <div className={styles.UserInfoLoader}>
            <Skeleton count={1}/>
            <div className={styles.UserPosition}>
                <Skeleton count={1}/>
              </div>
        </div>
      </div>
      <div className={styles.UserCard}>
          <div className={styles.UserProfilePictureImageLoader}></div>
          <div className={styles.UserInfoLoader}>
            <Skeleton count={1}/>
            <div className={styles.UserPosition}>
                <Skeleton count={1}/>
              </div>
        </div>
      </div>
    </div>
    )
  }
}

export default UserCardLoader