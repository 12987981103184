import React, { Component } from 'react'
import styles from './SemanticSearchCardStyles.module.css'

export default class SemanticSearchCard extends Component {
  constructor(props){
    super(props);
    this.handleSelection = this.handleSelection.bind(this);
  }

  handleSelection() {
    this.props.handleSemanticSelect(this.props.paperId);
    this.props.handleSemanticSearchClose();
  }
  render() {
    const {paperId, title, authors, year} = this.props;
    return (
      <div className={styles.SemanticSearchCard} onClick={this.handleSelection}>
        <div className={styles.SemanticTitle}>
          {title}
        </div>
        <div className={styles.SemanticAuthors}>
          {authors.map((author, index) => 
            `${author.name}${index === authors.length - 1 ? '': ', '}`)} {`(${year})`}
        </div>
      </div>
    )
  }
}
