import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import _config from "../signup/config";
import React from 'react';
import { TextField, TextArea, AutoCompleteField } from '../components/text-field';
import { DropDown } from '../components/drop-down'
import styles from './createEventStyles.module.css';
import DateTimePicker from 'react-datetime-picker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css'
import { ThumbnailUploader, ReferenceUploader } from '../components/file-uploader';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { UrlField } from '../components/url-field';
import StaticNav from '../components/StaticNav';
import axios from 'axios';
import basic_info_icon from '../../images/basic-info.svg'
import details_icon from '../../images/details.svg'
import location_icon from '../../images/location.svg'
import moment from 'moment';
import Popup from '../components/popup/popup';
import crypto from 'crypto-browserify';
import seedrandom from 'seedrandom';
const randomGenerator = seedrandom();

const eventOptions = [
    { label: 'Journal Club', value: 'journal-club' },
    { label: 'Talk/Seminar', value: 'talk-seminar' },
    { label: 'Dissertation Defense', value: 'defense' },
    { label: 'Workshop/Tutorial', value: 'workshop-tutorial' },
    { label: 'Open Meeting', value: 'meeting' },
    { label: 'Conference', value: 'conference' }
];

const visibilityOptions = [
    { label: 'Public (default)', value: 'public' },
    { label: 'Registered users', value: 'registered-users' }
];

const timeZoneOptions = [
    { label: 'Eastern Daylight Time - Washington DC (GMT-4)', value: 'EDT' },
    { label: 'Central Daylight Time - Chicago (GMT-5)', value: 'CDT' },
    { label: 'Mountain Daylight Time - Denver (GMT-6)', value: 'MDT' },
    // this needs to be automated to handle Mountain Time changes
    { label: 'Mountain Standard Time - Phoenix (GMT-7)', value: 'MST' },
    { label: 'Pacific Daylight Time - Los Angeles (GMT-7)', value: 'PDT' },
    { label: 'Alaska Daylight Time - Anchorage (GMT-8)', value: 'ADT' },
    { label: 'Hawaii-Aleutian Standard Time - Honolulu (GMT-10)', value: 'HST' }
];

const locTypeOptions = {
    INPERSON: 'In Person',
    VIRTUAL: 'Virtual',
    HYBRID: 'Hybrid'
}

function toUrlString(buffer) {
    return buffer.toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

const randomHexString = Array.from({ length: 16 }, () =>
    Math.floor(randomGenerator() * 256).toString(16).padStart(2, '0')
).join('');

const defaultValues = { // need to add the dropdown value.
    eventId: toUrlString(randomHexString),
    thumbnailURL: '',
    title: '',
    hostName: '',
    institution: '',
    eventType: eventOptions[0],
    description: '',
    keywords: '',
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
    timeZone: timeZoneOptions[0],
    addInfo: '',
    activeLoc: "In Person",
    locType: locTypeOptions,
    venue: '',
    meetingLink: '',
    meetingDetails: '',
    visibility: visibilityOptions[0]
}



// const loadScript = (url, callback) => {
//     let script = document.createElement("script");
//     script.type = "text/javascript";

//     if (script.readyState) {
//         script.onreadystatechange = function () {
//             if (script.readyState === "loaded" || script.readyState === "complete") {
//                 script.onreadystatechange = null;
//                 callback();
//             }
//         };
//     } else {
//         script.onload = () => callback();
//     }

//     script.src = url;
//     document.getElementsByTagName("head")[0].appendChild(script);
// }

// let autocomplete;

// function handleScriptLoad(updateQuery, autoCompleteRef) {
//     // debugger;
//     autocomplete = new window.google.maps.places.Autocomplete(
//         autoCompleteRef.current,
//         {
//             componentRestrictions: { 'country': ['US'] },
//             fields: ['geometry', 'name', 'formatted_address']
//         }
//     );
//     autocomplete.setFields(['geometry', 'name', 'formatted_address']);
//     autocomplete.setComponentRestrictions({ 'country': ['US'] });
//     // debugger;
//     autocomplete.addListener('place_changed', () => onPlaceChanged(updateQuery));

// }

// async function onPlaceChanged(updateQuery) {
//     var place = autocomplete.getPlace();
//     const query = place;
//     updateQuery(prev => ({
//         ...prev,
//         venue: query
//     }));

//     console.log(place);
// }


class CreateEventForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: this.props.match.path === '/editevent/:eventId' ? this.props.match.params.eventId : toUrlString(randomBytes(16)),
            userId: '',
            thumbnail: null,
            thumbnailURL: '',
            title: '',
            hostName: '',
            institution: '',
            eventType: eventOptions[0],
            description: '',
            keywords: [],
            startDate: null,
            startTime: null,
            endDate: null,
            endTime: null,
            timeZone: timeZoneOptions[0],
            refFiles: null,
            refFilesURL: '',
            addInfo: '',
            activeLoc: "Hybrid",
            locType: locTypeOptions,
            venue: {},
            address: '',
            meetingLink: '',
            meetingDetails: '',
            visibility: visibilityOptions[0],
            discardPopup: false,
            submitPopup: false,
            windowWidth: window.innerWidth
        }

        const poolData = {
            UserPoolId: _config.cognito.userPoolId,
            ClientId: _config.cognito.userPoolClientId,
        };
        this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

        this.getUser = this.getUser.bind(this);
        this.autocomplete = null;
        this.toggleActive = this.toggleActive.bind(this);
        this.handleVenueSelector = this.handleVenueSelector.bind(this);
        this.toggleActiveStyles = this.toggleActiveStyles.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.onPlaceChanged = this.onPlaceChanged.bind(this);
        this.autoCompleteRef = React.createRef();

        this.handleAdditionalInfoChange = this.handleAdditionalInfoChange.bind(this);
        this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleEventTypeChange = this.handleEventTypeChange.bind(this);
        this.handleHostNameChange = this.handleHostNameChange.bind(this);
        this.handleInstitutionChange = this.handleInstitutionChange.bind(this);
        this.handleKeywordsChange = this.handleKeywordsChange.bind(this);
        this.handleMeetingDetailsChange = this.handleMeetingDetailsChange.bind(this);
        this.handleMeetingLinkChange = this.handleMeetingLinkChange.bind(this);
        this.handleRefFilesChange = this.handleRefFilesChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleThumbnailChange = this.handleThumbnailChange.bind(this);
        this.handleThumbnailURLChange = this.handleThumbnailURLChange.bind(this);
        this.handleTimeZoneChange = this.handleTimeZoneChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleVenueSelector = this.handleVenueSelector.bind(this);
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
        this.setSubmitPopup = this.setSubmitPopup.bind(this);
        this.setDiscardPopup = this.setDiscardPopup.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.getEvent = this.getEvent.bind(this);
        if (this.props.match.path === '/editevent/:eventId') {
            this.getEvent(this.props.match.params.eventId);
        }





    }

    componentDidMount() {
        this.getUser();
        window.addEventListener('resize', this.handleResize)

        const googleScript = document.getElementById('googleMapPlacesScript')
        var self = this;
        function setUpAutocomplete(self) {
            self.autocomplete = new window.google.maps.places.Autocomplete(
                self.autoCompleteRef.current,
                {
                    componentRestrictions: { 'country': ['US'] },
                    fields: ['geometry', 'name', 'formatted_address']
                }
            );
            self.autocomplete.setFields(['geometry', 'name', 'formatted_address']);
            self.autocomplete.setComponentRestrictions({ 'country': ['US'] });
            self.autocomplete.addListener('place_changed', self.onPlaceChanged);
        }

        if (window.google && window.google.maps && window.google.maps.places && !this.autocomplete) {
            setUpAutocomplete(self);
        } else if (googleScript) {
            googleScript.addEventListener('load', () => {
                setUpAutocomplete(self);

            });
        }
    }

    getEvent(eventId) {
        var cognitoUser = this.userPool.getCurrentUser();

        if (cognitoUser) {
            var self = this;
            cognitoUser.getSession(function sessionCallback(err, session) {
                if (err) {
                    console.log(err);
                } else if (!session.isValid()) {
                    console.log('invalid sess');
                } else {
                    const authToken = session.getIdToken().getJwtToken();
                    cognitoUser.getUserAttributes(function (err, result) {
                        if (err) {
                            console.log(err);
                        } else {
                            const loggedInUserId = result[3].Value;
                            const api = _config.api.invokeUrl + '/getevent?eventId=' + eventId + '&userId=' + loggedInUserId;
                            const axiosConfig = {
                                headers: {
                                    "Authorization": authToken,
                                }
                            };
                            axios
                                .get(api, axiosConfig)
                                .then((response) => {
                                    console.log(response);
                                    self.setState({
                                        thumbnailURL: response.data.thumbnailURL,
                                        thumbnail: response.data.thumbnailURL ? { name: 'eventId_' + eventId + '_thumbnail' } : undefined,
                                        title: response.data.title,
                                        hostName: response.data.hostName,
                                        institution: response.data.institution,
                                        eventType: response.data.eventType,
                                        description: response.data.description,
                                        keywords: response.data.keywords,
                                        timeZone: response.data.timeZone,
                                        activeLoc: response.data.activeLoc,
                                        venue: response.data.venue,
                                        refFilesURL: response.data.refFilesURL,
                                        addInfo: response.data.additionalInfo,
                                        userId: response.data.userId,
                                        eventId: response.data.EventId,
                                        startDate: new Date(response.data.startDate),
                                        endDate: new Date(response.data.endDate),
                                        meetingLink: response.data.meetingLink,
                                        meetingDetails: response.data.meetingDetails,
                                        address: response.data.venue ? response.data.venue.formatted_address : '',
                                        visibility: response.data.visibility,
                                        liked: response.data.liked,
                                    });
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    });
                }
            });
        }
    }

    getUser() {
        var cognitoUser = this.userPool.getCurrentUser();
        if (cognitoUser) {
            let self = this;
            cognitoUser.getSession(function sessionCallback(err, session) {
                if (err) {
                    console.log(err);
                } else if (!session.isValid()) {
                    console.log('invalid sess');
                } else {
                    cognitoUser.getUserAttributes(function (err, result) {
                        if (err) {
                            console.log(err);
                        } else {
                            if (result[3].Name === 'custom:userId' && result[3].Value) {
                                self.setState({ userId: result[3].Value });
                            }
                        }
                    }
                    );
                }
            });
        }

    }

    handleResize() {
        this.setState({ windowWidth: window.innerWidth });
    }


    onPlaceChanged() {
        let place = this.autocomplete.getPlace();
        this.setState({ venue: place, address: place.name + ', ' + place.formatted_address });
        console.log(place);
    }


    toggleActive(key) {
        const locType = this.state.locType[key];
        const previousActiveLoc = this.state.activeLoc;
        if (previousActiveLoc === locTypeOptions.VIRTUAL) {
            this.componentDidMount();
        }
        this.setState({ activeLoc: locType });
        return this.handleVenueSelector()
    }

    handleVenueSelector() {
        if (this.state.activeLoc === "In Person") {
            return;
        }
    }

    toggleActiveStyles(key) {
        if (this.state.locType[key] === this.state.activeLoc) {
            return styles.boxButton + ' ' + styles.active;
        } else {
            return styles.boxButton + ' ' + styles.inactive;
        }
    }

    async submitForm(e) {
        e.preventDefault();

        if (this.state.refFiles) {
            const fileName = "reference_files/eventId_" + this.state.eventId + '_refFile';
            let blob = this.state.refFiles.slice(0, this.state.refFiles.size, this.state.refFiles.type);
            const renamedFile = new File([blob], fileName, { type: this.state.refFiles.type });
            const params = {
                Bucket: _config.s3RefFiles.bucketName,
                Key: 'reference_files/' + fileName,
            };

            var self = this;
            let cognitoUser = this.userPool.getCurrentUser();
            if (cognitoUser) {
                await cognitoUser.getSession(async function sessionCallback(err, session) {
                    if (err) {
                        console.log(err);
                    } else if (!session.isValid()) {
                        console.log('invalid sess');
                    } else {
                        const authToken = session.getIdToken().getJwtToken();
                        const api = _config.api.invokeUrl + '/gets3uploadurl';
                        const axiosConfig = {
                            headers: {
                                "Authorization": authToken,
                            },
                        };
                        const data = { "fileName": fileName, };

                        const options = {
                            headers: {
                                'Content-Type': blob.type
                            }
                        };
                        await axios
                            .post(api, data, axiosConfig)
                            .then(async (response) => {
                                await axios
                                    .put(response.data.uploadURL, blob, options)
                                    .then((responseS3) => {
                                        const longS3Location = responseS3.config.url;
                                        const strippedS3Location = longS3Location.substring(0, longS3Location.indexOf('?'));
                                        self.setState({ refFilesURL: strippedS3Location });
                                    }).catch((error) => {
                                        console.log(error);
                                    });
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                });
            }

        }

        var onSuccess = function registerSuccess(result) {
            var eventId = result.data.eventId;
            console.log('event id is ' + eventId);
            var confirmation = ('event id created!');
            if (confirmation) {
                window.location.href = '/viewevent/' + eventId;
            }
        };

        var onFailure = function registerFailure(err) {
            alert(err.message);
        };

        const data = {
            "eventId": this.state.eventId,
            "userId": this.state.userId,
            'thumbnailURL': this.state.thumbnailURL,
            "title": this.state.title,
            "hostName": this.state.hostName,
            "institution": this.state.institution,
            "eventType": this.state.eventType,
            "description": this.state.description,
            "keywords": this.state.keywords,
            "startDate": this.state.startDate,
            "startTime": moment(this.state.startDate).unix(),
            "endDate": this.state.endDate,
            "endTime": this.state.endTime,
            "timeZone": this.state.timeZone,
            "refFilesURL": this.state.refFilesURL,
            "additionalInfo": this.state.addInfo,
            "activeLoc": this.state.activeLoc,
            "venue": this.state.venue,
            "meetingLink": this.state.meetingLink,
            "meetingDetails": this.state.meetingDetails,
            "visibility": this.state.visibility
        };

        var cognitoUser = this.userPool.getCurrentUser();

        if (cognitoUser) {
            var self = this;
            cognitoUser.getSession(function sessionCallback(err, session) {
                if (err) {
                    console.log(err);
                } else if (!session.isValid()) {
                    console.log('invalid sess');
                } else {
                    const authToken = session.getIdToken().getJwtToken();

                    const api = _config.api.invokeUrl + '/createevent';
                    const axiosConfig = {
                        headers: {
                            "Authorization": authToken,
                        }
                    };
                    axios
                        .post(api, data, axiosConfig)
                        .then((response) => {
                            console.log(response);
                            onSuccess(response)
                        })
                        .catch((error) => {
                            console.log(error);
                            onFailure(error);
                        });
                }
            });
        }
    };

    handleAutocompleteChange(text) {
        if (window.google.maps.places && !this.autoComplete) {
            this.componentDidMount();
        }
        this.setState({ address: text });
    }

    handleThumbnailChange(thumbnail) {
        this.setState({ thumbnail: thumbnail });
    }

    handleThumbnailURLChange(thumbnailURL) {
        this.setState({ thumbnailURL: thumbnailURL });
    }

    handleTitleChange(text) {
        this.setState({ title: text });
    }

    handleHostNameChange(text) {
        this.setState({ hostName: text });
    }

    handleInstitutionChange(text) {
        this.setState({ institution: text });
    }

    handleEventTypeChange(option) {
        this.setState({ eventType: option })
    }

    handleDescriptionChange(text) {
        this.setState({ description: text });
    }

    handleKeywordsChange(chips) {
        this.setState({ keywords: chips });
    }

    handleStartDateChange(datetime) {
        this.setState({ startDate: datetime })
    }

    handleEndDateChange(datetime) {
        this.setState({ endDate: datetime })
    }

    handleTimeZoneChange(option) {
        this.setState({ timeZone: option });
    }

    handleRefFilesChange(files) {
        this.setState({ refFiles: files })
    }

    handleAdditionalInfoChange(text) {
        this.setState({ addInfo: text });
    }

    handleMeetingLinkChange(url) {
        this.setState({ meetingLink: url, address: this.state.address });
    }

    handleMeetingDetailsChange(text) {
        this.setState({ meetingDetails: text })
    }

    handleVisibilityChange(option) {
        this.setState({ visibility: option });
    }

    setDiscardPopup(bool) {
        this.setState({ discardPopup: bool });
    }

    setSubmitPopup(bool) {
        this.setState({ submitPopup: bool });
    }


    render() {
        return (
            <>
                {this.props.match.path === '/editevent/:eventId' && <StaticNav navTitle='Edit event' />}
                {this.props.match.path === '/createEvent' && <StaticNav navTitle='Create an Event' />}
                {/* This doesn't seem efficient */}
                <div>
                    <form onSubmit={(e) => this.submitForm(e)}>
                        <div className={styles.center}>
                            <div>
                                <div className={styles.sectionHeader} style={{ marginTop: '32px' }}>
                                    {this.state.windowWidth > 960 &&
                                        <img src={basic_info_icon} alt='Basic Info Icon' className={styles.sectionIcon} style={{ marginLeft: '-72px' }} />}
                                    <h1 style={{ margin: '0px' }}>Basic Info</h1>
                                </div>
                                <h4 className={styles.descriptionText}>
                                    Advertise and name your event. Tell researchers what they should expect to learn.
                                    Add details that highlight what makes it unique.
                                </h4>

                                <h3 style={{ margin: '0px' }}>Thumbnail</h3>
                                <p className={styles.descriptionText}>
                                    Upload an image with a .jpg, .png, or .gif extension. Click below to upload (or change) your thumbnail.
                                </p>
                                <div>
                                    {this.state.thumbnail ?
                                        <p style={{ fontSize: '12px', marginBottom: '4px' }}>
                                            Uploaded file: <span style={{ color: 'green' }}>{this.state.thumbnail.name}</span>
                                        </p>
                                        :
                                        <p style={{ fontSize: '12px', marginBottom: '4px', textAlign: 'center' }}>
                                            A default Symposia logo is provided as the event thumbnail when this field is left blank.
                                        </p>}
                                </div>
                                < ThumbnailUploader
                                    onFileSelect={this.handleThumbnailChange}
                                    eventId={this.state.eventId}
                                    onS3Upload={this.handleThumbnailURLChange}
                                    thumbnailURL={this.state.thumbnailURL}
                                    userPool={this.userPool}
                                />

                                <div>
                                    <TextField
                                        label="Event Title"
                                        placeholder='Be clear concise, and descriptive.'
                                        onChange={this.handleTitleChange}
                                        value={this.state.title}
                                        required={true}
                                    />

                                    <TextField
                                        label="Speaker Name"
                                        placeholder='Who is speaking at this event?'
                                        onChange={this.handleHostNameChange}
                                        value={this.state.hostName}
                                        required={true}
                                    />

                                    <TextField
                                        label="Speaker Affiliation"
                                        placeholder='What institution are they affiliated with?'
                                        onChange={this.handleInstitutionChange}
                                        value={this.state.institution}

                                    />

                                    <DropDown //check the onChange behavior
                                        label='Event Type'
                                        options={eventOptions}
                                        onChange={this.handleEventTypeChange}
                                        value={this.state.eventType}
                                        defaultValue={eventOptions[0]}
                                        required={true}
                                    />

                                    <div>
                                        <div>
                                            <h3 style={{ marginBottom: '0px' }}>Description</h3>
                                            <p className={styles.descriptionText}>
                                                Give a summary of what the event will cover so attendees know what to expect.<span style={{ color: 'red', fontSize: '14px' }}>*</span>
                                            </p>
                                            <TextArea
                                                placeholder='Long enough for attendees to get the gist of your event, short enough to keep them eager to learn more.'
                                                onChange={this.handleDescriptionChange}
                                                value={this.state.description}
                                                required={true}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <div>
                                            <h3 style={{ marginBottom: '0px' }}>Keywords</h3>
                                            <p className={styles.descriptionText}>
                                                Help your colleagues find your event by adding up to five (5) keywords relevant to
                                                the topic of the event.
                                            </p>
                                            <Autocomplete
                                                value={this.state.keywords}
                                                onChange={(chips) => this.handleKeywordsChange(chips)}
                                                freeSolo
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        margin='normal'
                                                        variant="standard"
                                                        label="Areas of interest"
                                                        placeholder={'Press \'Enter\' to add keywords'}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.sectionSeparator}></div>

                            <div>
                                <div className={styles.sectionHeader}>
                                    {this.state.windowWidth > 960 &&
                                        <img src={details_icon} alt='Details Icon' className={styles.sectionIcon} />}
                                    <h1>Details</h1>
                                </div>
                                <h3 style={{ margin: '0px' }}>Date and Time</h3>
                                <p className={styles.descriptionText}>
                                    Tell event-goers when your event starts and ends so they can make plans to attend. Click 'X' to clear selection.
                                </p>
                                <div className={styles.horizontalalign}>
                                    <div className={styles.dateTimePickerDiv}>
                                        <label className={styles.labelText} style={{ marginBottom: '4px' }}>
                                            Event Starts<span style={{ color: 'red', fontSize: '14px' }}>*</span>
                                        </label>
                                        <DateTimePicker
                                            onChange={this.handleStartDateChange}
                                            minDate={new Date()}
                                            maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 4))}
                                            dayPlaceholder='dd'
                                            hourPlaceholder='hh'
                                            minutePlaceholder='mm'
                                            monthPlaceholder='mm'
                                            secondPlaceholder='ss'
                                            yearPlaceholder='yyyy'
                                            disableClock={true}
                                            value={this.state.startDate}
                                            required={true}
                                            noinvalidate
                                        />
                                    </div>
                                </div>
                                <div className={styles.horizontalalign}>
                                    <div className={styles.dateTimePickerDiv} >
                                        <label className={styles.labelText} style={{ marginBottom: '4px' }}>
                                            Event Ends<span style={{ color: 'red', fontSize: '14px' }}>*</span>
                                        </label>
                                        <DateTimePicker
                                            onChange={this.handleEndDateChange}
                                            minDate={this.state.startDate ? new Date(new Date(this.state.startDate).setHours(this.state.startDate.getHours() + 1)) : new Date()}
                                            maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 4))}

                                            dayPlaceholder='dd'
                                            hourPlaceholder='hh'
                                            minutePlaceholder='mm'
                                            monthPlaceholder='mm'
                                            secondPlaceholder='ss'
                                            yearPlaceholder='yyyy'
                                            disableClock={true}
                                            value={this.state.endDate}
                                            required={true}
                                        />
                                    </div>
                                </div>

                                <DropDown //check the onChange behavior
                                    label='Time Zone'
                                    options={timeZoneOptions}
                                    onChange={this.handleTimeZoneChange}
                                    value={this.state.timeZone}
                                    defaultValue={timeZoneOptions[0]}
                                    required={true}
                                />
                                <div>
                                    <h3 style={{ marginBottom: '0px' }}>Supporting Materials and Speaker Bio</h3>
                                    <p className={styles.descriptionText}>
                                        Upload supporting materials (1 document with a .pdf extension -- papers, slides, one-pager, etc.).
                                    </p>
                                    {/* Consider how we do multiple files uploaded together, lot of backend help needed here */}
                                    {this.state.refFilesURL && !this.state.refFiles && <div><a href={this.state.refFilesURL}>Your file</a></div>}

                                    < ReferenceUploader
                                        onFileSelect={this.handleRefFilesChange}
                                    />
                                    <p className={styles.descriptionText} >
                                        Add a bio of the speaker, if available.
                                    </p>

                                    <TextArea
                                        placeholder={'Include details like their education and work history, research interests, and more.'}
                                        onChange={this.handleAdditionalInfoChange}
                                        value={this.state.addInfo}
                                    />
                                </div>
                            </div>

                            <div className={styles.sectionSeparator}></div>

                            <div>
                                <div className={styles.sectionHeader} style={{ marginTop: '24px' }}>
                                    {this.state.windowWidth > 960 &&
                                        <img
                                            src={location_icon}
                                            alt='Location Icon'
                                            className={styles.sectionIcon}
                                            style={{ width: '32px', height: '37px', marginLeft: '-56px' }}

                                        />}
                                    <h1 style={{ margin: '0px' }}>Location</h1>
                                </div>
                                <p className={styles.descriptionText}>
                                    Let attendees know where to show up.
                                </p>
                                <div className={styles.locTypeMargin + ' ' + styles.horizontalalign}>
                                    {Object.keys(this.state.locType).map((key, index) => (
                                        <div
                                            key={index}
                                            className={this.toggleActiveStyles(key) + ' ' + styles.horizontalalign}
                                            onClick={() => {
                                                this.toggleActive(key)
                                            }}

                                        >{this.state.locType[key]}</div>
                                    ))}
                                </div>

                                {this.state.activeLoc !== "Virtual" &&
                                    <AutoCompleteField
                                        label='Venue Location'
                                        id='autocomplete'
                                        autoRef={this.autoCompleteRef}
                                        placeholder='Search for a venue or address'
                                        onChange={this.handleAutocompleteChange}
                                        value={this.state.address}
                                        required={true}
                                    />}
                                {this.state.activeLoc === "Virtual" &&
                                    <div>
                                        <UrlField
                                            type='url'
                                            label='Virtual Meeting Link'
                                            placeholder='Copy and paste the virtual meeting link.'
                                            onChange={this.handleMeetingLinkChange}
                                            value={this.state.meetingLink}
                                        />{/* make sure this is checking URL validation */}

                                    </div>
                                }
                                {this.state.activeLoc === "Hybrid" &&
                                    <div>
                                        <UrlField
                                            type='url'
                                            label='Virtual Meeting Link'
                                            placeholder='Copy and paste the virtual meeting link.'
                                            onChange={this.handleMeetingLinkChange}
                                            value={this.state.meetingLink}

                                        />{/* make sure this is checking URL validation */}
                                    </div>
                                }
                                <div>
                                    <TextArea
                                        label='Meeting Details'
                                        placeholder='Passcodes, call-in details, COVID protocols, video conferencing expectations, etc.'
                                        onChange={this.handleMeetingDetailsChange}
                                        value={this.state.meetingDetails}
                                    />
                                </div>
                                {/* <h3 style={{ marginBottom: '0px' }}>Location Visibility</h3>
                                <p className={styles.descriptionText}>
                                    The location for public events will be visible to everyone on the event page. For events that require registration, only logged in users (with “.edu” emails) who have registered for the event will be able to see the location.
                                </p>
                                <DropDown //check the onChange behavior
                                    style={styles.select}
                                    label=''
                                    options={visibilityOptions}
                                    onChange={this.handleVisibilityChange}
                                    value={this.state.visibility}
                                    defaultValue={visibilityOptions[0]}
                                /> */}
                                <div style={{ margin: '20px' }}></div>

                            </div>
                        </div>
                        <div className={styles.submitDiv}>
                            <button type="button" className={styles.discardButton} onClick={() => this.setDiscardPopup(true)}>Discard</button>
                            <button type="button" className={styles.submitButton} onClick={() => this.setSubmitPopup(true)}>Save and Continue</button>
                        </div>
                        <Popup
                            trigger={this.state.discardPopup}
                            setTrigger={this.setDiscardPopup}
                        >
                            <h3 style={{ marginBottom: '0px' }}>Are you sure you want to discard your entry?</h3>
                            <h4 style={{ marginTop: '0px' }}>Data you have entered will not be saved.</h4>
                            <h5 style={{ margin: '0px' }}>Click "Discard" to continue. You will be redirected to the homepage.</h5>
                            <h5 style={{ margin: '0px' }}>Click "Go Back" to return to the form.</h5>
                            <div className={styles.popupButtonDiv}>
                                <button type="button" className={styles.goBackButton} onClick={() => this.setDiscardPopup(false)}>Go back</button>
                                <button type="button" className={styles.discardPopupButton} onClick={() => { window.location.href = '/' }}>Discard</button>
                            </div>
                        </Popup>
                        <Popup
                            trigger={this.state.submitPopup}
                            setTrigger={this.setSubmitPopup}
                        >
                            <h3 style={{ marginBottom: '0px' }}>Have you double-checked your entry?</h3>
                            <h5 style={{ margin: '0px' }}>Click "Submit" to create your event!</h5>
                            <h5 style={{ margin: '0px' }}>Click "Go Back" to return to the form.</h5>
                            <div className={styles.popupButtonDiv}>
                                <button type="button" className={styles.discardPopupButton} onClick={() => this.setSubmitPopup(false)}>Go back</button>
                                <button type="submit" className={styles.goBackButton}>Submit</button>
                            </div>
                        </Popup>

                    </form>
                </div>

            </>

        );
    }

}

export default CreateEventForm;