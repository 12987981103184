import { Button, TextField, TextareaAutosize } from '@mui/material';
import PaperComment from '../PaperComment/PaperComment';
import TalkComment from '../TalkComment/TalkComment';
import React, { Component } from 'react'
import styles from './CommentsStyles.module.css'
import RequestService from "../../requestservice/requestservice";

// TODO: Cache user data (in map) from child (TalkComment) to parent (TalkComments) in order to reduce lambda getUserLite calls.
export default class Comments extends Component {

  constructor(props) {
    super(props);
    this.requestService = new RequestService();
    this.state = {
      comments: [],
      loading: true,
      newComment: '',
      selfUserId: '',
      pictureUrl: '',
      user: '',
      addCommentButtonDisabled: false,
    };
    this.getSelfUserLite = this.getSelfUserLite.bind(this);
    this.getSelfUserLite();
    this.addComment = this.addComment.bind(this);
    this.addCommentToCommentsUi = this.addCommentToCommentsUi.bind(this);
    this.deleteCommentFromCommentsUi = this.deleteCommentFromCommentsUi.bind(this);
    this.handleNewCommentChange = this.handleNewCommentChange.bind(this);
    this.eventId = this.props.eventId;
    this.paperId = this.props.paperId;
    this.addCommentTextAreaRef = React.createRef();
  }

  componentDidMount() {
    this.getComments();
  }

  async getComments() {
    // Implemented in sub-class
  }

  async addComment() {
    // Implemented in sub-class
  }

  addCommentToCommentsUi(newComment) {
    this.state.comments.push(newComment);
    this.setState({ comments: this.state.comments });
    // Erase text in text box and enable add comment button.
    this.setState({ newComment: '', });
    this.setState({ addCommentButtonDisabled: false });
  }

  deleteCommentFromCommentsUi(deleteCommentId) {
    this.setState({
      comments: this.state.comments.filter((comment) => {
        return (comment.EventCommentId ?? comment.PaperCommentId) !== deleteCommentId;
      })
    });
  }

  handleNewCommentChange(text) {
    this.setState({ newComment: text, });
  }

  async getSelfUserLite() {
    await this.requestService.getSelfUserLite((user) => { this.setState({ user: user, selfUserId: user.userId }) });
  }

  render() {
    return (
      <div>
        {this.eventId ?
          this.state.comments.map((comment) => {
            return (<TalkComment
              key={comment.EventCommentId}
              commentUserId={comment.userId}
              comment={comment.comment}
              commentId={comment.EventCommentId}
              commentCreationTime={comment.commentCreationTime}
              eventId={this.eventId}
              selfUserId={this.state.selfUserId}
              deleteCommentFromCommentsUiCallback={this.deleteCommentFromCommentsUi}
            />);
          })
          :
          this.state.comments.map((comment) => {
            return (<PaperComment
              key={comment.PaperCommentId}
              commentUserId={comment.userId}
              comment={comment.comment}
              commentId={comment.PaperCommentId}
              commentCreationTime={comment.commentCreationTime}
              paperId={this.paperId}
              selfUserId={this.state.selfUserId}
              deleteCommentFromCommentsUiCallback={this.deleteCommentFromCommentsUi}
            />);
          })
        }
        <div>
          <div className={styles.addCommentTextAreaContainer}>
            <a className={styles.avatarHyperLink} href={'/profile/' + this.state.user.userId}>
              {this.state.user.profilePictureUrl
                ? (<img className={styles.selfUserProfilePictureImage} src={this.state.user.profilePictureUrl + '?' + Date.now()} alt={`Your Profile Picture`} />)
                : (<div className={styles.selfUserProfilePictureImage + ' ' + styles.AvatarFill}>
                  {this.state.user.firstName ? this.state.user.firstName.charAt(0).toUpperCase() : ''}{this.state.user.lastName ? this.state.user.lastName.charAt(0).toUpperCase() : ''}
                </div>)}
            </a>
            {/* <TextField></TextField> */}
            <TextareaAutosize
              aria-label='Add a comment'
              placeholder='Post your comment'
              value={this.state.newComment}
              className={styles.addCommentTextArea}
              required={false}
              onInput={(e) => this.handleNewCommentChange(e.currentTarget.value)}
              name=''
              minRows={2}
              ref={this.addCommentTextAreaRef}
            />
            <div className={styles.commentButtonContainer} >
              <input disabled={this.state.newComment.trim() === '' || this.state.addCommentButtonDisabled} className={styles.commentButton} type="submit" value='Comment' onClick={this.addComment}></input>
              {/* <Button disabled={this.state.newComment.trim() === '' || this.state.addCommentButtonDisabled} className={styles.commentButton} type="submit" onClick={this.addComment}>Comment</Button> */}

            </div>
          </div>
        </div>
      </div >
    );
  }
}
