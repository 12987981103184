import React, { useState, useRef } from 'react';
import _config from "../../signup/config";
import { Link } from 'react-router-dom';
import UserCardFeed from '../UserCardFeed/UserCardFeed';
import { Info } from '@mui/icons-material';
import moment from 'moment';
import styles from './JobCardStyles.module.css';
import { Button, Typography } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';

const JobCard = (props) => {
  const [showMore, setShowMore] = useState(false);
  const [jobId, setJobId] = useState(props.jobId);

  const showText = () => {
    e.preventDefault()
    e.stopPropagation();
    setShowMore(!showMore);
  };

  const {
    allUserItems, userId, jobTitle, description, employer, location,
    compensation, qualifications, applicationDeadline, datePosted
  } = props;

  var a = moment();
  var b = moment(datePosted);
  var inSeconds = a.diff(b, 'seconds');
  var inMinutes = a.diff(b, 'minutes');
  var inHours = a.diff(b, 'hours');
  var inDays = a.diff(b, 'days');
  var inWeeks = a.diff(b, 'weeks');
  const postTime = inWeeks > 0 ? b.format('MMM D') : (inDays > 0 ? `${inDays}d` : (inHours > 0 ? `${inHours}h` : (inMinutes > 0 ? `${inMinutes}m` : `${inSeconds}s`)));

  return (
    <>
      <Link style={{ textDecoration: 'none', zIndex: 11 }}
        to={{
          pathname: `/job/${jobId}`,
          jobProps: props,
          jobState: { showMore, jobId }
        }}>
        <div className={styles.JobDiv}>
          <div className={styles.JobDivBody}>
            <div className={styles.UserCardAndPostTime}>
              {userId &&
                <>
                  {userId !== 'dOIL81did2gwO6Wz5Afbcw' ?
                    <UserCardFeed
                      userId={userId}
                      allUserItems={allUserItems} />
                    :
                    <div className={styles.Info}>
                      <Info sx={{ fontSize: '16px', color: '#5591C9', marginRight: '2px' }} />
                      <span>This job was sourced from a public website.</span>
                    </div>
                  }
                </>
              }
              <WorkIcon style={{ color: '#FEBA3F' }}></WorkIcon>
            </div>
            <div className={styles.JobTitle}>
              <Typography variant='h3'>{(jobTitle.length > 100) ? `${jobTitle.substring(0, 100)}...` : jobTitle}</Typography>
            </div>
            <div className={styles.descriptionContainer}>
              <Typography variant="body1">{description.length < 175 || showMore ? description : `${description.substring(0, 175)}...`}</Typography>
            </div>
            {employer &&
              <div className={styles.Employer}>
                <Typography variant='h3'>
                  {employer}
                </Typography>
              </div>
            }
            {/* Terrible name i know */}
            <div className={styles.LocationAndViewButtonContainer}>
              {location &&
                <div className={styles.Tldr}>
                  <Typography variant='body1'>{location}</Typography>
                </div>
              }
              <button className={styles.ViewJobButton}>View</button>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default JobCard;
