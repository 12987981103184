import React, { useState, useEffect, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Badge, Box, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import NotificationsIcon from '@mui/icons-material/Notifications';
import styles from './TopBarStyles.module.css';
import _config from "../../signup/config";
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { Link, useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Logo from '../logo/logo';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import RequestService from '../../requestservice/requestservice';
import moment from 'moment';

function TopBar({ userInfo, numMessages, toggleDrawer, windowWidth, showDrawerButton = true, requestService = new RequestService(), userId, hideNotifications = false }) {
  const theme = useTheme();
  const [notifications, setNotifications] = useState([]);
  const [numOfUnseenNotifications, setNumOfUnseenNotifications] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);
  const notificationsEl = useRef(null);
  const notificationsButtonEl = useRef(null);
  const poolData = {
    UserPoolId: _config.cognito.userPoolId,
    ClientId: _config.cognito.userPoolClientId,
  };
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const history = useHistory();


  useEffect(async () => {
    if (userId) {
      setNotifications(await requestService.getNotifications(userId));
    }
  }, [userId]);

  useEffect(async () => {
    if (notifications.length == 0) {
      return;
    }
    const numUnseenNotifications = notifications.reduce((acc, n) => acc + !n.seen, 0);
    setNumOfUnseenNotifications(numUnseenNotifications);
  }, [notifications]);

  const viewNotification = async (notification) => {
    if (!notification.seen) {
      await requestService.markNotificationsAsSeen([notification.notificationId]);
      notification.seen = true;
      setNumOfUnseenNotifications(numOfUnseenNotifications - 1);
    }
    history.push(notification.link);
  }

  const openNotifications = async () => {
    setShowNotifications(true);
    setNotifications(await requestService.getNotifications(userId));
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  function handleOutsideClick(event) {
    if (notificationsEl.current && !notificationsEl.current.contains(event.target)
      && !notificationsButtonEl.current.contains(event.target)) {
      setShowNotifications(false);
    }
  }

  const createNotifications = () => {
    return notifications.map(n => {
      var createTimestamp = moment(n.createTimestamp).local().fromNow();
      return (
        <Box className={!n.seen && styles.UnseenNotification} onClick={() => viewNotification(n)} key={n.notificationId}>
          <div className={styles.Notification}>
            <div>
              <img width='40px' height='40px' className={styles.NotificationPic} src={n.profilePictureUrl} />
            </div>
            <div className={styles.NotificationBodyText}>
              <div className={styles.NotificationTime}>{createTimestamp}</div>
              <div className={styles.NotificationText}>
                {n.message}
              </div>
            </div>
          </div>
        </Box>);
    });
  }

  return (
    <div className={styles.AppBarContainer}>
      <AppBar position="fixed" elevation={0} sx={{ zIndex: 1201, background: theme.palette.primary.mainGradient, boxShadow: '0px 1px 16px 0px #00000033;' }}>
        <Toolbar>
          {windowWidth <= 768 && showDrawerButton &&
            <IconButton onClick={toggleDrawer} sx={{ marginRight: '4px' }}>
              <Badge key={numMessages} badgeContent={numMessages} color="error">
                <Tooltip title='Close drawer'>
                  <DensityMediumIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                </Tooltip>
              </Badge>
            </IconButton>
          }
          <Logo />
          <Box sx={{
            ml: 'auto',
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            alignItems: 'center',
          }}>
            {/* Notifications */}
            {userPool.getCurrentUser() && !hideNotifications && notifications?.length != 0 &&
              <div className={styles.dropdown}>
                <IconButton ref={notificationsButtonEl} aria-label="notifications" onClick={openNotifications}>
                  <Badge badgeContent={numOfUnseenNotifications > 0 ? numOfUnseenNotifications : 0} color="error">
                    <NotificationsIcon aria-label="notifications" />
                  </Badge>
                </IconButton>
                {showNotifications &&
                  <div id='notifications' ref={notificationsEl} className={styles.dropdownContent}>
                    <Typography variant='h3' sx={{ p: '16px' }}>Notifications</Typography>
                    <div className={styles.NotificationsList}>
                      {createNotifications()}
                    </div>
                  </div>}
              </div>

            }
            <div className={styles.UserInfoDiv}>
              {userInfo}
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    </div >
  );
}

export default TopBar;