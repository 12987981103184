import React, { Component } from 'react'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import axios from 'axios';
import _config from "../../signup/config";
import {
  withRouter
} from "react-router-dom";
import moment from 'moment';
import google_calendar_logo from '../../../images/btn_google_signin_light_normal_web.png';
import { Helmet } from "react-helmet";
import { Button, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import KeywordSmall from '../KeywordSmall/KeywordSmall';
import { Bookmark, BookmarkBorderOutlined, CalendarToday, CalendarTodayOutlined, CommentOutlined, ContentCopyOutlined, DeleteOutline, Edit, Favorite, FavoriteBorderOutlined, Info, MeetingRoomOutlined, OndemandVideo } from '@mui/icons-material';
import TalkComments from '../TalkComments/TalkComments';
import UserCardFeed from '../UserCardFeed/UserCardFeed';
import DialogBox from '../DialogBox/DialogBox';
import CreateTalkForm from '../../CreateTalkForm/CreateTalkForm';
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from 'react-toastify';
import { gapi, CLIENT_ID, API_KEY, DISCOVERY_DOCS, SCOPES, timeZoneToIanaTimezone } from '../GoogleCalAPI/APIvariables';
import ScriptTag from 'react-script-tag';
import RequestService from "../../requestservice/requestservice";
import styles from './TalkThreadStyles.module.css';
let google = window.google;

class TalkThread extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thumbnailURL: '',
      title: '',
      hostName: '',
      institution: '',
      eventType: '',
      description: '',
      speakerBio: '',
      keywords: '',
      eventDate: '',
      eventTime: '',
      timeZone: '',
      activeLoc: '',
      venue: {},
      refFilesURL: '',
      additionalInfo: '',
      userId: '',
      selfUserId: '',
      eventId: '',
      startDate: '',
      endDate: '',
      meetingLink: '',
      meetingDetails: '',
      visibility: '',
      rsvpUserItems: [],
      attendingNames: [],
      numberRsvps: 0,
      liked: false,
      saved: false,
      loading: true,
      showBio: false,
      expand: false,
      editTalksPopup: false,
      addedToCalendar: false,
      checkDeletePopup: false,
      activeComp: false,
      talkWebPage: '',
    };

    var poolData = {
      UserPoolId: _config.cognito.userPoolId,
      ClientId: _config.cognito.userPoolClientId,
    };
    this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    this.commentButtonClicked = this.commentButtonClicked.bind(this);
    this.getEvent = this.getEvent.bind(this);
    this.getEventAPI = this.getEventAPI.bind(this);
    this.getRsvps = this.getRsvps.bind(this);
    this.getAttendingNames = this.getAttendingNames.bind(this);
    this.toggleExpand = this.toggleExpand.bind(this);
    this.handleEditPopupOpen = this.handleEditPopupOpen.bind(this);
    this.handleTalksPopupClose = this.handleTalksPopupClose.bind(this);
    this.copyLink = this.copyLink.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.addToCalendar = this.addToCalendar.bind(this);
    this.addToCalendarBackend = this.addToCalendarBackend.bind(this);
    this.removeFromCalendar = this.removeFromCalendar.bind(this);
    this.whenSaveClicked = this.whenSaveClicked.bind(this);
    this.whenUnsaveClicked = this.whenUnsaveClicked.bind(this);
    this.whenUnlikeClicked = this.whenUnlikeClicked.bind(this);
    this.whenLikeClicked = this.whenLikeClicked.bind(this);
    this.setDeletePopup = this.setDeletePopup.bind(this);
    this.goToMeeting = this.goToMeeting.bind(this);
    this.toggleBio = this.toggleBio.bind(this);
    this.visitTalk = this.visitTalk.bind(this);

    this.talkCommentsRef = React.createRef();
    this.requestService = new RequestService();
  }

  componentDidMount() {
    gapi.load('client', async () => {
      await gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: DISCOVERY_DOCS,
      });
    });
    const eventId = this.props.match.params.eventId;
    if (this.props.location.talkProps) {
      this.getEvent(eventId);
    } else {
      this.getEventAPI(eventId);
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.match.params.eventId !== prevProps.match.params.eventId) {
      const eventId = this.props.match.params.eventId;
      if (this.props.location.talkProps) {
        this.getEvent(eventId);
      } else {
        this.getEventAPI(eventId);
      }
    }
  }

  getEvent(eventId) {
    const talkState = this.props.location.talkState;
    const talkProps = this.props.location.talkProps;
    var self = this;
    if (this.props.location.talkProps) {
      self.setState({
        thumbnailURL: talkProps.thumbnailURL,
        title: talkProps.title,
        hostName: talkProps.hostName,
        institution: talkProps.institution,
        eventType: talkProps.eventType ? talkProps.eventType.label : '',
        description: talkProps.description,
        speakerBio: talkProps.speakerBio,
        keywords: talkProps.keywords,
        eventDate: [
          moment(talkProps.startDate).format("ddd, MMMM Do YYYY") === moment(talkProps.endDate).format("ddd, MMMM Do YYYY") ?
            moment(talkProps.startDate).format("ddd, MMMM Do YYYY") :
            moment(talkProps.startDate).format("ddd, MMMM Do YYYY") + ' to ' + moment(talkProps.endDate).format("ddd, MMMM Do YYYY")],
        eventTime: moment(talkProps.startDate).format('h:mm a')
          + ' to ' + moment(talkProps.endDate).format('h:mm a'),
        timeZone: talkProps.timeZone,
        activeLoc: talkProps.activeLoc,
        venue: talkProps.venue,
        userId: talkProps.userId,
        eventId: eventId,
        startDate: talkProps.startDate,
        endDate: talkProps.endDate,
        meetingLink: talkProps.meetingLink,
        liked: talkState.liked,
        saved: talkState.saved,
        addedToCalendar: talkState.addedToCalendar,
        loading: false,
        activeComp: talkProps.activeComp,
        talkWebPage: talkProps.talkWebPage,
        additionalInfo: talkProps.additionalInfo,
      }, self.getRsvps(eventId));
    }

  }

  getEventAPI(eventId) {
    var cognitoUser = this.userPool.getCurrentUser();
    var onSuccess = function registerSuccess(result) {
      var confirmation = ('get account complete!');
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };

    if (cognitoUser) {
      var self = this;
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const loggedInUserId = result[3].Value;
              self.setState({selfUserId: loggedInUserId});
              const api = _config.api.invokeUrl + '/getevent?eventId=' + eventId + '&userId=' + loggedInUserId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .get(api, axiosConfig)
                .then((response) => {
                  self.setState({
                    activeComp: response.data.activeComp,
                    thumbnailURL: response.data.thumbnailURL,
                    title: response.data.title,
                    hostName: response.data.hostName,
                    institution: response.data.institution,
                    eventType: response.data.eventType.label,
                    description: response.data.description,
                    speakerBio: response.data.speakerBio,
                    keywords: response.data.keywords,
                    eventDate: [
                      moment(response.data.startDate).format("ddd, MMMM Do YYYY") === moment(response.data.endDate).format("ddd, MMMM Do YYYY") ?
                        moment(response.data.startDate).format("ddd, MMMM Do YYYY") :
                        moment(response.data.startDate).format("ddd, MMMM Do YYYY") + ' to ' + moment(response.data.endDate).format("ddd, MMMM Do YYYY")],
                    eventTime: moment(response.data.startDate).format('h:mm a')
                      + ' to ' + moment(response.data.endDate).format('h:mm a'),
                    timeZone: response.data.timeZone.value,
                    activeLoc: response.data.activeLoc,
                    venue: response.data.venue,
                    refFilesURL: response.data.refFilesURL,
                    additionalInfo: response.data.additionalInfo,
                    userId: response.data.userId,
                    eventId: response.data.EventId,
                    startDate: response.data.startDate,
                    endDate: response.data.endDate,
                    meetingLink: response.data.meetingLink,
                    meetingDetails: response.data.meetingDetails,
                    visibility: response.data.visibility,
                    liked: response.data.liked,
                    saved: response.data.saved,
                    addedToCalendar: response.data.attending,
                    loading: false,
                    talkWebPage: response.data.talkWebPage,
                  }, self.getRsvps(eventId));
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
        }
      });
    }
  }

  handleEditPopupOpen() {
    this.setState({ editTalksPopup: true });
  };
  handleTalksPopupClose() {
    this.setState({ editTalksPopup: false });
  };

  toggleExpand() {
    this.setState({ expand: !this.state.expand });
  }

  addToCalendar() {
    try {
      const client = google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        callback: (tokenResponse) => {
          if (tokenResponse && tokenResponse.access_token &&
            google.accounts.oauth2.hasGrantedAllScopes(tokenResponse,
              SCOPES)) {
            // Can add calendar events
            const event = {
              'summary': this.state.title,
              'location': this.state.venue.formatted_address ? this.state.venue.formatted_address + ' ' + (this.state.meetingLink ? this.state.meetingLink : '')
                : (this.state.meetingLink ? this.state.meetingLink : ''),
              'description': this.state.description,
              'start': {
                'dateTime': moment(this.state.startDate).format().substring(0, moment(this.state.startDate).format().length - 6),
                'timeZone': moment.tz.guess(),
              },
              'end': {
                'dateTime': moment(this.state.endDate).format().substring(0, moment(this.state.startDate).format().length - 6),
                'timeZone': moment.tz.guess(),
              },
              'reminders': {
                'useDefault': false,
                'overrides': [
                  { 'method': 'email', 'minutes': 24 * 60 },
                  { 'method': 'popup', 'minutes': 10 }
                ]
              }
            };

            const request = gapi.client.calendar.events.insert({
              'calendarId': 'primary',
              'resource': event
            });

            request
              .execute(event => {
                window.open(event.htmlLink);
                this.addToCalendarBackend();
                toast("Talk added to your calendar!");
              });
          }
        }
      });
      client.requestAccessToken();
    } catch (err) {
      this.addToCalendarBackend();
      toast("Talk added to your calendar!");
    }
  }


  addToCalendarBackend() {
    this.setState({ addedToCalendar: true });
    var self = this;
    var onSuccessAdded = function registerSuccess(result) {
      var eventId = result.data.eventId;
      var userId = result.data.userId;
      var confirmation = ('talk added to event calendar database!');
      const event = {
        EventId: self.state.eventId,
        hostName: self.state.hostName,
        institution: self.state.institution,
        startDate: self.state.startDate,
        endDate: self.state.endDate,
        title: self.state.title,
        venue: self.state.venue,
        meetingLink: self.state.meetingLink,
        timeZone: self.state.timeZone,
        description: self.state.description,
        speakerBio: self.state.speakerBio,
        thumbnailURL: self.state.thumbnailURL,
        userId: self.state.userId,
        eventType: self.state.eventType,
        activeLoc: self.state.activeLoc,
        activeComp: self.state.activeComp,
        liked: self.state.liked,
        talkWebPage: self.state.talkWebPage,
        additionalInfo: self.state.additionalInfo,
      }
      if (confirmation) {
        self.setState({ addedToCalendar: true });
        self.props.handleAddedUpcomingTalk(event);
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
      self.setState({ addedToCalendar: false });
    };
    var cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const data = {
                "userId": result[3].Value,
                "eventId": self.state.eventId,
              };
              const user = self.props.allUserItems.find((user) => user.UserId === result[3].Value);
              const name = `${user.FirstName.trim()} ${user.LastName.trim()}`;
              self.setState({
                attendingNames: [...self.state.attendingNames, name],
                numberRsvps: self.state.numberRsvps + 1,
              });
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/addeventtousercalendar';
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .post(api, data, axiosConfig)
                .then((response) => {
                  onSuccessAdded(response)
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          });
        }
      }
      );
    }
  }

  getRsvps(eventId) {
    var self = this;

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };
    var cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + `/getrsvpsforevent?eventId=${eventId}`;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .get(api, axiosConfig)
                .then((response) => {
                  if (response.data) {
                    self.setState({
                      rsvpUserItems: response.data.Items,
                      numberRsvps: response.data.Count,
                    });
                    self.getAttendingNames();
                  }
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          })
        }
      });
    }
  }

  getAttendingNames() {
    var self = this;
    self.props.allUserItems.map((user) => {
      self.state.rsvpUserItems.map((rsvp) => {
        if (user.UserId === rsvp.UserId) {
          const name = `${user.FirstName.trim()} ${user.LastName.trim()}`;
          self.setState({ attendingNames: [...self.state.attendingNames, name] });
        }
      })

    })
  }

  commentButtonClicked() {
    this.talkCommentsRef.current.addCommentTextAreaRef.current.focus();
  }

  removeFromCalendar(evt) {
    evt.preventDefault();
    this.setState({ addedToCalendar: false });
    var self = this;
    var onSuccessDeleted = function registerSuccess(result) {
      var eventId = result.data.eventId;
      var userId = result.data.userId;
      var confirmation = ('event removed from the attending events database.');
      if (confirmation) {
        self.setState({ addedToCalendar: false });
        self.props.handleRemoveUpcomingTalk(eventId);
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
      self.setState({ addedToCalendar: true });
    };
    var cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              let currentUserId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();
              const data = {
                "userId": currentUserId,
                "eventId": self.state.eventId,
              };
              const user = self.props.allUserItems.find((user) => user.UserId === currentUserId);
              const name = `${user.FirstName.trim()} ${user.LastName.trim()}`;
              self.setState({
                attendingNames: self.state.attendingNames.filter((currentName) => currentName !== name),
                numberRsvps: self.state.numberRsvps - 1,
              });


              const api = _config.api.invokeUrl + '/deleteeventfromusercalendar?userId=' + currentUserId + '&eventId=' + self.state.eventId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .delete(api, axiosConfig)
                .then((response) => {
                  onSuccessDeleted(response)
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          })
        }
      });
    }
  }

  async whenSaveClicked() {
    this.setState({ saved: true });
    const data = { eventId: this.state.eventId };
    await this.requestService.addSavedEvent(data, this);
  }

  async whenUnsaveClicked() {
    this.setState({ saved: false });
    const data = { eventId: this.state.eventId };
    await this.requestService.removeSavedEvent(data, this);
  }

  whenLikeClicked() {
    this.setState({ liked: true });
    if (!this.userPool.getCurrentUser()) {
      return;
    }
    this.requestService.likeEvent({ userId: this.state.selfUserId, eventId: this.state.eventId });
  }

  whenUnlikeClicked() {
    var self = this;
    self.setState({ liked: false });
    var onSuccessUnliked = function registerSuccess(result) {
      var eventId = result.data.eventId;
      var userId = result.data.userId;
      var confirmation = ('event removed from the liked events database.');
      if (confirmation) {
        self.setState({ liked: false });
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
      self.setState({ liked: true });
    };
    var cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              let currentUserId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/unlikeevent?userId=' + currentUserId + '&eventId=' + self.state.eventId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .delete(api, axiosConfig)
                .then((response) => {
                  onSuccessUnliked(response)
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          })
        }
      });
    }
  }

  setDeletePopup(bool) {
    this.setState({ checkDeletePopup: bool });
  }

  copyLink(e) {
    navigator.clipboard.writeText(window.location.toString())
    toast("Talk URL copied to your clipboard!");
  }

  visitTalk() {
    if (!this.state.talkWebPage) {
      return;
    }
    const talkWebPage = this.state.talkWebPage.startsWith('http') ? this.state.talkWebPage : 'https://' + this.state.talkWebPage;
    window.open(talkWebPage);
  }

  deleteEvent() {
    var self = this;
    var onSuccess = function registerSuccess(result, self) {
      var eventId = result.data.eventID;
      var startDate = result.data.startDate;
      var confirmation = ('event id deleted!');
      if (confirmation) {
        self.props.history.goBack();
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };

    const cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();

          const api = _config.api.invokeUrl + '/deleteevent?eventId=' + self.state.eventId;
          const axiosConfig = {
            headers: {
              "Authorization": authToken,
            }
          };
          axios
            .delete(api, axiosConfig)
            .then((response) => {
              console.log(response);
              onSuccess(response, self);
            })
            .catch((error) => {
              console.log(error);
              onFailure(error);
            });
        }
      });
    }
  }

  goToMeeting() {
    window.open(this.state.meetingLink);
  }

  toggleBio() {
    this.setState({ showBio: !this.state.showBio });
  }

  render() {
    const { pageTitle, drawerWidth, history, allUserItems, signedInUserId } = this.props;
    const { editTalksPopup, eventId, userId, liked, saved, keywords, expand, thumbnailURL, institution, hostName,
      title, eventDate, eventTime, startDate, endDate, activeLoc, timeZone, description, speakerBio, numberRsvps, attendingNames,
      addedToCalendar, loading, showBio, activeComp, talkWebPage, additionalInfo, } = this.state;
    const startDateInMilliSecs = new Date(startDate).getTime();
    const endDateInMilliSecs = new Date(endDate).getTime();
    const isLive = startDateInMilliSecs <= Date.now() && Date.now() <= endDateInMilliSecs;
    const attendingDiv = <div className={styles.AttendingUsers}>
      {attendingNames.map((name) => {
        return (
          <span key={name}>{name}</span>
        )
      })}
    </div>
    const recorded = activeLoc == 'Recorded';
    return (
      <div className={styles.TalkThreadContainer}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <meta name="author" content={hostName} />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={thumbnailURL} />
          <meta property="og:image:alt" content={title} />
          <meta property="og:image:description" content={title} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Talks at GS" />
          <meta property="og:locale" content="en_US" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@talksatgs" />
          <meta name="twitter:creator" content="@talksatgs" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={thumbnailURL} />
          <meta name="twitter:url" content={window.location.href} />
          <meta name="twitter:domain" content="talksatgs.com" />
          <meta name="twitter:app:name:iphone" content="Talks at GS" />
          <meta name="twitter:app:name:ipad" content="Talks at GS" />
          <meta name="twitter:app:name:googleplay" content="Talks at GS" />
          <meta name="twitter:app:id:iphone" content="1520000000" />
          <meta name="twitter:app:id:ipad" content="1520000000" />
          <meta name="twitter:app:id:googleplay" content="com.talksatgs" />
          <meta name="twitter:app:url:iphone" content="talksatgs://talksatgs.com" />
          <meta name="twitter:app:url:ipad" content="talksatgs://talksatgs.com" />
          <meta name="twitter:app:url:googleplay" content="talksatgs://talksatgs.com" />
          <meta name="twitter:app:country" content="US" />
        </Helmet>

        <ScriptTag isHydrating={true} type="text/javascript"
          src="https://accounts.google.com/gsi/client" />
        {loading ?
          <div className={styles.LoadingDiv}>
            <Typography sx={{m: '8px', color: '#1B065E'}} variant='h1'>Loading...</Typography>
            <ClipLoader
              color={"var(--primary-color)"}
              loading={loading}
              size={100}
            />
          </div>
          :
          <div className={styles.TalkThread}>
            <div className={styles.TalkInfo}>
              <div className={styles.UserAndIcons}>
                {userId &&
                  <>
                    {userId !== 'dOIL81did2gwO6Wz5Afbcw' ?
                      <UserCardFeed userId={userId} allUserItems={allUserItems} />
                      :
                      <div className={styles.Info}>
                        <Info sx={{ fontSize: '16px', color: '#5591C9', marginRight: '2px' }} />
                        <span>Symposia sourced this talk from a public
                          website.</span>
                      </div>
                    }
                  </>
                }
                <div className={styles.LowLevelInteractions}>
                  {(userId === signedInUserId) &&
                    <>
                      <Tooltip title='Edit Talk'>
                        <IconButton onClick={this.handleEditPopupOpen}>
                          <Edit sx={{ fontSize: '24px' }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Delete Talk'>
                        <IconButton onClick={() => this.setDeletePopup(true)}>
                          <DeleteOutline sx={{ fontSize: '24px' }} />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                  <Tooltip title='Copy Talk Link'>
                    <IconButton onClick={this.copyLink}>
                      <ContentCopyOutlined sx={{ fontSize: '24px' }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <div className={styles.Thumbnail}>
                {thumbnailURL &&
                  <img className={styles.ThumbnailImage} src={thumbnailURL} />}
              </div>
              <div className={styles.TitleAndThumbnail}>
                <div className={styles.Title}>
                  <div className={styles.SpeakerNameAndInstitutionHighlight}>
                    <div className={styles.SpeakerName}>
                      <Typography variant='subtitle'>
                        {hostName}
                      </Typography>
                    </div>
                    <div className={styles.SpeakerInstitution}>
                      <Typography variant='body1'>
                        {institution}
                      </Typography>
                    </div>
                  </div>


                  <h1 className={styles.TalkTitle}>{title}</h1>
                  {recorded ?
                    <div className={styles.DateTime}>
                      (Recording)
                    </div>
                    :
                    <div className={styles.DateTime}>
                      {eventDate}, {eventTime} {moment.tz(moment.tz.guess()).format('z')}
                    </div>
                  }
                </div>
              </div>

              <div className={styles.BodyText}>
                <div>
                  <p className={styles.Description}>
                    <div className={styles.DescriptionHeader}>
                      Description
                    </div>
                    <div className={styles.DescriptionText}>
                      {description}
                    </div>
                    {speakerBio &&
                      <div className={styles.SpeakerBio}>

                        <div className={styles.SpeakerBioHeader}>Speaker bio</div>
                        <div className={styles.SpeakerBioText}>{speakerBio}</div>
                      </div>
                    }
                    {additionalInfo &&
                      <div className={styles.additionalInfo}>

                        <div className={styles.additionalInfoHeader}>Additional info</div>
                        <div className={styles.additionalInfoText}>{additionalInfo}</div>
                      </div>}
                  </p>
                </div>
              </div>
              <div className={styles.footer}>

                {keywords &&
                  <div className={styles.Keywords}>
                    {keywords.map((keyword) => {
                      return (
                        <KeywordSmall
                          key={keyword}
                          label={keyword}
                        />
                      );
                    })}
                  </div>
                }
                {talkWebPage &&
                  <button onClick={this.visitTalk} className={styles.visitTalkButton}>
                    <div>Visit talk</div>
                    <div><i className="material-icons-outlined small">open_in_new</i></div>

                  </button>
                }
              </div>
            </div>
            {attendingNames[0] &&
              <>
                <Divider />
                <Tooltip
                  title={attendingDiv}
                  placement='top'
                >
                  <div className={styles.Attending}>
                    <span style={{ fontWeight: 'bold', marginRight: '8px' }}>{numberRsvps}</span>Attending
                  </div>
                </Tooltip>
              </>
            }
            <Divider />
            <div className={styles.Interactions}>
              {liked ?
                <Tooltip title='Remove Like'>
                  <IconButton className={styles.Active} onClick={this.whenUnlikeClicked}>
                    <Favorite sx={{ fontSize: '32px', color: '#1B065E' }} className={styles.Icons} />
                  </IconButton>
                </Tooltip>
                :
                <Tooltip title='Like'>
                  <IconButton onClick={this.whenLikeClicked}>
                    <FavoriteBorderOutlined sx={{ fontSize: '32px', color: '#1B065E' }} className={styles.Icons} />
                  </IconButton>
                </Tooltip>
              }
              {isLive && this.state.meetingLink ?
                <Tooltip title='Join Meeting'>
                  <IconButton onClick={this.goToMeeting}>
                    <MeetingRoomOutlined sx={{ fontSize: '24px' }} />
                  </IconButton>
                </Tooltip>
                :
                <>
                  {recorded ?
                    <>
                      <Tooltip title='Watch Video'>
                        <IconButton onClick={this.goToMeeting}>
                          <OndemandVideo sx={{ fontSize: '24px' }} />
                        </IconButton>
                      </Tooltip>
                    </>
                    :
                    <>
                      {addedToCalendar ?
                        <Tooltip title='Remove from Upcoming Talks'>
                          <IconButton className={styles.Icons} onClick={this.removeFromCalendar}>
                            <CalendarToday sx={{ fontSize: '32px', color: '#43A4FE' }} />
                          </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title='Add to Google Calendar'>
                          {/* <button className={styles.Icons} onClick={this.addToCalendar}> */}
                          {/* <CalendarTodayOutlined sx={{ mr: '8px', fontSize: '24px' }} /> */}
                          <IconButton className={styles.Icons} onClick={this.addToCalendar}>
                            <img src={google_calendar_logo} style={{ width: "50px" }} alt="Add to Google Calendar"></img>
                          </IconButton>
                          {/* </button> */}
                          {/* <IconButton className={styles.Icons} onClick={this.addToCalendar}>
                            <CalendarTodayOutlined sx={{ fontSize: '32px' }} />
                          </IconButton> */}
                        </Tooltip>
                      }
                    </>
                  }
                </>
              }
              {saved ?
                <Tooltip title='Remove from Saved Talks'>
                  <IconButton className={styles.Active} onClick={this.whenUnsaveClicked}>
                    <Bookmark sx={{ fontSize: '32px', color: '#43A4FE' }} className={styles.Icons} />
                  </IconButton>
                </Tooltip>
                :
                <Tooltip title='Save for Later'>
                  <IconButton onClick={this.whenSaveClicked}>
                    <BookmarkBorderOutlined sx={{ fontSize: '32px' }} className={styles.Icons} />
                  </IconButton>
                </Tooltip>
              }

              {/* <Tooltip title='Comment'>
                <IconButton onClick={this.commentButtonClicked}>
                  <CommentOutlined sx={{ fontSize: '32px' }} />
                </IconButton>
              </Tooltip> */}
            </div>
            <div className={styles.Comments}>
              <>
                <TalkComments eventId={this.props.match.params.eventId} ref={this.talkCommentsRef} />              </>
            </div>
          </div>
        }

        <DialogBox
          open={editTalksPopup}
          handleClose={this.handleTalksPopupClose}
          title='Edit Talk'
        >
          <CreateTalkForm
            closeCreateTalkPopup={this.handleTalksPopupClose}
            eventId={eventId}
            talkState={this.state}
            userGroups={this.props.userGroups}
          />
        </DialogBox>
        <DialogBox
          open={this.state.checkDeletePopup}
          handleClose={() => this.setDeletePopup(false)}
          title='Delete talk?'
        >
          <div className={styles.PopupButtonDiv}>
            <Button
              type="button"
              variant='contained'
              className={styles.goBackButton}
              onClick={() => this.setDeletePopup(false)}
              sx={{
                background: 'white',
                border: '2px solid #FEBA3F',
                ":hover": {
                  bgcolor: '#eeeeee',
                }
              }}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              className={styles.discardPopupButton}
              sx={{ color: '#1B065E', ml: '12px', }}
              onClick={this.deleteEvent}>
              Delete
            </Button>
          </div>
        </DialogBox>
        <ToastContainer />
      </div>
    )
  }
}

export default withRouter(TalkThread);
