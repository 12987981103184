import React from 'react';
import { useState } from 'react';
import right_arrow from '../../../images/triangle-right-24.svg';
import down_arrow from '../../../images/triangle-down-24.svg';
import styles from './toggleQuestionStyles.module.css';

export default function ToggleQuestion({
  question = '',
  answer = '',
  link = '',
  linkText = ''
}) {

  const [toggleDown, setToggleDown] = useState(false);
  return (
    <div>
      {toggleDown ?
        <div className={styles.questionDiv}>
          <div className={styles.toggleDiv} >
            <img src={down_arrow} alt='Down Arrow Icon' className={styles.closeIcon} />
            <h2 className={styles.question}>{question}</h2>
          </div>
          {link ? <p className={styles.answer}>{answer} <a href={link}>{linkText} </a></p> :
            <p className={styles.answer}>{answer}</p>}
        </div>
        :
        <div className={styles.questionDiv} onClick={() => setToggleDown(toggleDown ? false : true)}>
          <div className={styles.toggleDiv} >
            <img src={right_arrow} alt='Right Arrow Icon' className={styles.closeIcon} />
            <h2 className={styles.question}>{question}</h2>
          </div>
        </div>
      }
      <div className={styles.questionSeparator}></div>

    </div>
  )
}
