import { React, useState, useEffect } from 'react'
import styles from './textFieldStyles.module.css'
import search_icon from '../../../images/search.svg'
import { TextareaAutosize } from '@mui/material';

export function TextField({
    type = 'text',
    placeholder,
    label = '',
    id = '',
    value,
    name = '',
    onChange = () => { },
    required = false,
    pattern = '(.*?)',
    autoRef = undefined,
    errorMessage = 'Please match the request format.',
}) {
    if (id === '') {
        id = label
            .toLowerCase()
            .split(' ')
            .map((word) => word.replace(/[^a-z]+/g, ""))
            .join("-");
    }

    return (
        <div className={styles.textFieldDiv}>
            {required ?
                <label className={styles.labelText}>
                    {label}<span style={{ color: 'red', fontSize: '14px' }}>*</span>
                </label> :
                <label className={styles.labelText}>
                    {label}
                </label>
            }
            <input
                className={styles.textField}
                type={type}
                id={id}
                ref={autoRef}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value, e)}
                value={value}
                required={required}
                pattern={pattern}
                title={errorMessage}
                name={name}
            />
        </div>
    );
}

export function TextArea({
    type = 'text',
    placeholder,
    label = '',
    value,
    onChange = () => { },
    required = false,
    name = '',
    className = styles.textAreaDiv,
    minRows = 1,
    ref,
}) {
    const id = label
        .toLowerCase()
        .split(' ')
        .map((word) => word.replace(/[^a-z]+/g, ""))
        .join("-");

    return (
        <TextareaAutosize
            id={id}
            aria-label={placeholder}
            placeholder={placeholder}
            value={value}
            className={className}
            required={required}
            onInput={(e) => onChange(e.currentTarget.value)} 
            name={name}
            minRows={minRows}
            ref={ref && ref}
        />
        // <div>
        //     <label className={styles.labelText}>
        //         {label}
        //     </label>
        //     <div id={id} dangerouslySetInnerHTML={{ __html: value }} className={styles.textAreaDiv} placeholder={placeholder} onInput={(e) => onChange(e)} required={required} contentEditable>
        //     </div>
        // </div>

    );
}

export function AutoCompleteField({
    type = 'text',
    placeholder,
    label = '',
    id = '',
    autoRef,
    value,
    onChange = () => { },
    required = false,
    name = '',
}) {

    if (id === '') {
        id = label
            .toLowerCase()
            .split(' ')
            .map((word) => word.replace(/[^a-z]+/g, ""))
            .join("-");
    }

    return (
        <div>
            <div>
                {required ?
                    <label className={styles.labelText} style={{ fontWeight: 'bold', fontSize: '12px' }}>
                        {label}<span style={{ color: 'red', fontSize: '14px' }}>*</span>
                    </label> :
                    <label className={styles.labelText} style={{ fontWeight: 'bold', fontSize: '12px' }}>
                        {label}
                    </label>
                }
            </div>
            <div className={styles.autocompleteDiv}>
                <img src={search_icon} alt='Search' style={{ marginRight: '8px', width: '32px', height: '32px' }} />
                <input
                    className={styles.autocompleteField}
                    type={type}
                    ref={autoRef}
                    id={id}
                    placeholder={placeholder}
                    onChange={(e) => onChange(e.target.value, e)}
                    value={value}
                    required={required}
                    name={name}
                />
            </div>

        </div>
    );
}