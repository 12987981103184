import { IconButton, Tooltip } from '@mui/material';
import { FavoriteBorder } from '@mui/icons-material';
import Comments from '../Comments/Comments';
import React, { Component } from 'react'
import styles from './TalkCommentsStyles.module.css'
import RequestService from "../../requestservice/requestservice";
import { TextArea } from '../text-field';

// TODO: Cache user data from child (TalkComment) to parent (TalkComments) in order to reduce lambda getUserLite calls.
export default class TalkComments extends Comments {

  constructor(props) {
    super(props);
    this.eventId = this.props.eventId;
  }

  async getComments() {
    this.requestService.getCommentsForEvent(this.eventId, (response) => this.setState({ comments: response }));
  }

  async addComment() {
    this.setState({addCommentButtonDisabled: true});
    await this.requestService.addCommentToEvent({ eventId: this.eventId, comment: this.state.newComment, },
      (newComment) => { this.addCommentToCommentsUi(newComment) });
  }
}
