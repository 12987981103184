import React, { Component } from 'react'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import _config from "../../signup/config";
import axios from 'axios';
import UserCardFeed from '../UserCardFeed/UserCardFeed';
import { Bookmark, BookmarkBorderOutlined, CommentOutlined, ContentCopyOutlined, DeleteOutline, Edit, Favorite, FavoriteBorderOutlined, Info, OndemandVideo, ReadMore, ReadMoreOutlined } from '@mui/icons-material';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import { Divider, Rating } from '@mui/material';
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from 'react-toastify';
import RequestService from "../../requestservice/requestservice";
import PaperComments from '../PaperComments/PaperComments';
import moment from 'moment';
import { Helmet } from "react-helmet";
import styles from './PaperThreadStyles.module.css';
import Skeleton from 'react-loading-skeleton'
import { withRouter } from 'react-router-dom';
import KeywordSmall from '../KeywordSmall/KeywordSmall';
import CreatePaperForm from '../../CreatePaperForm/CreatePaperForm';
import DialogBox from '../DialogBox/DialogBox';
import { getDocument } from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.entry'; // Import the worker entry

const OPEN_AI_API_KEY = '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';

class PaperThread extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paperUrl: null,
      title: '',
      authors: [],
      year: '',
      tldr: '',
      keywords: [],
      paperImageUrl: '',
      userId: '',
      paperId: this.props.match.params.paperId ? this.props.match.params.paperId : '',
      semanticPaperId: '',
      requestTime: '',
      videoLink: '',
      saved: false,
      liked: false,
      read: false,
      loading: true,
      expand: false,
      editPaperPopup: false,
      checkDeletePopup: false,
      readPaper: false,
      activeComp: false,
      chatGptMainFinding: null,
      chatGptSummary: null,
      usersWhoLikedPaperItems: [],
      numberOfLikes: 0,
    };


    var poolData = {
      UserPoolId: _config.cognito.userPoolId,
      ClientId: _config.cognito.userPoolClientId,
    };
    this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    this.getPaper = this.getPaper.bind(this);
    this.getPaperAPI = this.getPaperAPI.bind(this);
    this.handleEditPopupOpen = this.handleEditPopupOpen.bind(this);
    this.handlePaperPopupClose = this.handlePaperPopupClose.bind(this);
    this.whenUnsaveClicked = this.whenUnsaveClicked.bind(this);
    this.whenSaveClicked = this.whenSaveClicked.bind(this);
    this.whenLikeClicked = this.whenLikeClicked.bind(this);
    this.whenUnlikeClicked = this.whenUnlikeClicked.bind(this);
    this.whenUnreadClicked = this.whenUnreadClicked.bind(this);
    this.setDeletePopup = this.setDeletePopup.bind(this);
    this.goToPaper = this.goToPaper.bind(this);
    this.goToVideo = this.goToVideo.bind(this);
    this.deletePaper = this.deletePaper.bind(this);
    this.summarizePaper = this.summarizePaper.bind(this);
    this.getChatGptResponse = this.getChatGptResponse.bind(this);
    this.splitTextIntoChunks = this.splitTextIntoChunks.bind(this);
    this.getTextFromPDF = this.getTextFromPDF.bind(this);
    this.visitPaper = this.visitPaper.bind(this);
    this.getNumLikes = this.getNumLikes.bind(this);
    this.paperCommentsRef = React.createRef();
    this.requestService = new RequestService();
  }

  componentDidMount() {
    const paperId = this.props.match.params.paperId;
    if (this.props.location.paperProps) {
      this.getPaper(paperId);
    } else {
      this.getPaperAPI(paperId);
    }
    this.getNumLikes();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.match.params.paperId !== prevProps.match.params.paperId) {
      const paperId = this.props.match.params.paperId;
      if (this.props.location.paperProps) {
        this.getPaper(paperId);
      } else {
        this.getPaperAPI(paperId);
      }
    }
  }

  async getNumLikes() {
    const paperLikes = await this.requestService.getNumberOfPaperLikes({ paperId: this.state.paperId }, this);
    console.log('paperLikes');
    console.log(paperLikes);
    console.log('this.state.usersWhoLikedPaperItems');
    console.log(this.state.usersWhoLikedPaperItems);
    console.log('this.state.numberOfLikes');
    console.log(this.state.numberOfLikes);
  }

  async summarizePaper() {
    // Asynchronously download PDF file
    const mainFinding = this.state.chatGptMainFinding ?? this.props.location?.paperProps?.chatGptMainFinding;
    // const summary = this.state.chatGptSummary ?? this.props.location?.paperProps?.chatGptSummary;
    if (mainFinding) {
      return;
    }
    let self = this;
    const paperUrl = this.state.paperUrl ?? this.props.location.paperProps.paperUrl;
    getDocument(paperUrl).promise.then(async function (pdf) {
      const pdfText = await self.getTextFromPDF(pdf);
      if (pdfText !== '') {
        const chunks = self.splitTextIntoChunks(pdfText, 4000);
        // const summarizeChunkPrompt = 'Summarize this chunk of text from a research paper';
        // const summaries = await Promise.all(chunks.map((chunk) => {
        //   return self.getChatGptResponse(summarizeChunkPrompt, /* maxTokens */ 100, chunk);
        // }));

        const mainFindingPrompt = 'In 50 words, what is the main finding of the following research texts';
        let chatGptMainFinding = await self.getChatGptResponse(mainFindingPrompt, /* maxTokens= */ 100, chunks);
        chatGptMainFinding = chatGptMainFinding.slice(0, chatGptMainFinding.lastIndexOf('.') + 1);
        // const summaryPrompt = 'In more than 500 words, write a summary of the following research texts';
        // let chatGptSummary = await self.getChatGptResponse(summaryPrompt, /* maxTokens= */ 1000, summaries);
        // chatGptSummary = chatGptSummary.slice(0, chatGptSummary.lastIndexOf('.') + 1);

        self.setState({ chatGptMainFinding: chatGptMainFinding, });
        self.requestService.saveChatGptSummaries({ chatGptMainFinding, paperId: self.state.paperId }, self);
      }
    }).catch((error) => {
      self.setState({ chatGptMainFinding: `Couldn't find main finding. Please make sure the pdf is normal and pubically accessible.` });

      console.log(error);
    });
  }

  async getTextFromPDF(pdf) {
    let fullText = '';
    // Loop through each page to extract text
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const textContent = await page.getTextContent();
      const pageText = textContent.items.map(item => item.str).join(' ');
      fullText += pageText + '\n';
      if (fullText.length > 4000) {
        break;
      }
    }
    return fullText;
  }

  // Function to split the input text into chunks
  splitTextIntoChunks(text, chunkSize) {
    const chunks = [];
    // for (let i = 0; i < text.length; i += chunkSize) {
    //   chunks.push(text.slice(i, i + chunkSize));
    // }
    chunks.push(text.slice(0, chunkSize));
    return chunks;
  }

  async getChatGptResponse(prompt, maxTokens, summaries) {
    const endpoint = 'https://api.openai.com/v1/chat/completions';

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${OPEN_AI_API_KEY}`,
    };

    const requestBody = {
      model: 'gpt-4', // Use the appropriate model based on your API subscription
      messages: [
        {
          role: 'system',
          content: 'You are a helpful research assistant that summarizes research text.',
        },
        {
          role: 'user',
          content: `${prompt}: ${summaries}`,
        },
      ],
      max_tokens: 100, // Set the desired summary length (adjust as needed)
    };

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(endpoint, requestOptions);
    const data = await response.json();
    return data.choices ? data.choices[0].message.content : '';
  }

  async getPaper(paperId) {
    const paperState = this.props.location.paperState;
    const paperProps = this.props.location.paperProps;
    if (!paperProps) {
      return;
    }
    this.setState({
      paperUrl: paperProps.paperUrl,
      title: paperProps.title,
      authors: paperProps.authors,
      year: paperProps.year,
      tldr: paperProps.tldr,
      keywords: paperProps.keywords,
      paperImageUrl: paperProps.paperImageUrl,
      userId: paperProps.userId,
      paperId: paperId,
      semanticPaperId: paperProps.semanticPaperId,
      requestTime: paperProps.requestTime,
      videoLink: paperProps.videoLink,
      saved: paperState.saved,
      liked: paperState.liked,
      read: paperState.read,
      loading: false,
      activeComp: paperProps.activeComp,
      chatGptMainFinding: paperProps.chatGptMainFinding,
      chatGptSummary: paperProps.chatGptSummary,
    });
    await this.summarizePaper();
  }

  getPaperAPI(paperId) {
    var cognitoUser = this.userPool.getCurrentUser();
    var onSuccess = function registerSuccess(result) {
      var confirmation = ('get account complete!');
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };

    if (cognitoUser) {
      var self = this;
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const loggedInUserId = result[3].Value;
              const api = _config.api.invokeUrl + '/getpaper?paperId=' + paperId + '&userId=' + loggedInUserId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .get(api, axiosConfig)
                .then((response) => {
                  self.setState({
                    paperUrl: response.data.paperUrl,
                    title: response.data.title,
                    authors: response.data.authors,
                    year: response.data.year,
                    tldr: response.data.tldr,
                    keywords: response.data.keywords,
                    paperImageUrl: response.data.paperImageUrl,
                    userId: response.data.userId,
                    paperId: response.data.PaperId,
                    semanticPaperId: response.data.semanticPaperId,
                    requestTime: response.data.RequestTime,
                    videoLink: response.data.videoLink,
                    saved: response.data.saved,
                    liked: response.data.liked,
                    read: response.data.read,
                    loading: false,
                    activeComp: response.data.activeComp,
                    chatGptMainFinding: response.data.chatGptMainFinding,
                    chatGptSummary: response.data.chatGptSummary,
                  });
                  self.summarizePaper();
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
        }
      });
    }
  }

  commentButtonClicked() {
    this.paperCommentsRef.current.addCommentTextAreaRef.current.focus();
  }

  async whenUnsaveClicked() {
    this.setState({ saved: false });
    const data = { paperId: this.state.paperId }
    await this.requestService.removeSavedPaper(data, this);
    if (this.props.location.paperProps) {
      this.props.location.paperProps.handleRemoveSavedPaper(this.state.paperId);
    } else {
      this.props.handleRemoveSavedPaper(this.state.paperId);
    };
  }

  async whenSaveClicked() {
    this.setState({ saved: true });
    const data = { paperId: this.state.paperId };
    await this.requestService.addSavedPaper(data, this);
    const paper = {
      PaperId: this.state.paperId,
      semanticPaperId: this.state.semanticPaperId,
      paperUrl: this.state.paperUrl,
      paperImageUrl: this.state.paperImageUrl,
      userId: this.state.userId,
      title: this.state.title,
      year: this.state.year,
      authors: this.state.authors,
      tldr: this.state.tldr,
      videoLink: this.state.videoLink,
      keywords: this.state.keywords,
      RequestTime: this.state.requestTime,
      saved: this.state.saved,
      read: this.state.read,
      liked: this.state.liked,
      activeComp: this.state.activeComp,
    };
    if (this.props.location.paperProps) {
      this.props.location.paperProps.handleAddedSavedPaper(paper);
    } else {
      this.props.handleAddedSavedPaper(paper);
    }
  }

  async whenLikeClicked() {
    this.setState({ liked: true });
    const data = { paperId: this.state.paperId };
    await this.requestService.addLikedPaper(data, this);

  }

  async whenUnlikeClicked() {
    this.setState({ liked: false });
    const data = { paperId: this.state.paperId }
    await this.requestService.removeLikedPaper(data, this);
  }

  async whenUnreadClicked() {
    this.setState({ read: false });
    const data = { paperId: this.state.paperId }
    await this.requestService.removeReadPaper(data, this);
  }

  handleEditPopupOpen() {
    this.setState({ editPaperPopup: true });
  };
  handlePaperPopupClose() {
    this.setState({ editPaperPopup: false });
  };

  setDeletePopup(bool) {
    this.setState({ checkDeletePopup: bool });
  }

  goToPaper() {
    window.open(this.state.paperUrl);
  }

  goToVideo() {
    window.open(this.state.videoLink);
  }

  async deletePaper() {
    const data = { paperId: this.state.paperId }
    await this.requestService.deletePaper(data, () => this.props.history.goBack());
  }

  copyLink(e) {
    navigator.clipboard.writeText(window.location.toString())
    toast("Paper link copied to your clipboard!");
  }

  visitPaper() {
    if (!this.state.paperUrl) {
      return;
    }
    const paperUrl = this.state.paperUrl.startsWith('http') ? this.state.paperUrl : 'https://' + this.state.paperUrl;
    window.open(paperUrl);
  }


  render() {
    const { loading, editPaperPopup, userId, paperImageUrl, keywords, paperUrl, readPaper,
      requestTime, paperId, title, year, authors, tldr, semanticPaperId, videoLink, saved, liked, read, activeComp, chatGptMainFinding, chatGptSummary } = this.state;
    const { pageTitle, drawerWidth, history, allUserItems, signedInUserId } = this.props;
    var a = moment();//now
    var b = moment(requestTime);

    var inMinutes = a.diff(b, 'minutes');
    var inHours = a.diff(b, 'hours');
    var inDays = a.diff(b, 'days');
    var inWeeks = a.diff(b, 'weeks');
    const postTime = inWeeks > 0 ? b.format('MMM D') : (inDays > 0 ? `${inDays}d` : (inHours > 0 ? `${inHours}h` : `${inMinutes}m`));
    return (
      <div className={styles.PaperThreadContainer}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={tldr} />
          <meta name="keywords" content={keywords} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={tldr} />
          <meta property="og:image" content={paperImageUrl} />
          <meta property="og:image:alt" content={title} />
          <meta property="og:image:description" content={title} />
          <meta property="og:url" content={paperUrl} />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content="PaperHub" />
          <meta property="fb:app_id" content="123456789" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@PaperHub" />
          <meta name="twitter:creator" content="@PaperHub" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={tldr} />
          <meta name="twitter:image" content={paperImageUrl} />
          <meta name="twitter:url" content={paperUrl} />
          <meta name="twitter:domain" content="paperhub.com" />
          <meta name="twitter:app:name:iphone" content="PaperHub" />
          <meta name="twitter:app:name:ipad" content="PaperHub" />
          <meta name="twitter:app:name:googleplay" content="PaperHub" />
          <meta name="twitter:app:id:iphone" content="123456789" />
          <meta name="twitter:app:id:ipad" content="123456789" />
          <meta name="twitter:app:id:googleplay" content="com.paperhub" />
          <meta name="twitter:app:url:iphone" content="paperhub://paperhub.com" />
          <meta name="twitter:app:url:ipad" content="paperhub://paperhub.com" />
          <meta name="twitter:app:url:googleplay" content="paperhub://paperhub.com" />
          <meta name="twitter:app:country" content="US" />
        </Helmet>

        {loading ?
          <div className={styles.LoadingDiv}>
            <Typography sx={{ m: '8px', color: '#1B065E' }} variant='h1'>Loading...</Typography>
            <ClipLoader
              color={"var(--primary-color)"}
              loading={loading}
              size={100}
            />
          </div>
          :
          <div className={styles.PaperThread}>
            <div className={styles.PaperDiv}>
              <div className={styles.UserCard}>
                {userId &&
                  <>
                    {userId !== 'dOIL81did2gwO6Wz5Afbcw' ?
                      <UserCardFeed
                        userId={userId}
                        allUserItems={allUserItems} />
                      :
                      <div className={styles.Info}>
                        <Info sx={{ fontSize: '16px', color: '#5591C9', marginRight: '2px' }} />
                        <span>This paper was sourced from a public website as highly cited.</span>
                      </div>
                    }
                  </>
                }
                <div className={styles.TimeAndModifyButtons}>
                  <div className={styles.PostTime}>
                    {moment(requestTime).local().fromNow()}
                  </div>
                  <div className={styles.LowLevelInteractions}>
                    {(userId === signedInUserId) &&
                      <>
                        <Tooltip title='Edit Paper'>
                          <IconButton onClick={this.handleEditPopupOpen}>
                            <Edit sx={{ fontSize: '24px' }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Delete Paper'>
                          <IconButton onClick={() => this.setDeletePopup(true)}>
                            <DeleteOutline sx={{ fontSize: '24px' }} />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                    <Tooltip title='Copy Post Link'>
                      <IconButton onClick={this.copyLink}>
                        <ContentCopyOutlined sx={{ fontSize: '24px' }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>

              </div>

              <div className={styles.Title}>
                <Typography variant='h3'>
                  {authors.map((author, index) =>
                    `${author}${index === authors.length - 1 ? '' : ', '}`
                  )}, {year}, "{title}"
                </Typography>
              </div>

              {paperImageUrl && <div className={styles.PaperImageDiv}>
                <img className={styles.PaperImage} src={paperImageUrl} />
              </div>}

              <div className={styles.chatGptMainFindingContainer}>
                <div className={styles.chatGptMainFindingHeader}>
                  <Typography variant='subtitle'>Main finding (AI-generated)</Typography>
                </div>
                <div className={styles.chatGptMainFindingText}>
                  {this.state.chatGptMainFinding ?
                    <Typography variant='subtitle'>{this.state.chatGptMainFinding}</Typography>
                    : <Skeleton count={5} style={{ margin: '0px' }} />}
                </div>
              </div>

              {/* <div className={styles.chatGptMainFindingContainer}>
                <div className={styles.chatGptMainFindingHeader}>
                  <Typography variant='subtitle'>Smart summary (AI-generated)</Typography>
                </div>
                <div className={styles.chatGptMainFindingText}>
                  {this.state.chatGptSummary ?
                    <Typography variant='subtitle'>{this.state.chatGptSummary}</Typography>
                    : <Skeleton count={5} style={{ margin: '0px' }} />}
                </div>
              </div> */}
              <div className={styles.AbstractContainer}>
                <div className={styles.AbstractHeader}>
                  <Typography variant='h4'>Abstract</Typography>
                </div>
                <div className={styles.AbstractText}>
                  <Typography variant='body1'>{tldr}</Typography>
                </div>
              </div>

              <div className={styles.footer}>
                {keywords &&
                  <div className={styles.Keywords}>
                    {keywords.map((keyword) => {
                      return (
                        <KeywordSmall
                          key={keyword}
                          label={keyword}
                        />
                      );
                    })}
                  </div>}
                {paperUrl &&
                  <button onClick={this.visitPaper} className={styles.visitPaperButton}>
                    <div>Read paper</div>
                    <div><i className="material-icons-outlined small">open_in_new</i></div>
                  </button>
                }
              </div>
            </div>
            {this.state.numberOfLikes > 0 &&
              <>
                <Divider />
                {/* <Tooltip
                  title={'likes'}
                  placement='top'
                > */}
                  <div className={styles.numLikes}>
                    <span style={{ fontWeight: 'bold', marginRight: '8px' }}>{this.state.numberOfLikes}</span>Like{this.state.numberOfLikes > 1 ? 's' : ''}
                  </div>
                {/* </Tooltip> */}
              </>}
            <Divider />
            <div className={styles.Interactions}>
              {liked ?
                <Tooltip title='Remove Like'>
                  <IconButton className={styles.Active} onClick={this.whenUnlikeClicked}>
                    <Favorite sx={{ fontSize: '32px', color: '#1B065E' }} className={styles.Icons} />
                  </IconButton>
                </Tooltip>
                :
                <Tooltip title='Like'>
                  <IconButton onClick={this.whenLikeClicked}>
                    <FavoriteBorderOutlined sx={{ fontSize: '32px', color: '#1B065E' }} className={styles.Icons} />
                  </IconButton>
                </Tooltip>
              }
              {videoLink &&
                <>
                  <Tooltip title='Watch Video'>
                    <IconButton onClick={this.goToVideo}>
                      <OndemandVideo sx={{ fontSize: '24px', color: 'var(--secondary-color)' }} />
                    </IconButton>
                  </Tooltip>
                </>}
              {saved ?
                <Tooltip title='Remove from Saved Papers'>
                  <IconButton className={styles.Active} onClick={this.whenUnsaveClicked}>
                    <Bookmark sx={{ fontSize: '32px', color: 'var(--secondary-color)' }} className={styles.Icons} />
                  </IconButton>
                </Tooltip>
                :
                <Tooltip title='Save for Later'>
                  <IconButton onClick={this.whenSaveClicked}>
                    <BookmarkBorderOutlined sx={{ fontSize: '32px' }} className={styles.Icons} />
                  </IconButton>
                </Tooltip>
              }

              {/* <Tooltip title='Comment'>
                <IconButton onClick={this.commentButtonClicked}>
                  <CommentOutlined sx={{ fontSize: '32px' }} />
                </IconButton>
              </Tooltip> */}

            </div>
            <div className={styles.Comments}>
              <>
                <PaperComments paperId={this.props.match.params.paperId} ref={this.paperCommentsRef} />
              </>
            </div>
          </div>
        }
        <DialogBox
          open={editPaperPopup}
          handleClose={this.handlePaperPopupClose}
          title='Edit Paper'
        >
          <CreatePaperForm
            closeCreatePaperPopup={this.handlePaperPopupClose}
            paperId={paperId}
            paperState={this.state}
          />
        </DialogBox>
        <DialogBox
          open={this.state.checkDeletePopup}
          handleClose={() => this.setDeletePopup(false)}
          title='Delete paper?'
        >
          <div className={styles.PopupButtonDiv}>
            <Button
              type="button"
              variant='contained'
              className={styles.goBackButton}
              onClick={() => this.setDeletePopup(false)}
              sx={{
                background: 'white',
                border: '2px solid #FEBA3F',
                ":hover": {
                  bgcolor: '#eeeeee',
                }
              }}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              className={styles.discardPopupButton}
              sx={{ color: '#1B065E', ml: '12px', }}
              onClick={this.deletePaper}>
              Delete
            </Button>
          </div>
        </DialogBox>
        <ToastContainer />
      </div >
    )
  }
}

export default withRouter(PaperThread)
