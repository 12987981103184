import React, { Component } from 'react'
import styles from './PeopleStyles.module.css'
import Person from '../Person/Person';


const respectablePrefixes = new Set(['Dr.', 'Prof.', 'Rev.']);

export default class People extends Component {

  componentDidMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  render() {
    const { allUserItems, pageTitle, drawerWidth, history } = this.props;
    return (
      <div className={styles.People}>
        {allUserItems.map((user) =>
          <Person
            key={user.UserId}
            userId={user.UserId}
            firstName={user.FirstName}
            lastName={user.LastName}
            prefix={respectablePrefixes.has(user.Prefix) ? user.Prefix : ''}
            suffix={user.Suffix}
            profilePictureUrl={user.ProfilePictureUrl}
            jobTitle={user.JobTitle}
            organization={user.Organization}
            areasOfInterest={user.AreasOfInterest}
          />
        )}
      </div>
    )
  }
}