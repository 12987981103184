import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import Amplify from "aws-amplify";
import React, { useState, useEffect, useRef } from 'react';
import _config from "./config.js";
import awsExports from "../../aws-exports.js";
import styles from './signupStyles.module.css';
import { Link, useHistory } from "react-router-dom";
import { AppBar, Box, Button, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import Logo from '../components/logo/logo.jsx';

Amplify.configure(awsExports);

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = window.innerWidth <= 768;

  const emailRef = useRef(null);

  const poolData = {
    UserPoolId: _config.cognito.userPoolId,
    ClientId: _config.cognito.userPoolClientId,
  };
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

  const history = useHistory();
  const theme = useTheme();

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const register = (event) => {
    event.preventDefault();
    // if (!email.endsWith('.edu') && !email.endsWith('.ac.in') && !email.endsWith('.edu.in')) {
    //   toast.error('Emails must end in .edu, .ac.in, or .edu.in.');
    //   return;
    // }
    // if (!emailRef.current.checkValidity()) {
    //   return;
    // }
    if (password !== confirmPassword) {
      toast.error('Passwords do not match.')
      return;
    }

    var dataEmail = {
      Name: 'email',
      Value: email,
    };
    var attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);

    userPool.signUp(toUsername(email), password, [attributeEmail], null, signUpCallback);
  };

  const signUpCallback = (err, result) => {
    if (!err) {
      registerSuccess(result);
    } else {
      toast.error(err.message);
    }
  };

  const registerSuccess = (result) => {
    history.push({
      pathname: '/verify',
      state: { email, password, confirmPassword, },
    });
  };

  const toUsername = (email) => {
    return email.replace('@', '-at-');
  };

  return (
    <div className={styles.signUpContainer}>
      {isMobile && <AppBar position="fixed" elevation={0} style={{ zIndex: 1201, background: theme.palette.primary.mainGradient }}
      >
        <Logo width={'240px'} centerImg></Logo>
      </AppBar>}
      {!isMobile && <Box sx={{
        background: 'linear-gradient(to left, #FE9D44, #FFF500)', height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', color: 'black', ml: '100px' }}>
          <Logo></Logo>
          <Typography variant='body'>
            Discover. Discuss. Develop.
          </Typography>
        </Box>
      </Box>}
      <div className={styles.signUpHolder}>
        <div className={styles.signUpUi}>
          <form id="signUpForm" onSubmit={(e) => this.register(e)}>
            <Box sx={{ mb: !isMobile ? '204px' : '0px' }}>
              <Typography variant='h1'>
                Sign up
              </Typography>
            </Box>
            {/* <Typography variant="subtitle1">University emails only</Typography> */}
            <TextField
              id='emailAddress'
              InputLabelProps={{ shrink: true }}
              autoRef={emailRef}
              label="Email"
              variant='standard'
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              margin='normal'
              type='email'
              sx={{ width: '100%' }}
              required
            />
            <TextField
              id='password'
              InputLabelProps={{ shrink: true }}
              label="Password"
              variant='standard'
              autoFocus
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              margin='normal'
              type='password'
              sx={{ width: '100%' }}
              required
            />
            {/* {confirmPassword && !(password === confirmPassword) &&
              <div>
                <p style={{ color: 'red', margin: '2px', fontSize: '12px' }}>
                  Passwords do not match.
                </p>
              </div>
            }
            {confirmPassword && (password === confirmPassword) &&
              <div>
                <p style={{ color: 'green', margin: '2px', fontSize: '12px' }}>
                  Passwords match!
                </p>
              </div>
            } */}
            <TextField
              id='confirmpassword'
              InputLabelProps={{ shrink: true }}
              label="Confirm password"
              variant='standard'
              autoFocus
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              margin='normal'
              type='password'
              sx={{ width: '100%' }}
              required
            />
            <Button
              sx={{
                background: '#FEBA3F',
                color: '#1B065E',
                width: '100%',
                boxShadow: '0px 0px 12px 0px #1B065E26',
                textTransform: 'none',
              }} type="submit" onClick={(e) => register(e)}>
              <Typography variant='subtitle1'>
                Continue
              </Typography>
            </Button>
          </form>
          <Box sx={{ color: '#311860', textAlign: 'center', padding: '8px 0px' }}>
            <Typography variant='subtitle1'>or</Typography>
          </Box>
          <Link to='/signin'>
            <Button
              sx={{
                background: theme.palette.secondary.light,
                border: '3px solid #FEBA3F',
                color: '#1B065E',
                width: '100%',
                boxShadow: '0px 0px 12px 0px #1B065E26',
                textTransform: 'none',
              }} type="button">
              <Typography variant='subtitle1'>
                Log in
              </Typography>
            </Button>
          </Link>

        </div>
        <ToastContainer />
      </div>
      <ToastContainer></ToastContainer>
    </div>
  );
}

export default Signup;