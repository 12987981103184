import React, { useState, useEffect } from 'react';
import Logo from '../logo/logo.jsx';
import styles from './signedInNavStyles.module.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import _config from "../../signup/config";
import getProfilePicture from '../getProfilePicture/getProfilePicture'

class SignedInNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            click: false,
            avatar: true,
            userId: '',
            profilePictureUrl: '',
            navbar: false,
            firstName: '',
            lastName: '',
        };
        this.handleClick = this.handleClick.bind(this);
        this.closeMobileMenu = this.closeMobileMenu.bind(this);
        this.signOut = this.signOut.bind(this);
        this.showAvatar = this.showAvatar.bind(this);
        this.getUser = this.getUser.bind(this);
        this.handleNavbarScroll = this.handleNavbarScroll.bind(this);

        const poolData = {
            UserPoolId: _config.cognito.userPoolId,
            ClientId: _config.cognito.userPoolClientId,
        };
        this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    }

    componentDidMount() {
        this.getUser();
        window.addEventListener('resize', this.showAvatar);
        window.addEventListener('scroll', this.handleNavbarScroll);
    }

    handleClick() {
        this.setState({ click: !this.state.click });
    }

    closeMobileMenu(click) {
        this.setState({ click: false });
    };

    showAvatar() {
        this.setState({ avatar: (window.innerWidth > 960) });
    }

    getUser() {
        var cognitoUser = this.userPool.getCurrentUser();
        if (cognitoUser) {
            let self = this;
            cognitoUser.getSession(function sessionCallback(err, session) {
                if (err) {
                    console.log(err);
                } else if (!session.isValid()) {
                    console.log('invalid sess');
                } else {
                    cognitoUser.getUserAttributes(function (err, result) {
                        if (err) {
                            console.log(err);
                        } else {
                            if (result[3].Name === 'custom:userId' && result[3].Value) {
                                self.setState({ userId: result[3].Value });
                                const authToken = session.getIdToken().getJwtToken();
                                if (self.state.userId) {
                                    const api = _config.api.invokeUrl + '/getuser?userId=' + self.state.userId;
                                    const axiosConfig = {
                                        headers: {
                                            "Authorization": authToken,
                                        }
                                    };
                                    axios
                                        .get(api, axiosConfig)
                                        .then((response) => {
                                            if (response.data.profilePictureUrl) {
                                                const url = getProfilePicture(self.state.userId);
                                                self.setState({ profilePictureUrl: url });
                                            }
                                            self.setState({
                                                firstName: response.data.firstName,
                                                lastName: response.data.lastName
                                            });
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                }
                            }
                        }
                    }
                    );
                }
            });

        }
    }

    signOut() {
        this.userPool.getCurrentUser().signOut();
        window.location.href = '/';
    }

    handleNavbarScroll = () => {
        if (window.scrollY >= 10) {
            this.setState({ navbar: true });
        } else {
            this.setState({ navbar: false });
        }
    };



    render() {
        return (
            <>
                <IconContext.Provider value={{ color: '#000' }}>
                    <div className={this.state.navbar ? styles.navbar + ' ' + styles.active : styles.navbar}>
                        <div className={styles.navbarContainer + ' ' + styles.container}>
                            <Logo onClick={this.closeMobileMenu} />
                            <div className={styles.menuIcon} onClick={this.handleClick}>
                                {this.state.click ? <FaTimes /> : <FaBars />}
                            </div>
                            <h2>{this.props.title}</h2>
                            <ul className={this.state.click ? styles.navMenu + ' ' + styles.active : styles.navMenu}>
                                <li className={styles.navItem}>
                                    <Link to='/createEvent'
                                        className={styles.navLinks}
                                        style={{ color: 'blue' }}
                                        onClick={this.closeMobileMenu}
                                    >
                                        Host an Event
                                    </Link>
                                </li>
                                <li className={styles.navItem}>
                                    <Link to='/peerReview' className={styles.navLinks} onClick={this.closeMobileMenu}>
                                        Leave Feedback
                                    </Link>
                                </li>
                                <li className={styles.navItem}>
                                    <Link to='/faqs' className={styles.navLinks} onClick={this.closeMobileMenu}>
                                        FAQs
                                    </Link>
                                </li>
                                <li className={styles.navItem}>
                                    <Link className={styles.navLinks} onClick={this.signOut}>
                                        Sign Out
                                    </Link>
                                </li>
                                <li className={styles.navItem}>
                                    {this.state.avatar ? (
                                        <Link className={styles.navLinks} onClick={() => { window.location.href = '/user/' + this.state.userId }}>
                                            {this.state.profilePictureUrl
                                                ? (<img className={styles.userProfilePictureImage} src={this.state.profilePictureUrl} alt='Profile Picture' />)
                                                : (<div className={styles.avatarFill}>
                                                    {this.state.firstName ? this.state.firstName.charAt(0).toUpperCase() : ''}{this.state.lastName ? this.state.lastName.charAt(0).toUpperCase() : ''}
                                                </div>)}
                                        </Link>
                                    ) : (
                                        <Link className={styles.navLinks} onClick={() => { window.location.href = '/user/' + this.state.userId; this.closeMobileMenu() }}>
                                            Profile
                                        </Link>
                                    )}

                                </li>
                            </ul>
                        </div>

                    </div>
                </IconContext.Provider>
            </ >
        );
    }
}

export default SignedInNav
