import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import _config from "../signup/config";
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { gapi, CLIENT_ID, API_KEY, DISCOVERY_DOCS, SCOPES } from '../components/GoogleCalAPI/APIvariables';

export default class RequestService {

  constructor() {
    const poolData = {
      UserPoolId: _config.cognito.userPoolId,
      ClientId: _config.cognito.userPoolClientId,
    };
    this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  }

  async searchEvents(data, callback) {
    var onSuccess = function registerSuccess(result) {
      var confirmation = ('search events complete!');
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };

    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const loggedInUserId = result[3].Value;
              const api = _config.api.invokeUrl + '/searchevents?searchQuery=' + data.searchQuery + '&userId=' + loggedInUserId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .get(api, axiosConfig)
                .then((response) => {
                  let newsFeedEvents = response.data;
                  if (callback) {
                    callback(newsFeedEvents);
                  }

                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
        }
      });
    }
  }

  async searchJobs(data, callback) {

    const cognitoUser = this.userPool.getCurrentUser();
    var onSuccess = function registerSuccess(result) {
      var confirmation = ('search jobs complete!');
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };

    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const loggedInUserId = result[3].Value;
              const api = _config.api.invokeUrl + '/searchjobs?searchQuery=' + data.searchQuery + '&userId=' + loggedInUserId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .get(api, axiosConfig)
                .then((response) => {
                  let loadedJobItems = response.data;
                  const scrollPosition = sessionStorage.getItem("scrollPosition");
                  if (scrollPosition) {
                    window.scrollTo(0, parseInt(scrollPosition));
                    sessionStorage.removeItem("scrollPosition");
                  }
                  if (callback) {
                    callback(loadedJobItems);
                  }

                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
        }
      });
    }
  }

  async searchPapers(data, callback) {

    const cognitoUser = this.userPool.getCurrentUser();
    var onSuccess = function registerSuccess(result) {
      var confirmation = ('search papers complete!');
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };

    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const loggedInUserId = result[3].Value;
              const api = _config.api.invokeUrl + '/searchpapers?searchQuery=' + data.searchQuery + '&userId=' + loggedInUserId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .get(api, axiosConfig)
                .then((response) => {
                  const loadedPaperItems = response.data;
                  const scrollPosition = sessionStorage.getItem("scrollPosition");
                  if (scrollPosition) {
                    window.scrollTo(0, parseInt(scrollPosition));
                    sessionStorage.removeItem("scrollPosition");
                  }
                  if (callback) {
                    callback(loadedPaperItems);
                  }

                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
        }
      });
    }
  }

  addToCalendar(self) {
    const userPool = this.userPool;
    gapi.load('client:auth2', () => {

      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })

      gapi.client.load('calendar', 'v3');

      // Event's time in local.
      gapi.auth2.getAuthInstance().signIn()
        .then(() => {
          const event = {
            'summary': self.state.title,
            'location': self.state.venue.formatted_address ? self.state.venue.formatted_address + ' ' + (self.state.meetingLink ? self.state.meetingLink : '')
              : (self.state.meetingLink ? self.state.meetingLink : ''),
            'description': self.state.description,
            'start': {
              'dateTime': moment(self.state.startDate).format().substring(0, moment(self.state.startDate).format().length - 6),
              'timeZone': moment.tz.guess(),
            },
            'end': {
              'dateTime': moment(self.state.endDate).format().substring(0, moment(self.state.startDate).format().length - 6),
              'timeZone': moment.tz.guess(),
            },
            'reminders': {
              'useDefault': false,
              'overrides': [
                { 'method': 'email', 'minutes': 24 * 60 },
                { 'method': 'popup', 'minutes': 10 }
              ]
            }
          };

          const request = gapi.client.calendar.events.insert({
            'calendarId': 'primary',
            'resource': event
          });
          request.execute(event => {
            window.open(event.htmlLink);
            this.addToCalendarBackend(userPool, self);
            toast("Event added to your calendar!");
          });
        })
    })
  }

  addToCalendarBackend(userPool, self) {
    self.setState({ addedToCalendar: true });
    var onSuccessAdded = function registerSuccess(result) {
      var eventId = result.data.eventId;
      var userId = result.data.userId;
      var confirmation = ('talk added to event calendar database!');
      if (confirmation) {
        self.setState({ addedToCalendar: true });
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
      self.setState({ addedToCalendar: false });
    };
    var cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const data = {
                "userId": result[3].Value,
                "eventId": self.state.eventId,
              };
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/addeventtousercalendar';
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .post(api, data, axiosConfig)
                .then((response) => {
                  onSuccessAdded(response)
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          });
        }
      }
      );
    }
  }


  /**
   * @param {Object} data Information about the event.
   * @param {string} data.eventId The event id associated with the event.
   * @param {Object} self The component that is calling this method - gives access to the props and state.
   * @returns {Promise<Object>} JSON
   */
  // returns false if removed and true if not removed.
  async removeFromCalendar(data, self) {
    const cognitoUser = this.userPool.getCurrentUser();
    var onSuccessDeleted = function registerSuccess(result) {
      var eventId = result.data.eventId;
      var userId = result.data.userId;
      var confirmation = ('event removed from the liked events database.');
      if (confirmation) {
        self.setState({ addedToCalendar: false });
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
      self.setState({ addedToCalendar: true });
    };

    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              let currentUserId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/deleteeventfromusercalendar?userId=' + currentUserId + '&eventId=' + data.eventId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .delete(api, axiosConfig)
                .then((response) => {
                  onSuccessDeleted(response)
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          })
        }
      });
    }
  }

  /**
 * Callback to add new comment to UI.
 *
 * @callback addNewCommentToUiCallback
 * @param {Object} newComment - An Object representing the new comment.
 */

  /**
   * @param {Object} data Information about the user.
   * @param {string} data.eventId The event id associated with the comment.
   * @param {string} data.comment The actual message of the comment .
   * @param {addNewCommentToUiCallback} callback callback to add new comment to UI.
   * @returns {Promise<Object>} JSON
   */
  async addCommentToEvent(data, callback) {

    const cognitoUser = this.userPool.getCurrentUser();
    var onSuccess = function registerSuccess(result) {
      var eventCommentId = result.data.eventCommentId;
      console.log('event comment id is ' + eventCommentId);
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };


    if (cognitoUser) {
      return cognitoUser.getSession(async function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          return cognitoUser.getUserAttributes(async function (err, result) {
            if (err) {
              console.log(err);
            } else {
              data.userId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/addcommenttoevent';
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              return await axios
                .post(api, data, axiosConfig)
                .then((response) => {
                  onSuccess(response);
                  callback(response.data);
                  return response;
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                  return error;
                });
            }
          });
        }
      });
    }
  }

  /**
* Callback to add new comment to UI.
*
* @callback addNewCommentToUiCallback
* @param {Object} newComment - An Object representing the new comment.
*/
  /**
   * @param {Object} data Information about the user.
   * @param {string} data.paperId The paper id associated with the comment.
   * @param {string} data.comment The actual message of the comment .
   * @param {string} data.userId The user id of the user who is commenting.
   * @param {addNewCommentToUiCallback} callback callback to add new comment to UI.
   */
  async addCommentToPaper(data, callback) {
    const cognitoUser = this.userPool.getCurrentUser();

    var onSuccess = function registerSuccess(result) {
      var paperCommentId = result.data.paperCommentId;
      console.log('paper comment id is ' + paperCommentId);
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };


    if (cognitoUser) {
      cognitoUser.getSession(async function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(async function (err, result) {
            if (err) {
              console.log(err);
            } else {
              data.userId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/addcommenttopaper';
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              await axios
                .post(api, data, axiosConfig)
                .then((response) => {
                  onSuccess(response);
                  callback(response.data);
                  return response;
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                  return error;
                });
            }
          });
        }
      });
    }
  }

  /**
   * @param {Object} data Information about the event.
   * @param {string} data.eventId The event id associated with the event.
   * @param {Object} self The component that is calling this method - gives access to the props and state.
   * @returns {Promise<Object>} JSON
   */
  // returns false if removed and true if not removed.
  async removeSavedEvent(data, self) {
    const cognitoUser = this.userPool.getCurrentUser();
    var onSuccessUnsaved = function registerSuccess(result) {
      var eventId = result.data.eventId;
      var userId = result.data.userId;
      var confirmation = ('event removed from the saved events database.');
      if (confirmation) {
        self.setState({ saved: false });
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
      self.setState({ saved: true });
    };


    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              let currentUserId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/removesavedevent?userId=' + currentUserId + '&eventId=' + data.eventId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .delete(api, axiosConfig)
                .then((response) => {
                  onSuccessUnsaved(response)
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          })
        }
      });
    }
  }
  /**
   * @param {Object} data Information about the paper.
   * @param {string} data.paperId The paper id associated with the paper.
   * @param {Object} self The component that is calling this method - gives access to the props and state.
   * @returns {Promise<Object>} JSON
   */
  // returns false if removed and true if not removed.
  async removeSavedPaper(data, self) {
    const cognitoUser = this.userPool.getCurrentUser();
    var onSuccessUnsaved = function registerSuccess(result) {
      var paperId = result.data.paperId;
      var userId = result.data.userId;
      var confirmation = ('paper removed from the saved papers database.');
      if (confirmation) {
        self.setState({ saved: false });
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
      self.setState({ saved: true });
    };


    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              let currentUserId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/removesavedpaper?userId=' + currentUserId + '&paperId=' + data.paperId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .delete(api, axiosConfig)
                .then((response) => {
                  onSuccessUnsaved(response)
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          })
        }
      });
    }
  }

  /**
   * @param {Object} data Information about the event.
   * @param {string} data.eventId The event id associated with the event.
   * @param {Object} self The component that is calling this method - gives access to the props and state.
   * @returns {Promise<Object>} JSON
   */
  async addSavedEvent(data, self) {
    const cognitoUser = this.userPool.getCurrentUser();
    var onSuccessSaved = function registerSuccess(result) {
      var eventId = result.data.eventId;
      var userId = result.data.userId;
      var confirmation = ('event put into the saved events database!');
      if (confirmation) {
        self.setState({ saved: true });
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
      self.setState({ saved: false });
    };

    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const apiData = {
                "userId": result[3].Value,
                "eventId": data.eventId,
              };
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/addsavedevent';
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .post(api, apiData, axiosConfig)
                .then((response) => {
                  onSuccessSaved(response)
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          });
        }
      }
      );
    }
  }
  /**
   * @param {Object} data Information about the paper.
   * @param {string} data.paperId The paper id associated with the paper.
   * @param {Object} self The component that is calling this method - gives access to the props and state.
   * @returns {Promise<Object>} JSON
   */
  async addSavedPaper(data, self) {
    const cognitoUser = this.userPool.getCurrentUser();
    var onSuccessSaved = function registerSuccess(result) {
      var paperId = result.data.paperId;
      var userId = result.data.userId;
      var confirmation = ('paper put into the saved papers database!');
      if (confirmation) {
        self.setState({ saved: true });
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
      self.setState({ saved: false });
    };

    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const apiData = {
                "userId": result[3].Value,
                "paperId": data.paperId,
              };
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/addsavedpaper';
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .post(api, apiData, axiosConfig)
                .then((response) => {
                  onSuccessSaved(response)
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          });
        }
      }
      );
    }
  }

  /**
   * @param {Object} data Information about the paper.
   * @param {string} data.paperId The paper id associated with the paper.
   * @param {Object} self The component that is calling this method - gives access to the props and state.
   * @returns {Promise<Object>} JSON
   */
  // returns false if removed and true if not removed.
  async removeLikedPaper(data, self) {
    const cognitoUser = this.userPool.getCurrentUser();
    var onSuccessUnliked = function registerSuccess(result) {
      var paperId = result.data.paperId;
      var userId = result.data.userId;
      var confirmation = ('paper removed from the liked papers database.');
      console.log(confirmation)
      if (confirmation) {
        self.setState({ liked: false });
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
      self.setState({ liked: true });
    };


    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              let currentUserId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/removelikedpaper?userId=' + currentUserId + '&paperId=' + data.paperId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .delete(api, axiosConfig)
                .then((response) => {
                  onSuccessUnliked(response)
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          })
        }
      });
    }
  }

  /**
   * @param {Object} data Information about the paper.
   * @param {string} data.paperId The paper id associated with the paper.
   * @param {Object} self The component that is calling this method - gives access to the props and state.
   * @returns {Promise<Object>} JSON
   */
  async addLikedPaper(data, self) {
    const cognitoUser = this.userPool.getCurrentUser();
    var onSuccessLiked = function registerSuccess(result) {
      var paperId = result.data.paperId;
      var userId = result.data.userId;
      var confirmation = ('paper put into the liked papers database!');
      if (confirmation) {
        self.setState({ liked: true });
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
      self.setState({ liked: false });
    };

    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const apiData = {
                "userId": result[3].Value,
                "paperId": data.paperId,
              };
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/addlikedpaper';
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .post(api, apiData, axiosConfig)
                .then((response) => {
                  onSuccessLiked(response)
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          });
        }
      }
      );
    }
  }

  /**
   * @param {Object} data Information about the paper.
   * @param {string} data.paperId The paper id associated with the paper.
   * @param {Object} self The component that is calling this method - gives access to the props and state.
   * @returns {Promise<Object>} JSON
   */
  // returns false if removed and true if not removed.
  async removeReadPaper(data, self) {
    const cognitoUser = this.userPool.getCurrentUser();
    var onSuccessUnread = function registerSuccess(result) {
      var paperId = result.data.paperId;
      var userId = result.data.userId;
      var confirmation = ('paper removed from the read papers database.');
      if (confirmation) {
        self.setState({ read: false });
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
      self.setState({ read: true });
    };


    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              let currentUserId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/removereadpaper?userId=' + currentUserId + '&paperId=' + data.paperId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .delete(api, axiosConfig)
                .then((response) => {
                  onSuccessUnread(response)
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          })
        }
      });
    }
  }

  /**
   * @param {Object} data Information about the paper.
   * @param {string} data.paperId The paper id associated with the paper.
   * @param {Object} self The component that is calling this method - gives access to the props and state.
   * @returns {Promise<Object>} JSON
   */
  async addReadPaper(data, self) {
    var onSuccessRead = function registerSuccess(result) {
      var paperId = result.data.paperId;
      var userId = result.data.userId;
      var confirmation = ('paper put into the read papers database!');
      if (confirmation) {
        self.setState({ read: true });
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
      self.setState({ read: false });
    };

    const cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const apiData = {
                "userId": result[3].Value,
                "paperId": data.paperId,
              };
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/addreadpaper';
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .post(api, apiData, axiosConfig)
                .then((response) => {
                  onSuccessRead(response)
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          });
        }
      }
      );
    }
  }


  /**
   * @param {Object} data Information about the paper.
   * @param {Object} self The component that is calling this method - gives access to the props and state.
   * @returns {Promise<Object>} JSON
   */
  async getNumberOfPaperLikes(data, self) {
    var onFailure = function registerFailure(err) {
      alert(err.message);
    };

    const cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + `/getnumberofpaperlikes?paperId=${data.paperId}`;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .get(api, axiosConfig)
                .then((response) => {
                  if (response.data) {
                    self.setState({
                      usersWhoLikedPaperItems: response.data.Items,
                      numberOfLikes: response.data.Count,
                    });
                  }
                  return 
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          })
        }
      });
    }
  }

  /**
   * @param {Object} data Information about the event.
   * @param {string} data.eventId The event id associated with the event.
   * @param {Object} self The component that is calling this method - gives access to the props and state.
   * @returns {Promise<Object>} JSON
   */
  async getNumberOfEventLikes(data, self) {
    var onFailure = function registerFailure(err) {
      alert(err.message);
    };

    const cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + `/getnumberofeventlikes?eventId=${data.eventId}`;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .get(api, axiosConfig)
                .then((response) => {
                  if (response.data) {
                    self.setState({
                      usersWhoLikedEventItems: response.data.Items,
                      numberOfLikes: response.data.Count,
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                });
            }
          })
        }
      });
    }
  }

  /**
   * Get the current logged in user's group.
 * @param {Object} userId the user id
 * @param {string} data.paperId The paper id associated with the paper.
 * @param {Object} self The component that is calling this method - gives access to the props and state.
 * @returns {Promise<Object>} JSON
 */
  async getSelfUserGroups(self) {
    var onFailure = function registerFailure(err) {
      alert(err.message);
    };
    const cognitoUser = this.userPool.getCurrentUser();
    if (!cognitoUser) {
      return [];
    }

    cognitoUser.getSession(function sessionCallback(err, session) {
      if (err) {
        console.log(err);
      } else if (!session.isValid()) {
        console.log('invalid sess');
      } else {
        cognitoUser.getUserAttributes(function (err, result) {
          if (err) {
            console.log(err);
          } else {
            const authToken = session.getIdToken().getJwtToken();
            const loggedInUserId = result[3].Value;

            const api = _config.api.invokeUrl + `/getusergroups?userId=${loggedInUserId}`;
            const axiosConfig = {
              headers: {
                "Authorization": authToken,
              }
            };
            axios
              .get(api, axiosConfig)
              .then((response) => {
                if (response.data) {
                  self.setState({
                    userGroups: response.data,
                    loadingUserGroups: false
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                onFailure(error);
              });
          }
        })
      }
    });
  }

  async getPaperAPI(data, self, callback) {

    var onSuccess = function registerSuccess(result) {
      var confirmation = ('get account complete!');
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };
    const cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const loggedInUserId = result[3].Value;
              const api = _config.api.invokeUrl + '/getpaper?paperId=' + data.paperId + '&userId=' + loggedInUserId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .get(api, axiosConfig)
                .then((response) => {
                  self.setState({
                    paperUrl: response.data.paperUrl,
                    title: response.data.title,
                    authors: response.data.authors,
                    year: response.data.year,
                    tldr: response.data.tldr,
                    keywords: response.data.keywords,
                    paperImageUrl: response.data.paperImageUrl,
                    userId: response.data.userId,
                    paperId: response.data.PaperId,
                    semanticPaperId: response.data.semanticPaperId,
                    requestTime: response.data.RequestTime,
                    videoLink: response.data.videoLink,
                    saved: response.data.saved,
                    liked: response.data.liked,
                    read: response.data.read,
                    activeComp: response.data.activeComp,
                    groupId: response.data.groupId,
                  });
                  if (callback) {
                    callback();
                  }

                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
        }
      });
    }
  }

  async deleteEvent(data, callback) {
    var onSuccess = function registerSuccess(result) {
      var eventId = result.data.eventId;
      var startDate = result.data.startDate;
      var confirmation = ('event id deleted!');
      if (confirmation && callback) {
        callback();
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };

    const cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();

          const api = _config.api.invokeUrl + '/deleteevent?eventId=' + data.eventId;
          const axiosConfig = {
            headers: {
              "Authorization": authToken,
            }
          };
          axios
            .delete(api, axiosConfig)
            .then((response) => {
              onSuccess(response);
            })
            .catch((error) => {
              console.log(error);
              onFailure(error);
            });
        }
      });
    }
  }

  async deletePaper(data, callback) {
    var self = this;
    var onSuccess = function registerSuccess(result) {
      var eventId = result.data.paperId;
      var confirmation = ('paper id deleted!');
      if (confirmation && callback) {
        callback();
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };

    const cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();

          const api = _config.api.invokeUrl + '/deletepaper?paperId=' + data.paperId;
          const axiosConfig = {
            headers: {
              "Authorization": authToken,
            }
          };
          axios
            .delete(api, axiosConfig)
            .then((response) => {
              onSuccess(response);
            })
            .catch((error) => {
              console.log(error);
              onFailure(error);
            });
        }
      });
    }
  }

  /**
   * Callback to add new comment to UI.
   *  @callback getUserLiteCallback
   * @param {Object} userData - An Object representing the user data.
   */
  /**
   * @param {string} userId The user id associated with the user to get.
   * @param {getUserLiteCallback} callback callback to add new comment to UI.
   * */
  async getUserLite(userId, callback) {
    const cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser) {
      return cognitoUser.getSession(async function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();
          const api = _config.api.invokeUrl + '/getuserlite?userId=' + userId;
          const axiosConfig = {
            headers: {
              "Authorization": authToken,
            }
          };
          return await axios
            .get(api, axiosConfig)
            .then((response) => {
              if (callback && response.data) {

                callback(response.data);
              }
              return response;
            })
            .catch((error) => {
              console.log(error);
              return error;
            });
        }
      });
    } else {
      return [];
    }
  }

  /**
  * Callback to add new comment to UI.
  *
  * @callback addCommentsToUiCallback
  * @param {Object} newComment - An Object representing the new comment.
  */
  /**
   * @param {string} eventId The event id associated with the comments to get.
   * @param {addCommentsToUiCallback} callback callback to add new comment to UI.
   */
  async getCommentsForEvent(eventId, callback) {

    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession(async function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();
          const api = _config.api.invokeUrl + '/getcommentsforevent?eventId=' + eventId;
          const axiosConfig = {
            headers: {
              "Authorization": authToken,
            }
          };
          axios
            .get(api, axiosConfig)
            .then((response) => {
              if (response.data && response.data.Items) {
                const commentItems = response.data.Items;
                // TODO(mike): remove sort once relation db.
                // commentItems.sort((a, b) => a.commentCreationTime - b.commentCreationTime);
                callback(commentItems);
              }
              return response;
            })
            .catch((error) => {
              console.log(error);
              return error;
            });
        }
      });
    }
  }

  /**
  * Callback to add new comment to UI.
  *
  * @callback addCommentsToUiCallback
  * @param {Object} newComment - An Object representing the new comment.
  */
  /**
   * @param {string} paperId The paper id associated with the comments to get.
   * @param {addCommentsToUiCallback} callback callback to add new comment to UI.
   */
  async getCommentsForPaper(paperId, callback) {
    const cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession(async function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();
          const api = _config.api.invokeUrl + '/getcommentsforpaper?paperId=' + paperId;
          const axiosConfig = {
            headers: {
              "Authorization": authToken,
            }
          };
          axios
            .get(api, axiosConfig)
            .then((response) => {
              if (response.data && response.data.Items) {
                const commentItems = response.data.Items;
                // commentItems.sort((a, b) => a.commentCreationTime - b.commentCreationTime);
                callback(commentItems);
              }
              return response;
            })
            .catch((error) => {
              console.log(error);
              return error;
            });
        }
      });
    }
  }

  /**
   * Callback to add increment number of likes and unlike comment in ui UI.
   * @callback setNumLikesAndSelfUserLikedCallback
   * @param {Object} newComment - An Object representing the new comment.
   */
  /**
   * @param {string} eventCommentId The event comment id associated with the comment to get.
   * @param {setNumLikesAndSelfUserLikedCallback} callback callback to add new comment to UI.
  */
  async getLikesForEventComment(eventCommentId, callback) {
    const cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser) {
      return await cognitoUser.getSession(async function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          return await cognitoUser.getUserAttributes(async function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const selfUserId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();
              const api = _config.api.invokeUrl + '/getlikesforeventcomment?eventCommentId=' + eventCommentId + '&selfUserId=' + selfUserId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              return await axios
                .get(api, axiosConfig)
                .then((response) => {
                  callback(response);
                  return response;
                })
                .catch((error) => {
                  console.log(error);
                  return error;
                });
            }
          });
        }
      })
    }
  }

  /**
   * Callback to add increment number of likes and unlike comment in ui UI.
   * @callback setNumLikesAndSelfUserLikedCallback
   * @param {Object} newComment - An Object representing the new comment.
   */
  /**
   * @param {string} paperCommentId The paper comment id associated with the comment to get.
   * @param {setNumLikesAndSelfUserLikedCallback} callback callback to add new comment to UI.
   */
  async getLikesForPaperComment(paperCommentId, callback) {
    const cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession(async function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(async function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const selfUserId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();
              const api = _config.api.invokeUrl + '/getlikesforpapercomment?paperCommentId=' + paperCommentId + '&selfUserId=' + selfUserId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .get(api, axiosConfig)
                .then((response) => {
                  callback(response);
                  return response;
                })
                .catch((error) => {
                  console.log(error);
                  return error;
                });
            }
          });

        }
      });
    }
  }

  /**
  * Callback to add increment number of likes and unlike comment in UI.
  *
  * @callback unlikeCommentUpdateUiCallback
  * @param {Object} newComment - An Object representing the new comment.
  */
  /**
   * @param {Object} data Information about the event comment.
   * @param {string} data.eventCommentId The event comment id associated with the comment.
   * @param {unlikeCommentUpdateUiCallback} callback callback to unlike comment in UI.
*/
  async unlikeEventComment(data, callback) {
    callback();
    let cognitoUser = this.userPool.getCurrentUser();

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };
    if (cognitoUser) {
      return await cognitoUser.getSession(async function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          return await cognitoUser.getUserAttributes(async function (err, result) {
            if (err) {
              console.log(err);
            } else {
              console.log(result);
              let currentUserId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/unlikeeventcomment?userId=' + currentUserId + '&eventCommentId=' + data.eventCommentId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              return await axios
                .delete(api, axiosConfig)
                .then((response) => {
                  return response;
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                  return error;
                });
            }
          })
        }
      });
    }
  }

  /**
  * Callback to add increment number of likes and unlike comment in UI.
  *
  * @callback likeCommentUpdateUiCallback
  * @param {Object} newComment - An Object representing the new comment.
  */
  /**
   * @param {Object} data Information about the event comment.
   * @param {string} data.eventCommentId The event comment id associated with the comment.
   * @param {likeCommentUpdateUiCallback} callback callback to add new comment to UI.
   */
  async likeEventComment(data, callback) {
    callback();
    var onFailure = function registerFailure(err) {
      alert(err.message);
    };

    const cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser) {
      return await cognitoUser.getSession(async function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          return await cognitoUser.getUserAttributes(async function (err, result) {
            if (err) {
              console.log(err);
            } else {
              data.userId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/likeeventcomment';
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              return await axios
                .post(api, data, axiosConfig)
                .then((response) => {
                  console.log(response);
                  return response;
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                  return error;
                });
            }
          });
        }
      }
      );
    }
  }

  /**
  * Callback to add increment number of likes and unlike comment in UI.
  *
  * @callback unlikeCommentUpdateUiCallback
  * @param {Object} newComment - An Object representing the new comment.
  */
  /**
  * @param {Object} data Information about the paper comment.
  * @param {string} data.paperCommentId The paper comment id associated with the comment.
  * @param {unlikeCommentUpdateUiCallback} callback callback to unlike a comment in UI.
  */
  async unlikePaperComment(data, callback) {
    callback();
    let cognitoUser = this.userPool.getCurrentUser();

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };
    if (cognitoUser) {
      cognitoUser.getSession(async function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(async function (err, result) {
            if (err) {
              console.log(err);
            } else {
              let currentUserId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/unlikepapercomment';
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              data.userId = currentUserId;
              axios
                .post(api, data, axiosConfig)
                .then((response) => {
                  return response;
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                  return error;
                });
            }
          })
        }
      });
    }
  }


  /**
  * Callback to add increment number of likes and unlike comment in UI.
  *
  * @callback unlikeCommentUpdateUiCallback
  * @param {Object} newComment - An Object representing the new comment.
  */
  /**
  * @param {Object} data Information about the paper comment.
  * @param {string} data.paperCommentId The paper comment id associated with the comment.
  * @param {unlikeCommentUpdateUiCallback} callback callback to like a comment in UI.
  */
  async likePaperComment(data, callback) {
    callback();
    let cognitoUser = this.userPool.getCurrentUser();

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };
    if (cognitoUser) {
      cognitoUser.getSession(async function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(async function (err, result) {
            if (err) {
              console.log(err);
            } else {
              let currentUserId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/likepapercomment';
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              data.userId = currentUserId;
              axios
                .post(api, data, axiosConfig)
                .then((response) => {
                  return response;
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                  return error;
                });
            }
          })
        }
      });
    }
  }

  /**
* Callback to add increment number of likes and unlike comment in ui UI.
*
* @callback likeCommentUpdateUiCallback
* @param {Object} newComment - An Object representing the new comment.
*/
  /**
   * @param {Object} data Information about the paper comment.
   * @param {string} data.paperCommentId The paper comment id associated with the comment.
   * @param {likeCommentUpdateUiCallback} callback callback to add new comment to UI.
   **/
  async Comment(data, callback) {
    callback();
    var onFailure = function registerFailure(err) {
      alert(err.message);
    };

    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      return await cognitoUser.getSession(async function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          return await cognitoUser.getUserAttributes(async function (err, result) {
            if (err) {
              console.log(err);
            } else {
              data.userId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/likepapercomment';
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              return await axios
                .post(api, data, axiosConfig)
                .then((response) => {
                  return response;
                })
                .catch((error) => {
                  console.log(error);
                  onFailure(error);
                  return error;
                });
            }
          });
        }
      }
      );
    }
  }

  /**
  * Callback to for deleting a comment in UI.
  *
  * @callback deleteCommentUpdateUiCallback
  */
  /**
  * @param {Object} data Information about the paper comment.
  * @param {string} data.paperId The paper id associated with the comment.
  * @param {string} data.paperCommentId The paper comment id associated with the comment.
  * @param {deleteCommentUpdateUiCallback} callback callback to delete a comment in UI.
  */
  async deletePaperComment(data, callback) {
    callback();
    let cognitoUser = this.userPool.getCurrentUser();

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };
    if (cognitoUser) {
      cognitoUser.getSession(async function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();
          const api = _config.api.invokeUrl + '/deletepapercomment?paperId=' + data.paperId + '&paperCommentId=' + data.paperCommentId;
          const axiosConfig = {
            headers: {
              "Authorization": authToken,
            }
          };
          axios
            .delete(api, axiosConfig)
            .then((response) => {
              return response;
            })
            .catch((error) => {
              console.log(error);
              onFailure(error);
              return error;
            });
        }
      });
    }
  }

  /**
  * Callback to for deleting a comment in UI.
  *
  * @callback deleteCommentUpdateUiCallback
  */
  /**
  * @param {Object} data Information about the event comment.
  * @param {string} data.eventId The event id associated with the comment.
  * @param {string} data.eventCommentId The event comment id associated with the comment.
  * @param {deleteCommentUpdateUiCallback} callback callback to delete a comment in UI.
  */
  async deleteEventComment(data, callback) {
    callback();
    let cognitoUser = this.userPool.getCurrentUser();

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };
    if (cognitoUser) {
      cognitoUser.getSession(async function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();
          const api = _config.api.invokeUrl + '/deleteeventcomment?eventId=' + data.eventId + '&eventCommentId=' + data.eventCommentId;
          const axiosConfig = {
            headers: {
              "Authorization": authToken,
            }
          };
          axios
            .delete(api, axiosConfig)
            .then((response) => {
              return response;
            })
            .catch((error) => {
              console.log(error);
              onFailure(error);
              return error;
            });
        }
      });
    }
  }

  /**
   * Callback to call when the self user's lite info is retrieved.
   *
   * @callback getSelfUserLiteCallback
   * @param {string} userId - the self user id
   * */
  /**
   * @param {getSelfUserLiteCallback} callback callback after self user lite info is retrieved.
   */
  async getSelfUserLite(callback) {
    const cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser) {
      return cognitoUser.getSession(async function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          return cognitoUser.getUserAttributes(async function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const userId = result[3].Value;
              const authToken = session.getIdToken().getJwtToken();
              const api = _config.api.invokeUrl + '/getuserlite?userId=' + userId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              return await axios
                .get(api, axiosConfig)
                .then((response) => {
                  if (callback && response.data) {

                    callback(response.data);
                    return response.data;
                  }
                  return response;
                })
                .catch((error) => {
                  console.log(error);
                  return error;
                });
            }
          });
        }
      });
    } else {
      return [];
    }
  }


  /**
   * Callback to add new comment to UI.
   *
   * @callback ingestUserIdCallback
   * @param {string} selfUserId - the self user id.
   */

  /**
   * @param {ingestUserIdCallback} callback callback to send the user id to.
   */
  getSelfUserId(callback) {
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getUserAttributes(function (err, result) {
        if (err) {
          console.log(err);
        } else {
          const selfUserId = result[3].Value;
          callback(selfUserId);
        }
      });
    }
  }

  // returns s3 file location.
  async uploadEventThumbnailPicToS3(eventId, file) {
    const fileName = 'thumbnails/eventId_' + eventId + '_thumbnail';
    return await this.uploadFileToS3(file, fileName);
  }

  // returns s3 file location.
  async uploadJobThumbnailPicToS3(jobId, file) {
    const fileName = 'thumbnails/jobId_' + jobId + '_thumbnail';
    return await this.uploadFileToS3(file, fileName);
  }

  async uploadPaperImageToS3(paperId, file) {
    const fileName = 'thumbnails/paperId_' + paperId + '_thumbnail';
    return await this.uploadFileToS3(file, fileName);
  }

  // returns s3 file location.
  async uploadFileToS3(file, fileName) {
    let cognitoUser = this.userPool.getCurrentUser();
    let blob = file.slice(0, file.size, file.type);
    if (cognitoUser) {
      return await cognitoUser.getSession(async function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();
          const api = _config.api.invokeUrl + '/gets3uploadurl';
          const axiosConfig = {
            headers: {
              "Authorization": authToken,
            },
          };
          const data = { "fileName": fileName, };

          const options = {
            headers: {
              'Content-Type': file.type
            }
          };
          return await axios
            .post(api, data, axiosConfig)
            .then(async (response) => {
              return await axios
                .put(response.data.uploadURL, blob, options)
                .then((responseS3) => {
                  console.log(responseS3);
                  const longS3PicLocation = responseS3.config.url;
                  const strippedS3PicLocation = longS3PicLocation.substring(0, longS3PicLocation.indexOf('?'));
                  return strippedS3PicLocation;
                }).catch((error) => {
                  console.log(error);
                  return ''
                });
            })
            .catch((error) => {
              console.log(error);
              return '';
            });
        }
      });

    }
  }

  async deleteJob(data, callback) {
    var self = this;
    var onSuccess = function registerSuccess(result) {
      var eventId = result.data.jobId;
      var confirmation = ('job id deleted!');
      if (confirmation && callback) {
        callback();
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };

    const cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();

          const api = _config.api.invokeUrl + '/deletejob?jobId=' + data.jobId;
          const axiosConfig = {
            headers: {
              "Authorization": authToken,
            }
          };
          axios
            .delete(api, axiosConfig)
            .then((response) => {
              onSuccess(response);
            })
            .catch((error) => {
              console.log(error);
              onFailure(error);
            });
        }
      });
    }
  }

  async createGroup(data, self, callback) {
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      var onSuccess = function registerSuccess(result, self) {
        var groupId = result.data.groupId;
        console.log('group id is ' + groupId);
        var confirmation = ('group id created!');
        if (confirmation) {
          console.log('Done. Going to group.');
          window.location.href = `/group/${groupId}`;
        }
      };

      var onFailure = function registerFailure(err) {
        alert(err.message);
      };

      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();
          const api = _config.api.invokeUrl + '/creategroup';
          const axiosConfig = {
            headers: {
              "Authorization": authToken,
            }
          };
          axios
            .post(api, data, axiosConfig)
            .then((response) => {
              console.log(response);
              self.setState({
                groupId: response.data.jobId,
                groupName: response.data.groupName,
                description: response.data.description,
                creatorId: response.data.creatorId,
              });
              onSuccess(response);
              if (callback) {
                callback();
              }
            })
            .catch((error) => {
              console.log(error);
              onFailure(error);
            });
        }
      });
    }
  }

  async createJob(data, self, callback) {
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();
          const api = _config.api.invokeUrl + '/createjob';
          const axiosConfig = {
            headers: {
              "Authorization": authToken,
            }
          };
          axios
            .post(api, data, axiosConfig)
            .then((response) => {
              console.log(response);
              self.setState({
                jobId: response.data.jobId,
                userId: response.data.userId,
                jobTitle: response.data.jobTitle,
                description: response.data.description,
                employer: response.data.employer,
                location: response.data.location,
                compensation: response.data.compensation,
                qualifications: response.data.qualifications,
                disclaimers: response.data.disclaimers,
                benefits: response.data.benefits,
                companyOverview: response.data.companyOverview,
                instructions: response.data.instructions,
                thumbnailUrl: response.data.thumbnailUrl,
                applicationDeadline: response.data.applicationDeadline,
                datePosted: response.data.datePosted,
              });
              if (callback) {
                callback();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }
  }

  async searchJobs(data, callback) {

    var onSuccess = function registerSuccess(result) {
      var confirmation = ('search jobs complete!');
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };

    const cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const loggedInUserId = result[3].Value;
              const api = _config.api.invokeUrl + '/searchjobs?searchQuery=' + data.searchQuery + '&userId=' + loggedInUserId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .get(api, axiosConfig)
                .then((response) => {
                  let loadedJobItems = response.data;
                  const scrollPosition = sessionStorage.getItem("scrollPosition");
                  if (scrollPosition) {
                    window.scrollTo(0, parseInt(scrollPosition));
                    sessionStorage.removeItem("scrollPosition");
                  }
                  if (callback) {
                    callback(loadedJobItems);
                  }

                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
        }
      });
    }
  }

  getJobsFeed(currentPage, callback) {
    const cognitoUser = this.userPool.getCurrentUser();
    if (!cognitoUser) {
      const api = `${_config.api.invokeUrl}/getjobsfeed?page=${currentPage}`;
      axios
        .get(api)
        .then((response) => {
          let loadedJobItems = response.data;
          const scrollPosition = sessionStorage.getItem("scrollPosition");
          if (scrollPosition) {
            window.scrollTo(0, parseInt(scrollPosition));
            sessionStorage.removeItem("scrollPosition");
          }
          if (callback) {
            callback(loadedJobItems);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      return;
    } else {
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              if (result[3].Name === 'custom:userId' && result[3].Value) {
                const loggedInUserId = result[3].Value;
                const authToken = session.getIdToken().getJwtToken();
                let api = `${_config.api.invokeUrl}/getjobsfeed?userId=${loggedInUserId}&page=${currentPage}`;
                const axiosConfig = {
                  headers: {
                    "Authorization": authToken,
                  }
                };
                axios
                  .get(api, axiosConfig)
                  .then((response) => {
                    let loadedJobItems = response.data;
                    const scrollPosition = sessionStorage.getItem("scrollPosition");
                    if (scrollPosition) {
                      window.scrollTo(0, parseInt(scrollPosition));
                      sessionStorage.removeItem("scrollPosition");
                    }
                    if (callback) {
                      callback(loadedJobItems);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            }
          })
        }
      });

    }
  }

  /**
   * Saves ChatGPT paper summaries
   */
  async saveChatGptSummaries(data, self) {
    await this.callLambdaPost('savechatgptsummaries', data, (response) => {
      self.setState({
        paperId: response.data.paperId,
        chatGptMainFinding: response.data.chatGptMainFinding,
        chatGptSummary: response.data.chatGptSummary,
      });
    });
  }

  /**
   * Like event via lamda API.
   */
  async likeEvent(data) {
    await this.callLambdaPost('addlikedevent', data);
  }

  /**
   * Get notifications for the given user id.
   * @param {string} userId 
   */
  async getNotifications(userId) {
    return await this.callLambdaGet(`getnotifications?userId=${userId}`);
  }

  /**
 * Mark notifications as seen
 * @param {string} notificationIds 
 */
  async markNotificationsAsSeen(notificationIds) {
    return await this.callLambdaPost(`marknotificationsasseen`, { notificationIds });
  }

  /**
   * Get user for the given user id.
   * @param {string} userId 
   */
  async getUser(userId) {
    return await this.callLambdaGet(`getUser?userId=${userId}`);
  }

  /**
   * Get papers feed for the given user id and page.
   * @param {string} userId 
   * @param {string} page 
   */
  async getPapersFeed(userId, page) {
    const cognitoUser = this.userPool.getCurrentUser();
    if (!cognitoUser) {
      return await this.callLambdaGet(`getpapersfeed`);
    }
    return await this.callLambdaGet(`getpapersfeed?userId=${userId}&page=${page}`);
  }

  /**
 * @param {Object} userId Information about the paper.
 * @param {string} paperId The paper id associated with the paper.
 * @returns {Promise<Object>} JSON
 */
  async getPaperNote(userId, paperId) {
    return await this.callLambdaGet(`getpapernote?userId=${userId}&paperId=${paperId}`);
  }

  /**
  * @param {Object} data Information about the paper.
  * @param {string} data.paperId The paper id associated with the paper.
  * @param {string} data.note The note (associated with the paper) to be added to the database.
  * @param {Object} self The component that is calling this method - gives access to the props and state.
  * @returns {Promise<Object>} JSON
  */
  async addPaperNote(data) {
    return await this.callLambdaPost(`addpapernote`, data);
  }

  async getJob(jobId, userId) {
    return await this.callLambdaGet(`getjob?jobId=${jobId}&userId=${userId}`);
  }

  // async getJob(data, self, callback) {

  //   var onSuccess = function registerSuccess(result) {
  //     var confirmation = ('get account complete!');
  //   };

  //   var onFailure = function registerFailure(err) {
  //     alert(err.message);
  //   };

  //   const cognitoUser = this.userPool.getCurrentUser();

  //   if (cognitoUser) {
  //     cognitoUser.getSession(function sessionCallback(err, session) {
  //       if (err) {
  //         console.log(err);
  //       } else if (!session.isValid()) {
  //         console.log('invalid sess');
  //       } else {
  //         const authToken = session.getIdToken().getJwtToken();
  //         cognitoUser.getUserAttributes(function (err, result) {
  //           if (err) {
  //             console.log(err);
  //           } else {
  //             const loggedInUserId = result[3].Value;
  //             const api = _config.api.invokeUrl + '/getjob?jobId=' + data.jobId + '&userId=' + loggedInUserId;
  //             const axiosConfig = {
  //               headers: {
  //                 "Authorization": authToken,
  //               }
  //             };
  //             axios
  //               .get(api, axiosConfig)
  //               .then((response) => {
  //                 self.setState({
  //                   jobId: response.data.jobId,
  //                   userId: response.data.userId,
  //                   jobTitle: response.data.jobTitle,
  //                   description: response.data.description,
  //                   employer: response.data.employer,
  //                   location: response.data.location,
  //                   compensation: response.data.compensation,
  //                   qualifications: response.data.qualifications,
  //                   disclaimers: response.data.disclaimers,
  //                   benefits: response.data.benefits,
  //                   companyOverview: response.data.companyOverview,
  //                   thumbnailUrl: response.data.thumbnailUrl,
  //                   instructions: response.data.instructions,
  //                   applicationDeadline: response.data.applicationDeadline,
  //                   datePosted: response.data.datePosted,
  //                 });
  //                 if (callback) {
  //                   callback();
  //                 }

  //               })
  //               .catch((error) => {
  //                 console.log(error);
  //               });
  //           }
  //         });
  //       }
  //     });
  //   }
  // }

  async callLambdaGet(lambdaName, callback) {
    const api = `${_config.api.invokeUrl}/${lambdaName}`;
    const axiosConfig = {
      headers: {
        "Authorization": await this.getAWSAuthToken(),
      }
    };
    return axios
      .get(api, axiosConfig)
      .then((response) => {
        if (callback) {
          callback(response);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        alert(error.message);
      });
  }

  async callLambdaPost(lambdaName, data, callback) {
    const api = `${_config.api.invokeUrl}/${lambdaName}`;
    const axiosConfig = {
      headers: {
        "Authorization": await this.getAWSAuthToken(),
      }
    };
    axios
      .post(api, data, axiosConfig)
      .then((response) => {
        console.log(response);
        if (callback) {
          callback(response);
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error.message);
      });
  }

  async getUserId() {
    const cognitoUser = this.userPool.getCurrentUser();
    return await cognitoUser.getSession(async function sessionCallback(err, session) {
      if (err || !session.isValid()) {
        // Handle invalid session or error
        console.log(err);
      } else {
        return await cognitoUser.getUserAttributes(async function (err, result) {
          if (err) {
            // Handle error
            console.log(err);
          } else {
            if (result[3].Name === 'custom:userId' && result[3].Value) {
              const userId = result[3].Value;
              return userId;
            }
          }
        })
      }
    });
  }

  async getAWSAuthToken() {
    const cognitoUser = this.userPool.getCurrentUser();
    if (!cognitoUser) {
      return 0;
    }
    return await cognitoUser.getSession(function sessionCallback(err, session) {
      if (err) {
        console.log(err);
        return 0;
      } else if (!session.isValid()) {
        console.log('invalid sess');
        return 0;
      } else {
        const authToken = session.getIdToken().getJwtToken();
        return authToken;
      }
    });
  }
}