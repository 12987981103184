import React, { useState, useRef, useEffect } from 'react';
import PubNub from "pubnub";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import axios from 'axios';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import _config from "../signup/config";
import { Link } from 'react-router-dom';
import UserCard from '../components/UserCard/UserCard';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Login, Person, PersonOutline, Feedback, QuestionAnswer, PeopleAlt, PeopleAltOutlined } from '@mui/icons-material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import HomeFeed from "../HomeFeed/HomeFeed";
import DMs from "../dms/dms";
import UserCardLoader from '../components/UserCard/UserCardLoader';
import { Badge, Fab, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import LeaveFeedback from '../Feedback/Feedback';
import Questions from '../Questions/Questions';
import PrivacyPolicy from '../privacyPolicy/privacyPolicy';
import styles from './ThreeColumnWrapperStyles.module.css';
import UserInfo from '../components/UserInfo/UserInfo';
import People from '../components/People/People';
import Profile from '../Profile/Profile';
import TalkThread from '../components/TalkThread/TalkThread';
import DialogBox from '../components/DialogBox/DialogBox';
import PaperThread from '../components/PaperThread/PaperThread';
import JobThread from '../components/JobThread/JobThread';
import RequestService from "../requestservice/requestservice";
import TopBar from '../components/TopBar/TopBar';
import { useTheme } from '@mui/material/styles';
import MessageIcon from '@mui/icons-material/Message';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { Typography } from '@mui/material';
import ContactsIcon from '@mui/icons-material/Contacts';

function shuffleArray(array) {
  let i = array.length - 1;
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

const respectablePrefixes = new Set(['Dr.', 'Prof.', 'Rev.']);

const LEFT_DRAWER_WIDTH = 274;
const RIGHT_DRAWER_WIDTH = 350;

function ThreeColumnWrapper(props) {
  const [click, setClick] = useState(false);
  const isMobile = window.innerWidth <= 768;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showRightDrawer, setShowRightDrawer] = useState(window.innerWidth < 1200 ? false : true);
  const [fullLeftBar, setFullLeftBar] = useState(true);
  // if mobile, don't show left drawer initially.
  const [showLeftDrawer, setShowLeftDrawer] = useState(isMobile ? false : true);
  const [leftDrawerWidth, setLeftDrawerWidth] = useState(LEFT_DRAWER_WIDTH);
  const [rightDrawerWidth, setRightDrawerWidth] = useState(RIGHT_DRAWER_WIDTH);
  const [userId, setUserId] = useState('');
  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  const [navbar, setNavbar] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [prefix, setPrefix] = useState('');
  const [suffix, setSuffix] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [organization, setOrganization] = useState('');
  const [userEvents, setUserEvents] = useState([]);
  const [userSavedEvents, setUserSavedEvents] = useState([]);
  const [userRsvpEvents, setUserRsvpEvents] = useState([]);
  const [userLikedEvents, setUserLikedEvents] = useState([]);
  const [userPapers, setUserPapers] = useState([]);
  const [userSavedPapers, setUserSavedPapers] = useState([]);
  const [userReadPapers, setUserReadPapers] = useState([]);
  const [userLikedPapers, setUserLikedPapers] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [loadingUserInfo, setLoadingUserInfo] = useState(true);
  const [loadingAllUsers, setLoadingAllUsers] = useState(true);
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [openCreateGroupDialog, setOpenCreateGroupDialog] = useState(false);
  const [allUserItems, setAllUserItems] = useState([]);
  const [allUserItemsInclusive, setAllUserItemsInclusive] = useState([]);
  const [messageCounts, setMessageCounts] = useState({});
  const [numMessages, setNumMessages] = useState(0);

  const theme = useTheme();
  const requestService = new RequestService();
  const userPool = new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: _config.cognito.userPoolId,
    ClientId: _config.cognito.userPoolClientId,
  });
  const leftDrawerRef = useRef(null);
  const rightDrawerRef = useRef(null);
  const leftDrawerVariant = isMobile ? "temporary" : "permanent";
  const rightDrawerVariant = window.innerWidth < 1200 ? "temporary" : "permanent";

  // Runs once when component mounts
  useEffect(() => {
    getUser();
    getAllUsers();
    resizeSymposia();
    window.addEventListener('resize', resizeSymposia);
    window.addEventListener('scroll', handleNavbarScroll);
    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener('resize', resizeSymposia);
      window.removeEventListener('scroll', handleNavbarScroll);
    };
  }, []);

  useEffect(() => {
    resizeSymposia();
  }, [props.pageTitle]);

  useEffect(async () => {
    if (!userId) {
      return;
    }
    const pubnubKeys = {
      publishKey: 'pub-c-42e76bcd-57cc-42fe-813d-3bacadfb1461',
      subscribeKey: 'sub-c-233f627c-b4d2-4a00-b88d-8d14d6141924',
    };
    const pubnub = new PubNub({
      ...pubnubKeys,
      uuid: userId,
      fileUploadPublishRetryLimit: 0,
    });
    const response = await pubnub.objects.getMemberships({
      include: {
        customFields: true
      }
    });
    if (!response && !response.data && !response.data.length == 0) {
      return;
    }
    const channels = response.data.map(m => m.channel.id).filter(cid => cid !== 'space.149e60f311749f2a7c6515f7b34');
    const channelTimetokens = response.data.filter(m => m.channel.id !== 'space.149e60f311749f2a7c6515f7b34').map(m => m.custom?.lastReadTimetoken ?? 0);

    const channelIdToTimetoken = response.data
      .filter(m => m.channel.id !== 'space.149e60f311749f2a7c6515f7b34')
      .reduce(function (map, m) {
        map[m.channel.id] = m.custom?.lastReadTimetoken ?? 0;
        return map;
      }, {});
    pubnub.messageCounts({
      channels,
      channelTimetokens,
    }, (status, response) => {
      if (status.error || !response) {
        console.log(status);
        console.log(response);
        return;
      }
      const messageCountsNew = { ...response.channels };
      setMessageCounts(messageCountsNew);
      pubnub.addListener({
        message: handleMessage,
      });
      pubnub.subscribe({ channels: channels });
    });
  }, [userId]);

  function correctMAU(m, prevState) {
    if (m.publisher === userId) {
      return prevState[m.channel] ? prevState[m.channel] : 0;
    }
    return prevState[m.channel] ? prevState[m.channel] + 1 : 0;
  }

  function handleMessage(m) {
    setMessageCounts((prevState) => ({
      ...prevState,
      [m.channel]: correctMAU(m, prevState),
    }));
  }

  useEffect(() => {
    if (!userId) {
      return;
    }
    const channelIds = Object.keys(messageCounts);
    const values = Object.values(messageCounts);
    const numberOfMessages = values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    setNumMessages(numberOfMessages);

    const pubnubKeys = {
      publishKey: 'pub-c-42e76bcd-57cc-42fe-813d-3bacadfb1461',
      subscribeKey: 'sub-c-233f627c-b4d2-4a00-b88d-8d14d6141924',
    };
    const pubnub = new PubNub({
      ...pubnubKeys,
      uuid: userId,
      fileUploadPublishRetryLimit: 0,
    });
    channelIds.forEach((channelId) => {
      const messageCount = messageCounts[channelId];
      if (messageCount === 0) {
        return;
      }
      pubnub.fetchMessages(
        {
          channels: [channelId],
          // start: channelIdToTimetoken[channelId],
          count: messageCount
        },
        async (status2, response2) => {
          if (status2.error || !response2) {
            console.log(status2);
            console.log(response2);
            return;
          }
          const convertedChannelId = channelId.replace('@', '%40');
          if (response2.channels[convertedChannelId][0].message.sender.id === userId) {

            const messageCountsNew = { ...messageCounts };
            messageCountsNew[channelId] = messageCountsNew[channelId] - 1;
            setMessageCounts(messageCountsNew);
            const lastReadTimetoken = Date.now() * 10000;
            await pubnub.objects.setMemberships({
              channels: [{
                id: channelId,
                custom: {
                  lastReadTimetoken,
                }
              }],
              uuid: pubnub.getUUID()
            });
          }
        }
      );
    });
  }, [messageCounts]);

  useEffect(() => {
    resizeSymposia()
  }, [showLeftDrawer]);

  const handleFeedbackOpen = () => {
    setOpenFeedbackDialog(true);
    setShowLeftDrawer(leftDrawerVariant === 'permanent');
  };

  const handleFeedbackClose = () => {
    setOpenFeedbackDialog(false);
  };

  const handleCreateGroupClose = () => {
    setOpenCreateGroupDialog(false);
  };


  const closeMobileMenu = () => {
    setClick(false);
  };

  const toggleDrawer = () => {
    setLeftDrawerWidth(LEFT_DRAWER_WIDTH);
    setShowLeftDrawer(prevState => !prevState);
  };

  const openRightDrawer = (e) => {
    console.log('openRightDrawer');
    setRightDrawerWidth(RIGHT_DRAWER_WIDTH);
    setShowRightDrawer(true);
  };

  const resizeSymposia = () => {
    setWindowWidth(window.innerWidth);
    setLeftDrawerWidth(showLeftDrawer ? LEFT_DRAWER_WIDTH : 0);
    setRightDrawerWidth(showRightDrawer ? RIGHT_DRAWER_WIDTH : 0);
    if (window.location.href.includes('messages')) {
      setShowRightDrawer(false);
      setRightDrawerWidth(0);
      return;
    }
    if (window.innerWidth < 1200) {
      setShowRightDrawer(false);
      setRightDrawerWidth(0);
      return;
    }
    setShowRightDrawer(true);
    setRightDrawerWidth(RIGHT_DRAWER_WIDTH);
  };

  const getUser = () => {
    const poolData = {
      UserPoolId: _config.cognito.userPoolId,
      ClientId: _config.cognito.userPoolClientId,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      setLoadingUserInfo(false);
      return;
    }

    cognitoUser.getSession(function sessionCallback(err, session) {
      if (err || !session.isValid()) {
        // Handle invalid session or error
        console.log(err);
        props.history.push({
          pathname: '/signin',
          state: { from: { pathname: window.location.toString() } },
        });
      } else {
        cognitoUser.getUserAttributes(function (err, result) {
          if (err) {
            // Handle error
            console.log(err);
            props.history.push({
              pathname: '/signin',
              state: { from: { pathname: window.location.toString() } },
            });
          } else {
            if (result[3].Name === 'custom:userId' && result[3].Value) {
              const authToken = session.getIdToken().getJwtToken();
              const api = _config.api.invokeUrl + '/getuser?userId=' + result[3].Value;

              axios
                .get(api, {
                  headers: {
                    Authorization: authToken,
                  },
                })
                .then((response) => {
                  if (response.data.profilePictureUrl) {
                    setProfilePictureUrl(response.data.profilePictureUrl);
                  }
                  setUserId(result[3].Value);
                  setFirstName(response.data.firstName);
                  setLastName(response.data.lastName);
                  setPrefix(
                    respectablePrefixes.has(response.data.prefix)
                      ? response.data.prefix
                      : ''
                  );
                  setSuffix(response.data.suffix);
                  setJobTitle(response.data.jobTitle);
                  setOrganization(response.data.organization);
                  setUserEvents(response.data.userEvents);
                  setUserSavedEvents(response.data.userSavedEvents);
                  setUserRsvpEvents(response.data.userRsvpEvents);
                  setUserLikedEvents(response.data.userLikedEvents);
                  setUserPapers(response.data.userPapers);
                  setUserSavedPapers(response.data.userSavedPapers);
                  setUserReadPapers(response.data.userReadPapers);
                  setUserLikedPapers(response.data.userLikedPapers);
                  setLoadingUserInfo(false);
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }
        });
      }
    });
  };

  const getAllUsers = () => {
    const poolData = {
      UserPoolId: _config.cognito.userPoolId,
      ClientId: _config.cognito.userPoolClientId,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      const api = _config.api.invokeUrl + '/getallusers';

      axios
        .get(api)
        .then((response) => {
          const allUsers = response.data.filter(
            (user) =>
              !(
                user.FirstName === 'Symposia' ||
                user.FirstName === 'Devxn' ||
                user.UserId === 'PdT4dUHg8CUlZvjdepDK9g' ||
                (user.FirstName.trim() === 'Tom' &&
                  user.LastName.trim() === 'Thomas') ||
                (user.FirstName === 'Michael' &&
                  user.LastName === 'Araya') ||
                (user.UserId === 'YSRoiPNsvutRrCg97xPl2A') ||
                user.UserId === userId
              )
          );
          setAllUserItems([...allUserItems, ...shuffleArray(allUsers)]);
          setLoadingAllUsers(false);
          setAllUserItemsInclusive([
            ...allUserItemsInclusive,
            ...response.data,
          ]);
        })
        .catch((error) => {
          console.log(error);
        });
      return;
    }

    cognitoUser.getSession(function sessionCallback(err, session) {
      if (err || !session.isValid()) {
        // Handle invalid session or error
        console.log(err);
      } else {
        cognitoUser.getUserAttributes(function (err, result) {
          if (err) {
            // Handle error
            console.log(err);
          } else {
            if (result[3].Name === 'custom:userId' && result[3].Value) {
              setUserId(result[3].Value);
              const authToken = session.getIdToken().getJwtToken();

              const api = _config.api.invokeUrl + '/getallusers';
              axios
                .get(api, {
                  headers: {
                    Authorization: authToken,
                  },
                })
                .then((response) => {
                  const allUsers = response.data.filter(
                    (user) =>
                      !(
                        user.FirstName === 'Symposia' ||
                        user.FirstName === 'Devxn' ||
                        (user.FirstName.trim() === 'Tom' &&
                          user.LastName.trim() === 'Thomas') ||
                        (user.FirstName === 'Michael' &&
                          user.LastName === 'Araya') ||
                        (user.UserId === 'YSRoiPNsvutRrCg97xPl2A') ||
                        user.UserId === userId
                      )
                  );
                  setAllUserItems([...allUserItems, ...shuffleArray(allUsers)]);
                  setLoadingAllUsers(false);
                  setAllUserItemsInclusive([
                    ...allUserItemsInclusive,
                    ...response.data,
                  ]);
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }
        });
      }
    });
  };

  const addSavedPaperToMiniProfile = (paper) => {
    setUserSavedPapers([...userSavedPapers, paper]);
  };

  const removeSavedPaperFromMiniProfile = (paperId) => {
    setUserSavedPapers(userSavedPapers.filter((paper) => paper.PaperId !== paperId));
  };

  const addUpcomingTalkToMiniProfile = (event) => {
    setUserRsvpEvents([...userRsvpEvents, event]);
  };

  const removeUpcomingTalkFromMiniProfile = (eventId) => {
    setUserRsvpEvents(userRsvpEvents.filter((event) => event.EventId !== eventId));
  };

  const signOut = () => {
    const userPoolData = {
      UserPoolId: _config.cognito.userPoolId,
      ClientId: _config.cognito.userPoolClientId,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(userPoolData);
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.signOut();
    }

    window.location.href = '/';
  };

  const handleNavbarScroll = () => {
    if (window.scrollY >= 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const calculateMainWidth = () => {
    return `calc(${window.innerWidth}px - ${showLeftDrawer ? leftDrawerWidth : 0}px - ${showRightDrawer ? rightDrawerWidth : 0}px);`
  }

  const calculateMainLeft = () => {
    return `${showLeftDrawer ? leftDrawerWidth : 0}px`;
  }

  const pageTitle = props.pageTitle;

  return (
    <>
      <TopBar
        userInfo={
          <UserInfo
            userId={userId}
            profilePictureUrl={profilePictureUrl}
            firstName={firstName}
            lastName={lastName} />
        }
        windowWidth={windowWidth}
        toggleDrawer={toggleDrawer}
        pageTitle={'Profile'}
        history={history}
        requestService={requestService}
        userId={userId}
        numberMessages={numMessages}
      />
      <Box
        sx={{ display: 'block', width: { sm: leftDrawerWidth }, flexShrink: { sm: 0 } }}
      >
        <CssBaseline />
        {/* Left drawer */}
        <Drawer
          open={showLeftDrawer}
          onClose={(ev, reason) => setShowLeftDrawer(false)}
          ref={leftDrawerRef}
          sx={{
            width: `${leftDrawerWidth}px`,
            '& .MuiDrawer-paper': {
              background: theme.palette.secondary.main,
              width: `${leftDrawerWidth}px`
            },
          }}
          variant={leftDrawerVariant}
          anchor="left"
        >
          <div className={styles.Drawer} >

            {fullLeftBar ?
              <>
                <div className={styles.MenuItems + ' ' + theme.mixins.toolbar}>
                  <Link to='/' onClick={() => setShowLeftDrawer(leftDrawerVariant === 'permanent')} className={styles.NoTextDecoration}>
                    <Box
                      sx={{
                        backgroundColor: (props.pageTitle === 'Home' || props.pageTitle === 'Papers' || props.pageTitle === 'Jobs') ? theme.palette.secondary.background : theme.palette.secondary.main,
                      }}>
                      <div
                        className={styles.MenuItemButton}>
                        {pageTitle === 'Home' || pageTitle === 'Papers' || pageTitle === 'Jobs' ?
                          <HomeIcon sx={{ mr: '24px', fontSize: '24px', color: theme.palette.secondary.dark }} />
                          :
                          <HomeOutlinedIcon sx={{ mr: '24px', fontSize: '24px' }} />
                        }
                        <Typography variant='subtitle1'>Home</Typography>
                      </div>
                    </Box>
                  </Link>

                  {userPool.getCurrentUser() &&
                    <Link to='/messages' onClick={() => setShowLeftDrawer(leftDrawerVariant === 'permanent')} className={styles.NoTextDecoration}>
                      <Box
                        sx={{
                          backgroundColor: props.pageTitle === 'messages' ? theme.palette.secondary.background : theme.palette.secondary.main,
                        }}>
                        <div className={styles.MenuItemButton}>
                          {props.pageTitle === 'messages' ?
                            <Badge badgeContent={numMessages} color="error">
                              <MessageIcon sx={{ fontSize: '24px' }} />
                            </Badge>
                            :
                            <Badge badgeContent={numMessages} color="error">
                              <MessageOutlinedIcon sx={{ fontSize: '24px' }} />
                            </Badge>
                          }
                          <Box sx={{ ml: '24px' }}>
                            <Typography variant='subtitle1'>Messages</Typography>
                          </Box>
                        </div>
                      </Box>
                    </Link>}
                  {/* {!userPool.getCurrentUser() &&
                    <Link to='/signin' className={styles.NoTextDecoration}>
                      <button
                        type='button'
                        className={styles.MenuItemButton + ' ' + ((pageTitle === 'Profile') ? styles.ActiveButton : '')}
                      >

                        {window.location.href === '/signin' ?
                          <Login sx={{ mr: '16px', fontSize: '40px' }} />
                          :
                          <LoginOutlined sx={{ mr: '16px', fontSize: '40px' }} />
                        }
                        Sign in
                      </button>
                    </Link>
                  } */}
                  {/* {!userPool.getCurrentUser() &&
                    <Link to='/signup' className={styles.NoTextDecoration}>
                      <button
                        type='button'
                        className={styles.MenuItemButton + ' ' + ((pageTitle === 'Profile') ? styles.ActiveButton : '')}
                      >

                        {window.location.href === '/signup' ?
                          <AppRegistrationIcon sx={{ mr: '16px', fontSize: '40px' }} />
                          :
                          <AppRegistrationOutlinedIcon sx={{ mr: '16px', fontSize: '40px' }} />
                        }
                        Sign up
                      </button>
                    </Link>
                  } */}
                </div>
              </>
              :
              <>
                <div className={styles.MenuItemsSmall}>
                  <Link to='/home' className={styles.NoTextDecoration}>
                    <Tooltip title="Home">
                      <IconButton>
                        {props.pageTitle === 'Home' ?
                          <HomeIcon sx={{ fontSize: '40px' }} />
                          :
                          <HomeOutlinedIcon sx={{ fontSize: '40px' }} />
                        }
                      </IconButton>
                    </Tooltip>
                  </Link>
                  {userPool.getCurrentUser() &&
                    <Link to={'/profile/' + userId} className={styles.NoTextDecoration}>
                      <Tooltip title="Profile">
                        <IconButton sx={{}}>
                          {props.pageTitle === 'Profile' ?
                            <Person sx={{ fontSize: '40px' }} />
                            :
                            <PersonOutline sx={{ fontSize: '40px' }} />
                          }
                        </IconButton>
                      </Tooltip>
                    </Link>
                  }
                  {userPool.getCurrentUser() &&
                    <Link to='/people' className={styles.NoTextDecoration}>
                      <Tooltip title="People">
                        <IconButton>
                          {props.pageTitle === 'People' ?
                            <PeopleAlt sx={{ fontSize: '40px' }} />
                            :
                            <PeopleAltOutlined sx={{ fontSize: '40px' }} />
                          }
                        </IconButton>
                      </Tooltip>
                    </Link>
                  }
                  {!userPool.getCurrentUser() &&
                    <Link to='/signin' className={styles.NoTextDecoration}>
                      <Tooltip title="Sign in">
                        <IconButton>
                          <Login sx={{ fontSize: '40px' }} />
                        </IconButton>
                      </Tooltip>
                    </Link>
                  }
                  {!userPool.getCurrentUser() &&
                    <Link to='/signup' className={styles.NoTextDecoration}>
                      <Tooltip title="Sign up">
                        <IconButton>
                          {window.location.href === '/signup' ?
                            <AppRegistrationIcon sx={{ fontSize: '40px' }} />
                            :
                            <AppRegistrationOutlinedIcon sx={{ fontSize: '40px' }} />
                          }
                        </IconButton>
                      </Tooltip>
                    </Link>
                  }
                </div>
              </>
            }
            {fullLeftBar ?
              <div className={styles.FooterInfo}>
                <div>
                  <Link style={{ color: theme.palette.secondary.dark }} to='/questions' onClick={() => setShowLeftDrawer(leftDrawerVariant === 'permanent')} className={styles.FooterItem}>
                    FAQs
                  </Link>
                </div>
                <div>
                  <Link style={{ color: theme.palette.secondary.dark }} to='/privacy' onClick={() => setShowLeftDrawer(leftDrawerVariant === 'permanent')} className={styles.FooterItem}>
                    Privacy Policy
                  </Link>
                </div>
                <div style={{ color: theme.palette.secondary.dark }} className={styles.FooterItem} onClick={() => handleFeedbackOpen()}>
                  Feedback
                </div>
                {userPool.getCurrentUser() && <div style={{ color: theme.palette.secondary.dark }} className={styles.FooterItem} onClick={() => signOut()}>
                  Sign out
                </div>}

                {!userPool.getCurrentUser() &&

                  <Link style={{ color: theme.palette.secondary.dark }} to='/signin' onClick={() => setShowLeftDrawer(leftDrawerVariant === 'permanent')} className={styles.FooterItem}>
                    <div style={{ color: theme.palette.secondary.dark }} className={styles.FooterItem} onClick={() => signIn()}>
                      Sign in
                    </div>
                  </Link>
                }
              </div>
              :
              <div className={styles.FooterInfoSmall}>
                <div className={styles.FooterItem} >
                  <Tooltip title="Feedback">
                    <IconButton onClick={() => handleFeedbackOpen()}>
                      <Feedback sx={{ color: 'black' }} />
                    </IconButton>
                  </Tooltip>
                </div>
                <div>
                  <Link to='/questions' className={styles.FooterItem}>
                    <Tooltip title="FAQs">
                      <IconButton>
                        <QuestionAnswer sx={{ color: 'black' }} />
                      </IconButton>
                    </Tooltip>
                  </Link>
                </div>
              </div>
            }
          </div>
        </Drawer >

        {/* Main panel */}
        < Box
          component="main"
          sx={{
            bgcolor: theme.palette.primary.light,
            display: 'flex',
            position: 'relative',
            left: calculateMainLeft(),
            width: calculateMainWidth(),
            mt: '86.5px',
            justifyContent: 'center',
          }}>
          {props.pageTitle === 'messages' &&
            <DMs
              userId={userId}
              messageCounts={messageCounts}
              setMessageCounts={setMessageCounts}
              isMobile={isMobile}
            />}
          {(props.pageTitle === 'Home' || props.pageTitle === 'Papers' || props.pageTitle === 'Jobs') &&
            <div className={styles.Feed} sx={{ bgcolor: theme.palette.primary.light }}>
              <HomeFeed
                fullLeftBar={fullLeftBar}
                pageTitle={props.pageTitle}
                drawerWidth={leftDrawerWidth}
                history={history}
                profilePictureUrl={profilePictureUrl}
                userId={userId}
                firstName={firstName}
                lastName={lastName}
                prefix={prefix}
                suffix={suffix}
                allUserItems={allUserItemsInclusive}
                handleAddedSavedPaper={addSavedPaperToMiniProfile}
                handleRemoveSavedPaper={removeSavedPaperFromMiniProfile}
                handleAddedUpcomingTalk={addUpcomingTalkToMiniProfile}
                handleRemoveUpcomingTalk={removeUpcomingTalkFromMiniProfile}
              />
              {window.innerWidth < 1200 &&
                <Fab
                  sx={{
                    position: 'fixed',
                    bottom: '24px',
                    right: '24px',
                  }}
                  color="secondaryLight" aria-label="view researchers"
                  onClick={openRightDrawer}>
                  <ContactsIcon color="secondaryDark"></ContactsIcon>
                </Fab>
              }
            </div>
          }

          {pageTitle === 'Privacy Policy' && <PrivacyPolicy />}
          {pageTitle === 'People' && <People allUserItems={allUserItems} pageTitle={pageTitle} drawerWidth={leftDrawerWidth} history={history} />}
          {pageTitle === 'FAQs' && <Questions pageTitle={pageTitle} drawerWidth={leftDrawerWidth} history={history} />}
          {
            (pageTitle === 'Profile' || pageTitle === 'User Profile') &&
            <Profile
              pageTitle={pageTitle}
              drawerWidth={leftDrawerWidth}
              fullLeftBar={fullLeftBar}
              user={{ firstName, lastName, profilePictureUrl, organization, jobTitle }}
              history={history}
              allUserItems={allUserItemsInclusive}
              handleAddedSavedPaper={addSavedPaperToMiniProfile}
              handleRemoveSavedPaper={removeSavedPaperFromMiniProfile}
              handleAddedUpcomingTalk={addUpcomingTalkToMiniProfile}
              handleRemoveUpcomingTalk={removeUpcomingTalkFromMiniProfile}
              requestService={requestService}
            />
          }
          {
            pageTitle === 'Talk Thread' &&
            <TalkThread
              pageTitle={pageTitle}
              drawerWidth={leftDrawerWidth}
              history={history}
              allUserItems={allUserItemsInclusive}
              signedInUserId={userId}
              handleAddedUpcomingTalk={addUpcomingTalkToMiniProfile}
              handleRemoveUpcomingTalk={removeUpcomingTalkFromMiniProfile}
              userGroups={userGroups}
            />
          }
          {
            pageTitle === 'Paper Thread' &&
            <PaperThread
              pageTitle={pageTitle}
              drawerWidth={leftDrawerWidth}
              history={history}
              allUserItems={allUserItemsInclusive}
              signedInUserId={userId}
              handleAddedSavedPaper={addSavedPaperToMiniProfile}
              handleRemoveSavedPaper={removeSavedPaperFromMiniProfile}
              userGroups={userGroups}
            />
          }
          {
            pageTitle === 'Job Thread' &&
            <JobThread
              pageTitle={pageTitle}
              drawerWidth={leftDrawerWidth}
              history={history}
              allUserItems={allUserItemsInclusive}
              signedInUserId={userId}
              userGroups={userGroups}
            />
          }
        </Box >

        {/* Right drawer */}
        {pageTitle !== 'messages' &&
          <Drawer
            open={showRightDrawer}
            onClose={(ev, reason) => setShowRightDrawer(false)}
            ref={rightDrawerRef}
            sx={{
              width: `${rightDrawerWidth}px`,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                background: theme.palette.secondary.light,
                width: `${rightDrawerWidth}px`,
                boxSizing: 'border-box',
              },
            }}
            variant={rightDrawerVariant}
            anchor="right"
          >
            <div className={styles.Drawer}>
              <div className={styles.PeopleDiv}>
                <Typography variant="h4">Researchers</Typography>
                {loadingAllUsers ? <UserCardLoader /> :
                  <div>
                    {allUserItems.slice(0, 5).map((user) =>
                      <UserCard
                        key={user.UserId}
                        userId={user.UserId}
                        firstName={user.FirstName}
                        lastName={user.LastName}
                        prefix={respectablePrefixes.has(user.Prefix) ? user.Prefix : ''}
                        suffix={user.Suffix}
                        profilePictureUrl={user.ProfilePictureUrl}
                        jobTitle={user.JobTitle}
                        organization={user.Organization}
                      />
                    )}
                    <Link to={userId ? '/people' : '/signin'} className={styles.ShowMoreUsers}>
                      All People
                    </Link>
                  </div>
                }

              </div>
            </div>
          </Drawer>
        }
      </Box >
      <DialogBox
        open={openFeedbackDialog}
        handleClose={() => handleFeedbackClose()}
        title="Give use feedback"
      >
        <LeaveFeedback
          userId={userId}
          setOpenFeedbackDialog={setOpenFeedbackDialog}
        />
      </DialogBox >
    </>
  );
}

export default ThreeColumnWrapper;
