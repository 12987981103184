import React from 'react'
import Select from 'react-select';
import styles from './dropdownStyles.module.css'



export function DropDown({
    label, 
    options, 
    onChange=() => {}, 
    value, 
    defaultValue,
    required=false
}) {
    return (

        <div className={styles.dropdownDiv}>
            {/* {required ? 
            <label className={styles.labelText}>
                {label}<span style={{color:'red', fontSize:'14px'}}>*</span>
            </label> :
            <label className={styles.labelText}>
                {label}
            </label> 
            } */}
            <Select 
                options={options} 
                onChange={(e) => onChange(e)} 
                value = {((value["label"]) &&  (value["label"] !== "")) ? value : ""}
                placeholder = {label}
                defaultValue={defaultValue}
                theme={theme => ({...theme, borderRadius:2, colors: {...theme.colors, primary:'black'},})}
                styles={{control: base => ({...base, })}}
            />
        </div>
            
    )
}
