import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import _config from "../signup/config";
import React from 'react';
import styles from './CreateJobFormStyles.module.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import axios from 'axios';
import Popup from '../components/popup/popup';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers';
import { keywords1, keywords2, keywords3 } from '../components/KeywordsStore/KeywordsStore';
import { Box, Button, Typography } from '@mui/material';
import crypto from 'crypto-browserify';
import moment from 'moment-timezone';
const randomBytes = crypto.randomBytes;

const keywordsArray = [...keywords1, ...keywords2, ...keywords3];

const jobOptions = [
  { label: 'Journal Club', value: 'journal-club' },
  { label: 'Talk/Seminar', value: 'job-seminar' },
  { label: 'Dissertation Defense', value: 'defense' },
  { label: 'Workshop/Tutorial', value: 'workshop-tutorial' },
  { label: 'Open Meeting', value: 'meeting' },
  { label: 'Conference', value: 'conference' }
];

const visibilityOptions = [
  { label: 'Public (default)', value: 'public' },
  { label: 'Registered users', value: 'registered-users' }
];

const timeZoneOptions = [
  { label: 'Eastern Daylight Time - Washington DC (GMT-4)', value: 'EDT' },
  { label: 'Central Daylight Time - Chicago (GMT-5)', value: 'CDT' },
  { label: 'Mountain Daylight Time - Denver (GMT-6)', value: 'MDT' },
  // this needs to be automated to handle Mountain Time changes
  { label: 'Mountain Standard Time - Phoenix (GMT-7)', value: 'MST' },
  { label: 'Pacific Daylight Time - Los Angeles (GMT-7)', value: 'PDT' },
  { label: 'Alaska Daylight Time - Anchorage (GMT-8)', value: 'ADT' },
  { label: 'Hawaii-Aleutian Standard Time - Honolulu (GMT-10)', value: 'HST' }
];

const locTypeOptions = {
  VIRTUAL: 'Virtual',
  // HYBRID: 'Hybrid',
  RECORDED: 'Recorded',
}

function toUrlString(buffer) {
  return buffer.toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

class CreateJobForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobId: this.props.jobId ? this.props.jobId : toUrlString(randomBytes(16)),
      userId: '',
      thumbnail: null,
      thumbnailUrl: '',
      location: '',
      jobTitle: '',
      compensation: '',
      employer: '',
      jobType: jobOptions[0],
      description: '',
      keywords: [],
      applicationDeadline: null,
      startTime: null,
      endDate: null,
      endTime: null,
      timeZone: { label: moment.tz.guess(), value: moment.tz(moment.tz.guess()).format('z') },
      refFiles: null,
      refFilesURL: '',
      instructions: '',
      qualifications: '',
      disclaimers: '',
      benefits: '',
      activeLoc: "Virtual",
      sourceJobLink: '',
      locType: locTypeOptions,
      venue: {},
      address: '',
      meetingLink: '',
      meetingDetails: '',
      visibility: visibilityOptions[0],
      discardPopup: false,
      submitPopup: false,
      companyOverview: '',
      allRequiredComplete: true, // assume true to start
      windowWidth: window.innerWidth
    }

    const poolData = {
      UserPoolId: _config.cognito.userPoolId,
      ClientId: _config.cognito.userPoolClientId,
    };
    this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    this.getUser = this.getUser.bind(this);
    this.autocomplete = null;
    this.handleVenueSelector = this.handleVenueSelector.bind(this);
    this.toggleActiveStyles = this.toggleActiveStyles.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onPlaceChanged = this.onPlaceChanged.bind(this);
    this.autoCompleteRef = React.createRef();

    this.handleInstructionsChange = this.handleInstructionsChange.bind(this);
    this.handleQualificationsChange = this.handleQualificationsChange.bind(this);
    this.handleDisclaimersChange = this.handleDisclaimersChange.bind(this);
    this.handleBenefitsChange = this.handleBenefitsChange.bind(this);
    this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleJobTypeChange = this.handleJobTypeChange.bind(this);
    this.handleCompensationChange = this.handleCompensationChange.bind(this);
    this.handleInstitutionChange = this.handleInstitutionChange.bind(this);
    this.handleKeywordsChange = this.handleKeywordsChange.bind(this);
    this.handleMeetingDetailsChange = this.handleMeetingDetailsChange.bind(this);
    this.handleMeetingLinkChange = this.handleMeetingLinkChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleRefFilesChange = this.handleRefFilesChange.bind(this);
    this.handleApplicationDeadlineChange = this.handleApplicationDeadlineChange.bind(this);
    this.handleThumbnailChange = this.handleThumbnailChange.bind(this);
    this.handleThumbnailUrlChange = this.handleThumbnailUrlChange.bind(this);
    this.handleTimeZoneChange = this.handleTimeZoneChange.bind(this);
    this.handleJobTitleChange = this.handleJobTitleChange.bind(this);
    this.handleSourceJobLinkChange = this.handleSourceJobLinkChange.bind(this);
    this.handleVenueSelector = this.handleVenueSelector.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.setSubmitPopup = this.setSubmitPopup.bind(this);
    this.setDiscardPopup = this.setDiscardPopup.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleCompanyOverviewChange = this.handleCompanyOverviewChange.bind(this);
    this.getJob = this.getJob.bind(this);
    this.getJobAPI = this.getJobAPI.bind(this);
  }

  componentDidMount() {
    this.getUser();
    if (this.props.jobId) {
      this.getJobAPI(this.props.jobId); // need to double check something is wrong here
    }
    // window.addJobListener('resize', this.handleResize)
  }

  getJob(jobId) {
    let jobState = this.props.jobState
    this.setState({
      thumbnailUrl: jobState.thumbnailUrl,
      jobTitle: jobState.jobTitle,
      compensation: jobState.compensation,
      employer: jobState.employer,
      companyOverview: jobState.companyOverview,
      jobType: jobState.jobType,
      description: jobState.description,
      keywords: jobState.keywords,
      timeZone: jobState.timeZone,
      activeLoc: jobState.activeLoc,
      venue: jobState.venue,
      refFilesURL: jobState.refFilesURL,
      instructions: jobState.instructions,
      qualifications: jobState.qualifications,
      disclaimers: jobState.disclaimers,
      benefits: jobState.benefits,
      userId: jobState.userId,
      jobId: jobState.jobId,
      applicationDeadline: jobState.applicationDeadline ? new Date(jobState.applicationDeadline) : null,
      endDate: new Date(jobState.endDate),
      meetingLink: jobState.meetingLink,
      meetingDetails: jobState.meetingDetails,
      location: jobState.location,
      visibility: jobState.visibility,
      sourceJobLink: jobState.sourceJobLink,
      liked: jobState.liked,
    });
  }

  getJobAPI(jobId) {
    var cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser) {
      var self = this;
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          const authToken = session.getIdToken().getJwtToken();
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const loggedInUserId = result[3].Value;
              const api = _config.api.invokeUrl + '/getjob?jobId=' + jobId + '&userId=' + loggedInUserId;
              const axiosConfig = {
                headers: {
                  "Authorization": authToken,
                }
              };
              axios
                .get(api, axiosConfig)
                .then((response) => {
                  console.log(response);
                  self.setState({
                    thumbnailUrl: response.data.thumbnailUrl,
                    thumbnail: response.data.thumbnailUrl ? { name: 'jobId_' + jobId + '_thumbnail' } : undefined,
                    jobTitle: response.data.jobTitle,
                    compensation: response.data.compensation,
                    employer: response.data.employer,
                    companyOverview: response.data.companyOverview,
                    jobType: response.data.jobType,
                    description: response.data.description,
                    location: response.data.location,
                    keywords: response.data.keywords,
                    timeZone: response.data.timeZone,
                    activeLoc: response.data.activeLoc,
                    venue: response.data.venue,
                    refFilesURL: response.data.refFilesURL,
                    instructions: response.data.instructions,
                    qualifications: response.data.qualifications,
                    disclaimers: response.data.disclaimers,
                    benefits: response.data.benefits,
                    userId: response.data.userId,
                    jobId: response.data.JobId,
                    applicationDeadline: response.data.applicationDeadline ? new Date(response.data.applicationDeadline) : '',
                    endDate: new Date(response.data.endDate),
                    meetingLink: response.data.meetingLink,
                    meetingDetails: response.data.meetingDetails,
                    address: response.data.venue ? response.data.venue.formatted_address : '',
                    visibility: response.data.visibility,
                    liked: response.data.liked,
                    sourceJobLink: response.data.sourceJobLink,
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
        }
      });
    }
  }

  getUser() {
    var cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      let self = this;
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              if (result[3].Name === 'custom:userId' && result[3].Value) {
                self.setState({ userId: result[3].Value });
              }
            }
          }
          );
        }
      });
    }

  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }


  onPlaceChanged() {
    let place = this.autocomplete.getPlace();
    this.setState({ venue: place, address: place.name + ', ' + place.formatted_address });
    console.log(place);
  }


  toggleActive(key) {
    const locType = this.state.locType[key];
    const previousActiveLoc = this.state.activeLoc;
    if (previousActiveLoc === locTypeOptions.VIRTUAL) {
      this.componentDidMount();
    }
    this.setState({ activeLoc: locType });
    return this.handleVenueSelector()
  }

  handleVenueSelector() {
    if (this.state.activeLoc === "In Person") {
      return;
    }
  }

  toggleActiveStyles(key) {
    if (this.state.locType[key] === this.state.activeLoc) {
      return styles.boxButton + ' ' + styles.active;
    } else {
      return styles.boxButton + ' ' + styles.inactive;
    }
  }

  async submitForm(e) {
    e.preventDefault();
    var self = this;
    const { jobTitle, description, compensation, activeLoc, applicationDeadline, endDate, meetingLink } = this.state;
    this.setState({ allRequiredComplete: true }); // assume innocent until proven guilty

    var onSuccess = function registerSuccess(result, self) {
      var jobId = result.data.jobId;
      console.log('job id is ' + jobId);
      var confirmation = ('job id created!');
      if (confirmation) {
        console.log('Done. Going to job.');
        window.location.href = `/job/${jobId}`;
      }
    };

    var onFailure = function registerFailure(err) {
      alert(err.message);
    };

    const data = {
      "jobId": this.state.jobId || this.props.jobId,
      "userId": this.state.userId,
      'thumbnailUrl': this.state.thumbnailUrl,
      "jobTitle": this.state.jobTitle,
      "compensation": this.state.compensation,
      "employer": this.state.employer,
      "jobType": this.state.jobType,
      "description": this.state.description,
      "keywords": this.state.keywords,
      "applicationDeadline": this.state.applicationDeadline ? this.state.applicationDeadline : Date.now(),
      "startTime": moment(this.state.applicationDeadline).unix(),
      "endDate": this.state.endDate ? this.state.endDate : Date.now(),
      "endTime": this.state.endTime,
      "timeZone": this.state.timeZone,
      "refFilesURL": this.state.refFilesURL,
      "instructions": this.state.instructions,
      "qualifications": this.state.qualifications,
      "disclaimers": this.state.disclaimers,
      "benefits": this.state.benefits,
      "activeLoc": this.state.activeLoc,
      "venue": this.state.venue,
      "meetingLink": this.state.meetingLink,
      "meetingDetails": this.state.meetingDetails,
      "visibility": this.state.visibility,
      "location": this.state.location,
      "companyOverview": this.state.companyOverview,
      "sourceJobLink": this.state.sourceJobLink,
    };


    if (jobTitle && description) {

      var cognitoUser = this.userPool.getCurrentUser();

      if (cognitoUser) {
        cognitoUser.getSession(function sessionCallback(err, session) {
          if (err) {
            console.log(err);
          } else if (!session.isValid()) {
            console.log('invalid sess');
          } else {
            const authToken = session.getIdToken().getJwtToken();

            const api = _config.api.invokeUrl + '/createjob';
            const axiosConfig = {
              headers: {
                "Authorization": authToken,
              }
            };
            axios
              .post(api, data, axiosConfig)
              .then((response) => {
                console.log(response);
                onSuccess(response, self);
              })
              .catch((error) => {
                console.log(error);
                onFailure(error);
              });
          }
        });
      }
    } else {
      this.setState({ allRequiredComplete: false });
    }
  };

  handleAutocompleteChange(text) {
    if (window.google.maps.places && !this.autoComplete) {
      this.componentDidMount();
    }
    this.setState({ address: text });
  }

  handleThumbnailChange(thumbnail) {
    this.setState({ thumbnail: thumbnail });
  }

  handleSourceJobLinkChange(text) {
    this.setState({ sourceJobLink: text.target.value });
  }

  handleThumbnailUrlChange(thumbnailUrl) {
    this.setState({ thumbnailUrl: thumbnailUrl });
  }

  handleJobTitleChange(text) {
    this.setState({ jobTitle: text.target.value });
  }

  handleCompensationChange(text) {
    this.setState({ compensation: text.target.value });
  }

  handleInstitutionChange(text) {
    this.setState({ employer: text.target.value });
  }

  handleCompanyOverviewChange(text) {
    this.setState({ companyOverview: text.target.value });
  }

  handleJobTypeChange(option) {
    this.setState({ jobType: option })
  }

  handleDescriptionChange(text) {
    this.setState({ description: text.target.value });
  }

  handleKeywordsChange(text) {
    this.setState({ keywords: text });
  }

  handleApplicationDeadlineChange(datetime) {
    this.setState({
      applicationDeadline: datetime,
    });

  }

  handleEndDateChange(datetime) {
    this.setState({
      endDate: datetime,
    });
  }

  handleTimeZoneChange(option) {
    this.setState({ timeZone: option });
  }

  handleRefFilesChange(files) {
    this.setState({ refFiles: files })
  }

  handleInstructionsChange(text) {
    this.setState({ instructions: text.target.value });
  }

  handleQualificationsChange(event) {
    this.setState({ qualifications: event.target.value });
  }

  handleDisclaimersChange(event) {
    this.setState({ disclaimers: event.target.value });
  }

  handleBenefitsChange(event) {
    this.setState({ benefits: event.target.value });
  }

  handleMeetingLinkChange(url) {
    this.setState({ meetingLink: url.target.value, address: this.state.address });
  }

  handleLocationChange(event) {
    this.setState({ location: event.target.value });
  }

  handleMeetingDetailsChange(text) {
    this.setState({ meetingDetails: text })
  }

  handleVisibilityChange(option) {
    this.setState({ visibility: option });
  }

  setDiscardPopup(bool) {
    this.setState({ discardPopup: bool });
  }

  setSubmitPopup(bool) {
    this.setState({ submitPopup: bool });
  }


  render() {
    return (
      <>
        <form onSubmit={(e) => this.submitForm(e)}>
          <TextField
            id='jobTitle'
            label="Job Title"
            variant='standard'
            autoFocus
            fullWidth
            multiline
            onChange={this.handleJobTitleChange}
            value={this.state.jobTitle}
            required={true}
            margin='normal'
            error={!this.state.allRequiredComplete && !this.state.jobTitle}
          />
          <TextField
            id='description'
            label="Description"
            variant='standard'
            fullWidth
            multiline
            onChange={this.handleDescriptionChange}
            value={this.state.description}
            required={true}
            margin='normal'
            error={!this.state.allRequiredComplete && !this.state.description}
          />
          <TextField
            id='sourceJobLink'
            label="Source job link"
            variant='standard'
            fullWidth
            multiline
            onChange={this.handleSourceJobLinkChange}
            value={this.state.sourceJobLink}
            required={true}
            margin='normal'
            error={!this.state.allRequiredComplete && !this.state.sourceJobLink}
          />

          {/* <h3 style={{ marginTop: '24px', marginBottom: '0px' }}>Speaker(s)</h3> */}
          <Box sx={{ display: 'flex' }}>
            <TextField
              label="Compensation"
              id='compensation'
              fullWidth
              variant='standard'
              margin='normal'
              multiline
              onChange={this.handleCompensationChange}
              value={this.state.compensation}
              required={false}
              error={!this.state.allRequiredComplete && !this.state.compensation}
            />

            <TextField
              sx={{ ml: '8px' }}
              label="Employer"
              id='employer'
              fullWidth
              variant='standard'
              margin='normal'
              placeholder='Company, institution, etc.'
              onChange={this.handleInstitutionChange}
              required={true}
              value={this.state.employer}
            />
          </Box>
          <TextField
            label="Company overview"
            id='company-overview'
            fullWidth
            multiline
            variant='standard'
            required={false}
            margin='normal'
            onChange={this.handleCompanyOverviewChange}
            value={this.state.companyOverview}
          />

          <>
            <Typography>
              Enter times in your local time zone:
              <span style={{ color: 'green' }}>{` ${moment.tz(moment.tz.guess()).format('z')} - ${moment.tz.guess()}`}</span>
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                className={styles.dateTimePicker}
                renderInput={(props) => <TextField {...props} margin='none' variant='standard' />}
                label="Application deadline"
                minDate={new Date()}
                maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 4))}
                value={this.state.applicationDeadline ? this.state.applicationDeadline : null}
                onChange={this.handleApplicationDeadlineChange}
                clearable
                required={false}
              />
            </LocalizationProvider>
          </>

          {(!this.state.allRequiredComplete && !this.state.applicationDeadline) &&
            <div style={{ color: 'red' }}>
              *** Set the application deadline. ***
            </div>

          }
          <Box sx={{ display: 'flex' }}>
            <TextField
              sx={{ mt: '16px' }}
              id='location'
              label='Location'
              fullWidth
              variant='standard'
              margin='none'
              onChange={this.handleLocationChange}
              value={this.state.location}
              required={true}
            />
            <TextField
              sx={{ ml: '8px' }}
              id='instructions'
              label="Instructions"
              variant='standard'
              fullWidth
              multiline
              onChange={this.handleInstructionsChange}
              value={this.state.instructions}
              margin='normal'
              placeholder='Instructions for applying'
            />
          </Box>
          <Box sx={{ display: 'flex' }}>
            <TextField
              id='qualifications'
              label="Qualifications"
              variant='standard'
              fullWidth
              multiline
              onChange={this.handleQualificationsChange}
              value={this.state.qualifications}
              margin='normal'
              placeholder='Qualifications for applying'
            />
            <TextField
              sx={{ ml: '8px' }}
              id='disclaimers'
              label="Disclaimers"
              variant='standard'
              fullWidth
              multiline
              onChange={this.handleDisclaimersChange}
              value={this.state.disclaimers}
              margin='normal'
              placeholder='Disclaimers for applying'
            />
          </Box>
          <TextField
            id='benefits'
            label="Benefits"
            variant='standard'
            fullWidth
            multiline
            onChange={this.handleBenefitsChange}
            value={this.state.benefits}
            margin='normal'
            placeholder='Job benefits'
          />

          <Box sx={{ color: '#0288D1' }}>
            *required fields
          </Box>
          <div className={styles.submitDivCreateTalk}>
            <Button
              type="button"
              variant='contained'
              onClick={() => this.setDiscardPopup(true)}
              sx={{
                background: 'white',
                border: '2px solid #FEBA3F',
                ":hover": {
                  bgcolor: '#eeeeee',
                }
              }}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ color: '#1B065E', ml: '12px', }}
            >
              Post
            </Button>
          </div>
          <Popup
            trigger={this.state.discardPopup}
            setTrigger={this.setDiscardPopup}
          >
            <Typography variant='h3'>Discard your entry?</Typography>
            <div className={styles.popupButtonDiv}>
              <Button
                type="button"
                variant='contained'
                className={styles.goBackButton}
                onClick={() => this.setDiscardPopup(false)}
                sx={{
                  background: 'white',
                  border: '2px solid #FEBA3F',
                  ":hover": {
                    bgcolor: '#eeeeee',
                  }
                }}>Cancel</Button>
              <Button
                variant="contained"
                className={styles.discardPopupButton}
                sx={{ color: '#1B065E', ml: '12px', }}
                onClick={this.props.closeCreateJobPopup}
              >
                Discard
              </Button>

            </div>
          </Popup>
        </form>
      </>
    );
  }

}

export default CreateJobForm;