export var gapi = window.gapi
export var CLIENT_ID = '183240475397-s59efn9fbm8c2uem6580dla7cibud0h0.apps.googleusercontent.com'
export var API_KEY = 'AIzaSyA7rQqsJ-IfmKiI4WuzOVW3mykFulvQocw'
export var DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest']
export var SCOPES = 'https://www.googleapis.com/auth/calendar.events'

export const timeZoneToIanaTimezone = {
  'EDT': 'America/New_York',
  'CDT': 'America/Chicago',
  'MDT': 'America/Denver',
  'MST': 'America/Phoenix',
  'PDT': 'America/Los_Angeles',
  'ADT': 'America/Anchorage',
  'HST': 'Pacific/Honolulu',
};