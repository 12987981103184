import { IconButton, Tooltip } from '@mui/material';
import { FavoriteBorder } from '@mui/icons-material';
import Comments from '../Comments/Comments';
import React, { Component } from 'react'
import styles from './PaperCommentsStyles.module.css'
import RequestService from "../../requestservice/requestservice";

export default class PaperComments extends Comments {

  constructor(props) {
    super(props);
    this.paperId = this.props.paperId;
  }

  async getComments() {
    this.requestService.getCommentsForPaper(this.paperId, (response) => this.setState({ comments: response, loading: false }));
  }

  async addComment() {
    this.setState({addCommentButtonDisabled: true});
    await this.requestService.addCommentToPaper({ paperId: this.paperId, comment: this.state.newComment, },
      (newComment) => { this.addCommentToCommentsUi(newComment) });
  }
}
