import React, { Component } from "react";
//FOR FORGOT PASSWORD
import { Amplify, Auth } from 'aws-amplify';
import awsExports from "../../aws-exports";
//FOR FORGOT PASSWORD
import SignedOutNav from '../components/SignedOutNav/SignedOutNav';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import _config from "../signup/config";
import { TextField } from '../components/text-field';
import {withRouter} from "react-router-dom";
import "./resetpassword.css";

Amplify.configure(awsExports);

class Reset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationSent: false,
      emailValue: '',
      password: '',
      verificationCode: '',
      confirmPassword: ''
    };

    var poolData = {
      UserPoolId: _config.cognito.userPoolId,
      ClientId: _config.cognito.userPoolClientId,
    };

    this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleVerificationCodeChange = this.handleVerificationCodeChange.bind(this);
    this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
  }

  handleEmailChange(text) {
    if (text !== null) {
      this.setState({ emailValue: text });
    }
  }

  handlePassword(text) {
    if (text !== null) {
      this.setState({ password: text });
    }
  }

  handleVerificationSent() {
    this.state.verificationSent = true;
  }

  handleVerificationCodeChange(text) {
      this.setState({ verificationCode: text });
  }

  handleConfirmPassword(text) {
    if (text !== null) {
      this.setState({ confirmPassword: text });
    }
  }

  render() {
    const handleForgotPassword = async () => {
      try {
        await Auth.forgotPassword(this.state.emailValue.toLowerCase())
        .then(data =>  {console.log("Data: ", data)}, alert("A verification code has been sent to your email. If you have yet to recieve the verification in 10 minutes, you will need to contact symposia.site@gmail.com to have your password changed manually."), handleVerificationSent())
        .catch(err => alert(err), console.log("Error #1: ", this.state.emailValue.toLowerCase()),);
      } catch(error) {
        console.log("Error #2")
        alert(error);
      }
    }
  
    const handlePasswordVerification = async () => {
      try {
        await Auth.forgotPasswordSubmit(this.state.emailValue.toLowerCase(), this.state.verificationCode, this.state.password)
        .then(alert("Your password has been successfully changed."), this.props.history.push('/signin'))
        .catch(console.log("Error #3"), alert("Something went wrong. Please be sure you entered all information correctly. If the issue continues, please contact symposia.site@gmail.com for help."));
      } catch(error) {
        console.log("Error #4");
        alert("Something is wrong with one of your entries. Please be sure you entered all information correctly. If the issue continues, please contact symposia.site@gmail.com for help.");
      }
    }

    const handleVerificationSent = () => {
      this.setState({verificationSent: true})
    }
    /*const handleForgotPassword = () => {
      try {
      var userData = {
        Username: this.state.emailValue.toLowerCase(),
        Pool: this.userPool,
      }
  
      var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  
      cognitoUser.forgotPassword({
        onSuccess: () => {
          alert("A verification code has been sent to your email. If you have yet to recieve the verification in 10 minutes, you will need to contact symposia.site@gmail.com to have your password changed manually.");
          handleVerificationSent();
        },
        onFailure: (error) => {
          alert(error);
        },
      })
      } catch(error) {
        alert(error);
      }
    }
  
    const handlePasswordVerification = () => {
      try {
      var userData = {
        Username: this.state.emailValue.toLowerCase(),
        Pool: this.userPool,
      }

      var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  
      cognitoUser.confirmPassword((this.state.verificationCode, this.state.password, this),
        {
          onSuccess: () => {
            alert("Your password has been successfully changed.");
            this.props.history.push('/signin');
          },
          onFailure: () => {
            alert("Something went wrong. Please be sure you entered all information correctly. If the issue continues, please contact symposia.site@gmail.com for help.");
          },
        }
      )
      } catch(error) {
        alert("Something is wrong with one of your entries. Please be sure you entered all information correctly. If the issue continues, please contact symposia.site@gmail.com for help.");
      }
    }

    const handleVerificationSent = () => {
      this.setState({verificationSent: true})
    }*/

    return (
      <>
        <SignedOutNav/>
        <div className = "wrapper">
          <div className = "topText">
            {(!this.state.verificationSent) && (
              <>
                <div className="resetText">Forgot your password?</div>
                <small>Please enter your email to begin resetting your password.</small>
              </>
            )}
            {(this.state.verificationSent) && (
              <>
                <div className="resetText">Set your new password</div>
                <small>Please enter your verafication code sent to your email, your email, and new password below.</small>
              </>
            )}
          </div>
          <div className = "resetWrapper">
            {(!this.state.verificationSent) && (<div>
              <TextField
                placeholder='Enter Email'
                onChange={this.handleEmailChange}
                value={this.state.emailValue}
                type='email'
              />
              <button type = "button" className = "button" onClick = {handleForgotPassword} disabled = {!this.state.emailValue}>Submit</button>
            </div>)}
            {(this.state.verificationSent) && (<div>
              <TextField
                placeholder='Enter Verification Code'
                onChange={this.handleVerificationCodeChange}
                value={this.state.verificationCode}
                type = 'text'
              />
              <TextField
                placeholder='Enter Email'
                onChange={this.handleEmailChange}
                value={this.state.emailValue}
                type='email'
              />
              <TextField
                placeholder='New Password'
                onChange={this.handlePassword}
                value={this.state.password}
                type='password'
              />
              <TextField
                placeholder='Confirm Password'
                onChange={this.handleConfirmPassword}
                value={this.state.confirmPassword}
                type='password'
              />
              <button type = "button" className = "button" onClick = {handlePasswordVerification} disabled = {(!this.state.verificationCode) || (!this.state.password) || (!this.state.confirmPassword) || (this.state.password !== this.state.confirmPassword) || (!this.state.emailValue)}>Submit</button>
            </div>)}
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(Reset)