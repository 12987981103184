import React from 'react'
import Logo from '../logo/logo.jsx';
import styles from './staticNavStyles.module.css'
import { Link } from "react-router-dom";

export default function StaticNav(navTitle) {
    return (
        <div className={styles.navBar}>
            <Logo />
            <div className={styles.titleDiv}>
                {Object.values(navTitle)}
            </div>
        </div>
    )
}
