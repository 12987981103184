import Comment from '../Comment/Comment';
import React, { Component } from 'react'
import styles from './CommentWrapperStyles.module.css'
import RequestService from "../../requestservice/requestservice";

export default class CommentWrapper extends Component {

  constructor(props) {
    super(props);
    this.state = {
      commentUserId: this.props.commentUserId,
      numCommentLikes: 0,
      selfUserId: this.props.selfUserId,
      selfUserLikedComment: false,
      user: {},
    };
    this.comment = this.props.comment;
    this.commentCreationTime = this.props.commentCreationTime;
    this.commentId = this.props.commentId;
    
    this.requestService = new RequestService();

    this.deleteComment = this.deleteComment.bind(this);
    this.getCommentLikes = this.getCommentLikes.bind(this);
    this.getUserLite = this.getUserLite.bind(this);
    this.likeComment = this.likeComment.bind(this);
    this.unlikeComment = this.unlikeComment.bind(this);
    this.setNumLikesAndSelfUserLikedCallback = this.setNumLikesAndSelfUserLikedCallback.bind(this);
}

  componentDidMount() {
    this.getUserLite();
    this.getCommentLikes();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.selfUserId !== prevProps.selfUserId) {
      this.setState({ selfUserId: this.props.selfUserId });
    }
  }

  async getUserLite() {
    await this.requestService.getUserLite(this.state.commentUserId, (userData) => this.setState({ user: userData }));
  }

  setNumLikesAndSelfUserLikedCallback(commentLikesData) {
    const numCommentLikes = commentLikesData && commentLikesData.data ? commentLikesData.data.Count : 0;
    const selfUserLikedComment = commentLikesData && commentLikesData.data ? commentLikesData.data.selfUserLikedComment : false;
    this.setState({ numCommentLikes: numCommentLikes, selfUserLikedComment: selfUserLikedComment });
  }

  likeCommentUpdateUiCallback() {
    const incrementedNumCommentLikes = this.state.numCommentLikes + 1;
    this.setState({ selfUserLikedComment: true, numCommentLikes: incrementedNumCommentLikes });
  }

  unlikeCommentUpdateUiCallback() {
    const decrementedNumCommentLikes = this.state.numCommentLikes - 1;
    this.setState({ selfUserLikedComment: false, numCommentLikes: decrementedNumCommentLikes });
  }

  async getCommentLikes() { }

  async likeComment() { }
  
  async unlikeComment() { }

  async deleteComment() {}

  render() {
    return (
      <div>
        <Comment
          commentUserId={this.state.commentUserId}
          firstName={this.state.user.firstName}
          lastName={this.state.user.lastName}
          position={this.state.user.jobTitle}
          organization={this.state.user.organization}
          pictureUrl={this.state.user.profilePictureUrl}
          numberOfLikes={this.state.numCommentLikes}
          comment={this.comment}
          selfUserLikedComment={this.state.selfUserLikedComment}
          deleteComment={this.deleteComment}
          likeComment={this.likeComment}
          unlikeComment={this.unlikeComment}
          commentCreationTime={this.commentCreationTime}
          selfUserId={this.state.selfUserId}
        />
      </div>

    );
  }
}
