import React from 'react';
import styles from './popup.module.css';
import close_icon from '../../../images/close_icon.png';
import { Close } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

export default function Popup(props) {

  return (props.trigger) ? (
    <div className={styles.popup}>
      <div className={styles.popupInner}>
        <Tooltip title='Close'>
          <Close sx={{fontSize: '24px'}} className={styles.closeIcon} aria-label='Close Icon' onClick={() => props.setTrigger(false)}/>
        </Tooltip>
        { props.children }
      </div>
    </div>
  ) : "";
}
