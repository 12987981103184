import React, { Component } from 'react'
import styles from './NotFoundStyles.module.css'
import notFoundImage from '../../../images/page_not_found_figure.png'
import Logo from '../logo/logo'

export default class NotFound extends Component {
  render() {
    return (
      <div className={styles.NotFound}>
        <Logo />
        <img src={notFoundImage} alt={'Page not found'} className={styles.Image}/>
        <h2>404 Error: Page not found</h2>
        <button className={styles.BackButton} onClick={() => this.props.history.goBack()}>
          Take me back
        </button>
      </div>
    )
  }
}
