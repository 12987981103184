import React, { Component, useEffect } from 'react'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import _config from "../../signup/config";
import { Link } from 'react-router-dom';
import UserCardFeed from '../UserCardFeed/UserCardFeed';
import styles from './PaperCardStyles.module.css';
import { Bookmark, BookmarkBorderOutlined, CommentOutlined, Favorite, FavoriteBorderOutlined, Info, OndemandVideo, ReadMore, ReadMoreOutlined } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import RequestService from "../../requestservice/requestservice";
import PaperComments from '../PaperComments/PaperComments';
import moment from 'moment';
import ArticleIcon from '@mui/icons-material/Article';

export default class PaperCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saved: this.props.saved,
      liked: this.props.liked,
      showMore: true,
      read: this.props.read,
      paperId: this.props.paperId,
      showComments: false,
    }

    var poolData = {
      UserPoolId: _config.cognito.userPoolId,
      ClientId: _config.cognito.userPoolClientId,
    };

    this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    this.clickedCommentButton = this.clickedCommentButton.bind(this);
    this.showMoreText = this.showMoreText.bind(this);
    this.showLessText = this.showLessText.bind(this);
    this.whenUnsaveClicked = this.whenUnsaveClicked.bind(this);
    this.whenSaveClicked = this.whenSaveClicked.bind(this);
    this.whenLikeClicked = this.whenLikeClicked.bind(this);
    this.whenUnlikeClicked = this.whenUnlikeClicked.bind(this);
    this.whenUnreadClicked = this.whenUnreadClicked.bind(this);
    this.goToPaper = this.goToPaper.bind(this);
    this.goToVideo = this.goToVideo.bind(this);
    this.ref = React.createRef();
    this.requestService = new RequestService();
  }

  async whenUnsaveClicked(evt) {
    evt.preventDefault();
    this.setState({ saved: false });
    const data = { paperId: this.props.paperId }
    await this.requestService.removeSavedPaper(data, this);
    this.props.handleRemoveSavedPaper(this.props.paperId);
  }

  async whenSaveClicked(evt) {
    evt.preventDefault();
    const cognitoUser = this.userPool.getCurrentUser();
    if (!cognitoUser) {
      window.location.href = '/signin';
      return;
    }
    this.setState({ saved: true });
    const data = { paperId: this.props.paperId };
    await this.requestService.addSavedPaper(data, this);
    // add to mini profile as well
    const paper = {
      PaperId: this.props.paperId,
      semanticPaperId: this.props.semanticPaperId,
      paperUrl: this.props.paperUrl,
      paperImageUrl: this.props.paperImageUrl,
      userId: this.props.userId,
      title: this.props.title,
      year: this.props.year,
      authors: this.props.authors,
      tldr: this.props.tldr,
      videoLink: this.props.videoLink,
      keywords: this.props.keywords,
      chatGptMainFinding: this.props.chatGptMainFinding,
      chatGptSummary: this.props.chatGptSummary,
      RequestTime: this.props.requestTime,
      saved: this.state.saved,
      read: this.state.read,
      liked: this.state.liked,
    };
    this.props.handleAddedSavedPaper(paper);

    alert("View saved papers in your profile, where you can add notes.");
  }

  async whenLikeClicked(evt) {
    evt.preventDefault();
    const cognitoUser = this.userPool.getCurrentUser();
    if (!cognitoUser) {
      window.location.href = '/signin';
      return;
    }
    this.setState({ liked: true });
    const data = { paperId: this.props.paperId };
    await this.requestService.addLikedPaper(data, this);
  }

  async whenUnlikeClicked(evt) {
    evt.preventDefault();
    this.setState({ liked: false });
    const data = { paperId: this.props.paperId }
    await this.requestService.removeLikedPaper(data, this);
  }

  async whenUnreadClicked(evt) {
    evt.preventDefault();
    this.setState({ read: false });
    const data = { paperId: this.state.paperId }
    await this.requestService.removeReadPaper(data, this);
  }

  goToPaper(evt) {
    evt.preventDefault();
    window.open(this.props.paperUrl);
    this.requestService.addReadPaper({ paperId: this.state.paperId }, this);
  }

  goToVideo(e) {
    e.preventDefault();
    window.open(this.props.videoLink);
  }

  clickedCommentButton(e) {
    e.preventDefault();
    const cognitoUser = this.userPool.getCurrentUser();
    if (!cognitoUser) {
      window.location.href = '/signin';
      return;
    }
    this.setState({ showComments: !this.state.showComments });
  }

  showMoreText() {
    this.setState({ showMore: false });
  }

  showLessText() {
    this.setState({ showMore: true });
  }

  render() {
    const { userId, paperImageUrl, paperUrl, requestTime, paperId, title, year, authors, tldr, fullLeftBar, allUserItems, semanticPaperId, videoLink, activeComp, height, width } = this.props;
    const { saved, liked, read } = this.state;
    const firstAuthorArray = authors[0].split(' ');
    const firstAuthorFirstInitial = firstAuthorArray[0].charAt(0);
    const firstAuthorLastName = firstAuthorArray[firstAuthorArray.length - 1];
    var a = moment();//now
    var b = moment(requestTime);
    var inSeconds = a.diff(b, 'seconds');
    var inMinutes = a.diff(b, 'minutes');
    var inHours = a.diff(b, 'hours');
    var inDays = a.diff(b, 'days');
    var inWeeks = a.diff(b, 'weeks');
    const postTime = inWeeks > 0 ? b.format('MMM D') : (inDays > 0 ? `${inDays}d` : (inHours > 0 ? `${inHours}h` : (inMinutes > 0 ? `${inMinutes}m` : `${inSeconds}s`)));

    return (
      <div className={styles.PaperDiv}>
        <Link style={{ textDecoration: 'none', color: 'black', background: "red", zIndex: 11 }}
          to={{
            pathname: `/paper/${paperId}`,
            paperProps: this.props,
            paperState: this.state
          }}>
          <div className={styles.PaperText}>
            <div className={styles.UserCardAndPostTime}>
              {userId &&
                <>
                  {userId !== 'dOIL81did2gwO6Wz5Afbcw' ?
                    <UserCardFeed
                      userId={userId}
                      allUserItems={allUserItems} />
                    :
                    <div className={styles.Info}>
                      <Info sx={{ fontSize: '16px', color: '#5591C9', marginRight: '2px' }} />
                      <span>This paper was sourced from a public website as highly cited.</span>
                    </div>
                  }
                </>
              }
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <ArticleIcon style={{ color: '#006F82' }}></ArticleIcon>
                <div className={styles.PostTime}>
                  {postTime}
                </div>
              </Box>
            </div>
            <div className={styles.PaperImageDiv}>
              {paperImageUrl &&
                <img className={styles.PaperImage} src={paperImageUrl} /> // need to do something with videos
              }
            </div>
            <div className={styles.Title}>
              {authors.length > 1 ?
                <Typography variant='h3'>{firstAuthorLastName}, {firstAuthorFirstInitial}. et al., "{title}"</Typography>
                :
                <Typography variant='h3'>{firstAuthorLastName}, {firstAuthorFirstInitial}, {year}, "{title}"</Typography>}
            </div>

            {(tldr.length < 250) && (<p className={styles.Tldr}>
              <Typography variant='body1'>{tldr}</Typography>
            </p>)}
            {(tldr.length >= 250) && (<p className={styles.Tldr}>
              {this.state.showMore ? `${tldr.substring(0, 250)}...` : tldr}
              {this.state.showMore ? <button type="button" className={styles.showButton} onClick={this.showMoreText}>Read</button> : <button type="button" className={styles.showButton} onClick={this.showLessText}>Show Less</button>}
            </p>)}
            {this.state.showComments && <PaperComments paperId={this.state.paperId} />}
          </div>
        </Link>
        <div className={styles.Interactions}>
          {liked ?
            <Tooltip title='Remove Like'>
              <button className={styles.InteractionButton + ' ' + styles.Liked} onClick={this.whenUnlikeClicked}>
                {fullLeftBar ?
                  <>
                    <Favorite sx={{ mr: '8px', fontSize: '24px', color: '#1B065E' }} />
                    {window.innerWidth > 768 && 'Liked'}
                  </>
                  :
                  <>
                    <Favorite sx={{ fontSize: '24px', color: '#1B065E' }} />
                  </>}
              </button>
            </Tooltip>
            :
            <>
              {fullLeftBar ?
                <button className={styles.InteractionButton} onClick={this.whenLikeClicked}>
                  <FavoriteBorderOutlined sx={{ mr: '8px', fontSize: '24px', color: '#1B065E' }} />
                  {window.innerWidth > 768 && 'Like'}
                </button>
                :
                <Tooltip title='Like'>
                  <button className={styles.InteractionButton} onClick={this.whenLikeClicked}>
                    <FavoriteBorderOutlined sx={{ fontSize: '24px', color: '#1B065E' }} />
                  </button>
                </Tooltip>}
            </>
          }
          {paperUrl &&
            <div>
              {videoLink ?
                <>
                  {fullLeftBar ?
                    <button className={styles.InteractionButton + ' ' + styles.Interacted} onClick={this.goToVideo}>
                      <OndemandVideo sx={{ mr: '8px', fontSize: '24px' }} />
                      {window.innerWidth > 768 && 'Watch'}
                    </button>
                    :
                    <Tooltip title='Watch Video'>
                      <button className={styles.InteractionButton + ' ' + styles.Interacted} onClick={this.goToVideo}>
                        <OndemandVideo sx={{ fontSize: '24px' }} />
                      </button>
                    </Tooltip>
                  }
                </>
                :
                <>
                  {read ?
                    <>
                      <button className={styles.InteractionButton + ' ' + styles.Interacted} onClick={this.whenUnreadClicked}>
                        <ReadMore sx={{ mr: '8px', fontSize: '24px' }} />
                        {window.innerWidth > 768 && 'Unread'}
                      </button>

                    </>
                    :
                    <>
                      <button className={styles.InteractionButton} onClick={this.goToPaper}>
                        <ReadMoreOutlined sx={{ mr: '8px', fontSize: '24px' }} />
                        {window.innerWidth > 768 && 'Read'}
                      </button>
                    </>
                  }
                </>
              }
            </div>
          }
          {saved ?
            <Tooltip title='Remove from Saved Papers'>
              <button className={styles.InteractionButton + ' ' + styles.Interacted} onClick={this.whenUnsaveClicked}>
                {fullLeftBar ?
                  <>
                    <Bookmark sx={{ mr: '8px', fontSize: '24px' }} />
                    {window.innerWidth > 768 && 'Saved'}
                  </>
                  :
                  <>
                    <Bookmark sx={{ fontSize: '24px' }} />
                  </>}
              </button>
            </Tooltip>
            :
            <>
              {fullLeftBar ?
                <button className={styles.InteractionButton} onClick={this.whenSaveClicked}>
                  <BookmarkBorderOutlined sx={{ mr: '8px', fontSize: '24px' }} />
                  {window.innerWidth > 768 && 'Save'}
                </button>
                :
                <Tooltip title='Save for Later'>
                  <button className={styles.InteractionButton} onClick={this.whenSaveClicked}>
                    <BookmarkBorderOutlined sx={{ fontSize: '24px' }} />
                  </button>
                </Tooltip>}
            </>
          }
          {fullLeftBar ?
            <button className={styles.InteractionButton} onClick={this.clickedCommentButton}>
              <CommentOutlined sx={{ mr: '8px', fontSize: '24px' }} />
              {window.innerWidth > 768 && 'Comment'}
            </button>
            :
            <Tooltip title='Comment'>
              <button className={styles.InteractionButton} onClick={this.clickedCommentButton}>
                <CommentOutlined sx={{ fontSize: '24px' }} />
              </button>
            </Tooltip>}
        </div>
      </div >
    )
  }
}
