import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import Amplify from "aws-amplify";
//import ForgotPassword from '../forgotpassword/forgotpassword.js';
import React, { useState, useEffect } from 'react';
import _config from "../signup/config.js";
import awsExports from "../../aws-exports.js";
import styles from './signinStyles.module.css';
import { Link } from "react-router-dom";
import { AppBar, Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
Amplify.configure(awsExports);
import Logo from '../components/logo/logo.jsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignIn = (props) => {
  const isMobile = window.innerWidth <= 768;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const poolData = {
    UserPoolId: _config.cognito.userPoolId,
    ClientId: _config.cognito.userPoolClientId,
  };

  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const theme = useTheme();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const createCognitoUser = (email) => {
    return new AmazonCognitoIdentity.CognitoUser({
      Username: toUsername(email),
      Pool: userPool
    });
  };

  const toUsername = (email) => {
    return email.replace('@', '-at-');
  };

  const signin = (event) => {
    if (!email || !password) {
      return;
    }
    event.preventDefault();
    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
      Username: toUsername(email),
      Password: password
    });
    var cognitoUser = createCognitoUser(email);

    var onSuccess = function signinSuccess() {
      console.log('Successfully Logged In');
      cognitoUser.getSession(function sessionCallback(err, session) {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log('invalid sess');
        } else {
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              console.log(err);
            } else {
              const userHasUserId = result[3] && result[3].Name === 'custom:userId' && result[3].Value;
              goNextPageLink(userHasUserId);
            }
          }
          );
        }
      });
    };
    var onFailure = function signinError(err) {
      toast.error(err.message);
    }
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: onSuccess,
      onFailure: onFailure,
    });
  };

  const goNextPageLink = (userHasUserId) => {
    const prevPathname = props.location.state?.from?.pathname;
    if (prevPathname) {
      window.location.href = prevPathname;
      return;
    }
    if (userHasUserId) {
      window.location.href = '/';
      return;
    }
    window.location.href = '/createaccount';
  };

  return (
    <div className={styles.signInContainer}>
      {isMobile && <AppBar position="fixed" elevation={0} style={{ zIndex: 1201, background: theme.palette.primary.mainGradient }}
      >
        <Logo width={'240px'} centerImg></Logo>
      </AppBar>}
      {!isMobile && <Box sx={{
        background: 'linear-gradient(to left, #FE9D44, #FFF500)', height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', color: 'black', ml: '100px' }}>
          <Logo></Logo>
          <Typography variant='body'>
            Discover. Discuss. Develop.
          </Typography>
        </Box>
      </Box>}
      <div className={styles.signInHolder}>
        <div className={styles.signInUi}>
          <form id="signinForm">
          <Box sx={{ mb: !isMobile ? '204px' : '0px'}}>
              <Typography variant='h1'>
                Log in
              </Typography>
            </Box>
            <TextField
              id='emailAddress'
              InputLabelProps={{ shrink: true }}
              label="Email"
              variant='standard'
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              margin='normal'
              type='email'
              className={styles.emailTextField}
              sx={{ width: '100%' }}
              required
            />
            <TextField
              id='password'
              InputLabelProps={{ shrink: true }}
              label="Password"
              variant='standard'
              autoFocus
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              margin='normal'
              type='password'
              className={styles.passwordTextField}
              sx={{ width: '100%' }}
              required
            />
            <Button
              sx={{
                background: '#FEBA3F',
                color: '#1B065E',
                width: '100%',
                boxShadow: '0px 0px 12px 0px #1B065E26',
                textTransform: 'none',
              }} type="submit" onClick={(e) => signin(e)}>
              <Typography variant='subtitle1'>
                Log in
              </Typography>
            </Button>
          </form>
          <Box sx={{ color: '#311860', textAlign: 'center', padding: '8px 0px' }}>
            <Typography variant='subtitle1'>or</Typography>
          </Box>
          <Link to='/signup'>
            <Button
              sx={{
                background: theme.palette.secondary.light,
                border: '3px solid #FEBA3F',
                color: '#1B065E',
                width: '100%',
                boxShadow: '0px 0px 12px 0px #1B065E26',
                textTransform: 'none',
              }} type="button">
              <Typography variant='subtitle1'>
                Sign up
              </Typography>
            </Button>
          </Link>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default SignIn;