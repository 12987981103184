import React, { useState, useEffect, useCallback, MouseEvent } from "react";
import { ChannelMetadataObject, ObjectCustom, BaseObjectsEvent } from "pubnub";
import Picker from '@emoji-mart/react'
import { usePubNub } from "pubnub-react";
import {
  ChannelList,
  Chat,
  MemberList,
  // MessageEnvelope,
  MessageInput,
  MessageList,
  TypingIndicator,
  useChannelMembers,
  useChannels,
  usePresence,
  useUser,
  useUserMemberships,
  useUsers,
} from "@pubnub/react-chat-components";
import PubNub from "pubnub";
import PersonIcon from '@mui/icons-material/Person';
import { CreateChatModal } from "./create-chat-modal";
import { ReportUserModal } from "./report-user-modal";
import { PublicChannelsModal } from "./public-channels-modal";
import "./moderated-chat.css";
import { Link } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import moment from 'moment';

// const defaultChannel = {
//   id: "space.149e60f311749f2a7c6515f7b34",
//   name: "Symposia Announcements",
//   description: "Announcements regarding Symposia",
// };

const defaultChannel = {
  id: null,
  name: null,
};
// const defaultChannel = {
//   id: "direct.PdT4dUHg8CUlZvjdepDK9g@Q49mSUEmTpBiMqd3ARLiUA",
//   name: "Devon Cash",
// };

export default function ModeratedChat({ messageCounts, setMessageCounts, isMobile }) {
  /**
   * Component state related hooks
   * Those mostly store the current channel, modals and side panels being shown
   */
  const [theme, setTheme] = useState("light");
  const [currentChannel, setCurrentChannel] = useState(defaultChannel);
  const [showMembers, setShowMembers] = useState(false);
  const [showChannels, setShowChannels] = useState(true);
  const [showPublicChannelsModal, setShowPublicChannelsModal] = useState(false);
  const [showCreateChatModal, setShowCreateChatModal] = useState(false);
  const [showReportUserModal, setShowReportUserModal] = useState(false);
  const [channelsFilter, setChannelsFilter] = useState("");
  const [membersFilter, setMembersFilter] = useState("");
  const [reportedMessage, setReportedMessage] = useState();

  /**
   * All data related to Users, Channels and Memberships is stored within PubNub Objects API
   * It can be easily accessed using React Chat Components hooks
   */
  const pubnub = usePubNub();
  const uuid = pubnub.getUUID();
  const [currentUser] = useUser({ uuid });
  const [allUsers] = useUsers({ include: { customFields: true } });
  const [allChannels] = useChannels({ include: { customFields: true } });
  const [joinedChannels, , refetchJoinedChannels] = useUserMemberships({
    include: { channelFields: true, customChannelFields: true, customFields: true },
  });
  const [channelMembers, , refetchChannelMemberships, totalChannelMembers] = useChannelMembers({
    channel: currentChannel.id,
    include: { customUUIDFields: true },
  });
  const [presenceData] = usePresence({
    channels: joinedChannels.length ? joinedChannels.map((c) => c.id) : [currentChannel.id],
  });

  /**
   * Some of the data related to current channel, current user and its' joined channels
   * has to be filtered down and mapped from the hooks data
   */
  const presentUUIDs = presenceData[currentChannel.id]?.occupants?.map((o) => o.uuid);
  const groupChannels = joinedChannels.filter(
    (c) =>
      c.id?.startsWith("space.") && c.name?.toLowerCase().includes(channelsFilter.toLowerCase())
  );
  const groupChannelsToJoin = allChannels.filter(
    (c) => c.id.startsWith("space.") && !joinedChannels.some((b) => c.id === b.id)
  );
  const directChannels = joinedChannels
    .filter((c) => c.id?.startsWith("direct.") || c.id?.startsWith("group."))
    .map((c) => {
      if (!c.id?.startsWith("direct.")) return c;
      const interlocutorId = c.id.replace(uuid, "").replace("direct.", "").replace("@", "");
      const interlocutor = allUsers.find((u) => u.id === interlocutorId);
      if (interlocutor) {
        c.custom = { thumb: interlocutor.profileUrl || "" };
        c.name = interlocutor.name;
      }
      return c;
    })
    .filter((c) => c.name?.toLowerCase().includes(channelsFilter.toLowerCase()));

  const isUserBanned = currentUser?.custom?.ban;
  const isUserMuted = (currentUser?.custom?.mutedChannels)
    ?.split(",")
    .includes(currentChannel.id);
  const isUserBlocked = (currentUser?.custom?.blockedChannels)
    ?.split(",")
    .includes(currentChannel.id);

  /**
   * Creating and removing channel memberships (not subscriptions!)
   */
  const leaveChannel = async (channel, event) => {
    event.stopPropagation();
    await pubnub.objects.removeMemberships({ channels: [channel.id] });
    setAnotherCurrentChannel(channel.id);
  };

  /**
   * Creating and removing channel memberships (not subscriptions!)
   */
  const handleMessage = async (message) => {
    if (message.channel === currentChannel.id || message.message.sender.id === uuid) {
      // set last read stamp. need to set after changing rooms
      const lastReadTimetoken = Date.now() * 10000;
      await pubnub.objects.setMemberships({
        channels: [{
          id: currentChannel.id,
          custom: {
            lastReadTimetoken,
          }
        }],
        uuid: pubnub.getUUID()
      });
      refetchJoinedChannels();
    } else {
      const messageCountsNew = { ...messageCounts };
      messageCountsNew[message.channel] = messageCountsNew[message.channel] ? messageCountsNew[message.channel] + 1 : 1;
      setMessageCounts(messageCountsNew);
    }
  };

  /**
 * Creating and removing channel memberships (not subscriptions!)
 */
  const switchChannel = async (channel, e) => {
    // set last read stamp. need to set after changing rooms
    const lastReadTimetoken = Date.now() * 10000;
    const messageCountsNew = { ...messageCounts };
    messageCountsNew[channel.id] = 0;
    if (currentChannel.id) {
      await pubnub.objects.setMemberships({
        channels: [{
          id: currentChannel.id,
          custom: {
            lastReadTimetoken,
          }
        }],
        uuid: pubnub.getUUID()
      });
      messageCountsNew[currentChannel.id] = 0;
    }
    await pubnub.objects.setMemberships({
      channels: [{
        id: channel.id,
        custom: {
          lastReadTimetoken,
        }
      }],
      uuid: pubnub.getUUID()
    });
    setMessageCounts(messageCountsNew);
    setCurrentChannel(channel);
    setShowChannels(false);
  };

  const setLastReadTimetoken = async () => {
    if (!currentChannel && !currentChannel?.id) {
      return;
    }
    if (currentChannel.id === 'default') {
      return;
    }

    const lastReadTimetoken = Date.now() * 10000;
    // Set last read time stamp to three seconds later so there's no message 
    // race condition.
    // const lastReadTimetoken = moment().valueOf() * 10000 + 10000;


    await pubnub.objects.setMemberships({
      channels: [{
        id: currentChannel.id,
        custom: {
          lastReadTimetoken,
        }
      }],
      uuid: pubnub.getUUID()
    });
  };

  const refreshMemberships = useCallback(
    (event) => {
      if (event.channel.startsWith("user_")) refetchJoinedChannels();
      if (event.channel === currentChannel.id) refetchChannelMemberships();
    },
    [currentChannel, refetchJoinedChannels, refetchChannelMemberships]
  );

  const setAnotherCurrentChannel = (channelId) => {
    if (currentChannel.id === channelId) {
      const newCurrentChannel = joinedChannels?.find((ch) => ch.id !== channelId);
      if (newCurrentChannel) setCurrentChannel(newCurrentChannel);
    }
  };

  /**
   * Handling publish errors
   */
  const handleError = (e) => {
    if (
      (e.status?.operation === "PNPublishOperation" && e.status?.statusCode === 403) ||
      e.message.startsWith("Publish failed")
    ) {
      alert(
        "Your message was blocked. Perhaps you tried to use offensive language or send an image that contains nudity?"
      );
    }
  };

  useEffect(() => {
    if (currentChannel.id === "default" && joinedChannels.length)
      setCurrentChannel(joinedChannels[0]);
  }, [currentChannel, joinedChannels]);

  /**
   * Rendered markup is a mixture of PubNub Chat Components (Chat, ChannelList, MessageList,
   * MessageInput, MemberList) and some elements to display additional information and to handle
   * custom behaviors (channels modal, showing/hiding panels, responsive design)
   */
  return (
    <div className={`app-moderated app-moderated--${theme}`}>
      {/* Be sure to wrap Chat component in PubNubProvider from pubnub-react package.
        In this case it's done in the index.tsx file */}
      {/* Current uuid is passed to channels prop to subscribe and listen to User metadata changes */}
      <Chat
        theme={theme}
        users={allUsers}
        currentChannel={currentChannel.id}
        channels={[...joinedChannels.map((c) => c.id), uuid]}
        onError={handleError}
        onMembership={(e) => refreshMemberships(e)}
        onMessage={(m) => handleMessage(m)}
      >
        {showPublicChannelsModal && (
          <PublicChannelsModal
            {...{
              groupChannelsToJoin,
              hideModal: () => setShowPublicChannelsModal(false),
              setCurrentChannel,
            }}
          />
        )}
        {showCreateChatModal && (
          <CreateChatModal
            {...{
              currentUser,
              hideModal: () => setShowCreateChatModal(false),
              setCurrentChannel,
              users: allUsers.filter((u) => u.id !== uuid),
            }}
          />
        )}
        {showReportUserModal && (
          <ReportUserModal
            {...{
              currentUser,
              reportedMessage,
              hideModal: () => setShowReportUserModal(false),
              users: allUsers,
            }}
          />
        )}
        {isUserBanned ? (
          <strong className="error">Unfortunately, you were banned from the chat.</strong>
        ) : (
          <>
            <div className={`channels-panel ${showChannels && "shown"}`}>
              <div className="user-info">
                {currentUser && <MemberList members={[currentUser]} selfText="" />}
                <button
                  className="mobile material-icons-outlined"
                  onClick={() => setShowChannels(false)}
                >
                  close
                </button>
              </div>

              {/* <div className="theme-switcher">
                <i className="material-icons-outlined">brightness_4</i>
                <button
                  className={theme}
                  onClick={() => setTheme(theme === "light" ? "dark" : "light")}
                >
                  <span></span>
                </button>
              </div> */}

              <div className="filter-input">
                <input
                  onChange={(e) => setChannelsFilter(e.target.value)}
                  placeholder="Search in..."
                  type="text"
                  value={channelsFilter}
                />
                <i className="material-icons-outlined">search</i>
              </div>

              <div className="channel-lists">
                {/* <h2>
                  Channels{" "}
                  <button
                    className="material-icons-outlined"
                    onClick={() => setShowPublicChannelsModal(true)}
                  >
                    add_circle_outline
                  </button>
                </h2>
                <div>
                  <ChannelList
                    channels={groupChannels}
                    onChannelSwitched={(channel) => setCurrentChannel(channel)}
                    extraActionsRenderer={(c) => (
                      <div onClick={(e) => leaveChannel(c, e)} title="Leave channel">
                        <i className="material-icons-outlined small">logout</i>
                      </div>
                    )}
                  />
                </div> */}
                <h2>
                  Direct chats{" "}
                  <button
                    className="material-icons-outlined"
                    onClick={() => setShowCreateChatModal(true)}
                  >
                    add_circle_outline
                  </button>
                </h2>
                <div>
                  <ChannelList
                    channels={directChannels}
                    // sort={(a, b) => a.updated > b.updated}
                    channelRenderer={(c) => (
                      <div onClick={(e) => switchChannel(c, e)} key={c.id} className={`pn-channel ${c.id == currentChannel.id ? "pn-channel--active" : ""}`}>
                        <div className="pn-channel__title">
                          <div className={`pn-channel__name ${c.id !== currentChannel.id && messageCounts[c.id] > 0 ? "pn-channel--bold" : ""}`} data-channel-id={c.id}>
                            {c.name}
                          </div>
                          <div>
                            {c.id !== currentChannel.id && <Badge badgeContent={messageCounts[c.id]} color="error">
                            </Badge>}
                          </div>
                        </div>
                        <div>
                          <div className="pn-channel__actions">
                            <div onClick={(e) => leaveChannel(c, e)} title="Leave channel">
                              <i className="material-icons-outlined small">logout</i>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  // extraActionsRenderer={(c) => (
                  //   <div onClick={(e) => leaveChannel(c, e)} title="Leave channel">
                  //     <i className="material-icons-outlined small">logout</i>
                  //   </div>
                  // )}
                  />
                </div>
              </div>
            </div>

            <div className="chat-window">
              {currentChannel?.id && <div className="channel-info">
                <button
                  className="mobile material-icons-outlined"
                  onClick={() => setShowChannels(true)}
                >
                  arrow_back
                </button>
                <span onClick={() => setShowMembers(!showMembers)}>
                  <strong>
                    {currentChannel.name || currentChannel.id}
                    {!isUserBlocked && <i className="material-icons-outlined">arrow_right</i>}
                  </strong>
                  <p>{totalChannelMembers} members</p>
                </span>
                <hr />
              </div>}

              {isUserBlocked ? (
                <strong className="error">
                  Unfortunately, you were blocked from this channel.
                </strong>
              ) : (
                currentChannel?.id ?
                  <>
                    <MessageList
                      // onMouseMove={(ev) => setLastReadTimetoken()}
                      fetchMessages={100}
                      enableReactions={!isUserMuted}
                      reactionsPicker={isUserMuted ? undefined : <Picker theme={theme} />}
                      extraActionsRenderer={(message) =>
                        isUserMuted ? (
                          <></>
                        ) : (
                          <div
                            onClick={() => {
                              setReportedMessage(message);
                              setShowReportUserModal(true);
                            }}
                            title="Report user"
                          >
                            <i className="material-icons-outlined">campaign</i>
                          </div>
                        )
                      }
                    />
                    <TypingIndicator />
                    <hr />
                    <MessageInput
                      senderInfo
                      disabled={isUserMuted}
                      typingIndicator
                      fileUpload="all"
                      onSend={setLastReadTimetoken}
                      // onBeforeSend={(m) => setLastReadTimetoken(m)}
                      emojiPicker={<Picker theme={theme} />}
                      placeholder={isUserMuted ? "You were muted from this channel" : "Send message"}
                    />
                  </> : <strong className="no-channel">
                    Open a channel
                  </strong>
              )}
            </div>

            <div className={`members-panel ${showMembers && !isUserBlocked ? "shown" : "hidden"}`}>
              <h2>
                Members
                <button className="material-icons-outlined" onClick={() => setShowMembers(false)}>
                  close
                </button>
              </h2>
              <div className="filter-input">
                <input
                  onChange={(e) => setMembersFilter(e.target.value)}
                  placeholder="Search in members"
                  type="text"
                  value={membersFilter}
                />
                <i className="material-icons-outlined">search</i>
              </div>
              <MemberList
                members={channelMembers.filter((c) =>
                  c.name?.toLowerCase().includes(membersFilter.toLowerCase())
                )}
                presentMembers={presentUUIDs}
                extraActionsRenderer={(m) => (
                  <Link to={`/profile/${m.id}`} style={{ textDecoration: 'none' }}>
                    <div title="Go to profile">
                      <i className="material-icons-outlined small">person</i>
                    </div>
                  </Link>
                )}
              />
            </div>
          </>
        )}
      </Chat>
    </div>
  );
}
