import { IconButton, Tooltip } from '@mui/material';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Skeleton from 'react-loading-skeleton';
import React, { Component } from 'react'
import styles from './CommentStyles.module.css'
import moment from 'moment';

export default class Comment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentUserId: this.props.commentUserId,
      selfUserId: this.props.selfUserId,
      user: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selfUserId !== prevProps.selfUserId) {
      this.setState({ selfUserId: this.props.selfUserId });
    }
  }

  render() {
    const { comment, pictureUrl, firstName, lastName, position, organization,
      numberOfLikes, selfUserLikedComment, deleteComment, likeComment, unlikeComment, commentCreationTime } = this.props;
    const name = firstName + ' ' + lastName;
    return (

      <div className={styles.Comment}>
        <a className={styles.avatarHyperLink} href={'/profile/' + this.state.commentUserId}>
          {pictureUrl
            ? (<img className={styles.UserProfilePictureImage} src={pictureUrl} href={'/profile/' + this.state.commentUserId} alt={`${name}'s Profile Picture`} />)
            : (<div className={styles.UserProfilePictureImage + ' ' + styles.AvatarFill}>
              {firstName ? firstName.charAt(0).toUpperCase() : ''}{lastName ? lastName.charAt(0).toUpperCase() : ''}
            </div>)}
        </a>
        <div className={styles.UserInfoAndComment}>
          <div className={styles.UserInfo}>
            <div className={styles.UserName}>
              {firstName && lastName ? name : <Skeleton count={1} />}
            </div>
            {/* <div className={styles.UserPositionAndInstitution}>
              {position && <span>{position}</span>} {organization && <span>at {organization}</span>}
            </div> */}
          </div>
          {this.state.selfUserId === this.state.commentUserId &&
            <div className={styles.deleteCommentIcon} onClick={deleteComment}>
              <DeleteOutlineIcon sx={{ fontSize: '24px', color: 'black' }} />
            </div>
          }
          <div className={styles.commentCreationTime}>
            {moment(commentCreationTime).local().fromNow()}
          </div>
          <div className={styles.CommentBody}>
            {comment}
          </div>
        </div>
        <div className={styles.Likes}>
          {selfUserLikedComment ?
            (<Tooltip title='Unlike'>
              <IconButton className={styles.LikedIcon}>
                <Favorite sx={{ fontSize: '24px', color: '#006F82' }} onClick={unlikeComment} />
              </IconButton>
            </Tooltip>) :
            (<Tooltip title='Like'>
              <IconButton className={styles.LikeIcon}>
                <FavoriteBorder sx={{ fontSize: '24px', color: '#006F82' }} onClick={likeComment} />
              </IconButton>
            </Tooltip>)}
          <span>{numberOfLikes != 0 && numberOfLikes}</span>
        </div>
      </div>
    )
  }
}
