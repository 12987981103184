import React, { Component } from 'react'
import ProfileTalk from '../ProfileTalk/ProfileTalk'
import moment from 'moment';
import styles from './ProfileSavedTalksStyles.module.css'

export default class ProfileSavedTalks extends Component {
  render() {
    const {userTalks, type, removeFromSavedTalksCall, removeFromRsvpTalksCall, fromSavedToRsvpTalkCall} = this.props;
    userTalks.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
    const mostRecentTalks = userTalks.slice(userTalks.length-2,userTalks.length);
    return (
      <div className={styles.ProfileSavedTalks}>
      {mostRecentTalks.length === 0 ?
          <div className={styles.NoTalks}>
            {type === 'saved' ? 
            <>{'You don\'t have any talks saved.'}</>:
            <>{'You don\'t have any upcoming talks.'}</>
            }
          </div>
          :
        mostRecentTalks.map((e) => {
        const date = moment(e.startDate).format("ddd, M/D/YYYY") === moment(e.endDate).format("ddd, M/D/YYYY") ?
        moment(e.startDate).format("ddd, M/D/YYYY") :
        moment(e.startDate).format("ddd, M/D/YYYY") + ' to ' + moment(e.endDate).format("ddd, M/D/YYYY");
        const time = moment(e.startDate).format('h:mm a') + ' to ' + moment(e.endDate).format('h:mm a')
        return (
          <ProfileTalk
            eventId={e.EventId}
            hostName={e.hostName.trim()}
            institution={e.institution}
            dateTime={`${date}, ${time} ${moment.tz(moment.tz.guess()).format('z')}`}            
            title={e.title.trim()}
            venue={e.venue}
            meetingLink={e.meetingLink.trim()}
            timeZone={e.timeZone.value}
            startDate={e.startDate}
            endDate={e.endDate}
            description={e.description}
            speakerBio={e.speakerBio}
            key={e.EventId}
            thumbnailURL={e.thumbnailURL}
            userId={e.userId}
            date={
              moment(e.startDate).format("ddd, M/D/YYYY") === moment(e.endDate).format("ddd, M/D/YYYY") ?
                moment(e.startDate).format("ddd, M/D/YYYY") :
                moment(e.startDate).format("ddd, M/D/YYYY") + ' to ' + moment(e.endDate).format("ddd, M/D/YYYY")}
            time={moment(e.startDate).format('h:mm a') + ' to ' + moment(e.endDate).format('h:mm a')}
            eventType={e.eventType.label}
            activeLoc={e.activeLoc}
            keywords={e.keywords}
            liked={e.liked}   
            saved={e.saved}
            addedToCalendar={e.attending}
            type={type}
            removeFromSavedTalksCall={removeFromSavedTalksCall}
            removeFromRsvpTalksCall={removeFromRsvpTalksCall}
            fromSavedToRsvpTalkCall={fromSavedToRsvpTalkCall}
          />
        );
      })}
      </div>
      
    )
  }
}
