import React, { useRef, useEffect, useState } from "react";
import PubNub, { PubnubConfig } from "pubnub";
import { PubNubProvider } from "pubnub-react";
import ModeratedChat from "./moderated-chat";
import { Chat, MessageList, MessageInput } from "@pubnub/react-chat-components";
import "./dms.css";

const pubnubKeys = {
  publishKey: 'pub-c-42e76bcd-57cc-42fe-813d-3bacadfb1461',
  subscribeKey: 'sub-c-233f627c-b4d2-4a00-b88d-8d14d6141924',
};

const currentChannel = "Default";
const theme = "light";

function DMs(props) {
  const [pubnub, setPubNub] = useState(null);

  useEffect(() => {
    if (props.userId) {
      const newPubNub = new PubNub({
        ...pubnubKeys,
        uuid: props.userId,
        fileUploadPublishRetryLimit: 0,
      });
      setPubNub(newPubNub);
    }
  }, [props.userId]);

  return (
    <>
      {pubnub ? (
        <PubNubProvider client={pubnub}>
          <ModeratedChat
            messageCounts={props.messageCounts}
            setMessageCounts={props.setMessageCounts}
            isMobile={props.isMobile}
          />
        </PubNubProvider>
      ) : (
        <></>
      )}
    </>
  );
}

export default DMs;
